import React, { useState } from 'react';
import chevronDown from '/src/assets/icons/chevron_down.svg';
import { useNavigate } from 'react-router-dom';

interface MenuItemProps {
  data: {
    imgSrc: string;
    title: string;
    url: string;
    subMenu: {
      title: string;
      url: string;
    }[];
  };
}

const MenuItem: React.FC<MenuItemProps> = ({ data }) => {
  const { imgSrc, title, url, subMenu } = data;
  const navigate = useNavigate();
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  return (
    <div className={'menu-item-wrapper-container'}>
      <div className="menu-item-container">
        <div className="menu-item-row-container">
          <button
            className="menu-item-icon-text"
            onClick={() => {
              if (url === '/terms') {
                navigate('/terms/service');
              } else {
                navigate(url);
              }
            }}
          >
            <img src={imgSrc} alt={title} />
            <p className={'text-base'}>{title}</p>
          </button>
          {subMenu.length > 0 && (
            <button
              style={{ width: '24px', height: '24px' }}
              onClick={() => setSubMenuOpen(!subMenuOpen)}
            >
              <img
                src={chevronDown}
                alt={'chevron'}
                className={`chevron ${subMenuOpen ? 'up' : 'down'}`}
              />
            </button>
          )}
        </div>
        {subMenuOpen && (
          <div className="sub-menu-item-row-container">
            {subMenu.map((menu, i) => {
              let targetUrl = `${url}/${menu.url}`;
              if (url === '/terms') {
                targetUrl = `${url}/${menu.url}`;
              }
              return (
                <a
                  onClick={() => {
                    navigate(targetUrl);
                  }}
                  className={'text-regular pointer'}
                  key={i}
                >
                  {menu.title}
                </a>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuItem;
