import FileInput from '@src/components/FileInput.tsx';
import {
  API_URLS_ADMIN,
  CERTIFICATES_ISSUED_MENU,
  COLORS,
  FILE_ICONS,
} from '@src/util/constants.ts';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import RadioButton from '@src/components/RadioButton.tsx';
import {
  formatDateString,
  generateOptions,
  getFileExtension,
  isEmpty,
} from '@src/util/functions.ts';
import Input from '@src/components/Input.tsx';
import Icon from '@src/components/Icon.tsx';
import Snackbar from '@src/components/SnackBar.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useCertificateAdmin } from '@src/util/adminHooks.ts';
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  IApiError,
  IApiPOSTSuccess,
  ICertificatesPatchApiParams,
} from '@src/util/interfaces.ts';
import apiAdmin from '@src/util/apiAdmin.ts';
import moment from 'moment/moment';
import { AxiosRequestConfig } from 'axios';
import { z } from 'zod';
import Button from '@src/components/Button.tsx';
import BottomButtonGroup from '@src/components/BottomButtonGroup.tsx';

const CertificatesDetailAdminForm = z.object({
  status: z.string(),
  memo: z.string(),
});

const CertificatesDetailAdminFormServer = CertificatesDetailAdminForm.extend({
  id: z.number(),
  complete_date: z.string().optional(),
});

type TCertificatesDetailAdminForm = z.infer<typeof CertificatesDetailAdminForm>;
type TCertificatesDetailAdminFormServer = z.infer<
  typeof CertificatesDetailAdminFormServer
>;

const CertificatesDetailAdminContent = () => {
  const { id = '' } = useParams<{
    id: string;
  }>();
  const { data } = useCertificateAdmin(null, null, '', 'user_name', null);
  const navigate = useNavigate();
  const flattenedData = data?.pages
    .flatMap((page) => page.data)
    .filter((item) => item.id === parseInt(id))[0];

  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = useState<string>('');
  const [snackBarType, setSnackBarType] = useState<'info' | 'error'>('info');
  const queryClient = useQueryClient();

  const { control, register, handleSubmit } =
    useForm<TCertificatesDetailAdminForm>({
      resolver: zodResolver(CertificatesDetailAdminForm),
      defaultValues: {
        status: flattenedData?.status,
        memo: flattenedData?.memo,
      },
    });

  const { mutate, isPending } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TCertificatesDetailAdminFormServer
  >({
    mutationFn: (formData) => {
      return apiAdmin.patch(
        API_URLS_ADMIN.CERTIFICATES + `/${formData.id}`,
        formData,
      );
    },
  });

  const onSubmit: SubmitHandler<TCertificatesDetailAdminForm> = (data) => {
    const formData: ICertificatesPatchApiParams = {
      status: data.status,
      memo: data.memo,
      id: parseInt(id),
      complete_date:
        data.status === '발급완료' ? moment().format('YYYY-MM-DD') : '',
    };

    if (
      data.status === '발급완료' &&
      (flattenedData === undefined || isEmpty(flattenedData.download_url))
    ) {
      setSnackBarType('error');
      setSnackBarContent('파일을 먼저 업로드 해주세요.');
      setSnackBarOpen(true);
      setTimeout(() => {
        setSnackBarOpen(false);
      }, 3000);
      return;
    }

    mutate(formData, {
      onSuccess: (response) => {
        console.log(response);
        queryClient.invalidateQueries({ queryKey: ['certificates'] });
        setSnackBarType('info');
        setSnackBarContent('저장 되었습니다.');
        setSnackBarOpen(true);
        setTimeout(() => {
          setSnackBarOpen(false);
        }, 3000);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const handleFilesSelected = (files: FileList | null) => {
    if (files) {
      if (files.length > 1 || files[0].type !== 'application/pdf') {
        setSnackBarType('error');
        setSnackBarContent('pdf형식의 파일 1개만 업로드 가능합니다.');
        setSnackBarOpen(true);
        setTimeout(() => {
          setSnackBarOpen(false);
        }, 3000);
      } else {
        uploadFiles(files)
          .then((data) => {
            console.log(data);
            queryClient.invalidateQueries({ queryKey: ['certificates'] });
            setSnackBarType('info');
            setSnackBarContent('파일이 업로드 되었습니다.');
            setSnackBarOpen(true);
            setTimeout(() => {
              setSnackBarOpen(false);
            }, 3000);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const uploadFiles = async (files: FileList) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('document_id', id);
      const axiosConfig: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      apiAdmin
        .post(
          API_URLS_ADMIN.CERTIFICATES_UPLOAD + `/${id}`,
          formData,
          axiosConfig,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const renderAdditionalData = (type: string) => {
    if (
      ['원천징수영수증(근로소득)', '원천징수영수증(사업소득)'].includes(type)
    ) {
      return (
        <>
          <div className={'certificate-detail-admin-info-container'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              대상자
            </p>
            <p className={'text-regular'}>
              {flattenedData.employee_name.trim() === ''
                ? '-'
                : flattenedData.employee_name}
            </p>
          </div>
          <div className={'certificate-detail-admin-info-container'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              생년월일
            </p>
            <p className={'text-regular'}>
              {flattenedData.employee_birth.trim() === ''
                ? '-'
                : flattenedData.employee_birth}
            </p>
          </div>
          <div className={'certificate-detail-admin-info-container'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              요청연도
            </p>
            <p className={'text-regular'}>
              {flattenedData.year.trim() === ''
                ? '-'
                : `${flattenedData.year}년`}
            </p>
          </div>
        </>
      );
    } else if ('재무제표확인원' === type) {
      return (
        <>
          <div className={'certificate-detail-admin-info-container'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              요청연도
            </p>
            <p className={'text-regular'}>
              {flattenedData.year.trim() === ''
                ? '-'
                : `${flattenedData.year}년`}
            </p>
          </div>
          <div className={'certificate-detail-admin-info-container'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              요청문서
            </p>
            <div className={'flex-column-flex-start-stretch'}>
              {flattenedData.is_financial_statement && (
                <p className={'text-regular'}>재무상태표</p>
              )}
              {flattenedData.is_income_statement && (
                <p className={'text-regular'}>손익계산서</p>
              )}
              {flattenedData.is_bill_of_materials && (
                <p className={'text-regular'}>제조원가명세서</p>
              )}
              {flattenedData.is_retained_earnings_statement && (
                <p className={'text-regular'}>이익잉여금처분계산서</p>
              )}
            </div>
          </div>
          <div className={'certificate-detail-admin-info-container'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              제출기관
            </p>
            <p className={'text-regular'}>
              {flattenedData.organization === null ||
              flattenedData.organization === ''
                ? '-'
                : flattenedData.organization}
            </p>
          </div>
          <div className={'certificate-detail-admin-info-container'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              제출목적
            </p>
            <p className={'text-regular'}>
              {flattenedData.purpose === null || flattenedData.purpose === ''
                ? '-'
                : flattenedData.purpose}
            </p>
          </div>
        </>
      );
    } else if ('중소기업기준검토표' === type) {
      return (
        <>
          <div className={'certificate-detail-admin-info-container'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              요청연도
            </p>
            <p className={'text-regular'}>
              {flattenedData.year.trim() === ''
                ? '-'
                : `${flattenedData.year}년`}
            </p>
          </div>
        </>
      );
    } else if ('갑근세원천징수확인서' === type) {
      return (
        <>
          <div className={'certificate-detail-admin-info-container'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              대상자
            </p>
            <p className={'text-regular'}>
              {flattenedData.employee_name.trim() === ''
                ? '-'
                : flattenedData.employee_name}
            </p>
          </div>
          <div className={'certificate-detail-admin-info-container'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              생년월일
            </p>
            <p className={'text-regular'}>
              {flattenedData.employee_birth.trim() === ''
                ? '-'
                : flattenedData.employee_birth}
            </p>
          </div>
          <div className={'certificate-detail-admin-info-container'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              제출기관
            </p>
            <p className={'text-regular'}>
              {flattenedData.organization === null ||
              flattenedData.organization === ''
                ? '-'
                : flattenedData.organization}
            </p>
          </div>
          <div className={'certificate-detail-admin-info-container'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              제출목적
            </p>
            <p className={'text-regular'}>
              {flattenedData.purpose === null || flattenedData.purpose === ''
                ? '-'
                : flattenedData.purpose}
            </p>
          </div>
        </>
      );
    } else if ('국세완납증명' === type) {
      return <></>;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={'flex-column-flex-start-stretch'}
          style={{ gap: '32px', margin: '0 48px 48px 48px' }}
        >
          <p className={'text-md'}>증명서발급 상세</p>
          <div className={'flex-row'} style={{ gap: '60px' }}>
            {flattenedData.download_url !== '' ? (
              <div
                className={'flex-column-flex-start-stretch'}
                style={{ alignSelf: 'stretch', gap: '12px' }}
              >
                <embed
                  src={flattenedData.download_url}
                  style={{
                    minWidth: '383px',
                    height: '100%',
                    maxHeight: '640px',
                    boxSizing: 'border-box',
                  }}
                />
                <div
                  className={'flex-row-space-between-center'}
                  style={{ gap: '12px' }}
                >
                  <FileInput
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      backgroundColor: COLORS['primary'],
                    }}
                    text={'증명서 업로드'}
                    textStyle={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '16px',
                      color: COLORS['white'],
                    }}
                    iconDisplay={false}
                    onFilesSelected={handleFilesSelected}
                  />
                </div>
              </div>
            ) : (
              <>
                <FileInput
                  style={{
                    minWidth: '400px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    backgroundColor: COLORS['primary'],
                  }}
                  text={'증명서 업로드'}
                  textStyle={{
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    color: COLORS['white'],
                  }}
                  iconDisplay={false}
                  onFilesSelected={handleFilesSelected}
                />
              </>
            )}
            <div className={'certificate-detail-admin-info-list-container'}>
              <div className={'certificate-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  소속지점
                </p>
                <p className={'text-regular'}>
                  {flattenedData.company?.admin?.branch}
                </p>
              </div>
              <div className={'certificate-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  사업자명
                </p>
                <p className={'text-regular'}>{flattenedData.company.name}</p>
              </div>
              <div className={'certificate-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  사업자등록번호
                </p>
                <p className={'text-regular'}>
                  {flattenedData.company.resident_number}
                </p>
              </div>
              <div className={'certificate-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  상태
                </p>
                <Controller
                  name={'status'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioButton
                      name={'status'}
                      options={generateOptions(
                        Object.values(CERTIFICATES_ISSUED_MENU).filter(
                          (item) => item !== '전체',
                        ),
                        false,
                      )}
                      defaultValue={value}
                      onChange={onChange}
                      containerStyle={{ gap: '12px' }}
                      labelDivContainer={{ padding: '0' }}
                    />
                  )}
                />
              </div>

              <div className={'certificate-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  증명서 제목
                </p>
                <p className={'text-regular'}>{flattenedData.type}</p>
              </div>
              <div className={'certificate-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  요청일
                </p>
                <p className={'text-regular'}>
                  {formatDateString(flattenedData.request_date)}
                </p>
              </div>
              <div className={'certificate-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  발급일
                </p>
                <p className={'text-regular'}>
                  {formatDateString(flattenedData.complete_date).trim() === ''
                    ? '-'
                    : formatDateString(flattenedData.complete_date)}
                </p>
              </div>
              <div className={'certificate-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  비고
                </p>
                <Input
                  register={register('memo')}
                  isLoading={false}
                  inputMode={'text'}
                  style={{ minWidth: '400px' }}
                />
              </div>
              <div className={'certificate-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  업로드
                </p>
                <div className={'flex-row-center'} style={{ gap: '8px' }}>
                  <Icon
                    iconSrc={
                      FILE_ICONS[
                        getFileExtension(
                          flattenedData.download_url,
                        ) as keyof typeof FILE_ICONS
                      ]
                    }
                    style={{
                      width: '20px',
                      height: '20px',
                      cursor: 'default',
                    }}
                    imgStyle={{ width: '20px', height: '20px' }}
                  />
                  <p className={'text-regular'}>
                    {decodeURI(
                      flattenedData.download_url.split('/').pop() ?? '',
                    )}
                  </p>
                </div>
              </div>
              {renderAdditionalData(flattenedData.type)}

              <BottomButtonGroup
                left={
                  <Button
                    text={'목록으로'}
                    type={'button'}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                }
                right={
                  <Button
                    text={'저장하기'}
                    type={'submit'}
                    isLoading={isPending}
                  />
                }
              />
            </div>
          </div>
        </div>
      </form>
      <Snackbar
        message={snackBarContent}
        open={snackBarOpen}
        type={snackBarType}
      />
    </>
  );
};

export default CertificatesDetailAdminContent;
