import React from 'react';

interface TailSpinnerProps {
  type?: 'button' | 'full-page' | 'button-primary-color';
  style?: React.CSSProperties;
}

const TailSpinner: React.FC<TailSpinnerProps> = ({
  type = 'button',
  style,
}) => {
  if (type === 'full-page') {
    return (
      <div className={'center-layout'}>
        <div className={'tail-spinner full-page'} style={style}></div>
      </div>
    );
  } else {
    return <div className={`tail-spinner ${type}`} style={style}></div>;
  }
};

export default TailSpinner;
