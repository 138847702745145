import { FC } from 'react';
import CustomDrawer from '@src/components/CustomDrawer.tsx';
import { Dispatch, SetStateAction } from 'react';
import { TRegisterCompanyRequest } from '@src/pages/auth/RegisterCompany.tsx';

interface RegisterCompanyInfoDrawerProps {
  drawerOpen: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  companyInfo: TRegisterCompanyRequest | null;
  registerCompany: () => void;
  isLoading: boolean;
}

const RegisterCompanyInfoDrawer: FC<RegisterCompanyInfoDrawerProps> = ({
  drawerOpen,
  setDrawerOpen,
  companyInfo,
  registerCompany,
  isLoading,
}) => {
  return (
    <CustomDrawer
      open={drawerOpen}
      setOpen={setDrawerOpen}
      cancelButton={'취소'}
      onCancel={() => setDrawerOpen(false)}
      okButton={'추가'}
      isLoading={isLoading}
      onOk={() => {
        if (companyInfo) {
          registerCompany();
        }
      }}
    >
      <div className={'register-company-info-container'}>
        <div className={'register-company-info-row'}>
          <p className={'text-regular-bold'} style={{ width: '120px' }}>
            사업체명
          </p>
          <p className={'text-regular'}>{companyInfo?.name}</p>
        </div>
        <div className={'register-company-info-row'}>
          <p className={'text-regular-bold'} style={{ width: '120px' }}>
            사업자등록번호
          </p>
          <p className={'text-regular'}>{companyInfo?.resident_number}</p>
        </div>
        <div className={'register-company-info-row'}>
          <p className={'text-regular-bold'} style={{ width: '120px' }}>
            과세 유형
          </p>
          <p className={'text-regular'}>
            {companyInfo?.tax_type === 'TAXABLE' ? '과세' : '면세'}
          </p>
        </div>
      </div>
    </CustomDrawer>
  );
};

export default RegisterCompanyInfoDrawer;
