import React, { useState } from 'react';
import Container from '@src/layouts/Container';
import DetailHeader from '@src/components/DetailHeader.tsx';
import SideBar from '@src/components/SideBar.tsx';
import ExpectedPaymentDetailContent from '@src/pages/reports/ExpectedPaymentDetailContent.tsx';
import { useIsMobile } from '@src/util/hooks.ts';
import Header from '@src/components/Header.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import HeaderTabs from '@src/components/HeaderTabs.tsx';
import {
  EXPECTED_PAYMENT_DETAIL_MENU,
  EXPECTED_PAYMENT_DETAIL_MENU_LIST,
} from '@src/util/constants.ts';

interface ExpectedPaymentDetailProps {}

const ExpectedPaymentDetail: React.FC<ExpectedPaymentDetailProps> = () => {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState<boolean>(false);
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    EXPECTED_PAYMENT_DETAIL_MENU.PAYMENT,
  );

  return (
    <Container
      header={
        isMobile ? (
          <DetailHeader title={'입금 예정'} />
        ) : (
          <Header setOpen={setOpen} />
        )
      }
      headerTab={
        !isMobile && (
          <HeaderTabs
            menus={EXPECTED_PAYMENT_DETAIL_MENU_LIST}
            activeHeaderTab={activeHeaderTab}
            setActiveHeaderTab={(value) => {
              setActiveHeaderTab(value);
            }}
          />
        )
      }
      sideBar={<SideBar />}
      modal={<CompanyDrawer open={open} setOpen={setOpen} />}
    >
      <ExpectedPaymentDetailContent />
    </Container>
  );
};

export default ExpectedPaymentDetail;
