import React, { useState } from 'react';
import InputLabel from '@src/components/InputLabel.tsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import RadioButton from '@src/components/RadioButton.tsx';
import Select from '@src/components/Select.tsx';
import Modal from '@src/components/Modal.tsx';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@src/util/store.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { IApiError, IApiPOSTSuccess } from '@src/util/interfaces.ts';
import {
  generateLastYearsOptions,
  generateTxnrmStrtYm,
  objectToQueryString,
} from '@src/util/functions.ts';
import api from '@src/util/api.ts';
import {
  API_URL,
  cvaDcumSbmsOrgnClCd,
  cvaDcumSbmsOrgnClCdOptions,
  cvaDcumUseUsgCd,
  cvaDcumUseUsgCdOptions,
  monthCode,
  monthCodeOptions,
  taxCode,
  taxCodeOptions,
} from '@src/util/constants.ts';
import { z } from 'zod';
import moment from 'moment';
import CertificatesDescription from '@src/pages/certificates/CertificatesDescription';
import Button from '@src/components/Button.tsx';
import { useIsMobile } from '@src/util/hooks.ts';
import BottomWrapper from '@src/components/BottomWrapper.tsx';

interface CertificateFormContent5Props {}

/**
 *  납부내역증명 (납세사실증명)
 *    발급유형: englCvaAplnYn (한글증명 - N, 영문증명 - Y)
 *    세목: itrfCd (코드로 보내야함)
 *    수납기간시작: txnrmStrtYm(YYYMM) -- 당해연도부터 20년, select에서 month선택가능
 *    수납기간끝: txnrmEndYm(YYYMM) -- 당해연도부터 20년, select에서 month선택가능
 *    사용용도: cvaDcumUseUsgCd
 *    제출처: cvaDcumSbmsOrgnClCd
 *    주민등록번호 공개여부: resnoOpYn (비공개 - N, 공개 - Y)
 *
 */
const CertificateForm5 = z.object({
  englCvaAplnYn: z.enum(['N', 'Y']),
  itrfCd: z.object({
    value: z.enum(taxCode),
    label: z.string(),
  }),
  cvaDcumUseUsgCd: z.object({
    value: z.enum(cvaDcumUseUsgCd),
    label: z.string(),
  }),
  cvaDcumSbmsOrgnClCd: z.object({
    value: z.enum(cvaDcumSbmsOrgnClCd),
    label: z.string(),
  }),
  taxTermStartYear: z.object({
    label: z.string(),
    value: z.enum(generateTxnrmStrtYm(moment().year(), 20)),
  }),
  taxTermStartMonth: z.object({
    label: z.string(),
    value: z.enum(monthCode),
  }),
  taxTermEndYear: z.object({
    label: z.string(),
    value: z.enum(generateTxnrmStrtYm(moment().year(), 20)),
  }),
  taxTermEndMonth: z.object({
    label: z.string(),
    value: z.enum(monthCode),
  }),
  resnoOpYn: z.enum(['Y', 'N']),
});

const CertificateForm5Server = CertificateForm5.omit({
  cvaDcumUseUsgCd: true,
  cvaDcumSbmsOrgnClCd: true,
  taxTermStartYear: true,
  taxTermStartMonth: true,
  taxTermEndYear: true,
  taxTermEndMonth: true,
  itrfCd: true,
}).extend({
  company_id: z.number(),
  cvaDcumUseUsgCd: z.string(),
  cvaDcumSbmsOrgnClCd: z.string(),
  txnrmStrtYm: z.string(),
  txnrmEndYm: z.string(),
  itrfCd: z.string(),
});

type TCertificateForm1 = z.infer<typeof CertificateForm5>;
type TCertificateForm2Server = z.infer<typeof CertificateForm5Server>;
const CertificateFormContent5: React.FC<CertificateFormContent5Props> = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const isMobile = useIsMobile();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TCertificateForm1>({
    resolver: zodResolver(CertificateForm5),
    mode: 'onChange',
    defaultValues: {
      itrfCd: { label: '전체', value: 'all' },
      englCvaAplnYn: 'N',
      cvaDcumUseUsgCd: { label: '기타', value: '기타' },
      cvaDcumSbmsOrgnClCd: { label: '기타', value: '기타' },
      resnoOpYn: 'N',
    },
  });

  const { mutate, isSuccess, error, isPending } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TCertificateForm2Server
  >({
    mutationFn: (formData) => {
      const queryParams = objectToQueryString(formData);

      return api.post(
        API_URL + `/v1/communication/document?${queryParams}`,
        formData,
      );
    },
  });

  const onSubmit: SubmitHandler<TCertificateForm1> = (data) => {
    const txnrmStrtYm = `${data.taxTermStartYear.value}${data.taxTermStartMonth.value}`;
    const txnrmEndYm = `${data.taxTermEndYear.value}${data.taxTermEndMonth.value}`;

    const formData = {
      company_id: user?.selectedCompany?.id ?? 0,
      type: '납부내역증명',
      englCvaAplnYn: data.englCvaAplnYn,
      cvaDcumSbmsOrgnClCd: data.cvaDcumSbmsOrgnClCd.value,
      cvaDcumUseUsgCd: data.cvaDcumUseUsgCd.value,
      txnrmStrtYm: txnrmStrtYm,
      txnrmEndYm: txnrmEndYm,
      resnoOpYn: data.resnoOpYn,
      itrfCd: data.itrfCd.value,
    };
    mutate(formData, {
      onSuccess: (response) => {
        setModalOpen(true);
        console.log(response);
      },
      onError: (error) => {
        setModalOpen(true);
        console.log(error);
      },
    });
  };

  return (
    <>
      <form
        className="certificate-form-content"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CertificatesDescription
          title={'납부내역증명은 세금 납부 사실을 증명하는 서류입니다.'}
          description={
            '홈택스에서 세금 납부를 했음에도 납부내역증명에 조회되지 않는 경우에는 담당자에게 문의하시기 바랍니다.'
          }
        />
        <InputLabel text={'발급유형'}>
          <Controller
            name={'englCvaAplnYn'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioButton
                name={'englCvaAplnYn'}
                options={[
                  { label: '한글증명', value: 'N' },
                  { label: '영문증명', value: 'Y' },
                ]}
                defaultValue={value}
                onChange={onChange}
              />
            )}
          />
        </InputLabel>
        <InputLabel
          text="세목"
          required={true}
          error={
            errors.itrfCd && (
              <div className="error-message">세목을 선택해 주세요.</div>
            )
          }
        >
          <Controller
            name="itrfCd"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  name="itrfCd"
                  options={taxCodeOptions}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </InputLabel>
        <InputLabel
          text={'과세기간'}
          required={true}
          description={
            '2023년 이후분에 대한 간이과세자의 과제표준증명원은 과세기간을 “1기”로 선택하시기 바랍니다.'
          }
          error={
            (errors.taxTermStartYear ||
              errors.taxTermStartMonth ||
              errors.taxTermEndYear ||
              errors.taxTermEndMonth) && (
              <div className="error-message">과세기간을 입력해 주세요.</div>
            )
          }
        >
          <div className="flex-row-space-between-center">
            <div style={{ minWidth: !isMobile ? '120px' : '' }}>
              <Controller
                name="taxTermStartYear"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      name="taxTermStartYear"
                      options={generateLastYearsOptions(moment().year(), 20)}
                      onChange={onChange}
                      value={value}
                    />
                  );
                }}
              />
            </div>
            <p className="text-regular" style={{ lineHeight: '22px' }}>
              년
            </p>
            <div style={{ minWidth: !isMobile ? '120px' : '' }}>
              <Controller
                name="taxTermStartMonth"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      name="taxTermStartMonth"
                      options={monthCodeOptions}
                      onChange={onChange}
                      value={value}
                    />
                  );
                }}
              />
            </div>

            <p className="text-regular" style={{ lineHeight: '22px' }}>
              ~
            </p>
            <div style={{ minWidth: !isMobile ? '120px' : '' }}>
              <Controller
                name="taxTermEndYear"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      name="taxTermEndYear"
                      options={generateLastYearsOptions(moment().year(), 20)}
                      onChange={onChange}
                      value={value}
                    />
                  );
                }}
              />
            </div>

            <p className="text-regular" style={{ lineHeight: '22px' }}>
              년
            </p>
            <div style={{ minWidth: !isMobile ? '120px' : '' }}>
              <Controller
                name="taxTermEndMonth"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      name="taxTermEndMonth"
                      options={monthCodeOptions}
                      onChange={onChange}
                      value={value}
                    />
                  );
                }}
              />
            </div>
          </div>
        </InputLabel>
        <InputLabel text="사용용도">
          <Controller
            name="cvaDcumUseUsgCd"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  name="cvaDcumUseUsgCd"
                  options={cvaDcumUseUsgCdOptions}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </InputLabel>
        <InputLabel text="제출처">
          <Controller
            name="cvaDcumSbmsOrgnClCd"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  name="cvaDcumSbmsOrgnClCd"
                  options={cvaDcumSbmsOrgnClCdOptions}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </InputLabel>

        <InputLabel
          text={'주민등록번호 공개여부'}
          description={'비공개시 출력 예 : 881111-*******'}
        >
          <Controller
            name={'resnoOpYn'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioButton
                name={'resnoOpYn'}
                options={[
                  { label: '비공개', value: 'N' },
                  { label: '공개', value: 'Y' },
                ]}
                defaultValue={value}
                onChange={onChange}
              />
            )}
          />
        </InputLabel>

        <BottomWrapper>
          <Button text={'신청하기'} isLoading={isPending} type={'submit'} />
        </BottomWrapper>
      </form>
      <Modal
        isOpen={isModalOpen}
        onOk={() => {
          setModalOpen(false);
          if (isSuccess) {
            navigate('/certificates/issued', { replace: true });
          } else {
            navigate(-1);
          }
        }}
        title={isSuccess ? '증명서 발급 성공' : '증명서 발급 실패'}
        content={
          isSuccess
            ? '증명서 발급이 완료되었습니다. \n 증명서 발급 내역 메뉴에서 다운로드 가능합니다.'
            : error?.response.data.message ?? ''
        }
        okButton={'확인'}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};
export default CertificateFormContent5;
