import AdminSideBar from '@src/components/AdminSideBar.tsx';
import Container from '@src/layouts/Container.tsx';
import ReceiptDetailAdminContent from '@src/pages/admin/receipt/ReceiptDetailAdminContent.tsx';

const ReceiptDetailAdmin = () => {
  return (
    <Container sideBar={<AdminSideBar />}>
      <ReceiptDetailAdminContent />
    </Container>
  );
};

export default ReceiptDetailAdmin;
