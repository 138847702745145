import styled from '@emotion/styled';

const StyledBottomWrapper = styled.div`
  @media screen and (min-width: 481px) {
    @include flex(column, flex-start, center);
    padding: 0;
  }

  @media screen and (max-width: 480px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  margin-top: 100px;
  margin-bottom: 16px;
  padding: 0 16px;
`;

interface BottomWrapperProps {
  children: React.ReactNode;
}

const BottomWrapper: React.FC<BottomWrapperProps> = ({ children }) => {
  return <StyledBottomWrapper>{children}</StyledBottomWrapper>;
};

export default BottomWrapper;
