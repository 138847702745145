import React, { useCallback, useState } from 'react';
import Container from '@src/layouts/Container';
import {
  API_URLS,
  LABOR_MENU,
  LABOR_MENU_LIST,
  URLS,
} from '@src/util/constants.ts';
import { useNavigate } from 'react-router-dom';
import DetailHeader from '@src/components/DetailHeader';
import HeaderTabs from '@src/components/HeaderTabs';
import { useIsMobile } from '@src/util/hooks.ts';
import { createQuestionStore, useUserStore } from '@src/util/store';
import { useMutation } from '@tanstack/react-query';
import api from '@src/util/api';
import CustomModal from '@src/components/CustomModal';
import SideBar from '@src/components/SideBar';
import Header from '@src/components/Header.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import BottomWrapper from '@src/components/BottomWrapper.tsx';
import Button from '@src/components/Button.tsx';

interface LaborProps {}

const RegisterQuestion: React.FC<LaborProps> = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [phone_number, set_phone_number] = useState<string>('');
  const [user_name, set_user_name] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [error, setError] = useState<string>('');

  const [user] = useUserStore((state) => [state.user]);
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    LABOR_MENU.REQUEST,
  );
  const [open, setOpen] = useState<boolean>(false);

  const company_id = user?.selectedCompany?.id || 0;
  const company_resident_number = user?.selectedCompany?.resident_number || '';
  const company_name = user?.selectedCompany?.name || '';
  const branch = user?.selectedCompany?.admin?.branch || '';

  const [question] = createQuestionStore(company_id)((state) => [
    state.question,
  ]);

  const youth_job_amount = question?.youth_amount || 0;
  const senior_intern_amount = question?.senior_amount || 0;
  const parental_leave_amount = question?.baby_amount || 0;

  const { mutate: register } = useMutation({
    mutationFn: (data: {
      company_resident_number: string;
      company_name: string;
      branch: string;
      user_name: string;
      phone_number: string;
      youth_job_amount: number;
      senior_intern_amount: number;
      parental_leave_amount: number;
    }) => api.post(API_URLS.LABOR + `/${company_id}`, { ...data }),
    onSuccess: () => {
      setModalOpen(true);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const handleClick = useCallback(() => {
    if (phone_number == '' || user_name == '') {
      setError('담당자와 연락처를 입력해주세요');
      return;
    }

    register({
      user_name,
      phone_number: phone_number.replace(/-/g, ''),
      youth_job_amount,
      senior_intern_amount,
      parental_leave_amount,
      company_name,
      company_resident_number,
      branch,
    });
  }, [phone_number, user_name]);

  return (
    <Container
      sideBar={<SideBar />}
      header={
        isMobile ? (
          <DetailHeader title={'노무지원금 신청'} />
        ) : (
          <Header setOpen={setOpen} />
        )
      }
      headerTab={
        !isMobile && (
          <HeaderTabs
            menus={LABOR_MENU_LIST}
            activeHeaderTab={activeHeaderTab}
            setActiveHeaderTab={(value) => {
              setActiveHeaderTab(value);
            }}
          />
        )
      }
      modal={
        <>
          <CompanyDrawer open={open} setOpen={setOpen} />
          <CustomModal
            onClose={() => setModalOpen(false)}
            isOpen={modalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2 className={'modal-custom-title'}>신청 완료</h2>
                    <p className={'modal-custom-content'}>
                      노무지원금 신청이 완료되었습니다.
                    </p>
                  </div>
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-button-wrapper'}>
                  <button
                    className={'custom-modal-button'}
                    onClick={() => navigate(URLS.LABOR)}
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />
        </>
      }
    >
      <div className={'labor-request-wrapper-container'}>
        <div className={'labor-request-container'}>
          <div className="labor-register-container">
            <div className={'labor-register-input-wrapper'}>
              <label>담당자</label>
              <input
                onFocus={() => setError('')}
                placeholder={'성명 입력'}
                value={user_name}
                onChange={(e) => set_user_name(e.target.value)}
              />
            </div>
            <div className={'labor-register-input-wrapper'}>
              <label>연락처</label>
              <input
                onFocus={() => setError('')}
                placeholder={'연락처 입력'}
                value={phone_number}
                onChange={(e) => set_phone_number(e.target.value)}
              />
              {error ? <p className={'error-message'}>{error}</p> : <></>}
            </div>
          </div>

          <BottomWrapper>
            <Button
              text={'전송하기'}
              onClick={() => handleClick()}
              type={'button'}
            />
          </BottomWrapper>
        </div>
      </div>
    </Container>
  );
};
export default RegisterQuestion;
