import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useUserStore } from '@src/util/store.ts';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const hasToken = Cookies.get('accessToken') || Cookies.get('refreshToken');
  const user = useUserStore((state) => state.user);

  if (!hasToken) {
    // Redirect to login if no token
    return <Navigate to="/login" replace />;
  }

  if (user?.selectedCompany === null || user?.companies?.length === 0) {
    return <Navigate to={'/register/company'} replace />;
  }

  // If token exists, render the children
  return children;
};

export default ProtectedRoute;
