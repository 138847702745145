import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface SidebarState {
  collapsed: boolean;
  activeSubMenus: string[]; // Now an array of strings
  toggleCollapsed: () => void;
  toggleSubMenu: (subMenuKey: string) => void; // Method to toggle a submenu's open/close state
}

export const useAdminSidebarStore = create<SidebarState>((set) => ({
  collapsed: false,
  activeSubMenus: [],
  toggleCollapsed: () => set((state) => ({ collapsed: !state.collapsed })),
  toggleSubMenu: (subMenuKey) =>
    set((state) => ({
      activeSubMenus: state.activeSubMenus.includes(subMenuKey)
        ? state.activeSubMenus.filter((key) => key !== subMenuKey) // Remove if it exists
        : [...state.activeSubMenus, subMenuKey], // Add if it doesn't
    })),
}));

export interface IAdmin {
  admin_id?: string;
  id?: number;
  name?: string;
  phone_number?: string;
  resident_number?: string;
  branch?: string;
}

interface IAdminState {
  admin: IAdmin | null;
  setAdmin: (admin: IAdmin) => void;
}

export const useAdminStore = create(
  persist<IAdminState>(
    (set) => ({
      admin: null,
      setAdmin: (newAdmin) =>
        set((state) => ({
          admin: { ...state.admin, ...newAdmin },
        })),
    }),
    {
      name: 'admin-storage',
    },
  ),
);
