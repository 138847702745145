import React, { useState } from 'react';
import InputLabel from '@src/components/InputLabel.tsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import Modal from '@src/components/Modal.tsx';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@src/util/store.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { IApiError, IApiPOSTSuccess } from '@src/util/interfaces.ts';
import { objectToQueryString } from '@src/util/functions.ts';
import api from '@src/util/api.ts';
import { API_URL } from '@src/util/constants.ts';
import { z } from 'zod';
import Input from '@src/components/Input.tsx';
import moment from 'moment';
import CertificatesDescription from '@src/pages/certificates/CertificatesDescription';
import Button from '@src/components/Button.tsx';
import BottomWrapper from '@src/components/BottomWrapper.tsx';

interface CertificateFormContent12Props {}

/**
 *  원천징수영수증(사업소득)
 *    대상자*: employee_name
 *    생년월일*: employee_birth
 *    요청연도*: year
 */
const currentYear = moment().year(); // Gets the current year

const CertificateForm12 = z.object({
  employee_name: z
    .string()
    .trim()
    .refine((val) => val.length > 0, {
      message: '대상자를 입력해 주세요.', // Custom message for empty input
    }),
  employee_birth: z
    .string()
    .trim()
    .refine((val) => val.length > 0, {
      message: '생년월일을 입력해 주세요.', // Custom message for empty input
    }),
  year: z
    .string()
    .trim() // Optional: trim whitespace
    .refine((val) => val.length > 0, {
      message: '과세연도를 입력해 주세요.', // Custom message for empty input
    })
    .transform((val) => parseInt(val, 10))
    .refine((val) => !isNaN(val), {
      message: '정확한 과세연도를 입력해 주세요.',
    })
    .refine((val) => val >= currentYear - 5 && val < currentYear, {
      message: `과세연도는 지난 5년간의 연도만 가능합니다. (${
        currentYear - 5
      } ~ ${currentYear - 1})`,
    }),
});

const CertificateForm12Server = CertificateForm12.extend({
  company_id: z.number(),
});

type TCertificateForm12 = z.infer<typeof CertificateForm12>;
type TCertificateForm12Server = z.infer<typeof CertificateForm12Server>;
const CertificateFormContent12: React.FC<
  CertificateFormContent12Props
> = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<TCertificateForm12>({
    resolver: zodResolver(CertificateForm12),
    mode: 'onSubmit',
  });

  const {
    mutate,
    isPending: isLoading,
    isSuccess,
    error,
  } = useMutation<IApiPOSTSuccess, IApiError, TCertificateForm12Server>({
    mutationFn: (formData) => {
      const queryParams = objectToQueryString(formData);

      return api.post(
        API_URL + `/v1/communication/document?${queryParams}`,
        formData,
      );
    },
  });

  const onSubmit: SubmitHandler<TCertificateForm12> = (data) => {
    const formData = {
      company_id: user?.selectedCompany?.id ?? 0,
      type: '원천징수영수증(사업소득)',
      employee_name: data.employee_name,
      employee_birth: data.employee_birth,
      year: data.year,
    };

    mutate(formData, {
      onSuccess: (response) => {
        setModalOpen(true);
        console.log(response);
      },
      onError: (error) => {
        setModalOpen(true);
        console.log(error);
      },
    });
  };

  return (
    <>
      <form
        className="certificate-form-content"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CertificatesDescription
          title={
            '사업소득 원천징수영수증은 프리랜서(3.3% 원천징수)에 대해 발급받을 수 있습니다.'
          }
          description={'요청 연도의 다음해 3월 1일부터 발급 가능합니다.'}
        />
        <InputLabel text={'대상자'} required={true}>
          <Input
            placeholder="성명 입력"
            register={register('employee_name')}
            type={'text'}
            isLoading={isLoading}
          />
          {errors.employee_name && (
            <div className="error-message">
              {errors.employee_name?.message?.toString()}
            </div>
          )}
        </InputLabel>
        <InputLabel text={'생년월일'} required={true}>
          <Input
            placeholder="생년월일 입력"
            register={register('employee_birth')}
            type={'text'}
            isLoading={isLoading}
          />
          {errors.employee_birth && (
            <div className="error-message">
              {errors.employee_birth?.message?.toString()}
            </div>
          )}
        </InputLabel>
        <InputLabel
          text={'요청연도'}
          required={true}
          description={
            '요청일 기준, 이전 5개 년도만 가능합니다.\n예시) 요청일이 2024.05.01 → 2023~2019년'
          }
          error={
            errors.year && (
              <div className="error-message">
                {errors.year?.message?.toString()}
              </div>
            )
          }
        >
          <div className="flex-row" style={{ gap: '10px' }}>
            <Input
              placeholder="연도를 입력하세요"
              register={register('year')}
              isLoading={isLoading}
              maxLength={4}
              inputMode={'numeric'}
              type={'number'}
            />
            <div>
              <p className="text-regular" style={{ lineHeight: '22px' }}>
                년
              </p>
            </div>
          </div>
        </InputLabel>
        <BottomWrapper>
          <Button text={'신청하기'} isLoading={isLoading} type={'submit'} />
        </BottomWrapper>
      </form>
      <Modal
        isOpen={isModalOpen}
        onOk={() => {
          setModalOpen(false);
          if (isSuccess) {
            navigate('/certificates/issued', { replace: true });
          } else {
            navigate(-1);
          }
        }}
        title={isSuccess ? '증명서 발급 요청' : '증명서 발급 실패'}
        content={
          isSuccess
            ? '증명서 발급 요청 되었습니다.'
            : error?.response.data.message ?? ''
        }
        okButton={'확인'}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};
export default CertificateFormContent12;
