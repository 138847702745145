import React, { useState } from 'react';
import Container from '@src/layouts/Container.tsx';
import DetailHeader from '@src/components/DetailHeader.tsx';
import SideBar from '@src/components/SideBar';
import { COLORS } from '@src/util/constants.ts';
import Button from '@src/components/Button.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import AdminSideBar from '@src/components/AdminSideBar.tsx';

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAdmin] = useState<boolean>(location.pathname.startsWith('/admin'));

  return (
    <Container
      header={<DetailHeader title={''} />}
      sideBar={isAdmin ? <AdminSideBar /> : <SideBar />}
    >
      <div className={'not-found-wrapper-container'}>
        <div className={'not-found-container'}>
          <div className={'not-found-title-container'}>
            <p
              className={'text-xxl'}
              style={{ color: COLORS['black'], marginTop: '50px' }}
            >
              서비스 준비중
            </p>
          </div>
          <div className={'not-found-body-container'}>
            <p
              className={'text-base'}
              style={{ fontWeight: '400', textAlign: 'center' }}
            >
              추후 서비스 제공 예정입니다.
            </p>
          </div>
          <Button
            text={'이전으로 돌아가기'}
            style={{
              width: '182px',
              backgroundColor: COLORS['white'],
              border: `1px solid ${COLORS['main']}`,
              color: COLORS['main'],
            }}
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
    </Container>
  );
};

export default NotFound;
