import '@src/scss/main.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { IBoolean, ICorpTaxData, IVatData } from '@src/util/interfaces';
import api from '@src/util/api';
import { useQuery } from '@tanstack/react-query';
import { API_URLS, TAX } from '@src/util/constants';
import { useUserStore } from '@src/util/store';
import React, { useState } from 'react';
import DetailHeader from '@src/components/DetailHeader';
import HeaderTabs from '@src/components/HeaderTabs';
import Container from '@src/layouts/Container';
import {
  findCorpTaxPeriod,
  findVatPeriod,
  formatCurrencyToKRW,
  formatDateKrType,
} from '@src/util/functions';
import SideBar from '@src/components/SideBar.tsx';
import Modal from '@src/components/Modal';
import { useIsMobile } from '@src/util/hooks.ts';
import Header from '@src/components/Header.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';

interface ExpectedTaxProps {}

const ExpectedTax: React.FC<ExpectedTaxProps> = () => {
  const [user] = useUserStore((state) => [state.user]);
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);

  const incomeTaxText =
    user?.selectedCompany?.type == 'CORP' ? '법인세' : '소득세';

  const EXPECTED_TAX_MENU_LIST = [
    { id: TAX.부가세, text: `예상 부가세`, url: '/expected-tax/additional' },
    {
      id: TAX.법인세,
      text: `예상 ${incomeTaxText}`,
      url: '/expected-tax/corp',
    },
  ];

  const isExempt: boolean = user?.selectedCompany?.tax_type == 'EXEMPT';

  if (isExempt) EXPECTED_TAX_MENU_LIST.shift();

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  const [headerTap, setHeaderTap] = useState<TAX>(
    (type as TAX) ?? (isExempt ? TAX.법인세 : TAX.부가세),
  );
  if (type !== null && type !== headerTap) {
    setHeaderTap(type as TAX);
  }

  const is_3_month_vat_period_calc = useQuery<IBoolean>({
    queryKey: [`report/vat/period-status/${user?.selectedCompany?.id}`],
    queryFn: () =>
      api.get(`${API_URLS.VAT}/period-status/${user?.selectedCompany?.id}`),
    enabled: !!user && !!user.selectedCompany && !isExempt,
    refetchOnWindowFocus: false,
    staleTime: 30 * 60 * 1000,
  });

  const { vat_start_date, vat_end_date, vat_expected_date } = findVatPeriod(
    is_3_month_vat_period_calc?.data?.data || false,
  );
  const { corp_start_date, corp_end_date, corp_expected_date } =
    findCorpTaxPeriod(user?.selectedCompany?.type || 'CORP');

  const vat = useQuery<IVatData>({
    queryKey: [`report/vat/${user?.selectedCompany?.id}`],
    queryFn: () =>
      api.get(
        `${API_URLS.VAT}/${user?.selectedCompany?.id}/${vat_start_date}/${vat_end_date}`,
      ),
    enabled:
      !!is_3_month_vat_period_calc.data &&
      !!user &&
      !!user.selectedCompany &&
      !isExempt,
    refetchOnWindowFocus: false,
    staleTime: 30 * 60 * 1000,
  });

  const incomeTax = useQuery<ICorpTaxData>({
    queryKey: [`report/corp-tax/${user?.selectedCompany?.id}`],
    queryFn: () =>
      api.get(
        `${API_URLS.CORP_TAX}/${user?.selectedCompany?.id}/${corp_start_date}/${corp_end_date}`,
      ),
    enabled: !!user && !!user.selectedCompany,
    refetchOnWindowFocus: false,
    staleTime: 30 * 60 * 1000,
  });

  const content = {
    [TAX.부가세]: (
      <React.Fragment>
        <div className={'expected-tax-title-wrapper'}>
          <h2 className={'expected-tax-title'}>
            다음 부가세 예상세액은 아래와 같습니다.
          </h2>
          <div className={'expected-tax-title-content'}>
            <div className={'expected-tax-subject-wrapper'}>
              <p className={'expected-tax-subject'}>사업자등록번호</p>
              <p className={'expected-tax-subject-data'}>
                {user?.selectedCompany?.resident_number}
              </p>
            </div>
            <div className={'expected-tax-subject-wrapper'}>
              <p className={'expected-tax-subject'}>집계기간</p>
              <p className={'expected-tax-subject-data'}>
                {formatDateKrType(vat_start_date)} ~{' '}
                {formatDateKrType(vat_end_date)}
              </p>
            </div>
            <div className={'expected-tax-subject-wrapper'}>
              <p className={'expected-tax-subject'}>납기예상일</p>
              <p className={'expected-tax-subject-data'}>
                {formatDateKrType(vat_expected_date)}
              </p>
            </div>
          </div>
        </div>
        <div className={'expected-tax-amount-wrapper'}>
          <div className={'expected-tax-amount'}>
            <div className={'expected-tax-amount-category'}>
              <p className={'category-left-text'}>매출 세액 합계</p>
              <p className={'category-right-text'}>
                {formatCurrencyToKRW(vat?.data?.data?.sales?.tot_amount ?? 0)}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>세금계산서 매출세액</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  vat?.data?.data?.sales?.tax_statement ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>현금영수증 매출세액</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(vat?.data?.data?.sales?.cash ?? 0)}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>카드 매출세액</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(vat?.data?.data?.sales?.card ?? 0)}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>커머스/배달앱 매출세액</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  (vat?.data?.data?.sales?.commerce ?? 0) +
                    (vat?.data?.data?.sales?.delivery ?? 0),
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-category'}>
              <p className={'category-left-text'}>매입 세액 합계</p>
              <p className={'category-right-text'}>
                {formatCurrencyToKRW(
                  vat?.data?.data?.purchase?.tot_amount ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>세금계산서 매입세액</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  vat?.data?.data?.purchase?.tax_statement ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>현금영수증 매입세액</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(vat?.data?.data?.purchase?.cash ?? 0)}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>카드 매입세액</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(vat?.data?.data?.purchase?.card ?? 0)}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>종이세금계산서 매입세액</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(vat?.data?.data?.purchase?.receipt ?? 0)}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>의제매입세액공제</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(vat?.data?.data?.purchase?.agenda ?? 0)}
              </p>
            </div>
            <div className={'expected-tax-amount-category'}>
              <p className={'category-left-text'}>납부 세액</p>
              <p className={'category-right-text'}>
                {formatCurrencyToKRW(vat?.data?.data?.paid_tax ?? 0)}
              </p>
            </div>
            <div
              className={'expected-tax-amount-category'}
              style={{ borderTop: 'none' }}
            >
              <p className={'category-left-text'}>공제세액 등</p>
              <p className={'category-right-text'}>
                {formatCurrencyToKRW(vat?.data?.data?.ddc_tax.tot_amount ?? 0)}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>신용카드매출전표등발행세액공제</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(vat?.data?.data?.ddc_tax?.issuance ?? 0)}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>예정고지세액 등</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(vat?.data?.data?.ddc_tax?.estimated ?? 0)}
              </p>
            </div>
            <div className={'expected-tax-amount-final'}>
              <p className={'sub-left-text'}>최종예상세액(차감납부할세액)</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(vat?.data?.data?.final_tax ?? 0)}
              </p>
            </div>
          </div>
          <div className={'expected-tax-explain'}>
            <p className={'explain-blue-text'}>
              위 부가세 예상 세액은 참고목적의 자료로 다음과 같은 이유 등으로
              실제 세액과 차이가 발생할 수 있습니다.
            </p>
            <div className={'explain-divider'} />
            <div className={'explain-content-wrapper'}>
              <p className={'explain-content-header'}>
                [자료 연동이 불완전한 경우]
              </p>
              <ul>
                <li>
                  자료 수집을 위한 데이터 연동(홈택스, 여신금융협회, 카드매입,
                  커머스/배달앱)이 일부 되어 있지 않은 경우 차이가 발생할 수
                  있습니다.
                </li>
                <li>
                  매입자납부특례, 수출기업수입분납부유예 등 특이한 사항이 있는
                  경우 차이가 발생할 수 있습니다.
                </li>
              </ul>
            </div>
            <div className={'explain-content-wrapper'}>
              <p className={'explain-content-header'}>
                [사업자 유형에 따른 차이]
              </p>
              <ul>
                <li>
                  간이과세자 : 위 예상세액 금액은 일반과세자 기준으로
                  간이과세자일 경우 차이가 발생할 수 있습니다.
                </li>
                <li>
                  과세/면세 겸영사업자 : 위 예상세액 금액은 면세비율을 고려하지
                  않은 산출내역으로 겸영사업자인 경우 차이가 발생할 수 있습니다.
                </li>
                <li>
                  기타 : 비영업용 승용차를 보유하고 있는 경우 예상세액 금액에
                  차이가 발생할 수 있습니다.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    ),
    [TAX.법인세]: (
      <React.Fragment>
        <div className={'expected-tax-title-wrapper'}>
          <h2 className={'expected-tax-title'}>
            다음 {incomeTaxText} 예상세액은 아래와 같습니다.
          </h2>
          <div className={'expected-tax-title-content'}>
            <div className={'expected-tax-subject-wrapper'}>
              <p className={'expected-tax-subject'}>사업자등록번호</p>
              <p className={'expected-tax-subject-data'}>
                {user?.selectedCompany?.resident_number}
              </p>
            </div>
            <div className={'expected-tax-subject-wrapper'}>
              <p className={'expected-tax-subject'}>집계기간</p>
              <p className={'expected-tax-subject-data'}>
                {formatDateKrType(corp_start_date)} ~{' '}
                {formatDateKrType(corp_end_date)}
              </p>
            </div>
            <div className={'expected-tax-subject-wrapper'}>
              <p className={'expected-tax-subject'}>납기예상일</p>
              <p className={'expected-tax-subject-data'}>
                {formatDateKrType(corp_expected_date)}
              </p>
            </div>
          </div>
        </div>
        <div className={'expected-tax-amount-wrapper'}>
          <div className={'expected-tax-amount'}>
            <div className={'expected-tax-amount-category'}>
              <p className={'category-left-text'}>매출액</p>
              <p className={'category-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.sales?.tot_amount ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>세금계산서 매출</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.sales?.tax_statement ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>계산서 매출</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.sales?.statement ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>현금영수증 매출</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(incomeTax?.data?.data?.sales?.cash ?? 0)}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>카드 매출</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(incomeTax?.data?.data?.sales?.card ?? 0)}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>커머스/배달앱 매출</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.sales?.commerce_and_delivery ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-category'}>
              <p className={'category-left-text'}>매입원가/판관비(인건비 외)</p>
              <p className={'category-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.purchase?.tot_amount ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>세금계산서 매입</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.purchase?.tax_statement ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>계산서 매입</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.purchase?.statement ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>현금영수증 매입</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.purchase?.cash ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>카드 매입</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.purchase?.card ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>간이영수증 매입</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.purchase?.receipt ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>재고자산 순감소액</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.purchase?.inventory_adjust ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>자산매입 조정</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.purchase?.asset_adjust ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>감가상각비</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.purchase?.depreciation ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-category'}>
              <p className={'category-left-text'}>인건비</p>
              <p className={'category-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.labor?.tot_amount ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>직원급여</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.labor?.employee ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>프리랜서</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.labor?.freelancer ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>퇴직금</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.labor?.severance ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>기타</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(incomeTax?.data?.data?.labor?.etc ?? 0)}
              </p>
            </div>
            <div className={'expected-tax-amount-category'}>
              <p className={'category-left-text'}>예상당기순이익</p>
              <p className={'category-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.estimated_net_income ?? 0,
                )}
              </p>
            </div>
            <div
              className={'expected-tax-amount-category'}
              style={{ borderTop: 'none' }}
            >
              <p className={'category-left-text'}>산출세액</p>
              <p className={'category-right-text'}>
                {formatCurrencyToKRW(incomeTax?.data?.data?.output_tax ?? 0)}
              </p>
            </div>
            <div
              className={'expected-tax-amount-category'}
              style={{ borderTop: 'none' }}
            >
              <p className={'category-left-text'}>세액공제 등</p>
              <p className={'category-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.ddc_tax.tot_amount ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>세액 감면/공제</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.ddc_tax?.tax_deduction ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-sub'}>
              <p className={'sub-left-text'}>기납부세액</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(
                  incomeTax?.data?.data?.ddc_tax?.paid_tax ?? 0,
                )}
              </p>
            </div>
            <div className={'expected-tax-amount-final'}>
              <p className={'sub-left-text'}>최종예상세액</p>
              <p className={'sub-right-text'}>
                {formatCurrencyToKRW(incomeTax?.data?.data?.final_tax ?? 0)}
              </p>
            </div>
          </div>
          <div className={'expected-tax-explain'}>
            <p className={'explain-blue-text'}>
              위 법인세(소득세) 예상 세액은 참고목적의 자료로 다음과 같은 이유
              등으로 실제 세액과 차이가 발생할 수 있습니다.
            </p>
            <div className={'explain-divider'} />
            <div className={'explain-content-wrapper'}>
              <ul>
                <li>
                  인건비는 원천세 신고된 데이터 기준으로 산출되며 신고시점에
                  따라 차이가 발생할 수 있습니다.
                </li>
                <li>
                  재고자산 금액의 증감에 의하여 차이가 발생할 수 있습니다.
                </li>
                <li>
                  당기에 매입한 금액 중 고정자산이 있을 경우 차이가 발생할 수
                  있습니다.
                </li>
                <li>세액 공제/감면 반영에 따라 차이가 발생할 수 있습니다.</li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    ),
  };

  if (!user?.role?.estimated_tax) {
    return (
      <>
        <Modal
          isOpen={true}
          onOk={() => {
            navigate(-1);
          }}
          title={'페이지 권한이 없습니다.'}
          okButton={'확인'}
          onClose={() => {}}
        />
      </>
    );
  }

  return (
    <Container
      header={
        isMobile ? (
          <DetailHeader title={'예상 세금'} />
        ) : (
          <Header setOpen={setOpen} />
        )
      }
      headerTab={
        <HeaderTabs
          menus={EXPECTED_TAX_MENU_LIST}
          activeHeaderTab={headerTap}
          setActiveHeaderTab={(value) => {
            setHeaderTap(value as TAX);
            navigate(`/expected-tax?type=${value}`, { replace: true });
          }}
        />
      }
      sideBar={<SideBar />}
      modal={<CompanyDrawer open={open} setOpen={setOpen} />}
    >
      <div className={'expected-tax-content-wrapper'}>{content[headerTap]}</div>
    </Container>
  );
};

export default ExpectedTax;
