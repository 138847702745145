import { FC } from 'react';

interface CheckListIconProps {
  fill: string;
  size: string;
}

const CheckListIcon: FC<CheckListIconProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon / checklist">
        <path
          id="Vector"
          d="M5.5248 16.175L9.0748 12.625C9.2748 12.425 9.50814 12.3292 9.7748 12.3375C10.0415 12.3458 10.2748 12.45 10.4748 12.65C10.6581 12.85 10.7498 13.0833 10.7498 13.35C10.7498 13.6167 10.6581 13.85 10.4748 14.05L6.2498 18.3C6.0498 18.5 5.81647 18.6 5.5498 18.6C5.28314 18.6 5.0498 18.5 4.8498 18.3L2.6998 16.15C2.51647 15.9667 2.4248 15.7333 2.4248 15.45C2.4248 15.1667 2.51647 14.9333 2.6998 14.75C2.88314 14.5667 3.11647 14.475 3.3998 14.475C3.68314 14.475 3.91647 14.5667 4.0998 14.75L5.5248 16.175ZM5.5248 8.17501L9.0748 4.62501C9.2748 4.42501 9.50814 4.32917 9.7748 4.33751C10.0415 4.34584 10.2748 4.45001 10.4748 4.65001C10.6581 4.85001 10.7498 5.08334 10.7498 5.35001C10.7498 5.61667 10.6581 5.85001 10.4748 6.05001L6.2498 10.3C6.0498 10.5 5.81647 10.6 5.5498 10.6C5.28314 10.6 5.0498 10.5 4.8498 10.3L2.6998 8.15001C2.51647 7.96667 2.4248 7.73334 2.4248 7.45001C2.4248 7.16667 2.51647 6.93334 2.6998 6.75001C2.88314 6.56667 3.11647 6.47501 3.3998 6.47501C3.68314 6.47501 3.91647 6.56667 4.0998 6.75001L5.5248 8.17501ZM13.9998 17C13.7165 17 13.479 16.9042 13.2873 16.7125C13.0956 16.5208 12.9998 16.2833 12.9998 16C12.9998 15.7167 13.0956 15.4792 13.2873 15.2875C13.479 15.0958 13.7165 15 13.9998 15H20.9998C21.2831 15 21.5206 15.0958 21.7123 15.2875C21.904 15.4792 21.9998 15.7167 21.9998 16C21.9998 16.2833 21.904 16.5208 21.7123 16.7125C21.5206 16.9042 21.2831 17 20.9998 17H13.9998ZM13.9998 9.00001C13.7165 9.00001 13.479 8.90417 13.2873 8.71251C13.0956 8.52084 12.9998 8.28334 12.9998 8.00001C12.9998 7.71667 13.0956 7.47917 13.2873 7.28751C13.479 7.09584 13.7165 7.00001 13.9998 7.00001H20.9998C21.2831 7.00001 21.5206 7.09584 21.7123 7.28751C21.904 7.47917 21.9998 7.71667 21.9998 8.00001C21.9998 8.28334 21.904 8.52084 21.7123 8.71251C21.5206 8.90417 21.2831 9.00001 20.9998 9.00001H13.9998Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default CheckListIcon;
