import ReportCard from '@src/pages/reports/ReportCard.tsx';
import ReportCardHeader from '@src/pages/reports/ReportCardHeader.tsx';
import { REPORT_CATEGORY, REPORT_TERM } from '@src/util/constants.ts';
import ReportCardStatement from '@src/pages/reports/ReportCardStatement.tsx';
import ReportGraph from '@src/pages/reports/ReportGraph.tsx';
import { useState } from 'react';
import moment, { Moment } from 'moment';
import { useReportAPI } from '@src/util/hooks.ts';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '@src/util/hooks.ts';
import styled from '@emotion/styled';

const StyledReportCardPCContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: center;
  max-width: 786px;
  width: 100%;
`;

const StyledReportCardPCRow = styled.div<{
  justifyContent?: string;
  background?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent = 'space-between' }) => justifyContent};
  width: 100%;
  max-width: 786px;
  align-items: center;
  background: ${({ background = '#F7FCFF' }) => background};
`;

const ReportMonthlyContent = () => {
  const [today] = useState<Moment>(moment().subtract(1, 'days'));
  const [activeCardHeaderTab, setActiveCardHeaderTab] = useState<string>(
    REPORT_CATEGORY.SALES,
  );
  const navigate = useNavigate();
  const { graphData, dashboardData } = useReportAPI(today, REPORT_TERM.MONTHLY);

  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div className={'flex-column-flex-start-center'}>
        <ReportCard>
          <ReportCardHeader
            activeHeaderTab={REPORT_TERM.MONTHLY}
            activeCardHeaderTab={activeCardHeaderTab}
            setActiveCardHeaderTab={setActiveCardHeaderTab}
            today={today}
          />
          <ReportCardStatement
            data={dashboardData}
            activeCardHeaderTab={activeCardHeaderTab}
            activeHeaderTab={REPORT_TERM.MONTHLY}
            moreClick={() => {
              navigate(
                `/reports/additional/${REPORT_TERM.MONTHLY}/${activeCardHeaderTab}`,
              );
            }}
          />
        </ReportCard>
        <ReportGraph
          activeHeaderTab={REPORT_TERM.MONTHLY}
          activeCardHeaderTab={activeCardHeaderTab}
          today={today}
          graphData={graphData}
        />
      </div>
    );
  } else {
    return (
      <StyledReportCardPCContainer>
        <StyledReportCardPCRow>
          <ReportCard>
            <ReportCardHeader
              activeHeaderTab={REPORT_TERM.MONTHLY}
              activeCardHeaderTab={REPORT_CATEGORY.SALES}
              setActiveCardHeaderTab={null}
              today={today}
            />
            <ReportCardStatement
              data={dashboardData}
              activeHeaderTab={REPORT_TERM.MONTHLY}
              activeCardHeaderTab={REPORT_CATEGORY.SALES}
              moreClick={() => {
                navigate(
                  `/reports/additional/${REPORT_TERM.MONTHLY}/${REPORT_CATEGORY.SALES}`,
                );
              }}
            />
          </ReportCard>
          <ReportCard>
            <ReportCardHeader
              activeHeaderTab={REPORT_TERM.MONTHLY}
              activeCardHeaderTab={REPORT_CATEGORY.PURCHASE}
              setActiveCardHeaderTab={null}
              today={today}
            />
            <ReportCardStatement
              data={dashboardData}
              activeCardHeaderTab={REPORT_CATEGORY.PURCHASE}
              activeHeaderTab={REPORT_TERM.MONTHLY}
              moreClick={() => {
                navigate(
                  `/reports/additional/${REPORT_TERM.MONTHLY}/${REPORT_CATEGORY.PURCHASE}`,
                );
              }}
            />
          </ReportCard>
        </StyledReportCardPCRow>
        <StyledReportCardPCRow background={'white'}>
          <ReportGraph
            activeHeaderTab={REPORT_TERM.MONTHLY}
            activeCardHeaderTab={REPORT_CATEGORY.SALES}
            today={today}
            graphData={graphData}
            barChartStyle={{ justifyContent: 'flex-start' }}
          />
          <ReportGraph
            activeHeaderTab={REPORT_TERM.MONTHLY}
            activeCardHeaderTab={REPORT_CATEGORY.PURCHASE}
            today={today}
            graphData={graphData}
            barChartStyle={{ justifyContent: 'flex-end' }}
          />
        </StyledReportCardPCRow>
        <StyledReportCardPCRow justifyContent={'flex-start'}>
          <div
            style={{
              width: '100%',
              maxWidth: '786px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <ReportCard
              containerStyle={{ width: '393px', boxSizing: 'border-box' }}
            >
              <ReportCardHeader
                activeHeaderTab={REPORT_TERM.MONTHLY}
                activeCardHeaderTab={REPORT_CATEGORY.TOTAL}
                setActiveCardHeaderTab={null}
                today={today}
              />
              <ReportCardStatement
                data={dashboardData}
                activeHeaderTab={REPORT_TERM.MONTHLY}
                activeCardHeaderTab={REPORT_CATEGORY.TOTAL}
                moreClick={() => {
                  navigate(
                    `/reports/additional/${REPORT_TERM.MONTHLY}/${REPORT_CATEGORY.TOTAL}`,
                  );
                }}
              />
            </ReportCard>
          </div>
        </StyledReportCardPCRow>
        <StyledReportCardPCRow
          justifyContent={'flex-start'}
          background={'white'}
        >
          <ReportGraph
            activeHeaderTab={REPORT_TERM.MONTHLY}
            activeCardHeaderTab={REPORT_CATEGORY.TOTAL}
            today={today}
            graphData={graphData}
            barChartStyle={{ justifyContent: 'flex-start', maxWidth: '393px' }}
          />
        </StyledReportCardPCRow>
      </StyledReportCardPCContainer>
    );
  }
};

export default ReportMonthlyContent;
