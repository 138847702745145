import React from 'react';
import chevronLeft from '/src/assets/icons/chevron_left.svg';
import chevronRight from '/src/assets/icons/chevron_right.svg';

interface ChevronBtnProps {
  direction: 'left' | 'right';
  onClick: () => void;
}

const ChevronButton: React.FC<ChevronBtnProps> = ({ direction, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={direction === 'left' ? chevronLeft : chevronRight}
        alt="arrow-back"
      />
    </button>
  );
};

export default ChevronButton;
