import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useCompanySync } from '@src/util/adminHooks.ts';
import Select, { OptionValue } from '@src/components/Select.tsx';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ICompanySync } from '@src/util/interfaces.ts';
import { convertToKST, YNtoOX } from '@src/util/functions.ts';
import Table from '@src/components/Table.tsx';
import BranchFilterSelect from '@src/components/BranchFilterSelect.tsx';
import StaffFilterSelect from '@src/components/StaffFilterSelect.tsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  COLORS,
  receiptKeywordSearchFilterOption,
} from '@src/util/constants.ts';
import Input from '@src/components/Input.tsx';
import Icon from '@src/components/Icon.tsx';
import searchIcon from '@src/assets/icons/search_black.svg';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useAdminStore } from '@src/util/adminStore.ts';

interface AdminSyncContentProps {}

const KeywordSearchForm = z.object({
  keyword: z.string(),
  keywordSearchFilter: z.object({
    label: z.string(),
    value: z.string(),
  }),
});

type TKeywordSearchForm = z.infer<typeof KeywordSearchForm>;

const AdminSyncContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 24px;
  margin: 0 48px 48px 48px;
`;

const StyledTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
  text-align: left;
`;

const AdminSyncContent: React.FC<AdminSyncContentProps> = () => {
  const [branchFilter, setBranchFilter] = useState<OptionValue | null>({
    label: '전체',
    value: '전체',
  });

  const [staffFilter, setStaffFilter] = useState<OptionValue | null>({
    label: '전체',
    value: '전체',
  });

  const [companyTypeFilter, setCompanyTypeFilter] =
    useState<OptionValue | null>({
      label: '전체',
      value: '전체',
    });

  const [keyword, setKeyword] = useState<string>('');

  const [keywordSearchCategory, setKeywordSearchCategory] = useState<
    'name' | 'resident_number'
  >('name');

  const [hometaxIdFilter, setHometaxIdFilter] = useState<OptionValue | null>({
    label: 'ID',
    value: 'ID',
  });

  const [hometaxSignCertFilter, setHometaxSignCertFilter] =
    useState<OptionValue | null>({
      label: '인증서',
      value: '인증서',
    });

  const [financingFilter, setFinancingFilter] = useState<OptionValue | null>({
    label: '여신',
    value: '여신',
  });

  const [cardFilter, setCardFilter] = useState<OptionValue | null>({
    label: '카드',
    value: '카드',
  });

  const [naverFilter, setNaverFilter] = useState<OptionValue | null>({
    label: '네이버',
    value: '네이버',
  });

  const [coupangFilter, setCoupangFilter] = useState<OptionValue | null>({
    label: '쿠팡',
    value: '쿠팡',
  });

  const [bmFilter, setBmFilter] = useState<OptionValue | null>({
    label: '배민',
    value: '배민',
  });

  const [yoFilter, setYoFilter] = useState<OptionValue | null>({
    label: '요기요',
    value: '요기요',
  });

  const [cpeFilter, setCpeFilter] = useState<OptionValue | null>({
    label: '쿠팡이츠',
    value: '쿠팡이츠',
  });

  const { data, fetchNextPage, hasNextPage } = useCompanySync(
    branchFilter,
    keyword,
    keywordSearchCategory,
    staffFilter,
    companyTypeFilter,
    hometaxIdFilter,
    hometaxSignCertFilter,
    financingFilter,
    cardFilter,
    naverFilter,
    coupangFilter,
    bmFilter,
    yoFilter,
    cpeFilter,
  );

  const { register, control, handleSubmit } = useForm<TKeywordSearchForm>({
    resolver: zodResolver(KeywordSearchForm),
    defaultValues: {
      keywordSearchFilter: { label: '사업자명', value: '사업자명' },
    },
  });

  const [admin] = useAdminStore((state) => [state.admin]);

  const onSubmit: SubmitHandler<TKeywordSearchForm> = (data) => {
    const keywordSearchCategoryValue = data.keywordSearchFilter.value;
    if (keywordSearchCategoryValue === '사업자명') {
      setKeywordSearchCategory('name');
    } else if (keywordSearchCategoryValue === '사업자등록번호') {
      setKeywordSearchCategory('resident_number');
    }

    setKeyword(data.keyword);
  };

  const flattenedData = data.pages.flatMap((page) => page.data);

  const columnHelper = createColumnHelper<ICompanySync>();

  const OXColor = (value: string) => {
    if (value === 'O') {
      return <i style={{ color: COLORS['primary'] }}>{value}</i>;
    } else if (value === 'X') {
      return <i style={{ color: COLORS['red'] }}>{value}</i>;
    } else if (value === '△') {
      return <i style={{ color: COLORS['green'] }}>{value}</i>;
    }
  };

  const columns: Array<ColumnDef<ICompanySync, string>> = [
    columnHelper.group({
      id: 'company_group',
      header: () => (
        <div>사업자 정보</div> // Adjust the width manually
      ),
      columns: [
        columnHelper.accessor('branch', {
          id: 'branch',
          cell: (info) => {
            return <i>{info.getValue()}</i>;
          },
          header: '소속지점',
        }),
        columnHelper.accessor('admin_staff_name', {
          id: 'admin_staff_name',
          cell: (info) => {
            return <i>{info.getValue()}</i>;
          },
          header: '담당자',
        }),
        columnHelper.accessor('company_name', {
          id: 'company_name',
          cell: (info) => {
            return <i>{info.getValue()}</i>;
          },
          header: '사업자명',
        }),
        columnHelper.accessor('company_resident_number', {
          id: 'company_resident_number',
          cell: (info) => {
            return <i>{info.getValue()}</i>;
          },
          header: '사업자등록번호',
        }),
        columnHelper.accessor('company_created_at', {
          id: 'company_created_at',
          cell: (info) => {
            return <i>{convertToKST(info.getValue())}</i>;
          },
          header: '가입일',
        }),
      ],
    }),
    columnHelper.group({
      id: 'hometax',
      header: '홈택스',
      columns: [
        columnHelper.accessor('hometax_id', {
          id: 'hometax_id',
          cell: (info) => OXColor(YNtoOX(info.getValue())),
          header: () => {
            return (
              <div style={{ margin: '0 2px' }}>
                <Select
                  name={'hometaxIdFilterSelect'}
                  options={[
                    {
                      label: 'ID',
                      value: 'ID',
                    },
                    {
                      label: 'ID(O)',
                      value: 'Y',
                    },
                    {
                      label: 'ID(X)',
                      value: 'N',
                    },
                  ]}
                  onChange={(selectedOption) => {
                    setHometaxIdFilter(selectedOption);
                  }}
                  value={hometaxIdFilter}
                  size={'sm'}
                />
              </div>
            );
          },
        }),
        columnHelper.accessor('hometax_sign_cert', {
          id: 'hometax_sign_cert',
          cell: (info) => OXColor(YNtoOX(info.getValue())),
          header: () => {
            return (
              <div style={{ margin: '0 2px' }}>
                <Select
                  name={'hometaxCertFilterSelect'}
                  options={[
                    {
                      label: '인증서',
                      value: '인증서',
                    },
                    {
                      label: '인증서(O)',
                      value: 'Y',
                    },
                    {
                      label: '인증서(△)',
                      value: 'S',
                    },
                    {
                      label: '인증서(X)',
                      value: 'N',
                    },
                  ]}
                  onChange={(selectedOption) => {
                    setHometaxSignCertFilter(selectedOption);
                  }}
                  value={hometaxSignCertFilter}
                  size={'sm'}
                />
              </div>
            );
          },
        }),
      ],
    }),
    columnHelper.group({
      id: 'finance',
      header: '카드',
      columns: [
        columnHelper.accessor('financing_id', {
          id: 'financing_id',
          cell: (info) => OXColor(YNtoOX(info.getValue())),
          header: () => {
            return (
              <div style={{ margin: '0 2px' }}>
                <Select
                  name={'financingFilterSelect'}
                  options={[
                    {
                      label: '여신',
                      value: '여신',
                    },
                    {
                      label: '여신(O)',
                      value: 'Y',
                    },
                    {
                      label: '여신(X)',
                      value: 'N',
                    },
                  ]}
                  onChange={(selectedOption) => {
                    setFinancingFilter(selectedOption);
                  }}
                  value={financingFilter}
                  size={'sm'}
                />
              </div>
            );
          },
        }),
        columnHelper.accessor('card_cert', {
          id: 'card_cert',
          cell: (info) => OXColor(YNtoOX(info.getValue())),
          header: () => {
            return (
              <div style={{ margin: '0 2px' }}>
                <Select
                  name={'cardFilterSelect'}
                  options={[
                    {
                      label: '카드',
                      value: '카드',
                    },
                    {
                      label: '카드(O)',
                      value: 'Y',
                    },
                    {
                      label: '카드(X)',
                      value: 'N',
                    },
                  ]}
                  onChange={(selectedOption) => {
                    setCardFilter(selectedOption);
                  }}
                  value={cardFilter}
                  size={'sm'}
                />
              </div>
            );
          },
        }),
      ],
    }),
    columnHelper.group({
      id: 'commerce',
      header: '커머스',
      columns: [
        columnHelper.accessor('naver_id', {
          id: 'naver_id',
          cell: (info) => OXColor(YNtoOX(info.getValue())),
          header: () => {
            return (
              <div style={{ margin: '0 2px' }}>
                <Select
                  name={'naverFilterSelect'}
                  options={[
                    {
                      label: '네이버',
                      value: '네이버',
                    },
                    {
                      label: '네이버(O)',
                      value: 'Y',
                    },
                    {
                      label: '네이버(X)',
                      value: 'N',
                    },
                  ]}
                  onChange={(selectedOption) => {
                    setNaverFilter(selectedOption);
                  }}
                  value={naverFilter}
                  size={'sm'}
                />
              </div>
            );
          },
        }),
        columnHelper.accessor('coupang_id', {
          id: 'coupang_id',
          cell: (info) => OXColor(YNtoOX(info.getValue())),
          header: () => {
            return (
              <div style={{ margin: '0 2px' }}>
                <Select
                  name={'coupangFilterSelect'}
                  options={[
                    {
                      label: '쿠팡',
                      value: '쿠팡',
                    },
                    {
                      label: '쿠팡(O)',
                      value: 'Y',
                    },
                    {
                      label: '쿠팡(X)',
                      value: 'N',
                    },
                  ]}
                  onChange={(selectedOption) => {
                    setCoupangFilter(selectedOption);
                  }}
                  value={coupangFilter}
                  size={'sm'}
                />
              </div>
            );
          },
        }),
      ],
    }),
    columnHelper.group({
      id: 'delivery',
      header: '배달앱',
      columns: [
        columnHelper.accessor('bm_id', {
          id: 'bm_id',
          cell: (info) => OXColor(YNtoOX(info.getValue())),
          header: () => {
            return (
              <div style={{ margin: '0 2px' }}>
                <Select
                  name={'bmFilterSelect'}
                  options={[
                    {
                      label: '배민',
                      value: '배민',
                    },
                    {
                      label: '배민(O)',
                      value: 'Y',
                    },
                    {
                      label: '배민(X)',
                      value: 'N',
                    },
                  ]}
                  onChange={(selectedOption) => {
                    setBmFilter(selectedOption);
                  }}
                  value={bmFilter}
                  size={'sm'}
                />
              </div>
            );
          },
        }),
        columnHelper.accessor('cpe_id', {
          id: 'cpe_id',
          cell: (info) => OXColor(YNtoOX(info.getValue())),
          header: () => {
            return (
              <div style={{ margin: '0 2px' }}>
                <Select
                  name={'cpeFilterSelect'}
                  options={[
                    {
                      label: '쿠팡이츠',
                      value: '쿠팡이츠',
                    },
                    {
                      label: '쿠팡이츠(O)',
                      value: 'Y',
                    },
                    {
                      label: '쿠팡이츠(X)',
                      value: 'N',
                    },
                  ]}
                  onChange={(selectedOption) => {
                    setCpeFilter(selectedOption);
                  }}
                  value={cpeFilter}
                  size={'sm'}
                />
              </div>
            );
          },
        }),
        columnHelper.accessor('yo_id', {
          id: 'yo_id',
          cell: (info) => OXColor(YNtoOX(info.getValue())),
          header: () => {
            return (
              <div style={{ margin: '0 2px' }}>
                <Select
                  name={'yoFilterSelect'}
                  options={[
                    {
                      label: '요기요',
                      value: '요기요',
                    },
                    {
                      label: '요기요(O)',
                      value: 'Y',
                    },
                    {
                      label: '요기요(X)',
                      value: 'N',
                    },
                  ]}
                  onChange={(selectedOption) => {
                    setYoFilter(selectedOption);
                  }}
                  value={yoFilter}
                  size={'sm'}
                />
              </div>
            );
          },
        }),
      ],
    }),
  ];

  const isAllNotSynced = (original: ICompanySync) => {
    const {
      hometax_id,
      hometax_sign_cert,
      financing_id,
      card_cert,
      naver_id,
      coupang_id,
      bm_id,
      cpe_id,
      yo_id,
    } = original;

    return Object.values({
      hometax_id,
      hometax_sign_cert,
      financing_id,
      card_cert,
      naver_id,
      coupang_id,
      bm_id,
      cpe_id,
      yo_id,
    }).every((value) => value === 'N');
  };
  return (
    <>
      <AdminSyncContentContainer>
        <StyledTitle>데이터 연동 현황</StyledTitle>
        <Table<ICompanySync>
          data={flattenedData}
          columns={columns}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          getRowStyle={(row) => ({
            backgroundColor: isAllNotSynced(row.original)
              ? COLORS['gray2']
              : '',
          })}
          filterContent={
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={'table-filter-container'}>
                {admin?.branch === '본점' && (
                  <BranchFilterSelect
                    branchFilter={branchFilter}
                    setBranchFilter={setBranchFilter}
                  />
                )}

                <StaffFilterSelect
                  branchFilter={branchFilter}
                  staffFilter={staffFilter}
                  setStaffFilter={setStaffFilter}
                />

                <Select
                  name={'businessTypeBranch'}
                  options={[
                    {
                      label: '전체',
                      value: '전체',
                    },
                    {
                      label: '법인',
                      value: 'CORP',
                    },
                    {
                      label: '개인',
                      value: 'PERSONAL',
                    },
                  ]}
                  onChange={(selectedOption) => {
                    setCompanyTypeFilter(selectedOption);
                  }}
                  value={companyTypeFilter}
                  size={'lg'}
                  label={'개인/법인'}
                />

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className={'table-filter-keyword-search-container'}>
                    <div style={{ width: '128px' }}>
                      <Controller
                        name="keywordSearchFilter"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              name="keywordSearchFilter"
                              options={receiptKeywordSearchFilterOption}
                              onChange={onChange}
                              value={value}
                              isFilterSearch={true}
                            />
                          );
                        }}
                      />
                    </div>

                    <Input
                      placeholder={'검색어 입력'}
                      register={register('keyword')}
                      style={{
                        height: 'auto',
                        borderRadius: '0',
                        borderLeft: 'none',
                        width: '414px',
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault(); // Prevent the default behavior of the enter key
                          handleSubmit(onSubmit)(); // Execute the submit handler
                        }
                      }}
                    />
                    <Icon
                      iconSrc={searchIcon}
                      style={{
                        padding: '10px',
                        border: `1px solid ${COLORS['gray2']}`,
                        borderLeft: 'none',
                        borderBottomRightRadius: '4px',
                        borderTopRightRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </div>
            </form>
          }
        />
      </AdminSyncContentContainer>
    </>
  );
};

export default AdminSyncContent;
