import CustomDrawer from '@src/components/CustomDrawer.tsx';
import { Dispatch, SetStateAction } from 'react';
import { COLORS } from '@src/util/constants.ts';
import { TAddressSearchResponse } from '@src/pages/address/AddressSearch.tsx';
import InputLabel from '@src/components/InputLabel.tsx';
import Input from '@src/components/Input.tsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '@src/components/Button.tsx';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTaxInvoiceFormStore } from '@src/util/store.ts';
import CloseIcon from '@src/components/CloseIcon.tsx';

interface AddressSearchDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedAddress: TAddressSearchResponse;
  onCloseAndNavigateBack: () => void;
}
const AddressDetailForm = z.object({
  addressDetail: z.string().min(1, { message: '상세주소를 입력해 주세요.' }),
});

type TAddressDetailForm = z.infer<typeof AddressDetailForm>;

const AddressSearchDetailDrawer: React.FC<AddressSearchDetailDrawerProps> = ({
  open,
  setOpen,
  selectedAddress,
  onCloseAndNavigateBack,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TAddressDetailForm>({
    resolver: zodResolver(AddressDetailForm),
  });

  const { taxInvoiceForm, setTaxInvoiceForm } = useTaxInvoiceFormStore(
    (state) => ({
      taxInvoiceForm: state.taxInvoiceForm,
      setTaxInvoiceForm: state.setTaxInvoiceForm,
    }),
  );

  const onSubmit: SubmitHandler<TAddressDetailForm> = (data) => {
    const fullAddress = `${selectedAddress.roadAddr}, ${data.addressDetail}`;
    const updatedFormData = {
      ...taxInvoiceForm,
      dmnrPfbAdr: fullAddress,
    };

    setTaxInvoiceForm(updatedFormData);
    onCloseAndNavigateBack();
  };

  return (
    <CustomDrawer open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'address-search-detail-drawer-container'}>
          <div className={'address-search-detail-drawer-header'}>
            <span>주소입력</span>
            <CloseIcon
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
          <div className={'address-search-detail-drawer-body'}>
            <div className={'address-search-detail-drawer-item'}>
              <div className={'flex-row'}>{selectedAddress.roadAddr}</div>
              <div className={'flex-row'} style={{ color: COLORS['gray4'] }}>
                {selectedAddress.jibunAddr}
              </div>
            </div>
            <InputLabel
              text={'상세주소'}
              required
              error={
                errors.addressDetail && (
                  <p className={'error-message'}>
                    {errors.addressDetail.message}
                  </p>
                )
              }
            >
              <Input
                register={register('addressDetail')}
                style={{
                  background: COLORS['gray0'],
                }}
              />
            </InputLabel>
            <Button text={'입력'} type={'submit'} />
          </div>
        </div>
      </form>
    </CustomDrawer>
  );
};

export default AddressSearchDetailDrawer;
