import DetailHeader from '@src/components/DetailHeader.tsx';
import Container from '@src/layouts/Container.tsx';
import CustomerSearchContent from '@src/pages/customer/CustomerSearchContent.tsx';
import { useState } from 'react';
import {
  CUSTOMER_SEARCH_MENU,
  CUSTOMER_SEARCH_MENU_LIST,
} from '@src/util/constants.ts';
import { useIsMobile } from '@src/util/hooks.ts';
import Header from '@src/components/Header.tsx';
import HeaderTabs from '@src/components/HeaderTabs.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import SideBar from '@src/components/SideBar.tsx';

const CustomerSearch = () => {
  const isMobile = useIsMobile();
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    CUSTOMER_SEARCH_MENU.CUSTOMER,
  );
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Container
      header={
        isMobile ? (
          <DetailHeader title={'거래처정보 목록조회'} />
        ) : (
          <Header setOpen={setOpen} />
        )
      }
      headerTab={
        !isMobile && (
          <HeaderTabs
            menus={CUSTOMER_SEARCH_MENU_LIST}
            activeHeaderTab={activeHeaderTab}
            setActiveHeaderTab={(value) => {
              setActiveHeaderTab(value);
            }}
          />
        )
      }
      modal={<CompanyDrawer open={open} setOpen={setOpen} />}
      sideBar={<SideBar />}
    >
      <CustomerSearchContent />
    </Container>
  );
};

export default CustomerSearch;
