import React from 'react';
import Card from '@src/components/Card.tsx';

interface ReportCardProps {
  children: React.ReactNode;
  cardStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}

const ReportCard: React.FC<ReportCardProps> = ({
  children,
  cardStyle,
  containerStyle,
}) => {
  return (
    <div className="report-card-container" style={containerStyle}>
      <Card style={cardStyle}>{children}</Card>
    </div>
  );
};

export default ReportCard;
