import { FC } from 'react';

interface CheckIconProps {
  fill: string;
  size: string;
}

const CheckIcon: FC<CheckIconProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon / check" clipPath="url(#clip0_2942_21044)">
        <mask
          id="mask0_2942_21044"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={size}
          height={size}
        >
          <rect id="Bounding box" width={size} height={size} fill={fill} />
        </mask>
        <g mask="url(#mask0_2942_21044)">
          <path
            id="check"
            d="M9.54972 15.15L18.0247 6.675C18.2247 6.475 18.4622 6.375 18.7372 6.375C19.0122 6.375 19.2497 6.475 19.4497 6.675C19.6497 6.875 19.7497 7.1125 19.7497 7.3875C19.7497 7.6625 19.6497 7.9 19.4497 8.1L10.2497 17.3C10.0497 17.5 9.81639 17.6 9.54972 17.6C9.28305 17.6 9.04972 17.5 8.84972 17.3L4.54972 13C4.34972 12.8 4.25389 12.5625 4.26222 12.2875C4.27055 12.0125 4.37472 11.775 4.57472 11.575C4.77472 11.375 5.01222 11.275 5.28722 11.275C5.56222 11.275 5.79972 11.375 5.99972 11.575L9.54972 15.15Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2942_21044">
          <rect width={size} height={size} fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckIcon;
