import {
  Menu,
  MenuItem,
  Sidebar,
  sidebarClasses,
  SubMenu,
} from 'react-pro-sidebar';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import {
  CERTIFICATES_MENU_LIST,
  COLORS,
  EXPECTED_TAX_MENU_LIST,
  MANAGER_STATUS,
  MY_PAGE_MENU_LIST,
  REPORT_TERM_MENU_LIST,
  TAX,
  TAX_INVOICE_MENU_LIST,
  TAX_MENU_LIST,
  TERMS_MENU_LIST,
  URLS,
} from '../util/constants';
import menuIcon from '/src/assets/icons/bottom/menu.svg';

import { useSidebarStore, useUserStore } from '../util/store.ts';
import IconSVG from './IconSVG.tsx';

interface SideBarProps {}

const SideBar: React.FC<SideBarProps> = () => {
  const { collapsed, toggleCollapsed, activeSubMenus, toggleSubMenu } =
    useSidebarStore();

  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);

  return (
    <Sidebar
      collapsed={collapsed}
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          position: 'fixed',
          zIndex: 9999,
        },
      }}
      className={'side-bar-container'}
      backgroundColor={'white'}
    >
      <button
        className={'side-bar-header'}
        onClick={toggleCollapsed}
        type={'button'}
      >
        <img className={'icon'} src={menuIcon} alt="메뉴" />
      </button>
      <Menu
        style={{
          fontSize: '16px',
          fontWeight: '600',
          width: collapsed ? '0' : '250px',
        }}
      >
        <SubMenu
          icon={
            <IconSVG
              fill={user?.role?.reports ? COLORS['main'] : COLORS['gray3']}
              size={'24'}
              iconSVG={'report'}
            />
          }
          label="리포트"
          style={{ paddingLeft: '16px', paddingRight: '16px' }}
          onOpenChange={() => toggleSubMenu('reportSubMenu')}
          open={activeSubMenus.includes('reportSubMenu')}
          onClick={() => {
            navigate('/reports');
          }}
          disabled={!user?.role?.reports}
        >
          {REPORT_TERM_MENU_LIST.map((item, i) => {
            return (
              <MenuItem
                style={{ fontSize: '14px', fontWeight: '400' }}
                key={i}
                onClick={(e) => {
                  navigate(`/reports/${item.id}`, { replace: true });
                  e.stopPropagation();
                }}
              >
                {item.text}
              </MenuItem>
            );
          })}
        </SubMenu>
        <SubMenu
          icon={
            <IconSVG
              fill={user?.role?.tax_invoice ? COLORS['main'] : COLORS['gray3']}
              size={'24'}
              iconSVG={'taxInvoice'}
            />
          }
          label="세금계산서 발급"
          style={{ paddingLeft: '16px', paddingRight: '16px' }}
          onClick={() => {
            navigate(URLS.TAX_INVOICE);
          }}
          onOpenChange={() => toggleSubMenu('taxInvoiceSubMenu')}
          open={activeSubMenus.includes('taxInvoiceSubMenu')}
          disabled={!user?.role?.tax_invoice}
        >
          {TAX_INVOICE_MENU_LIST.map((item, i) => {
            return (
              <MenuItem
                style={{ fontSize: '14px', fontWeight: '400' }}
                onClick={(e) => {
                  navigate(`/tax-invoice/${item.id}`);
                  e.stopPropagation();
                }}
                key={i}
              >
                {item.text}
              </MenuItem>
            );
          })}
        </SubMenu>
        <SubMenu
          icon={
            <IconSVG
              fill={user?.role?.cert ? COLORS['main'] : COLORS['gray3']}
              size={'24'}
              iconSVG={'receipt'}
            />
          }
          label="증명서 발급"
          style={{ paddingLeft: '16px', paddingRight: '16px' }}
          onClick={() => {
            navigate('/certificates');
          }}
          onOpenChange={() => toggleSubMenu('certificateSubMenu')}
          open={activeSubMenus.includes('certificateSubMenu')}
          disabled={!user?.role?.cert}
        >
          {CERTIFICATES_MENU_LIST.map((item, i) => {
            return (
              <MenuItem
                style={{ fontSize: '14px', fontWeight: '400' }}
                onClick={(e) => {
                  navigate(`/certificates/${item.id}`);
                  e.stopPropagation();
                }}
                key={i}
              >
                {item.text}
              </MenuItem>
            );
          })}
        </SubMenu>

        <SubMenu
          icon={
            <IconSVG
              fill={user?.role?.tax ? COLORS['main'] : COLORS['gray3']}
              size={'24'}
              iconSVG={'tax'}
            />
          }
          label="세금 관리"
          style={{ paddingLeft: '16px', paddingRight: '16px' }}
          onClick={() => {
            navigate('/tax');
          }}
          onOpenChange={() => toggleSubMenu('taxSubMenu')}
          open={activeSubMenus.includes('taxSubMenu')}
          disabled={!user?.role?.tax}
        >
          {TAX_MENU_LIST.map((item, i) => {
            return (
              <MenuItem
                style={{ fontSize: '14px', fontWeight: '400' }}
                onClick={(e) => {
                  navigate(`/tax/${item.id}`);
                  e.stopPropagation();
                }}
                key={i}
              >
                {item.text}
              </MenuItem>
            );
          })}
        </SubMenu>
        <SubMenu
          icon={
            <IconSVG
              fill={
                user?.role?.estimated_tax ? COLORS['main'] : COLORS['gray3']
              }
              size={'24'}
              iconSVG={'calculator'}
            />
          }
          label="예상 세금"
          style={{ paddingLeft: '16px', paddingRight: '16px' }}
          onClick={() => {
            navigate('/expected-tax');
          }}
          onOpenChange={() => toggleSubMenu('expectedTaxSubMenu')}
          open={activeSubMenus.includes('expectedTaxSubMenu')}
          disabled={!user?.role?.estimated_tax}
        >
          {EXPECTED_TAX_MENU_LIST.map((item, i) => {
            if (
              item.id == TAX.부가세 &&
              user?.selectedCompany?.tax_type === 'EXEMPT'
            ) {
              return;
            }

            if (
              item.id === TAX.법인세 &&
              user?.selectedCompany?.type !== 'CORP'
            ) {
              return (
                <MenuItem
                  style={{ fontSize: '14px', fontWeight: '400' }}
                  onClick={(e) => {
                    navigate(`/expected-tax?type=${item.id}`);
                    e.stopPropagation();
                  }}
                  key={i}
                >
                  예상 소득세
                </MenuItem>
              );
            }
            return (
              <MenuItem
                style={{ fontSize: '14px', fontWeight: '400' }}
                onClick={(e) => {
                  navigate(`/expected-tax?type=${item.id}`);
                  e.stopPropagation();
                }}
                key={i}
              >
                {item.text}
              </MenuItem>
            );
          })}
        </SubMenu>
        <SubMenu
          icon={
            <IconSVG
              fill={
                user?.role?.labor_subsidy ? COLORS['main'] : COLORS['gray3']
              }
              size={'24'}
              iconSVG={'money'}
            />
          }
          label="노무지원금"
          style={{ paddingLeft: '16px', paddingRight: '16px' }}
          onClick={() => {
            navigate('/labor');
          }}
          onOpenChange={() => toggleSubMenu('laborSubMenu')}
          open={activeSubMenus.includes('laborSubMenu')}
          disabled={!user?.role?.labor_subsidy}
        >
          <MenuItem
            style={{ fontSize: '14px', fontWeight: '400' }}
            onClick={(e) => {
              navigate('/labor');
              e.stopPropagation();
            }}
          >
            노무지원금 신청
          </MenuItem>
        </SubMenu>
        <MenuItem
          icon={
            <IconSVG
              fill={
                user?.role?.data_integration ? COLORS['main'] : COLORS['gray3']
              }
              size={'24'}
              iconSVG={'sync'}
            />
          }
          style={{ paddingLeft: '16px', paddingRight: '16px' }}
          onClick={() => navigate('/sync')}
          disabled={!user?.role?.data_integration}
        >
          데이터 연동
        </MenuItem>
        <SubMenu
          icon={
            <IconSVG fill={COLORS['main']} size={'24'} iconSVG={'account'} />
          }
          label="마이 페이지"
          style={{ paddingLeft: '16px', paddingRight: '16px' }}
          onClick={() => {
            navigate('/my-page');
          }}
          onOpenChange={() => toggleSubMenu('myPageSubMenu')}
          open={activeSubMenus.includes('myPageSubMenu')}
        >
          {user?.current_manager && !user.is_root_rights
            ? MY_PAGE_MENU_LIST.filter(
                (menu) => menu.id !== MANAGER_STATUS.MANAGER,
              ).map((item, i) => {
                return (
                  <MenuItem
                    style={{ fontSize: '14px', fontWeight: '400' }}
                    onClick={(e) => {
                      navigate(`/my-page?type=${item.id}`);
                      e.stopPropagation();
                    }}
                    key={i}
                  >
                    {item.text}
                  </MenuItem>
                );
              })
            : MY_PAGE_MENU_LIST.map((item, i) => {
                return (
                  <MenuItem
                    style={{ fontSize: '14px', fontWeight: '400' }}
                    onClick={(e) => {
                      navigate(`/my-page?type=${item.id}`);
                      e.stopPropagation();
                    }}
                    key={i}
                  >
                    {item.text}
                  </MenuItem>
                );
              })}
        </SubMenu>
        <SubMenu
          icon={
            <IconSVG
              fill={user?.role?.etc ? COLORS['main'] : COLORS['gray3']}
              size={'24'}
              iconSVG={'etc'}
            />
          }
          label="기타"
          style={{ paddingLeft: '16px', paddingRight: '16px' }}
          onClick={() => {
            navigate('/terms/service');
          }}
          onOpenChange={() => toggleSubMenu('etcSubMenu')}
          open={activeSubMenus.includes('etcSubMenu')}
        >
          {TERMS_MENU_LIST.map((item, i) => {
            return (
              <MenuItem
                style={{ fontSize: '14px', fontWeight: '400' }}
                onClick={(e) => {
                  navigate(`/terms/${item.id}`);
                  e.stopPropagation();
                }}
                key={i}
              >
                {item.text}
              </MenuItem>
            );
          })}
        </SubMenu>
      </Menu>
    </Sidebar>
  );
};

export default SideBar;
