import React, { useEffect, useState } from 'react';

type SnackbarProps = {
  message: string;
  open: boolean;
  type?: 'info' | 'error';
};

const Snackbar: React.FC<SnackbarProps> = ({ message, open, type }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (open) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000); // Snackbar will disappear after 3 seconds
      return () => clearTimeout(timer);
    } else {
      setIsVisible(false);
    }
  }, [open]);

  return (
    <div className={`snackbar ${isVisible ? 'show' : ''} --${type}`}>
      {message}
    </div>
  );
};

export default Snackbar;
