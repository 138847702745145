import { FC } from 'react';

interface AccountIconProps {
  fill: string;
  size: string;
}

const AccountIcon: FC<AccountIconProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon / account_circle">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5ZM7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8C17 10.7614 14.7614 13 12 13C9.23858 13 7 10.7614 7 8ZM10 16C7.79086 16 6 17.7909 6 20C6 20.5523 5.55228 21 5 21C4.44772 21 4 20.5523 4 20C4 16.6863 6.68629 14 10 14H14C17.3137 14 20 16.6863 20 20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20C18 17.7909 16.2091 16 14 16H10Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default AccountIcon;
