import '@src/scss/main.scss';
import { useNavigate } from 'react-router-dom';
import {
  HometaxUpdate,
  IApiError,
  IApiPOSTSuccess,
  ICert,
} from '@src/util/interfaces';
import api from '@src/util/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  API_URLS,
  HOMETAX_SYNC_MENU,
  HOMETAX_SYNC_MENU_LIST,
} from '@src/util/constants';
import { useUserStore } from '@src/util/store';
import React, { useCallback, useState } from 'react';

import Hometax_id from '/src/assets/icons/logo/hometax_id.svg';
import Hometax_cert from '/src/assets/icons/logo/hometax_cert.svg';
import CheckCircle from '/src/assets/icons/check_circle_blue.svg';
import ExclaimMark from '/src/assets/icons/exclaim_mark.svg';
import InstallDesktop from '/src/assets/icons/install_desktop.svg';
import Close from '/src/assets/icons/close.png';
import DetailHeader from '@src/components/DetailHeader';
import Container from '@src/layouts/Container';
import { isMacOs } from 'react-device-detect';
import CustomModal from '@src/components/CustomModal';
import {
  distingCert,
  fileDownload,
  fnNxCertList,
  fnNxCertResult,
  fnNxSetupCheck,
} from '@src/util/functions';
import TailSpinner from '@src/components/TailSpinner';
import SideBar from '@src/components/SideBar';
import Header from '@src/components/Header.tsx';
import { useIsMobile } from '@src/util/hooks.ts';
import { isMobile as isDeviceMobile } from 'react-device-detect';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import HeaderTabs from '@src/components/HeaderTabs.tsx';
import CustomModalTwoButton from '@src/components/CustomModalTwoButton.tsx';
import Snackbar from '@src/components/SnackBar.tsx';

interface DataSyncProps {}

const HometaxSync: React.FC<DataSyncProps> = () => {
  const navigate = useNavigate();
  const [user] = useUserStore((state) => [state.user]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [idModalOpen, setIdModalOpen] = useState<boolean>(false);
  const [clearModalOpen, setClearModalOpen] = useState<boolean>(false);
  const [certClearModalOpen, setCertClearModalOpen] = useState<boolean>(false);

  const [step1Open, setStep1Open] = useState<boolean>(false);
  const [step2Open, setStep2Open] = useState<boolean>(false);
  const [step2Error, setStep2Error] = useState<string>('');
  const [certModalOpen, setCertModalOpen] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const [selectedCert, setSelectedCert] = useState({
    certName: '',
    drive: '',
    fromDt: '',
    oid: '',
    path: '',
    pub: '',
    sn: '',
    toDt: '',
  });

  const [hometax_id, setHometaxId] = useState<string>('');
  const [hometax_password, setHometaxPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [key, setKey] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const [certList, setCertList] = useState<Record<string, string>[]>([]);
  const [certResultError, setCertResultError] = useState<string>('');

  const [hometax_sign_password, set_hometax_sign_password] =
    useState<string>('');
  const isMobile = useIsMobile();
  const [open, setOpen] = useState<boolean>(false);
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    HOMETAX_SYNC_MENU.HOMETAX,
  );
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = useState<string>('');
  const [snackBarType, setSnackBarType] = useState<'info' | 'error'>('info');

  const company_id = user?.selectedCompany?.id;

  const queryClient = useQueryClient();
  const cert = useQuery<ICert>({
    queryKey: [`cert`, company_id],
    queryFn: () => api.get(API_URLS.CERT + `/${company_id}`),
    enabled: !!user && !!user.selectedCompany,
    refetchOnWindowFocus: false,
    staleTime: 30 * 60 * 1000,
  });

  const { mutate: updateIdCert, isPending: updateIdCertPending } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    HometaxUpdate
  >({
    mutationFn: (data: HometaxUpdate) =>
      api.post(API_URLS.CERT + `/hometax/id`, { ...data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cert', company_id] });
      setIdModalOpen(false);
      setHometaxId('');
      setHometaxPassword('');
      setSnackBarType('info');
      setSnackBarContent('홈택스 아이디가 연동되었습니다.');
      setSnackBarOpen(true);
      setTimeout(() => {
        setSnackBarOpen(false);
      }, 3000);
    },
    onError: (e) => {
      console.log(e?.response?.data.message);
      setError(e?.response?.data.message);
    },
  });

  const { mutate: updateSignCert, isPending: updateSignCertPending } =
    useMutation({
      mutationFn: (data: HometaxUpdate) =>
        api.post(API_URLS.CERT + `/hometax`, { ...data }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['cert', company_id] });
        setCertModalOpen(false);
        setSuccessModal(true);
      },
      onError: (e: IApiError) => {
        setCertResultError(e.response.data.message);
      },
    });

  const { mutate: updateCertClear } = useMutation({
    mutationFn: (data: { key: string; value: string }) =>
      api.patch(API_URLS.CERT + `/${user?.selectedCompany?.id}`, {
        [data.key]: data.value,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cert', company_id] });
    },
    onError: (e: IApiError) => {
      console.log(e);
    },
    onSettled: () => {
      setClearModalOpen(false);
    },
  });

  const idSyncDisable =
    cert?.data?.data.hometax_id &&
    cert?.data?.data.hometax_password &&
    cert?.data?.data.hometax_password_error == false;
  const idPwError = cert?.data?.data.hometax_password_error == true;
  const certSyncDisable =
    cert?.data?.data.hometax_sign_cert &&
    cert?.data?.data.hometax_sign_password &&
    cert?.data?.data.hometax_sign_password_error == false &&
    cert?.data?.data.hometax_sign_pri;

  const handleClear = useCallback(
    (key: string, value: string, title: string) => {
      setKey(key);
      setValue(value);
      setTitle(title);
      setClearModalOpen(true);
    },
    [],
  );

  const handleCertSyncClick = useCallback(async () => {
    setStep2Error('');
    if (isDeviceMobile) {
      setModalOpen(true);
    } else {
      const isDownload = await fnNxSetupCheck({
        certImageUrl: '',
        nxKeypad: '',
      });
      if (isDownload) {
        const data = await fnNxCertList({});
        setCertList(data?.data.list);
        setCertModalOpen(true);
      } else {
        setStep1Open(true);
      }
    }
  }, [navigate]);

  const handleStep1 = useCallback(() => {
    setStep1Open(false);
    setStep2Open(true);
    const url = isMacOs
      ? import.meta.env.VITE_NX_MAC_DOWNLOAD_URL
      : import.meta.env.VITE_NX_DOWNLOAD_URL;
    fileDownload(url);
  }, []);

  const handleStep2 = useCallback(async () => {
    const isDownload = await fnNxSetupCheck({ certImageUrl: '', nxKeypad: '' });
    if (isDownload) {
      const data = await fnNxCertList({});
      setCertList(data?.data.list);
      setCertModalOpen(true);
    } else {
      setStep2Error('파일을 설치해주세요');
    }
  }, []);

  const handleRegisterCert = useCallback(async () => {
    if (selectedCert.certName == '') {
      setCertResultError('인증서 목록을 클릭해주세요.');
      return;
    }
    if (hometax_sign_password == '') {
      setCertResultError('인증서 비밀번호를 입력해주세요.');
      return;
    }

    setCertResultError('');

    const data = {
      ...selectedCert,
      signPw: hometax_sign_password,
    };

    const result = await fnNxCertResult(data);
    if (result && result.data.errYn == 'Y') {
      setCertResultError(result.data.errMsg);
    } else if (result && result.data.DER2PEM) {
      updateSignCert({
        company_id,
        hometax_sign_password,
        hometax_sign_cert: result.data.DER2PEM,
        hometax_sign_pri: result.data.KEY2PEM,
        certName: selectedCert.certName,
        drive: selectedCert.drive,
        oid: selectedCert.oid,
        toDt: selectedCert.toDt,
        pub: selectedCert.pub,
      });
    } else {
      setCertResultError('예상치 못한 오류 발생');
    }
  }, [hometax_sign_password, selectedCert]);

  const handleCertListClick = useCallback(
    (index: number, cert: Record<string, string>) => {
      setCertResultError('');
      setActiveIndex(index); // 활성화된 버튼의 인덱스를 설정
      setSelectedCert({
        certName: cert.certName,
        drive: cert.drive,
        fromDt: cert.fromDt,
        oid: cert.oid,
        path: cert.path,
        pub: cert.pub,
        sn: cert.sn,
        toDt: cert.toDt,
      });
    },
    [],
  );

  if (updateSignCertPending) {
    return <TailSpinner type={'full-page'} />;
  }

  return (
    <Container
      header={
        isMobile ? (
          <DetailHeader title={'홈택스 데이터 연결하기'} />
        ) : (
          <Header setOpen={setOpen} />
        )
      }
      headerTab={
        !isMobile && (
          <HeaderTabs
            menus={HOMETAX_SYNC_MENU_LIST}
            activeHeaderTab={activeHeaderTab}
            setActiveHeaderTab={(value) => {
              setActiveHeaderTab(value);
            }}
          />
        )
      }
      sideBar={<SideBar />}
      modal={
        <>
          <CompanyDrawer open={open} setOpen={setOpen} />
          <CustomModal
            onClose={() => {
              setIdModalOpen(false);
              setError('');
            }}
            isOpen={idModalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2 className={'modal-custom-title'}>{`아이디로 연결`}</h2>
                    <div className={'modal-input-wrapper'}>
                      <input
                        className={'modal-input'}
                        value={hometax_id}
                        placeholder={'아이디 입력'}
                        onFocus={() => setError('')}
                        onChange={(e) => setHometaxId(e.target.value)}
                      />
                      <input
                        type={'password'}
                        className={'modal-input'}
                        value={hometax_password}
                        placeholder={'비밀번호 입력'}
                        onFocus={() => setError('')}
                        onChange={(e) => setHometaxPassword(e.target.value)}
                      />
                      {error ? (
                        <p className={'error-message'}>{error}</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
            divider={false}
            footer={
              <CustomModalTwoButton
                onCancel={() => {
                  setIdModalOpen(false);
                  setHometaxId('');
                  setHometaxPassword('');
                }}
                onOk={() => {
                  if (!hometax_id) {
                    // setError("아이디 : 영문(대소문자 구분됨) 또는 영문, 숫자, 특수문자(_) 조합, 6~20자리")
                    setError('아이디를 입력해주세요');
                    return;
                  }
                  if (!hometax_password) {
                    // setError("비밀번호 : 영문, 숫자, 특수문자를 조합하여 9~15자리 (특수문자[~!@#$%^&*-+]만 가능)")
                    setError('비밀번호를 입력해주세요');
                    return;
                  }
                  updateIdCert({
                    hometax_id,
                    hometax_password,
                    company_id,
                  });
                }}
                isPending={updateIdCertPending}
              />
            }
          />
          <CustomModal
            onClose={() => setModalOpen(false)}
            isOpen={modalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2
                      className={'modal-custom-title'}
                    >{`공동인증서로 연결`}</h2>
                    <p className={'modal-custom-content'}>
                      인증서 연결은 PC에서만 가능합니다.
                    </p>
                  </div>
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-button-wrapper'}>
                  <button
                    className={'custom-modal-button'}
                    onClick={() => setModalOpen(false)}
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />

          <CustomModal
            onClose={() => setStep1Open(false)}
            isOpen={step1Open}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <img src={ExclaimMark} alt={'Exclamation mark'} />
                    <p className={'modal-custom-content'}>
                      서비스를 정상적으로 이용하기 위해서는 인증서 관리
                      프로그램을 설치하셔야 합니다. <br />
                      프로그램을 설치하시겠습니까?
                    </p>
                  </div>
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'left-button'}
                    onClick={() => setStep1Open(false)}
                  >
                    취소
                  </button>
                  <button
                    className={'right-button'}
                    onClick={() => handleStep1()}
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />

          <CustomModal
            onClose={() => setStep2Open(false)}
            isOpen={step2Open}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <img src={InstallDesktop} alt={'install'} />
                    <p className={'modal-custom-content'}>
                      인증서 관리 프로그램은 자동으로 다운로드 되며, 설치하신 후
                      확인을 누르시면 <br />
                      다음 단계로 이동합니다.
                    </p>
                    {step2Error ? (
                      <p className={'error-message-center'}>{step2Error}</p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'left-button'}
                    onClick={() => setStep2Open(false)}
                  >
                    취소
                  </button>
                  <button
                    className={'right-button'}
                    onClick={() => handleStep2()}
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />

          <CustomModal
            onClose={() => {
              setCertResultError('');
              setCertModalOpen(false);
            }}
            isOpen={certModalOpen}
            modalCss={'custom-modal-content'}
            children={
              <React.Fragment>
                <div className={'modal-cert-list-wrapper'}>
                  <div className={'modal-cert-list-title'}>
                    <h2 className={'title-text'}>인증서 등록</h2>
                    <button onClick={() => setCertModalOpen(false)}>
                      <img src={Close} alt={'close'} />
                    </button>
                  </div>
                  <div className={'modal-cert-list'}>
                    <h4 className={'title-text'}>인증서 선택</h4>
                    <div className={'modal-cert-content-wrapper'}>
                      <div className={'modal-cert-content-header'}>
                        <p className={'text'} style={{ width: '28px' }}>
                          구분
                        </p>
                        <p className={'text'} style={{ width: '288px' }}>
                          인증서명
                        </p>
                        <p className={'text'} style={{ width: '72px' }}>
                          만료일
                        </p>
                        <p className={'text'} style={{ width: '48px' }}>
                          발급자
                        </p>
                        <p className={'text'} style={{ width: '28px' }}>
                          위치
                        </p>
                      </div>
                      {certList.map((cert, index) => {
                        return (
                          <button
                            key={index}
                            className={`modal-cert-content ${
                              activeIndex === index ? 'active' : ''
                            }`}
                            onClick={() => handleCertListClick(index, cert)}
                          >
                            <p className={'text'} style={{ width: '28px' }}>
                              {distingCert(cert.oid)}
                            </p>
                            <p className={'text'} style={{ width: '288px' }}>
                              {cert.certName}
                            </p>
                            <p className={'text'} style={{ width: '72px' }}>
                              {cert.toDt.replace(/-/g, '.')}
                            </p>
                            <p className={'text'} style={{ width: '48px' }}>
                              {cert.pub}
                            </p>
                            <p className={'text'} style={{ width: '28px' }}>
                              {cert.drive}
                            </p>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className={'modal-cert-list'}>
                    <h4 className={'title-text'}>인증서 암호입력</h4>
                    <div className={'modal-cert-input-wrapper'}>
                      <p className={'text'}>**********</p>
                      <p className={'text-blue'}>|</p>
                      <input
                        type={'password'}
                        className={'modal-cert-input text'}
                        value={hometax_sign_password}
                        onChange={(e) =>
                          set_hometax_sign_password(e.target.value)
                        }
                      />
                    </div>
                    <p className={'sub-text'}>
                      안전한 개인정보 관리를 위해 6개월마다 비밀번호를
                      변경하시기 바랍니다.
                    </p>
                    {certResultError ? (
                      <p className={'error-message'}>{certResultError}</p>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className={'custom-modal-two-button-wrapper'}>
                    <button
                      className={'left-button'}
                      onClick={() => setCertModalOpen(false)}
                    >
                      취소
                    </button>
                    <button
                      className={'right-button'}
                      onClick={() => handleRegisterCert()}
                    >
                      확인
                    </button>
                  </div>
                </div>
              </React.Fragment>
            }
          />

          <CustomModal
            onClose={() => setSuccessModal(false)}
            isOpen={successModal}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <img src={CheckCircle} alt={'check-button'} />
                    <p className={'modal-custom-content'}>
                      인증서 등록이 완료되었습니다.
                    </p>
                  </div>
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-button-wrapper'}>
                  <button
                    className={'custom-modal-button'}
                    onClick={() => setSuccessModal(false)}
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />

          <CustomModal
            onClose={() => setClearModalOpen(false)}
            isOpen={clearModalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2 className={'modal-custom-title'}>{`${title}`}</h2>
                  </div>
                  {value ? (
                    <div className={'modal-input-wrapper'}>
                      <input
                        className={'modal-input'}
                        value={value}
                        disabled={true}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'left-button'}
                    onClick={() => setClearModalOpen(false)}
                  >
                    취소
                  </button>
                  <button
                    className={'right-button'}
                    onClick={() => {
                      updateCertClear({ key, value: '' });
                      setSnackBarType('info');
                      setSnackBarContent(
                        '홈택스 아이디가 연동해제 되었습니다.',
                      );
                      setSnackBarOpen(true);
                      setTimeout(() => {
                        setSnackBarOpen(false);
                      }, 3000);
                    }}
                  >
                    해지
                  </button>
                </div>
              </React.Fragment>
            }
          />

          <CustomModal
            onClose={() => setCertClearModalOpen(false)}
            isOpen={certClearModalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2 className={'modal-custom-title'}>
                      홈택스 인증서 연결 해제
                    </h2>
                  </div>
                  <div
                    className={'modal-cert-content-wrapper'}
                    style={{ width: '100%' }}
                  >
                    <div className={'modal-cert-content-header'}>
                      <p className={'text'} style={{ width: '28px' }}>
                        구분
                      </p>
                      <p className={'text'} style={{ width: '288px' }}>
                        인증서명
                      </p>
                      <p className={'text'} style={{ width: '72px' }}>
                        만료일
                      </p>
                      <p className={'text'} style={{ width: '48px' }}>
                        발급자
                      </p>
                      <p className={'text'} style={{ width: '28px' }}>
                        위치
                      </p>
                    </div>
                    <div className={`modal-cert-content`}>
                      <p className={'text'} style={{ width: '28px' }}>
                        {distingCert(cert?.data?.data.oid || '')}
                      </p>
                      <p className={'text'} style={{ width: '288px' }}>
                        {cert?.data?.data.certName}
                      </p>
                      <p className={'text'} style={{ width: '72px' }}>
                        {cert?.data?.data?.toDt?.replace(/-/g, '.')}
                      </p>
                      <p className={'text'} style={{ width: '48px' }}>
                        {cert?.data?.data.pub}
                      </p>
                      <p className={'text'} style={{ width: '28px' }}>
                        {cert?.data?.data.drive}
                      </p>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'left-button'}
                    onClick={() => setCertClearModalOpen(false)}
                  >
                    취소
                  </button>
                  <button
                    className={'right-button'}
                    onClick={() =>
                      updateCertClear({ key: 'hometax_sign_cert', value: '' })
                    }
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />
          <Snackbar
            message={snackBarContent}
            open={snackBarOpen}
            type={snackBarType}
          />
        </>
      }
    >
      <div className={'data-sync-wrapper-container'}>
        <div className="data-sync-detail-container">
          <div className={'data-sync-content-wrapper'}>
            <div className={'data-sync-content'}>
              <div className={'icon-wrapper'}>
                <div className={'hometax-detail-icon'}>
                  <img src={Hometax_id} alt="hometax" />
                </div>
                <p className={'icon-label'}>
                  홈택스 아이디로 연결
                  {idPwError && (
                    <span className={'error-message'}> (비밀번호 오류)</span>
                  )}
                </p>
              </div>
              {idSyncDisable && (
                <button
                  className={'sync-button-clear'}
                  onClick={() => {
                    handleClear(
                      'hometax_id',
                      cert?.data?.data.hometax_id || '',
                      '홈택스 아이디 연결해제',
                    );
                  }}
                >
                  연결해제
                </button>
              )}
              {!idSyncDisable && (
                <button
                  className={'sync-button'}
                  onClick={() => setIdModalOpen(true)}
                >
                  연결하기
                </button>
              )}
            </div>
          </div>
          <div className={'data-sync-content-wrapper'}>
            <div className={'data-sync-content'}>
              <div className={'icon-wrapper'}>
                <div className={'hometax-detail-icon'}>
                  <img src={Hometax_cert} alt="hometax" />
                </div>
                <p className={'icon-label'}>홈택스 공동인증서로 연결</p>
              </div>
              {certSyncDisable && (
                <button
                  className={'sync-button-clear'}
                  onClick={() => setCertClearModalOpen(true)}
                >
                  연결해제
                </button>
              )}
              {!certSyncDisable && (
                <button
                  className={'sync-button'}
                  onClick={() => handleCertSyncClick()}
                >
                  연결하기
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HometaxSync;
