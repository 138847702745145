import React from 'react';
import { COLORS, FILE_ICONS } from '../util/constants.ts';
import Icon from './Icon.tsx';
import { formatFileSize, getFileExtension } from '../util/functions.ts';

interface AttachedFileProps {
  filename: string;
  size: number;
}

const AttachedFile: React.FC<AttachedFileProps> = ({ filename, size }) => {
  const type = getFileExtension(filename);

  return (
    <div className="attached-file-container">
      <Icon iconSrc={FILE_ICONS[type as keyof typeof FILE_ICONS]} />
      <div className="attached-file-text-container">
        <p
          className="text-sm"
          style={{ lineHeight: '20px', fontWeight: '700' }}
        >
          {filename}
        </p>
        <p
          className="text-sm"
          style={{ fontWeight: '400', color: COLORS['gray4'] }}
        >
          {formatFileSize(size)}
        </p>
      </div>
    </div>
  );
};

export default AttachedFile;
