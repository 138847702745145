import React from 'react';
import CommerceIcon from '@src/components/CommerceIcon.tsx';
import DeliveryIcon from '@src/components/DeliveryIcon.tsx';

interface CommerceIconProps {
  name: string;
}

const CommerceAndDeliveryIcon: React.FC<CommerceIconProps> = ({ name }) => {
  switch (name) {
    case 'NAVER':
      return <CommerceIcon name={'NAVER'} />;
    case 'COUPANG':
      return <CommerceIcon name={'COUPANG'} />;
    case 'BM':
      return <DeliveryIcon name={'BM'} />;
    case 'YO':
      return <DeliveryIcon name={'YO'} />;
    case 'CPE':
      return <DeliveryIcon name={'CPE'} />;
  }
};
export default CommerceAndDeliveryIcon;
