import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from '@src/components/Select.tsx';
import {
  formatDateString,
  generateLastYearsOptions,
  generateTxnrmStrtYm,
} from '@src/util/functions.ts';
import moment from 'moment/moment';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useTaxPaid } from '@src/util/hooks.ts';
import { ITaxBilled } from '@src/util/interfaces.ts';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import Table from '@src/components/Table.tsx';

interface TaxBilledTableProps {}

const TaxBilledForm = z.object({
  year: z.object({
    label: z.string(),
    value: z.enum(generateTxnrmStrtYm(moment().year(), 5)),
  }),
  type: z.string(),
});

type TTaxBilledForm = z.infer<typeof TaxBilledForm>;

const columnHelper = createColumnHelper<ITaxBilled>();

const columns: Array<ColumnDef<ITaxBilled, string>> = [
  columnHelper.accessor('name', {
    id: 'name',
    cell: (info) => <i>{info.getValue().trim()}</i>,
    header: () => <div style={{ minWidth: '132px' }}>세목명</div>,
  }),
  columnHelper.accessor('pay_dt', {
    id: 'pay_dt',
    cell: (info) => <i>{formatDateString(info.getValue())}</i>,
    header: () => <div style={{ minWidth: '64px' }}>납부기한</div>,
  }),
  columnHelper.accessor('billing_amount', {
    id: 'billing_amount',
    cell: (info) => <i>{info.getValue().toLocaleString()}</i>,
    header: () => <div style={{ minWidth: '88px' }}>고지세액</div>,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    cell: (info) => <i>{info.getValue().trim()}</i>,
    header: () => <div style={{ minWidth: '48px' }}>납부여부</div>,
  }),
];

const TaxBilledTable: React.FC<TaxBilledTableProps> = () => {
  const { control, watch } = useForm<TTaxBilledForm>({
    resolver: zodResolver(TaxBilledForm),
    defaultValues: {
      year: {
        label: moment().year().toString(),
        value: moment().year().toString(),
      },
    },
  });

  const watchYear = watch('year');

  const { data } = useTaxPaid('billing', watchYear.value);

  return (
    <div className={'tax-paid-table-container'}>
      <div className={'tax-paid-table-title-container'}>
        <p className={'text-regular-bold'}>고지내역</p>
        <div className={'tax-paid-table-title-right'}>
          <p className={'text-regular'} style={{ whiteSpace: 'nowrap' }}>
            고지연도
          </p>
          <Controller
            name="year"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  name="year"
                  options={generateLastYearsOptions(moment().year(), 5)}
                  onChange={onChange}
                  value={value}
                  size={'sm'}
                />
              );
            }}
          />
        </div>
      </div>

      {!data?.data?.tax_billing ? (
        <div style={{ height: '120px', width: '100%' }}></div>
      ) : (
        <Table<ITaxBilled> data={data.data.tax_billing} columns={columns} />
      )}
    </div>
  );
};
export default TaxBilledTable;
