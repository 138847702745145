import React from 'react';
import Container from '@src/layouts/Container.tsx';
import AdminSideBar from '@src/components/AdminSideBar.tsx';
import ErrorsAdminContent from '@src/pages/admin/errors/ErrorsAdminContent.tsx';

interface ErrorsAdminProps {}

const ErrorsAdmin: React.FC<ErrorsAdminProps> = () => {
  return (
    <Container sideBar={<AdminSideBar />}>
      <ErrorsAdminContent />
    </Container>
  );
};

export default ErrorsAdmin;
