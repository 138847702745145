import React, { Suspense, useState } from 'react';
import Container from '@src/layouts/Container';
import DetailHeader from '@src/components/DetailHeader.tsx';
import { useParams } from 'react-router-dom';
import { COLORS } from '@src/util/constants.ts';
import IconSVG from '@src/components/IconSVG.tsx';
import TaskComment from '@src/pages/tax/TaskComment';
import AdminSideBar from '@src/components/AdminSideBar.tsx';
import TaskDetailAdminContent from '@src/pages/admin/task/TaskDetailAdminContent.tsx';
import TailSpinner from '@src/components/TailSpinner.tsx';

interface TaskAdminDetailProps {}

const TaskAdminDetail: React.FC<TaskAdminDetailProps> = () => {
  const { id = '' } = useParams<{
    id: string;
  }>();

  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <Container
      style={{ minHeight: 'calc(100vh - 56px - 64px)', paddingLeft: '-220px' }}
      header={
        <DetailHeader
          title={'요청 상세'}
          custom={
            <IconSVG
              iconSVG={'delete'}
              size={'24'}
              fill={COLORS['red']}
              onClick={() => setDeleteModalOpen(true)}
              containerStyle={{
                position: 'absolute',
                right: 0,
                cursor: 'pointer',
              }}
            />
          }
        />
      }
      sideBar={<AdminSideBar />}
      footer={
        <Suspense fallback={<TailSpinner type={'full-page'} />}>
          <TaskComment
            taskId={id}
            // comments={data.task_comments}
            setSnackBarOpen={setSnackBarOpen}
          />
        </Suspense>
      }
    >
      <TaskDetailAdminContent
        snackBarOpen={snackBarOpen}
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
      />
    </Container>
  );
};

export default TaskAdminDetail;
