import React from 'react';
import Container from '@src/layouts/Container.tsx';
import DetailHeader from '@src/components/DetailHeader.tsx';
import SideBar from '@src/components/SideBar';
import Icon from '@src/components/Icon.tsx';
import warningIcon from '../../assets/images/warning.svg';
import { COLORS } from '@src/util/constants.ts';
import Button from '@src/components/Button.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import AdminSideBar from '@src/components/AdminSideBar.tsx';

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Container
      header={<DetailHeader title={'오류'} />}
      sideBar={
        location.pathname.startsWith('/admin') ? <AdminSideBar /> : <SideBar />
      }
    >
      <div className={'not-found-wrapper-container'}>
        <div className={'not-found-container'}>
          <div className={'not-found-title-container'}>
            <Icon iconSrc={warningIcon} />
            <p className={'text-xxl'} style={{ color: COLORS['black'] }}>
              요청하신 페이지를
              <br /> 찾을 수 없습니다.
            </p>
          </div>
          <div className={'not-found-body-container'}>
            <p
              className={'text-base'}
              style={{ fontWeight: '400', textAlign: 'center' }}
            >
              입력하신 주소가 올바르지 않거나 삭제 혹은 변경된 주소일 수
              있습니다. <br />
              주소를 다시 확인해 주시고 문제가 지속될 경우 아래의 메일로
              문의해주세요.
            </p>
            <p
              className={'text-lg'}
              style={{ fontWeight: '700', color: COLORS['primary'] }}
            >
              jungyul_tax@jungyul.co.kr
            </p>
          </div>
          <Button
            text={'이전으로 돌아가기'}
            style={{
              width: '182px',
              backgroundColor: COLORS['white'],
              border: `1px solid ${COLORS['main']}`,
              color: COLORS['main'],
            }}
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
    </Container>
  );
};

export default NotFound;
