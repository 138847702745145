import React, { ChangeEventHandler, Dispatch, SetStateAction } from 'react';
import searchIcon from '/src/assets/icons/search.svg';

interface SearchBarProps {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchValue,
  setSearchValue,
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <div className="search-bar-container">
      <input
        type="text"
        className="search-bar"
        placeholder={'검색어를 입력하세요'}
        value={searchValue}
        onChange={handleChange}
      />
      <img src={searchIcon} className="pointer" alt="search" />
    </div>
  );
};

export default SearchBar;
