import TailSpinner from './TailSpinner.tsx';
import React from 'react';

interface ButtonProps {
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  customClassName?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  style?: React.CSSProperties;
  form?: string;
}

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  isLoading,
  customClassName,
  disabled = false,
  style,
  type,
  form,
}) => {
  const defaultClassName = 'button-default';
  let combinedClassName = customClassName
    ? `${defaultClassName} ${customClassName}`
    : defaultClassName;

  if (disabled) {
    combinedClassName = `${combinedClassName} .btn-disabled`;
  }

  return (
    <button
      className={combinedClassName}
      onClick={onClick}
      disabled={disabled || isLoading}
      style={style}
      type={type}
      form={form}
    >
      {isLoading ? (
        <TailSpinner
          type={
            customClassName === 'right-button'
              ? 'button-primary-color'
              : 'button'
          }
        />
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
