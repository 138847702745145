import React, { useState } from 'react';
import InputLabel from '@src/components/InputLabel.tsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import Modal from '@src/components/Modal.tsx';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@src/util/store.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { IApiError, IApiPOSTSuccess } from '@src/util/interfaces.ts';
import { objectToQueryString } from '@src/util/functions.ts';
import api from '@src/util/api.ts';
import { API_URL } from '@src/util/constants.ts';
import { z } from 'zod';
import Input from '@src/components/Input.tsx';
import moment from 'moment';
import CertificatesDescription from '@src/pages/certificates/CertificatesDescription';
import Button from '@src/components/Button.tsx';

interface CertificateFormContent13Props {}

/**
 *  중소기업기준검토표
 *    요청연도*: year
 */
const currentYear = moment().year(); // Gets the current year

const CertificateForm13 = z.object({
  year: z
    .string()
    .trim() // Optional: trim whitespace
    .refine((val) => val.length > 0, {
      message: '과세연도를 입력해 주세요.', // Custom message for empty input
    })
    .transform((val) => parseInt(val, 10))
    .refine((val) => !isNaN(val), {
      message: '정확한 과세연도를 입력해 주세요.',
    })
    .refine((val) => val >= currentYear - 5 && val < currentYear, {
      message: `과세연도는 지난 5년간의 연도만 가능합니다. (${
        currentYear - 5
      } ~ ${currentYear - 1})`,
    }),
});

const CertificateForm13Server = CertificateForm13.extend({
  company_id: z.number(),
});

type TCertificateForm13 = z.infer<typeof CertificateForm13>;
type TCertificateForm13Server = z.infer<typeof CertificateForm13Server>;
const CertificateFormContent13: React.FC<
  CertificateFormContent13Props
> = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<TCertificateForm13>({
    resolver: zodResolver(CertificateForm13),
    mode: 'onSubmit',
  });

  const { mutate, isSuccess, error, isPending } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TCertificateForm13Server
  >({
    mutationFn: (formData) => {
      const queryParams = objectToQueryString(formData);

      return api.post(
        API_URL + `/v1/communication/document?${queryParams}`,
        formData,
      );
    },
  });

  const onSubmit: SubmitHandler<TCertificateForm13> = (data) => {
    const formData = {
      company_id: user?.selectedCompany?.id ?? 0,
      type: '중소기업기준검토표',
      year: data.year,
    };

    mutate(formData, {
      onSuccess: (response) => {
        setModalOpen(true);
        console.log(response);
      },
      onError: (error) => {
        setModalOpen(true);
        console.log(error);
      },
    });
  };

  return (
    <>
      <form
        className="certificate-form-content"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CertificatesDescription
          title={
            '중소기업기준검토표는 해당 과세연도 정기 신고기한 익월 이후 발급 가능합니다.'
          }
          example={
            '법인의 경우 2023년 귀속 중소기업기준검토표는 2024.5.1. 이후 발급 가능\n' +
            '개인의 경우 2023년 귀속 중소기업기준검토표는 2024.7.1. 이후 발급 가능 (성실은 2024.8.1. 이후)'
          }
        />
        <InputLabel
          text={'요청연도'}
          required={true}
          description={
            '요청일 기준, 이전 5개 년도만 가능합니다.\n예시) 요청일이 2024.05.01 → 2023~2019년'
          }
          error={
            errors.year && (
              <div className="error-message">
                {errors.year?.message?.toString()}
              </div>
            )
          }
        >
          <div className="flex-row" style={{ gap: '10px' }}>
            <Input
              placeholder="연도를 입력하세요"
              register={register('year')}
              isLoading={isPending}
              maxLength={4}
              inputMode={'numeric'}
              type={'number'}
            />
            <div>
              <p className="text-regular" style={{ lineHeight: '22px' }}>
                년
              </p>
            </div>
          </div>
        </InputLabel>
        <div className={'bottom-button-wrapper-container'}>
          <Button
            text={'신청하기'}
            isLoading={isPending}
            type={'submit'}
            disabled={isPending}
            style={{
              width: '100%',
              maxWidth: '786px',
              boxSizing: 'border-box',
              borderRadius: '3px',
            }}
          />
        </div>
      </form>
      <Modal
        isOpen={isModalOpen}
        onOk={() => {
          setModalOpen(false);
          if (isSuccess) {
            navigate('/certificates/issued', { replace: true });
          } else {
            navigate(-1);
          }
        }}
        title={isSuccess ? '증명서 발급 요청' : '증명서 발급 실패'}
        content={
          isSuccess
            ? '증명서 발급 요청 되었습니다.'
            : error?.response.data.message ?? ''
        }
        okButton={'확인'}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};
export default CertificateFormContent13;
