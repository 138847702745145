import React from 'react';
import { iconSVGMap } from '../util/constants.ts';

interface IconSVGProps {
  fill: string;
  size: string;
  containerStyle?: React.CSSProperties;
  iconSVG: keyof typeof iconSVGMap;
  onClick?: () => void;
}

const IconSVG: React.FC<IconSVGProps> = ({
  fill,
  size,
  containerStyle,
  iconSVG,
  onClick,
}) => {
  const IconSVGComponent = iconSVGMap[iconSVG];
  return (
    <div
      className="icon-svg-container"
      style={containerStyle}
      onClick={onClick}
    >
      {IconSVGComponent ? <IconSVGComponent fill={fill} size={size} /> : null}
    </div>
  );
};

export default IconSVG;
