import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  REPORT_CATEGORY,
  REPORT_CATEGORY_TEXT,
  REPORT_TERM,
  REPORT_TERM_TEXT,
} from '@src/util/constants.ts';
import DetailHeader from '@src/components/DetailHeader.tsx';
import Container from '@src/layouts/Container';
import SideBar from '@src/components/SideBar.tsx';
import { useIsMobile } from '@src/util/hooks.ts';
import Header from '@src/components/Header.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import ReportAdditionalContent from '@src/pages/reports/ReportAdditionalContent.tsx';

interface ReportAdditionalProps {}

const ReportAdditional: React.FC<ReportAdditionalProps> = () => {
  const { term, category } = useParams<{
    term: REPORT_TERM;
    category: REPORT_CATEGORY;
  }>();

  const [open, setOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();

  return (
    <Container
      header={
        isMobile ? (
          <DetailHeader
            title={`${
              REPORT_TERM_TEXT[term as keyof typeof REPORT_TERM_TEXT]
            }별 ${
              REPORT_CATEGORY_TEXT[
                category as keyof typeof REPORT_CATEGORY_TEXT
              ]
            }`}
          />
        ) : (
          <Header setOpen={setOpen} />
        )
      }
      headerTab={
        !isMobile && (
          <div className="header-tab">
            <div className={`tab-item active`}>
              <p
                className="text-base"
                style={{
                  color: '#31343A',
                  fontSize: '15px',
                }}
              >
                {`${
                  REPORT_TERM_TEXT[term as keyof typeof REPORT_TERM_TEXT]
                }별 ${
                  REPORT_CATEGORY_TEXT[
                    category as keyof typeof REPORT_CATEGORY_TEXT
                  ]
                }`}
              </p>
            </div>
          </div>
        )
      }
      sideBar={<SideBar />}
      modal={<CompanyDrawer open={open} setOpen={setOpen} />}
    >
      {category !== undefined && term !== undefined && (
        <ReportAdditionalContent category={category} term={term} />
      )}
    </Container>
  );
};
export default ReportAdditional;
