import { FC } from 'react';

interface SendIconProps {
  fill: string;
  size: string;
}

const SendIcon: FC<SendIconProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon / send">
        <path
          id="Vector"
          d="M19.8 12.9242L4.4 19.4242C4.06667 19.5576 3.75 19.5284 3.45 19.3367C3.15 19.1451 3 18.8659 3 18.4992V5.49924C3 5.13257 3.15 4.85341 3.45 4.66174C3.75 4.47007 4.06667 4.44091 4.4 4.57424L19.8 11.0742C20.2167 11.2576 20.425 11.5659 20.425 11.9992C20.425 12.4326 20.2167 12.7409 19.8 12.9242ZM5 16.9992L16.85 11.9992L5 6.99924V10.4992L11 11.9992L5 13.4992V16.9992Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default SendIcon;
