import { Dispatch, SetStateAction, FC } from 'react';
import { IErrorData } from '@src/util/interfaces.ts';
import CustomDrawer from '@src/components/CustomDrawer.tsx';

interface CustomErrorDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  errorData: IErrorData | null;
}
const CustomErrorDrawer: FC<CustomErrorDrawerProps> = ({
  open,
  setOpen,
  errorData,
}) => {
  return (
    <CustomDrawer
      open={open}
      setOpen={setOpen}
      okButton={'닫기'}
      onOk={() => setOpen(false)}
    >
      <div
        className={'flex-column-flex-start-stretch'}
        style={{ padding: '14px', gap: '8px' }}
      >
        <p className={'text-md'}>{errorData?.title}</p>
        <p className={'text-regular'}>{errorData?.message}</p>
      </div>
    </CustomDrawer>
  );
};

export default CustomErrorDrawer;
