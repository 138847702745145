import React from 'react';
import Card from '@src/components/Card.tsx';
import {
  COLORS,
  REPORT_CATEGORY,
  REPORT_CATEGORY_TEXT,
} from '@src/util/constants.ts';
import { getYearlyGraphUnits } from '@src/util/functions.ts';
import ProgressBar from '@src/components/ProgressBar.tsx';
import { useIsMobile } from '@src/util/hooks.ts';

interface ReportGraphCardProgressBarProps {
  activeCardHeaderTab: string;
  thisYearValue: number;
  lastYearValue: number;
}

const ReportGraphCardProgressBar: React.FC<ReportGraphCardProgressBarProps> = ({
  activeCardHeaderTab,
  thisYearValue,
  lastYearValue,
}) => {
  const isMobile = useIsMobile();
  return (
    <div
      className="report-card-container"
      style={{ background: !isMobile ? 'white' : COLORS['bg'] }}
    >
      <Card style={{ padding: '24px', gap: '12px' }}>
        <p className="text-base">
          {
            REPORT_CATEGORY_TEXT[
              activeCardHeaderTab as keyof typeof REPORT_CATEGORY_TEXT
            ]
          }
        </p>
        <div className="report-card-yearly-graph-text">
          <p
            className="text-sm"
            style={{
              color:
                activeCardHeaderTab === REPORT_CATEGORY.SALES
                  ? '#3182F6'
                  : '#F96464',
            }}
          >
            현재 {getYearlyGraphUnits(thisYearValue)}만원
          </p>
          <p className="text-sm" style={{ color: '#949494' }}>
            작년 {getYearlyGraphUnits(lastYearValue)}만원
          </p>
        </div>
        <ProgressBar
          base={lastYearValue}
          current={thisYearValue}
          color={activeCardHeaderTab === REPORT_CATEGORY.SALES ? 'blue' : 'red'}
        />
      </Card>
    </div>
  );
};

export default ReportGraphCardProgressBar;
