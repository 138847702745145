import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../scss/components/_react-datepicker-custom.scss';
import { Locale } from 'date-fns';

interface ReactDatePickerProps {
  value: Date | null;
  className?: string | null;
  onChange: (date: Date) => void;
  locale?: Locale;
}

const CustomDatePicker: React.FC<ReactDatePickerProps> = ({
  value,
  className,
  onChange,
  locale,
}) => {
  return (
    <DatePicker
      className={className ?? ''}
      dateFormat="yyyy-MM-dd"
      shouldCloseOnSelect
      minDate={new Date('2020-01-01')}
      maxDate={new Date()}
      selected={value}
      onChange={onChange}
      locale={locale}
    />
  );
};

export default CustomDatePicker;
