import React from 'react';
import { useTaxPaid } from '@src/util/hooks.ts';
import { ITaxDebt } from '@src/util/interfaces.ts';
import Table from '@src/components/Table.tsx';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

interface TaxDebtTableProps {}

const columnHelper = createColumnHelper<ITaxDebt>();

const columns: Array<ColumnDef<ITaxDebt, string>> = [
  columnHelper.accessor('name', {
    id: 'name',
    cell: (info) => <i>{info.getValue().trim()}</i>,
    header: () => <div style={{ minWidth: '132px' }}>세목명</div>,
  }),
  columnHelper.accessor('billing_amount', {
    id: 'billing_amount',
    cell: (info) => <i>{info.getValue().toLocaleString()}</i>,
    header: () => <div style={{ minWidth: '88px' }}>고지세액</div>,
  }),
  columnHelper.accessor('reduction_amount', {
    id: 'reduction_amount',
    cell: (info) => <i>{info.getValue().toLocaleString()}</i>,
    header: () => <div style={{ minWidth: '48px' }}>감세세액</div>,
  }),
  columnHelper.accessor('partial_paid_amount', {
    id: 'partial_paid_amount',
    cell: (info) => <i>{info.getValue().toLocaleString()}</i>,
    header: () => <div style={{ minWidth: '88px' }}>일부 납부한 세액</div>,
  }),
  columnHelper.accessor('remaining_amount', {
    id: 'remaining_amount',
    cell: (info) => <i>{info.getValue().toLocaleString()}</i>,
    header: () => <div style={{ minWidth: '88px' }}>잔여 납부할 세액</div>,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    cell: (info) => <i>{info.getValue().trim()}</i>,
    header: () => <div style={{ minWidth: '48px' }}>납부여부</div>,
  }),
];

const TaxDebtTable: React.FC<TaxDebtTableProps> = () => {
  const { data } = useTaxPaid('debt', '');

  return (
    <div className={'tax-paid-table-container'}>
      <div className={'tax-paid-table-title-container'}>
        <p className={'text-regular-bold'}>체납내역</p>
      </div>

      {!data?.data?.tax_debt ? (
        <div style={{ height: '120px', width: '100%' }}></div>
      ) : (
        <Table<ITaxDebt> data={data.data.tax_debt} columns={columns} />
      )}
    </div>
  );
};
export default TaxDebtTable;
