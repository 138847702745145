import { IFailedBatch } from '@src/util/interfaces.ts';
import Select, { OptionValue } from '@src/components/Select.tsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  API_URLS_ADMIN,
  COLORS,
  receiptKeywordSearchFilterOption,
} from '@src/util/constants.ts';
import Input from '@src/components/Input.tsx';
import Icon from '@src/components/Icon.tsx';
import searchIcon from '@src/assets/icons/search_black.svg';
import Table from '@src/components/Table.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useState } from 'react';
import { useFailedBatch } from '@src/util/adminHooks.ts';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import BranchFilterSelect from '@src/components/BranchFilterSelect.tsx';
import StaffFilterSelect from '@src/components/StaffFilterSelect.tsx';
import { useAdminStore } from '@src/util/adminStore.ts';
import Button from '@src/components/Button.tsx';
import Modal from '@src/components/Modal.tsx';
import apiAdmin from '@src/util/apiAdmin.ts';
import { useQueryClient } from '@tanstack/react-query';
import Snackbar from '@src/components/SnackBar.tsx';
import IconSVG from '@src/components/IconSVG.tsx';

const KeywordSearchForm = z.object({
  keyword: z.string(),
  keywordSearchFilter: z.object({
    label: z.string(),
    value: z.string(),
  }),
});

type TKeywordSearchForm = z.infer<typeof KeywordSearchForm>;

const ErrorsAdminContent = () => {
  const [keyword, setKeyword] = useState<string>('');

  const [keywordSearchCategory, setKeywordSearchCategory] = useState<
    'company_name' | 'resident_number'
  >('company_name');
  const [admin] = useAdminStore((state) => [state.admin]);

  const [branchFilter, setBranchFilter] = useState<OptionValue | null>({
    label: '전체',
    value: '전체',
  });

  const [staffFilter, setStaffFilter] = useState<OptionValue | null>({
    label: '전체',
    value: '전체',
  });

  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] =
    useState<boolean>(false);
  const [deleteErrorNo, setDeleteErrorNo] = useState<number | null | 'all'>(
    null,
  );
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = useState<string>('');
  const [snackBarType, setSnackBarType] = useState<'info' | 'error'>('info');

  const { data, fetchNextPage, hasNextPage } = useFailedBatch(
    keyword,
    keywordSearchCategory,
    branchFilter,
    staffFilter,
  );

  const flattenedData = data.pages.flatMap((page) => page.data);

  const queryClient = useQueryClient();

  const columnHelper = createColumnHelper<IFailedBatch>();

  const columns: Array<ColumnDef<IFailedBatch, string>> = [
    columnHelper.accessor('company.admin.branch', {
      id: 'company.admin.branch',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '68px' }}>소속지점</div>,
    }),
    columnHelper.accessor('company.adminStaff.name', {
      id: 'adminStaff.name',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '68px' }}>담당자</div>,
    }),
    columnHelper.accessor('company.name', {
      id: 'company_name',
      cell: (info) => {
        return <i>{info.getValue()}</i>;
      },
      header: () => <div style={{ minWidth: '68px' }}>사업자명</div>,
    }),
    columnHelper.accessor('company.resident_number', {
      id: 'resident_number',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>사업자등록번호</div>,
    }),
    columnHelper.accessor('start_date', {
      id: 'start_date',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>검색기간(시작)</div>,
    }),
    columnHelper.accessor('end_date', {
      id: 'end_date',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>검색기간(종료)</div>,
    }),
    columnHelper.accessor('type', {
      id: 'type',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>에러 타입</div>,
    }),
    columnHelper.accessor('message', {
      id: 'message',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>내용</div>,
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      cell: (info) => (
        <i>{moment(info.getValue()).format('YYYY-MM-DD HH:mm:ss')}</i>
      ),
      header: () => <div style={{ minWidth: '64px' }}>생성일</div>,
    }),
    columnHelper.display({
      id: 'delete',
      cell: (props) => {
        return (
          <div className={'flex-row-center'}>
            <IconSVG
              fill={COLORS['red']}
              size={'24'}
              iconSVG={'delete'}
              containerStyle={{ cursor: 'pointer' }}
              onClick={() => {
                setDeleteErrorNo(props.row.original.id);
                setDeleteConfirmModalOpen(true);
              }}
            />
          </div>
        );
      },
      header: () => <div style={{ minWidth: '28px' }}>삭제</div>,
    }),
  ];
  const { register, control, handleSubmit } = useForm<TKeywordSearchForm>({
    resolver: zodResolver(KeywordSearchForm),
    defaultValues: {
      keywordSearchFilter: { label: '사업자명', value: '사업자명' },
    },
  });

  const onSubmit: SubmitHandler<TKeywordSearchForm> = (data) => {
    const keywordSearchCategoryValue = data.keywordSearchFilter.value;
    if (keywordSearchCategoryValue === '사업자명') {
      setKeywordSearchCategory('company_name');
    } else if (keywordSearchCategoryValue === '사업자등록번호') {
      setKeywordSearchCategory('resident_number');
    }

    setKeyword(data.keyword);
  };

  return (
    <>
      <div
        className={'flex-column-flex-start-stretch'}
        style={{
          gap: '24px',
          margin: '0 48px 48px 48px',
        }}
      >
        <p className={'text-md'}>에러 리스트</p>
        <Table<IFailedBatch>
          data={flattenedData}
          columns={columns}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          filterContent={
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={'flex-row-space-between-flex-end'}
            >
              <div className={'table-filter-container'}>
                {admin?.branch === '본점' && (
                  <BranchFilterSelect
                    branchFilter={branchFilter}
                    setBranchFilter={setBranchFilter}
                  />
                )}
                <StaffFilterSelect
                  branchFilter={branchFilter}
                  staffFilter={staffFilter}
                  setStaffFilter={setStaffFilter}
                />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className={'table-filter-keyword-search-container'}>
                    <div style={{ width: '128px' }}>
                      <Controller
                        name="keywordSearchFilter"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              name="keywordSearchFilter"
                              options={receiptKeywordSearchFilterOption}
                              onChange={onChange}
                              value={value}
                              isFilterSearch={true}
                            />
                          );
                        }}
                      />
                    </div>

                    <Input
                      placeholder={'검색어 입력'}
                      register={register('keyword')}
                      style={{
                        height: 'auto',
                        borderRadius: '0',
                        borderLeft: 'none',
                        width: '414px',
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault(); // Prevent the default behavior of the enter key
                          handleSubmit(onSubmit)(); // Execute the submit handler
                        }
                      }}
                    />
                    <Icon
                      iconSrc={searchIcon}
                      style={{
                        padding: '10px',
                        border: `1px solid ${COLORS['gray2']}`,
                        borderLeft: 'none',
                        borderBottomRightRadius: '4px',
                        borderTopRightRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </div>
              <Button
                text={'전체 삭제'}
                style={{
                  background: COLORS['red'],
                  width: '80px',
                  height: '46px',
                }}
                type={'button'}
                onClick={() => {
                  setDeleteErrorNo('all');
                  setDeleteConfirmModalOpen(true);
                }}
              />
            </form>
          }
        />
      </div>
      <Modal
        isOpen={deleteConfirmModalOpen}
        title={'에러내역 삭제'}
        content={'삭제된 에러는 다음 배치에 재시도 되지 않습니다.'}
        okButton={'삭제'}
        cancelButton={'취소'}
        onClose={() => {
          setDeleteErrorNo(null);
          setDeleteConfirmModalOpen(false);
        }}
        onCancel={() => {
          setDeleteErrorNo(null);
          setDeleteConfirmModalOpen(false);
        }}
        onOk={() => {
          let url;
          if (deleteErrorNo === 'all') {
            url = API_URLS_ADMIN.FAILED_BATCH_ALL;
          } else {
            url = API_URLS_ADMIN.FAILED_BATCH + `/${deleteErrorNo}`;
          }

          apiAdmin
            .delete(url)
            .then((res) => {
              console.log(res);
              setDeleteConfirmModalOpen(false);
              queryClient.invalidateQueries({ queryKey: ['failedBatch'] });
              setSnackBarContent(`에러내역이 삭제되었습니다.`);
              setSnackBarType('info');
              setSnackBarOpen(true);
              setTimeout(() => {
                setSnackBarOpen(false);
              }, 3000);
            })
            .catch((err) => {
              console.log(err);
              setDeleteConfirmModalOpen(false);
              setSnackBarContent(`에러내역이 삭제 실패.`);
              setSnackBarType('error');
              setSnackBarOpen(true);
              setTimeout(() => {
                setSnackBarOpen(false);
              }, 3000);
            })
            .finally(() => {
              setDeleteErrorNo(null);
            });
        }}
      />
      <Snackbar
        message={snackBarContent}
        open={snackBarOpen}
        type={snackBarType}
      />
    </>
  );
};

export default ErrorsAdminContent;
