import React, { useState } from 'react';
import Container from '@src/layouts/Container';
import { LABOR_MENU, LABOR_MENU_LIST, URLS } from '@src/util/constants';
import { Link, useNavigate } from 'react-router-dom';
import DetailHeader from '@src/components/DetailHeader';
import HeaderTabs from '@src/components/HeaderTabs';
import { useIsMobile } from '@src/util/hooks.ts';
import { createQuestionStore, useUserStore } from '@src/util/store';
import { formatCurrencyToKRW } from '@src/util/functions';

import Info from '/src/assets/icons/info.svg';
import Check from '/src/assets/icons/check.svg';
import SideBar from '@src/components/SideBar';
import Header from '@src/components/Header.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import BottomWrapper from '@src/components/BottomWrapper.tsx';

interface LaborProps {}

const EndQuestion: React.FC<LaborProps> = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [user] = useUserStore((state) => [state.user]);
  const company_id = user?.selectedCompany?.id || 0;
  const [question] = createQuestionStore(company_id)((state) => [
    state.question,
  ]);
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    LABOR_MENU.REQUEST,
  );
  const [open, setOpen] = useState<boolean>(false);

  const youthAmount = question?.youth_amount || 0;
  const seniorAmount = question?.senior_amount || 0;
  const babyAmount = question?.baby_amount || 0;

  return (
    <Container
      sideBar={<SideBar />}
      header={
        isMobile ? (
          <DetailHeader
            title={'노무지원금 신청'}
            custom={
              <button
                className={'labor-custom'}
                onClick={() => navigate(`${URLS.LABOR}`)}
              >
                처음으로
              </button>
            }
          />
        ) : (
          <Header setOpen={setOpen} />
        )
      }
      headerTab={
        !isMobile && (
          <HeaderTabs
            menus={LABOR_MENU_LIST}
            activeHeaderTab={activeHeaderTab}
            setActiveHeaderTab={(value) => {
              setActiveHeaderTab(value);
            }}
          />
        )
      }
      modal={<CompanyDrawer open={open} setOpen={setOpen} />}
    >
      <div className={'labor-request-wrapper-container'}>
        <div className={'labor-request-container'}>
          <div className={'labor-end-container-wrapper'}>
            <div className="labor-end-container">
              <div className={'expected-labor-amount-wrapper'}>
                <div className={'expected-labor-amount-box'}>
                  <h2 className={'amount-title'}>예상 노무지원금</h2>
                  <p className={'amount-text'}>
                    {formatCurrencyToKRW(
                      youthAmount + seniorAmount + babyAmount,
                    )}
                  </p>
                  <div className={'labor-end-button-wrapper'}>
                    <div className={'each-button-wrapper'}>
                      <div className={'left-box'}>
                        <p className={'left-box-title'}>청년일자리도약장려금</p>
                        <p className={'left-box-text'}>
                          약 {formatCurrencyToKRW(youthAmount)}
                        </p>
                      </div>
                      {(question?.youth_amount === null ||
                        question?.youth_amount === undefined) && (
                        <button
                          className={'examine-button'}
                          onClick={() => navigate(`${URLS.LABOR}/youth/1`)}
                        >
                          검토하기
                        </button>
                      )}
                      {!(
                        question?.youth_amount === null ||
                        question?.youth_amount === undefined
                      ) && (
                        <button className={'examine-button-finish'}>
                          <img src={Check} alt={'check'} />
                          검토 완료
                        </button>
                      )}
                    </div>
                    <div className={'each-button-wrapper'}>
                      <div className={'left-box'}>
                        <p className={'left-box-title'}>시니어인턴 지원금</p>
                        <p className={'left-box-text'}>
                          약 {formatCurrencyToKRW(seniorAmount)}
                        </p>
                      </div>
                      {(question?.senior_amount === null ||
                        question?.senior_amount === undefined) && (
                        <button
                          className={'examine-button'}
                          onClick={() => navigate(`${URLS.LABOR}/senior/1`)}
                        >
                          검토하기
                        </button>
                      )}
                      {!(
                        question?.senior_amount === null ||
                        question?.senior_amount === undefined
                      ) && (
                        <button className={'examine-button-finish'}>
                          <img src={Check} alt={'check'} />
                          검토 완료
                        </button>
                      )}
                    </div>
                    <div className={'each-button-wrapper'}>
                      <div className={'left-box'}>
                        <p className={'left-box-title'}>육아휴직 지원금</p>
                        <p className={'left-box-text'}>
                          최대 {formatCurrencyToKRW(babyAmount)}
                        </p>
                      </div>
                      {(question?.baby_amount === null ||
                        question?.baby_amount === undefined) && (
                        <button
                          className={'examine-button'}
                          onClick={() => navigate(`${URLS.LABOR}/baby/1`)}
                        >
                          검토하기
                        </button>
                      )}
                      {!(
                        question?.baby_amount === null ||
                        question?.baby_amount === undefined
                      ) && (
                        <button className={'examine-button-finish'}>
                          <img src={Check} alt={'check'} />
                          검토 완료
                        </button>
                      )}
                    </div>
                  </div>
                  <div className={'labor-end-info-wrapper'}>
                    <img src={Info} alt={'info'} />
                    <p className={'labor-end-info-text'}>
                      위 금액은 1인당 연간 기준 금액입니다.
                    </p>
                  </div>
                </div>
              </div>
              <div className={'explain-text-wrapper'}>
                <h3 className={'text-base'}>신청 및 상담 안내</h3>
                <div className={'explain-text-box'}>
                  <p className={'explain-text'}>
                    노무지원금 신청은 노무법인 정율과 위탁계약을 체결하신 후
                    정확한 금액 및 지원 여부를 확인 후 진행이 가능합니다.
                  </p>
                  <p className={'explain-text'}>
                    지원금 대행 수수료의 경우{' '}
                    <span className={'explain-blue-text'}>
                      별도 착수금은 발생하지 않으며,
                    </span>
                    실제 입금 받으신 금액의{' '}
                    <span className={'explain-blue-text'}>15%(VAT 별도)</span>로
                    책정드리고 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <BottomWrapper>
            <Link className={'button-default'} to={`${URLS.LABOR}/register`}>
              접수하기
            </Link>
          </BottomWrapper>
        </div>
      </div>
    </Container>
  );
};
export default EndQuestion;
