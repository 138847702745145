import { FC } from 'react';

interface MoneyIconProps {
  fill: string;
  size: string;
}

const MoneyIcon: FC<MoneyIconProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon / money">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2C6 1.44772 6.44772 1 7 1H17C17.5523 1 18 1.44772 18 2C18 2.55228 17.5523 3 17 3H7C6.44772 3 6 2.55228 6 2ZM4 5C4 4.44772 4.44772 4 5 4H19C19.5523 4 20 4.44772 20 5C20 5.55228 19.5523 6 19 6H5C4.44772 6 4 5.55228 4 5ZM1 10.1538C1 8.48311 2.27465 7 4 7H20C21.7254 7 23 8.48311 23 10.1538V19.8462C23 21.5169 21.7254 23 20 23H4C2.27465 23 1 21.5169 1 19.8462V10.1538ZM4 9C3.51621 9 3 9.44551 3 10.1538V10.5H3.5C4.32843 10.5 5 9.82843 5 9H4ZM7 9C7 10.933 5.433 12.5 3.5 12.5H3V17.5H3.5C5.433 17.5 7 19.067 7 21H17C17 19.067 18.567 17.5 20.5 17.5H21V12.5H20.5C18.567 12.5 17 10.933 17 9H7ZM19 9C19 9.82843 19.6716 10.5 20.5 10.5H21V10.1538C21 9.44551 20.4838 9 20 9H19ZM21 19.5H20.5C19.6716 19.5 19 20.1716 19 21H20C20.4838 21 21 20.5545 21 19.8462V19.5ZM5 21C5 20.1716 4.32843 19.5 3.5 19.5H3V19.8462C3 20.5545 3.51621 21 4 21H5ZM12.25 13.75C11.5596 13.75 11 14.3096 11 15C11 15.6904 11.5596 16.25 12.25 16.25C12.9404 16.25 13.5 15.6904 13.5 15C13.5 14.3096 12.9404 13.75 12.25 13.75ZM9 15C9 13.2051 10.4551 11.75 12.25 11.75C14.0449 11.75 15.5 13.2051 15.5 15C15.5 16.7949 14.0449 18.25 12.25 18.25C10.4551 18.25 9 16.7949 9 15Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default MoneyIcon;
