import React from 'react';
import { IReceipt } from '@src/util/interfaces.ts';
import Table from '@src/components/Table.tsx';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import Button from '@src/components/Button.tsx';
import { COLORS } from '@src/util/constants.ts';
import { convertToKST, formatDateString } from '@src/util/functions.ts';
import { useReceipt } from '@src/util/hooks.ts';

const columnHelper = createColumnHelper<IReceipt>();

interface ReceiptContentProps {
  selectedFilterValue: string;
  selectedStatusValue: string;
  setReceiptModalUrl: (value: string) => void;
  setReceiptModalOpen: (value: boolean) => void;
}

const ReceiptContent: React.FC<ReceiptContentProps> = ({
  selectedFilterValue,
  selectedStatusValue,
  setReceiptModalUrl,
  setReceiptModalOpen,
}) => {
  const { data } = useReceipt();

  const columns: Array<ColumnDef<IReceipt, string>> = [
    columnHelper.display({
      id: 'actions',
      cell: (props) => {
        return (
          <div className={'flex-row-center'}>
            <Button
              text={'보기'}
              style={{
                backgroundColor: COLORS['white'],
                color: COLORS['primary'],
                fontSize: '12px',
                width: '44px',
                padding: '4px 10px 4px 10px',
                border: '1px solid #3182F6',
                borderRadius: '50px',
              }}
              onClick={(e) => {
                e.stopPropagation();
                setReceiptModalUrl(props.row.original.url);
                setReceiptModalOpen(true);
              }}
            />
          </div>
        );
      },
      header: () => <div style={{ minWidth: '44px' }}>영수증</div>,
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      cell: (info) => <i>{convertToKST(info.getValue())}</i>,
      header: () => <div style={{ minWidth: '68px' }}>업로드 날짜</div>,
    }),
    columnHelper.accessor('tx_dt', {
      id: 'tx_dt',
      cell: (info) => <i>{formatDateString(info.getValue())}</i>,
      header: () => <div style={{ minWidth: '68px' }}>영수증 날짜</div>,
    }),
    columnHelper.accessor('tot_amount', {
      id: 'tot_amount',
      cell: (info) => <i>{info.getValue().toLocaleString()}</i>,
      header: () => <div style={{ minWidth: '88px' }}>금액</div>,
    }),
    columnHelper.accessor('business_name', {
      id: 'business_name',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '100px' }}>상호</div>,
    }),
    columnHelper.accessor('purpose', {
      id: 'purpose',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '64px' }}>구분</div>,
    }),
    columnHelper.accessor('status', {
      id: 'status',
      cell: (info) => {
        let color = COLORS['main'];
        if (info.getValue() === '완료') {
          color = COLORS['primary'];
        } else if (info.getValue() === '반려') {
          color = COLORS['red'];
        }
        return <i style={{ color: color }}>{info.getValue()}</i>;
      },
      header: () => <div style={{ minWidth: '64px' }}>처리여부</div>,
    }),

    columnHelper.accessor('reason', {
      id: 'reason',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '104px' }}>반려 사유</div>,
    }),
  ];

  return (
    <Table<IReceipt>
      data={data.data.data
        .filter(
          (item) =>
            item.purpose === selectedFilterValue ||
            selectedFilterValue === '전체',
        )
        .filter(
          (item) =>
            item.status === selectedStatusValue ||
            selectedStatusValue === '전체',
        )
        .map((receipt) => {
          if (receipt.status === '대기' || receipt.status === '반려') {
            receipt.tx_dt = '-';
            receipt.tot_amount = '-';
            receipt.business_name = '-';
            receipt.purpose = '-';
            if (receipt.status === '대기') {
              receipt.reason = '-';
            }
          }
          return receipt;
        })}
      columns={columns}
      onRowClick={(id) => `/tax/receipt/${id}`}
    />
  );
};

export default ReceiptContent;
