import React, { useState } from 'react';
import Container from '@src/layouts/Container';
import DetailHeader from '@src/components/DetailHeader.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserStore } from '@src/util/store.ts';
import {
  convertToKST,
  formatDateString,
  formatFileSize,
  getFileExtension,
} from '@src/util/functions.ts';
import Icon from '@src/components/Icon.tsx';
import { API_URLS, COLORS, FILE_ICONS } from '@src/util/constants.ts';
import Button from '@src/components/Button.tsx';
import SideBar from '@src/components/SideBar.tsx';
import IconSVG from '@src/components/IconSVG.tsx';
import Modal from '@src/components/Modal.tsx';
import api from '@src/util/api.ts';
import { useReceipt, useScrollToTop } from '@src/util/hooks.ts';

interface ReceiptDetailProps {}

const ReceiptDetail: React.FC<ReceiptDetailProps> = () => {
  const { id = '' } = useParams<{
    id: string;
  }>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);

  const { data } = useReceipt();

  const [user] = useUserStore((state) => [state.user, state.setUser]);

  const navigate = useNavigate();

  useScrollToTop();

  const receipt = data.data.data.filter(
    (receipt) => receipt.id === parseInt(id),
  )[0];

  if (receipt.status !== '완료') {
    receipt.tx_dt = '-';
    receipt.tot_amount = '-';
    receipt.business_name = '-';
    receipt.purpose = '-';
    if (receipt.status === '대기') {
      receipt.reason = '-';
    }
  }

  const type = getFileExtension(receipt.filename);
  return (
    <Container
      header={
        <DetailHeader
          title={'영수증 상세'}
          custom={
            <IconSVG
              iconSVG={'delete'}
              size={'24'}
              fill={COLORS['red']}
              onClick={() => setDeleteModalOpen(true)}
              containerStyle={{
                position: 'absolute',
                right: 0,
                cursor: 'pointer',
              }}
            />
          }
        />
      }
      sideBar={<SideBar />}
      modal={
        <>
          <Modal
            isOpen={deleteModalOpen}
            onOk={() => {
              setDeleteModalOpen(false);
              api
                .delete(
                  API_URLS.RECEIPT + `/${user?.selectedCompany?.id}/${id}`,
                )
                .then(() => {
                  setDeleteConfirmModalOpen(true);
                });
            }}
            title={'영수증 삭제'}
            content={'영수증을 삭제하시겠습니까?'}
            okButton={'삭제'}
            cancelButton={'취소'}
            onCancel={() => setDeleteModalOpen(false)}
            onClose={() => {
              setDeleteModalOpen(false);
            }}
          />
          <Modal
            isOpen={deleteConfirmModalOpen}
            onOk={() => {
              setDeleteConfirmModalOpen(false);
              navigate(-1);
            }}
            title={'영수증 삭제'}
            content={'영수증이 삭제되었습니다.'}
            okButton={'확인'}
            onClose={() => {
              setDeleteConfirmModalOpen(false);
            }}
          />
        </>
      }
    >
      <div className={'receipt-detail-wrapper-container'}>
        <div className={'receipt-detail-container'}>
          <img src={receipt.url} alt="" />
          <div className={'receipt-detail-body-container'}>
            <div className={'receipt-detail-body-item-container'}>
              <p
                className={'text-regular-bold'}
                style={{ width: '88px', boxSizing: 'border-box' }}
              >
                영수증
              </p>

              <div className={'receipt-detail-icon-row'}>
                <Icon
                  iconSrc={FILE_ICONS[type as keyof typeof FILE_ICONS]}
                  style={{ width: '20px', height: '20px', cursor: 'text' }}
                  imgStyle={{ width: '20px', height: '20px' }}
                />
                <p
                  className={'text-regular'}
                  style={{ width: '244px', boxSizing: 'border-box' }}
                >
                  {receipt.filename} ({formatFileSize(receipt.size)})
                </p>
              </div>
            </div>
            <div className={'receipt-detail-body-item-container'}>
              <p
                className={'text-regular-bold'}
                style={{ width: '88px', boxSizing: 'border-box' }}
              >
                업로드 날짜
              </p>
              <p
                className={'text-regular'}
                style={{ width: '240px', boxSizing: 'border-box' }}
              >
                {receipt.created_at.trim() === ''
                  ? '-'
                  : convertToKST(receipt.created_at)}
              </p>
            </div>
            <div className={'receipt-detail-body-item-container'}>
              <p
                className={'text-regular-bold'}
                style={{ width: '88px', boxSizing: 'border-box' }}
              >
                영수증 날짜
              </p>
              <p
                className={'text-regular'}
                style={{ width: '240px', boxSizing: 'border-box' }}
              >
                {receipt.tx_dt.trim() === '-' || receipt.tx_dt.trim() === ''
                  ? '-'
                  : formatDateString(receipt.tx_dt)}
              </p>
            </div>
            <div className={'receipt-detail-body-item-container'}>
              <p
                className={'text-regular-bold'}
                style={{ width: '88px', boxSizing: 'border-box' }}
              >
                금액
              </p>
              <p
                className={'text-regular'}
                style={{ width: '240px', boxSizing: 'border-box' }}
              >
                {receipt.tot_amount.toLocaleString()}
                {receipt.tot_amount === '-' ? '' : '원'}
              </p>
            </div>
            <div className={'receipt-detail-body-item-container'}>
              <p
                className={'text-regular-bold'}
                style={{ width: '88px', boxSizing: 'border-box' }}
              >
                상호
              </p>
              <p
                className={'text-regular'}
                style={{ width: '240px', boxSizing: 'border-box' }}
              >
                {receipt.business_name}
              </p>
            </div>
            <div className={'receipt-detail-body-item-container'}>
              <p
                className={'text-regular-bold'}
                style={{ width: '88px', boxSizing: 'border-box' }}
              >
                구분
              </p>
              <p
                className={'text-regular'}
                style={{ width: '240px', boxSizing: 'border-box' }}
              >
                {receipt.purpose}
              </p>
            </div>
            <div className={'receipt-detail-body-item-container'}>
              <p
                className={'text-regular-bold'}
                style={{ width: '88px', boxSizing: 'border-box' }}
              >
                처리여부
              </p>
              <p
                className={'text-regular'}
                style={{
                  width: '240px',
                  boxSizing: 'border-box',
                  color: `${
                    receipt.status === '반려'
                      ? COLORS['red']
                      : COLORS['primary']
                  }`,
                }}
              >
                {receipt.status}
              </p>
            </div>
            <div className={'receipt-detail-body-item-container'}>
              <p
                className={'text-regular-bold'}
                style={{ width: '88px', boxSizing: 'border-box' }}
              >
                반려 사유
              </p>
              <p
                className={'text-regular'}
                style={{
                  width: '240px',
                  boxSizing: 'border-box',
                }}
              >
                {receipt.reason.trim().length === 0 ? '-' : receipt.reason}
              </p>
            </div>
          </div>
          <Button
            text={'목록으로'}
            style={{
              padding: '14px 10px',
              border: `1px solid ${COLORS['main']}`,
              borderRadius: '3px',
              color: COLORS['main'],
              backgroundColor: COLORS['white'],
              width: '200px',
              alignSelf: 'center',
            }}
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default ReceiptDetail;
