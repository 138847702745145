import React from 'react';

interface DividerOrProps {
  text?: string;
}

const DividerOr: React.FC<DividerOrProps> = () => {
  return (
    <div className="divider-or">
      <div className="divider-or-line"></div>
      <p>또는</p>
      <div className="divider-or-line"></div>
    </div>
  );
};

export default DividerOr;
