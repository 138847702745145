import React from 'react';
import Container from '@src/layouts/Container.tsx';
import AdminSideBar from '@src/components/AdminSideBar.tsx';

import ExpectedTaxAdminContent from '@src/pages/admin/expected-tax/ExpectedTaxAdminContent.tsx';

interface ExpectedTaxAdminProps {}

const ExpectedTaxAdmin: React.FC<ExpectedTaxAdminProps> = () => {
  return (
    <Container sideBar={<AdminSideBar />}>
      <ExpectedTaxAdminContent />
    </Container>
  );
};

export default ExpectedTaxAdmin;
