import Table from '@src/components/Table.tsx';
import {
  IExpectedTaxAdmin,
  IExpectedTaxYearlyData,
} from '@src/util/interfaces.ts';
import Select, { OptionValue } from '@src/components/Select.tsx';
import {
  formatWithThousandSeparator,
  parseThousandSeparatedNumber,
} from '@src/util/functions.ts';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  API_URLS_ADMIN,
  COLORS,
  receiptKeywordSearchFilterOption,
} from '@src/util/constants.ts';
import Input from '@src/components/Input.tsx';
import Icon from '@src/components/Icon.tsx';
import searchIcon from '@src/assets/icons/search_black.svg';
import Snackbar from '@src/components/SnackBar.tsx';
import { useEffect, useMemo, useState } from 'react';
import { useAdminStore } from '@src/util/adminStore.ts';
import { useExpectedTaxAdmin } from '@src/util/adminHooks.ts';
import { useQueryClient } from '@tanstack/react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import apiAdmin from '@src/util/apiAdmin.ts';
import CheckBoxRHF from '@src/components/CheckBoxRHF.tsx';
import Button from '@src/components/Button.tsx';
import { z } from 'zod';
import BranchFilterSelect from '@src/components/BranchFilterSelect.tsx';
import StaffFilterSelect from '@src/components/StaffFilterSelect.tsx';

const KeywordSearchForm = z.object({
  keyword: z.string(),
  keywordSearchFilter: z.object({
    label: z.string(),
    value: z.string(),
  }),
});

const ExpectedTaxForm = z.object({
  credit_ratio: z.array(z.string()),
  credit_deduction: z.array(z.string()),
  interim_tax: z.array(z.string()),
  expected_tax: z.array(
    z.record(
      z.string(),
      z.object({
        inventory_adjust: z.string(),
        asset_adjust: z.string(),
        depreciation: z.string(),
        tax_deduction: z.string(),
        paid_tax: z.string(),
      }),
    ),
  ),
  is_3_month_vat_period_calc: z.array(z.boolean()),
});

type TKeywordSearchForm = z.infer<typeof KeywordSearchForm>;
type TExpectedTaxForm = z.infer<typeof ExpectedTaxForm>;

const ExpectedTaxAdminContent = () => {
  const [admin] = useAdminStore((state) => [state.admin]);

  const [branchFilter, setBranchFilter] = useState<OptionValue | null>({
    label: '전체',
    value: '전체',
  });

  const [staffFilter, setStaffFilter] = useState<OptionValue | null>({
    label: '전체',
    value: '전체',
  });

  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = useState<string>('');

  const [keyword, setKeyword] = useState<string>('');

  const [keywordSearchCategory, setKeywordSearchCategory] = useState<
    'name' | 'resident_number'
  >('name');
  const [selectedYear, setSelectedYear] = useState<string | null>('2024');

  const extractYears = (data: IExpectedTaxAdmin[]): string[] => {
    const years = new Set<string>();
    data.forEach((item) => {
      Object.keys(item.data).forEach((year) => years.add(year));
    });
    return Array.from(years);
  };

  const { data, fetchNextPage, hasNextPage } = useExpectedTaxAdmin(
    branchFilter,
    keyword,
    keywordSearchCategory,
    staffFilter,
  );

  const queryClient = useQueryClient();

  const flattenedData = useMemo(() => {
    return data?.pages.flatMap((page) => page.data) || [];
  }, [data]);

  const { register, control, handleSubmit } = useForm<TKeywordSearchForm>({
    resolver: zodResolver(KeywordSearchForm),
    defaultValues: {
      keywordSearchFilter: { label: '사업자명', value: '사업자명' },
    },
  });

  const {
    register: registerExpectedTax,
    getValues,
    reset,
    control: controlExpectedTax,
  } = useForm<TExpectedTaxForm>({
    resolver: zodResolver(ExpectedTaxForm),
    defaultValues: {
      credit_ratio: [],
      credit_deduction: [],
      interim_tax: [],
      expected_tax: [],
      is_3_month_vat_period_calc: [],
    },
  });

  useEffect(() => {
    if (flattenedData) {
      const formatNestedData = (data: {
        [year: string]: IExpectedTaxYearlyData;
      }) => {
        const formattedData: { [year: string]: IExpectedTaxYearlyData } = {};
        for (const year in data) {
          formattedData[year] = {
            inventory_adjust: formatWithThousandSeparator(
              data[year].inventory_adjust.toString(),
            ),
            asset_adjust: formatWithThousandSeparator(
              data[year].asset_adjust.toString(),
            ),
            depreciation: formatWithThousandSeparator(
              data[year].depreciation.toString(),
            ),
            tax_deduction: formatWithThousandSeparator(
              data[year].tax_deduction.toString(),
            ),
            paid_tax: formatWithThousandSeparator(
              data[year].paid_tax.toString(),
            ),
          };
        }
        return formattedData;
      };

      const formattedFlattenedData = flattenedData.map((data) => ({
        ...data,
        data: formatNestedData(data.data),
      }));

      reset({
        credit_ratio: formattedFlattenedData.map((data) =>
          formatWithThousandSeparator(data.credit_ratio.toString()),
        ),
        credit_deduction: formattedFlattenedData.map((data) =>
          formatWithThousandSeparator(data.credit_deduction.toString()),
        ),
        interim_tax: formattedFlattenedData.map((data) =>
          formatWithThousandSeparator(data.interim_tax.toString()),
        ),
        expected_tax: formattedFlattenedData.map((data) => data.data),
        is_3_month_vat_period_calc: formattedFlattenedData.map(
          (data) => data.is_3_month_vat_period_calc,
        ),
      });
    }
  }, [flattenedData, reset]);

  const onSubmit: SubmitHandler<TKeywordSearchForm> = (data) => {
    const keywordSearchCategoryValue = data.keywordSearchFilter.value;
    if (keywordSearchCategoryValue === '사업자명') {
      setKeywordSearchCategory('name');
    } else if (keywordSearchCategoryValue === '사업자등록번호') {
      setKeywordSearchCategory('resident_number');
    }
    setKeyword(data.keyword);
  };

  const columnHelper = createColumnHelper<IExpectedTaxAdmin>();
  const expectedCorpTaxColumns = (
    selectedYear: string | null,
  ): Array<ColumnDef<IExpectedTaxAdmin, string>> => {
    if (!selectedYear) return [];
    return [
      columnHelper.accessor(`data.${selectedYear}.inventory_adjust`, {
        id: 'inventory_adjust',
        cell: (props) => {
          return (
            <Controller
              name={`expected_tax.${props.row.index}.${selectedYear}.inventory_adjust`}
              control={controlExpectedTax}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    useThousandSeparator={true}
                    value={value}
                    onChange={onChange}
                    style={{
                      width: '120px',
                      borderRadius: '4px',
                      textAlign: 'right',
                    }}
                  />
                );
              }}
            />
          );
        },
        header: '재고자산 순감소액',
      }),
      columnHelper.accessor(`data.${selectedYear}.asset_adjust`, {
        id: 'asset_adjust',
        cell: (props) => {
          return (
            <Controller
              name={`expected_tax.${props.row.index}.${selectedYear}.asset_adjust`}
              control={controlExpectedTax}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    useThousandSeparator={true}
                    value={value}
                    onChange={onChange}
                    style={{
                      width: '120px',
                      borderRadius: '4px',
                      textAlign: 'right',
                    }}
                  />
                );
              }}
            />
          );
        },
        header: '자산매입 조정',
      }),
      columnHelper.accessor(`data.${selectedYear}.depreciation`, {
        id: 'depreciation',
        cell: (props) => {
          return (
            <Controller
              name={`expected_tax.${props.row.index}.${selectedYear}.depreciation`}
              control={controlExpectedTax}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    useThousandSeparator={true}
                    value={value}
                    onChange={onChange}
                    style={{
                      width: '120px',
                      borderRadius: '4px',
                      textAlign: 'right',
                    }}
                  />
                );
              }}
            />
          );
        },
        header: '감가상각비',
      }),
      columnHelper.accessor(`data.${selectedYear}.tax_deduction`, {
        id: 'tax_deduction',
        cell: (props) => {
          return (
            <Controller
              name={`expected_tax.${props.row.index}.${selectedYear}.tax_deduction`}
              control={controlExpectedTax}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    useThousandSeparator={true}
                    value={value}
                    onChange={onChange}
                    style={{
                      width: '120px',
                      borderRadius: '4px',
                      textAlign: 'right',
                    }}
                  />
                );
              }}
            />
          );
        },
        header: '세액 감면/공제',
      }),
      columnHelper.accessor(`data.${selectedYear}.paid_tax`, {
        id: 'paid_tax',
        cell: (props) => {
          return (
            <Controller
              name={`expected_tax.${props.row.index}.${selectedYear}.paid_tax`}
              control={controlExpectedTax}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    useThousandSeparator={true}
                    value={value}
                    onChange={onChange}
                    style={{
                      width: '120px',
                      borderRadius: '4px',
                      textAlign: 'right',
                    }}
                  />
                );
              }}
            />
          );
        },
        header: '기납부세액',
      }),
    ];
  };

  const handleRowSave = (rowIndex: number, company_id: number) => {
    const formData = {
      credit_ratio: parseThousandSeparatedNumber(
        getValues(`credit_ratio.${rowIndex}`),
      ),
      credit_deduction: parseThousandSeparatedNumber(
        getValues(`credit_deduction.${rowIndex}`),
      ),
      interim_tax: parseThousandSeparatedNumber(
        getValues(`interim_tax.${rowIndex}`),
      ),
      is_3_month_vat_period_calc: getValues(
        `is_3_month_vat_period_calc.${rowIndex}`,
      ),
      yearlyData: {
        year: selectedYear,
        data: {
          inventory_adjust: parseThousandSeparatedNumber(
            getValues(
              `expected_tax.${rowIndex}.${selectedYear}.inventory_adjust`,
            ),
          ),
          asset_adjust: parseThousandSeparatedNumber(
            getValues(`expected_tax.${rowIndex}.${selectedYear}.asset_adjust`),
          ),
          depreciation: parseThousandSeparatedNumber(
            getValues(`expected_tax.${rowIndex}.${selectedYear}.depreciation`),
          ),
          tax_deduction: parseThousandSeparatedNumber(
            getValues(`expected_tax.${rowIndex}.${selectedYear}.tax_deduction`),
          ),
          paid_tax: parseThousandSeparatedNumber(
            getValues(`expected_tax.${rowIndex}.${selectedYear}.paid_tax`),
          ),
        },
      },
    };
    apiAdmin
      .patch(API_URLS_ADMIN.EXPECTED_TAX + `/${company_id}`, formData)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['expectedTaxAdmin'] });
        setSnackBarContent('저장되었습니다.');
        setSnackBarOpen(true);
        setTimeout(() => {
          setSnackBarOpen(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns: Array<ColumnDef<IExpectedTaxAdmin, string>> = [
    columnHelper.group({
      id: 'company_group',
      header: () => (
        <div>사업자 정보</div> // Adjust the width manually
      ),
      columns: [
        columnHelper.accessor('company.admin.branch', {
          id: 'admin.branch',
          cell: (info) => {
            return <i>{info.getValue()}</i>;
          },
          size: 88,
          header: '소속지점',
        }),
        columnHelper.accessor('company.adminStaff.name', {
          id: 'adminStaff.name',
          cell: (info) => {
            return <i>{info.getValue()}</i>;
          },
          size: 88,
          header: '담당자',
        }),
        columnHelper.accessor('company.name', {
          id: 'company.name',
          cell: (info) => {
            return <i>{info.getValue()}</i>;
          },
          size: 160,
          header: '사업자명',
        }),
        columnHelper.accessor('company.resident_number', {
          id: 'company.resident_number',
          cell: (info) => {
            return <i>{info.getValue()}</i>;
          },
          size: 92,
          header: '사업자등록번호',
        }),
      ],
    }),
    columnHelper.group({
      id: 'expected-additional-tax',
      header: '예상 부가세',
      columns: [
        columnHelper.accessor('credit_ratio', {
          id: 'credit_ratio',
          cell: (props) => {
            return (
              <Controller
                name={`credit_ratio.${props.row.index}`}
                control={controlExpectedTax}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      useThousandSeparator={true}
                      value={value}
                      onChange={onChange}
                      style={{
                        width: '120px',
                        borderRadius: '4px',
                        textAlign: 'right',
                      }}
                      maxLength={3}
                    />
                  );
                }}
              />
            );
          },
          header: '카드매입세액공제비율',
          size: 120,
        }),
        columnHelper.accessor('credit_deduction', {
          id: 'credit_deduction',
          cell: (props) => {
            return (
              <Controller
                name={`credit_deduction.${props.row.index}`}
                control={controlExpectedTax}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      useThousandSeparator={true}
                      value={value}
                      onChange={onChange}
                      style={{
                        width: '120px',
                        borderRadius: '4px',
                        textAlign: 'right',
                      }}
                    />
                  );
                }}
              />
            );
          },
          header: '신용카드발행공제 소진액',
          size: 120,
        }),
        columnHelper.accessor('interim_tax', {
          id: 'interim_tax',
          cell: (props) => {
            return (
              <Controller
                name={`interim_tax.${props.row.index}`}
                control={controlExpectedTax}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      useThousandSeparator={true}
                      value={value}
                      onChange={onChange}
                      style={{
                        width: '120px',
                        borderRadius: '4px',
                        textAlign: 'right',
                      }}
                    />
                  );
                }}
              />
            );
          },
          header: '예정고지세액 등',
          size: 120,
        }),
        columnHelper.accessor('is_3_month_vat_period_calc', {
          id: 'is_3_month_vat_period_calc',
          cell: (props) => {
            return (
              <CheckBoxRHF
                register={registerExpectedTax(
                  `is_3_month_vat_period_calc.${props.row.index}`,
                )}
                style={{ width: '30px', borderRadius: '4px' }}
              />
            );
          },
          header: '3개월 단위',
          size: 30,
        }),
      ],
    }),
    columnHelper.group({
      id: 'expected-corp-tax',
      header: () => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '16px',
            justifyContent: 'center',
          }}
        >
          예상 법인세
          <Select
            name={'selectedYear'}
            options={extractYears(flattenedData).map((year) => ({
              label: year,
              value: year,
            }))}
            value={
              selectedYear ? { label: selectedYear, value: selectedYear } : null
            }
            onChange={(option) =>
              setSelectedYear(option === null ? option : option.value)
            }
            placeholder="년도 선택"
            size={'sm'}
          />
        </div>
      ),
      columns: selectedYear ? expectedCorpTaxColumns(selectedYear) : [],
    }),
    columnHelper.display({
      id: 'save',
      cell: (props) => {
        return (
          <div className={'flex-row-center'}>
            <>
              <Button
                text={'저장'}
                style={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: '600',
                  width: '92px',
                  padding: '4px 10px',
                  border: '1px solid #3182F6',
                  borderRadius: '4px',
                }}
                onClick={() =>
                  handleRowSave(props.row.index, props.row.original.company_id)
                }
              />
            </>
          </div>
        );
      },
      header: '저장',
      size: 44,
    }),
  ];
  return (
    <>
      <div
        className={'flex-column-flex-start-stretch'}
        style={{ gap: '24px', margin: '0 48px 48px 48px' }}
      >
        <p className={'text-md'}>예상세금 관리</p>

        <Table<IExpectedTaxAdmin>
          data={flattenedData}
          columns={columns}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          filterContent={
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={'table-filter-container'}>
                {admin?.branch === '본점' && (
                  <BranchFilterSelect
                    branchFilter={branchFilter}
                    setBranchFilter={setBranchFilter}
                  />
                )}

                <StaffFilterSelect
                  branchFilter={branchFilter}
                  staffFilter={staffFilter}
                  setStaffFilter={setStaffFilter}
                />

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className={'table-filter-keyword-search-container'}>
                    <div style={{ width: '128px' }}>
                      <Controller
                        name="keywordSearchFilter"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              name="keywordSearchFilter"
                              options={receiptKeywordSearchFilterOption}
                              onChange={onChange}
                              value={value}
                              isFilterSearch={true}
                            />
                          );
                        }}
                      />
                    </div>

                    <Input
                      placeholder={'검색어 입력'}
                      register={register('keyword')}
                      style={{
                        height: 'auto',
                        borderRadius: '0',
                        borderLeft: 'none',
                        width: '414px',
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault(); // Prevent the default behavior of the enter key
                          handleSubmit(onSubmit)(); // Execute the submit handler
                        }
                      }}
                    />
                    <Icon
                      iconSrc={searchIcon}
                      style={{
                        padding: '10px',
                        border: `1px solid ${COLORS['gray2']}`,
                        borderLeft: 'none',
                        borderBottomRightRadius: '4px',
                        borderTopRightRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </div>
            </form>
          }
        />
      </div>
      <Snackbar message={snackBarContent} open={snackBarOpen} type={'info'} />
    </>
  );
};

export default ExpectedTaxAdminContent;
