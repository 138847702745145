import { FC } from 'react';
import CustomDrawer from '@src/components/CustomDrawer.tsx';
import { Dispatch, SetStateAction } from 'react';

interface RegisterCompanyConfirmDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onOk: () => void;
}

const RegisterCompanyConfirmDrawer: FC<RegisterCompanyConfirmDrawerProps> = ({
  open,
  setOpen,
  onOk,
}) => {
  return (
    <CustomDrawer
      open={open}
      setOpen={setOpen}
      okButton={'확인'}
      onOk={() => {
        setOpen(false);
        onOk();
      }}
    >
      <div
        className={'flex-column-flex-start-stretch'}
        style={{ padding: '14px', gap: '8px' }}
      >
        <p className={'text-md'}>승인 대기</p>
        <p className={'text-regular'}>
          현재 사업장 승인 대기 중입니다. <br />
          승인이 완료된 후 서비스 이용이가능합니다.
        </p>
      </div>
    </CustomDrawer>
  );
};

export default RegisterCompanyConfirmDrawer;
