import { Drawer } from 'vaul';
import { Dispatch, SetStateAction, useState } from 'react';
import Button from '@src/components/Button.tsx';
import IconSVG from '@src/components/IconSVG.tsx';
import { COLORS } from '@src/util/constants.ts';
import { logoutLogic, logoutLogicAdmin } from '@src/util/functions.ts';
import { useNavigate } from 'react-router-dom';

interface ErrorModalProps {
  errorModalOpen: boolean;
  setErrorModalOpen: Dispatch<SetStateAction<boolean>>;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  errorModalOpen,
  setErrorModalOpen,
}) => {
  const navigate = useNavigate();
  const [isAdmin] = useState<boolean>(location.pathname.startsWith('/admin'));
  return (
    <Drawer.Root
      open={errorModalOpen}
      onOpenChange={setErrorModalOpen}
      shouldScaleBackground
    >
      <Drawer.Portal>
        <Drawer.Overlay className="filter-drawer-overlay" />
        <Drawer.Content data-vaul-no-drag className="filter-drawer-content">
          <div
            className={'filter-drawer-content-body'}
            style={{
              minHeight: '200px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: '12px',
              marginBottom: '12px',
            }}
          >
            <div>
              <IconSVG fill={COLORS['primary']} size={'48'} iconSVG={'error'} />
              <p
                className={'text-regular'}
                style={{ textAlign: 'center', marginTop: '12px' }}
              >
                서비스가 불안정 합니다.
                <br />
                잠시 후 다시 접속해 주시기 바랍니다.
              </p>
            </div>
            <Button
              text={'확인'}
              onClick={() => {
                setErrorModalOpen(false);
                if (isAdmin) {
                  logoutLogicAdmin(navigate);
                } else {
                  logoutLogic(navigate);
                }
              }}
            />
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default ErrorModal;
