import { API_URLS_ADMIN } from '@src/util/constants.ts';
import {
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import {
  IBranchAdminApi,
  IBranchApi,
  IBranchStaff,
  ICertificatesApi,
  ICertificatesApiParams,
  IClientApi,
  IClientApiParams,
  ICompanySyncApi,
  ICompanySyncApiParams,
  IEtcSales,
  IExpectedTaxAdminApi,
  IExpectedTaxAdminWithoutId,
  IExpectedTaxApiParams,
  IFailedBatchApi,
  IFailedBatchApiParams,
  ILaborAdminApi,
  ILaborApiParams,
  IReceiptAdminApi,
  IReceiptApiParams,
  ITaskAdminApi,
  ITaskApiParams,
  ITaskDetailApi,
} from '@src/util/interfaces.ts';
import apiAdmin from '@src/util/apiAdmin.ts';
import { OptionValue } from '@src/components/Select.tsx';
import { AxiosRequestConfig } from 'axios';
import { isNotEmpty } from '@src/util/functions.ts';
import { useAdminStore } from '@src/util/adminStore.ts';

export const useClient = (
  approvedFilter: OptionValue | null,
  branchFilter: OptionValue | null,
  keyword: string = '',
  keywordSearchCategory:
    | 'name'
    | 'user_name'
    | 'resident_number'
    | 'user_email' = 'user_name',
  staffFilter: OptionValue | null,
) => {
  const page_size = 15;
  const urlPath = API_URLS_ADMIN.CLIENT;

  const [admin] = useAdminStore((state) => [state.admin]);

  let approved: boolean | null = null;
  if (approvedFilter !== null && approvedFilter.value !== '전체') {
    approved = approvedFilter.value === '승인';
  }

  let branch: string | null = null;
  if (admin && admin.branch !== '본점' && admin.branch !== undefined) {
    //지점일 경우 branch 세팅
    branch = admin.branch;
  } else if (branchFilter !== null && branchFilter.value !== '전체') {
    // 본점일 경우
    branch = branchFilter.label;
  }

  let staff: string | null = null;
  if (staffFilter !== null && staffFilter.value !== '전체') {
    staff = staffFilter.value;
  }

  return useSuspenseInfiniteQuery<IClientApi>({
    initialPageParam: null,
    queryKey: [
      'client',
      approvedFilter,
      branchFilter,
      keyword,
      keywordSearchCategory,
      admin?.id,
      staff,
    ],
    queryFn: async ({ pageParam }) => {
      const params: IClientApiParams = {
        page_size: page_size,
        approved: approved,
        branch: branch,
        lastId: pageParam,
        staff: staff,
      };

      if (isNotEmpty(keyword)) {
        params[keywordSearchCategory] = keyword;
      }

      const response = await apiAdmin.get(urlPath, {
        params,
      });
      return response.data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext
        ? lastPage.data[lastPage.data.length - 1].id
        : undefined;
    },
    staleTime: 30 * 60 * 1000,
  });
};

export const useBranch = () => {
  const urlPath = API_URLS_ADMIN.BRANCH;

  const { data } = useSuspenseQuery<IBranchApi>({
    queryKey: ['branch'],
    queryFn: () => apiAdmin.get(urlPath),
    staleTime: 30 * 60 * 1000,
  });
  return data;
};

export const useCertificateAdmin = (
  statusFilter: OptionValue | null,
  branchFilter: OptionValue | null,
  keyword: string = '',
  keywordSearchCategory: 'name' | 'user_name' | 'resident_number' = 'user_name',
  staffFilter: OptionValue | null,
) => {
  const urlPath = API_URLS_ADMIN.CERTIFICATES;
  const page_size = 15;

  let status: string | null = null;

  if (statusFilter !== null && statusFilter.value !== '전체') {
    status = statusFilter.value;
  }

  const [admin] = useAdminStore((state) => [state.admin]);
  let branch: string | null = null;
  if (admin && admin.branch !== '본점' && admin.branch !== undefined) {
    //지점일 경우 branch 세팅
    branch = admin.branch;
  } else if (branchFilter !== null && branchFilter.value !== '전체') {
    // 본점일 경우
    branch = branchFilter.label;
  }

  let staff: string | null = null;
  if (staffFilter !== null && staffFilter.value !== '전체') {
    staff = staffFilter.value;
  }

  return useSuspenseInfiniteQuery<ICertificatesApi>({
    initialPageParam: null,
    queryKey: [
      'certificates',
      status,
      branch,
      page_size,
      keyword,
      keywordSearchCategory,
      admin?.id,
      staff,
    ],
    queryFn: async ({ pageParam = null }) => {
      const params: ICertificatesApiParams = {
        page_size: page_size,
        status: status,
        branch: branch,
        lastId: pageParam,
        staff: staff,
      };

      if (keyword.trim() !== '') {
        params[keywordSearchCategory] = keyword;
      }

      const axiosConfig: AxiosRequestConfig = {
        params,
      };

      const response = await apiAdmin.get(urlPath, axiosConfig);
      return response.data;
    },
    staleTime: 30 * 60 * 1000,
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext
        ? lastPage.data[lastPage.data.length - 1].id
        : undefined;
    },
  });
};

export const useLaborAdmin = (
  branchFilter: OptionValue | null,
  keyword: string = '',
  keywordSearchCategory:
    | 'company_name'
    | 'resident_number'
    | 'name'
    | 'phone_number' = 'company_name',
  staffFilter: OptionValue | null,
) => {
  const urlPath = API_URLS_ADMIN.LABOR;
  const page_size = 15;

  const [admin] = useAdminStore((state) => [state.admin]);

  let branch: string | null = null;
  if (admin && admin.branch !== '본점' && admin.branch !== undefined) {
    //지점일 경우 branch 세팅
    branch = admin.branch;
  } else if (branchFilter !== null && branchFilter.value !== '전체') {
    // 본점일 경우
    branch = branchFilter.label;
  }

  let staff: string | null = null;
  if (staffFilter !== null && staffFilter.value !== '전체') {
    staff = staffFilter.value;
  }

  return useSuspenseInfiniteQuery<ILaborAdminApi>({
    initialPageParam: null,
    queryKey: [
      'laborAdmin',
      branch,
      page_size,
      keyword,
      keywordSearchCategory,
      admin?.id,
      staff,
    ],
    queryFn: async ({ pageParam = null }) => {
      const params: ILaborApiParams = {
        page_size: page_size,
        branch: branch,
        lastId: pageParam,
        staff: staff,
      };

      if (keyword.trim() !== '') {
        params[keywordSearchCategory] = keyword;
      }
      const axiosConfig: AxiosRequestConfig = {
        params,
      };
      const response = await apiAdmin.get(urlPath, axiosConfig);
      return response.data;
    },
    staleTime: 30 * 60 * 1000,
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext
        ? lastPage.data[lastPage.data.length - 1].id
        : undefined;
    },
  });
};

export const useTaskAdmin = (
  statusFilter: OptionValue | null,
  branchFilter: OptionValue | null,
  keyword: string = '',
  keywordSearchCategory: 'name' | 'resident_number' = 'name',
  staffFilter: OptionValue | null,
) => {
  const urlPath = API_URLS_ADMIN.TASK;
  const page_size = 15;
  let status: string | null = null;

  if (statusFilter !== null && statusFilter.value !== '전체') {
    status = statusFilter.value;
  }
  const [admin] = useAdminStore((state) => [state.admin]);

  let branch: string | null = null;
  if (admin && admin.branch !== '본점' && admin.branch !== undefined) {
    //지점일 경우 branch 세팅
    branch = admin.branch;
  } else if (branchFilter !== null && branchFilter.value !== '전체') {
    // 본점일 경우
    branch = branchFilter.label;
  }
  let staff: string | null = null;
  if (staffFilter !== null && staffFilter.value !== '전체') {
    staff = staffFilter.value;
  }

  return useSuspenseInfiniteQuery<ITaskAdminApi>({
    initialPageParam: null,
    queryKey: [
      'taskAdmin',
      status,
      branch,
      page_size,
      keyword,
      keywordSearchCategory,
      admin?.id,
      staff,
    ],
    queryFn: async ({ pageParam = null }) => {
      const params: ITaskApiParams = {
        page_size: page_size,
        status: status,
        branch: branch,
        lastId: pageParam,
        staff: staff,
      };

      if (keyword.trim() !== '') {
        params[keywordSearchCategory] = keyword;
      }

      const axiosConfig: AxiosRequestConfig = {
        params,
      };

      const response = await apiAdmin.get(urlPath, axiosConfig);
      return response.data;
    },
    staleTime: 30 * 60 * 1000,
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext
        ? lastPage.data[lastPage.data.length - 1].id
        : undefined;
    },
  });
};

export const useTaskDetailAdmin = (id: string) => {
  const urlPath = API_URLS_ADMIN.TASK + `/${id}`;

  return useSuspenseQuery<ITaskDetailApi>({
    queryKey: ['taskAdminDetail', id],
    queryFn: async () => {
      const response = await apiAdmin(urlPath);
      return response.data;
    },
    staleTime: 30 * 60 * 1000,
  });
};

export const useReceiptAdmin = (
  statusFilter: OptionValue | null,
  branchFilter: OptionValue | null,
  keyword: string = '',
  keywordSearchCategory: 'name' | 'resident_number' = 'name',
  staffFilter: OptionValue | null,
) => {
  const urlPath = API_URLS_ADMIN.RECEIPT;
  const page_size = 15;
  let status: string | null = null;

  if (statusFilter !== null && statusFilter.value !== '전체') {
    status = statusFilter.value;
  }

  const [admin] = useAdminStore((state) => [state.admin]);
  let branch: string | null = null;
  if (admin && admin.branch !== '본점' && admin.branch !== undefined) {
    //지점일 경우 branch 세팅
    branch = admin.branch;
  } else if (branchFilter !== null && branchFilter.value !== '전체') {
    // 본점일 경우
    branch = branchFilter.label;
  }

  let staff: string | null = null;
  if (staffFilter !== null && staffFilter.value !== '전체') {
    staff = staffFilter.value;
  }

  return useSuspenseInfiniteQuery<IReceiptAdminApi>({
    initialPageParam: null,
    queryKey: [
      'receiptAdmin',
      status,
      branch,
      page_size,
      keyword,
      keywordSearchCategory,
      admin?.id,
      staff,
    ],
    queryFn: async ({ pageParam = null }) => {
      const params: IReceiptApiParams = {
        page_size: page_size,
        status: status,
        branch: branch,
        lastId: pageParam,
        staff: staff,
      };

      if (keyword.trim() !== '') {
        params[keywordSearchCategory] = keyword;
      }
      const axiosConfig: AxiosRequestConfig = {
        params,
      };
      const response = await apiAdmin.get(urlPath, axiosConfig);
      return response.data;
    },
    staleTime: 30 * 60 * 1000,
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext
        ? lastPage.data[lastPage.data.length - 1].id
        : undefined;
    },
  });
};

export const useExpectedTaxAdmin = (
  branchFilter: OptionValue | null,
  keyword: string = '',
  keywordSearchCategory: 'name' | 'resident_number' = 'name',
  staffFilter: OptionValue | null,
) => {
  const urlPath = API_URLS_ADMIN.EXPECTED_TAX;
  const page_size = 15;

  const [admin] = useAdminStore((state) => [state.admin]);
  let branch: string | null = null;
  if (admin && admin.branch !== '본점' && admin.branch !== undefined) {
    //지점일 경우 branch 세팅
    branch = admin.branch;
  } else if (branchFilter !== null && branchFilter.value !== '전체') {
    // 본점일 경우
    branch = branchFilter.label;
  }

  let staff: string | null = null;
  if (staffFilter !== null && staffFilter.value !== '전체') {
    staff = staffFilter.value;
  }

  return useSuspenseInfiniteQuery<IExpectedTaxAdminApi>({
    initialPageParam: null,
    queryKey: [
      'expectedTaxAdmin',
      branch,
      page_size,
      keyword,
      keywordSearchCategory,
      admin?.id,
      staff,
    ],
    queryFn: async ({ pageParam = null }) => {
      const params: IExpectedTaxApiParams = {
        page_size: page_size,
        branch: branch,
        lastId: pageParam,
        staff: staff,
      };

      if (keyword.trim() !== '') {
        params[keywordSearchCategory] = keyword;
      }
      const axiosConfig: AxiosRequestConfig = {
        params,
      };
      const response = await apiAdmin.get(urlPath, axiosConfig);

      return {
        data: response.data.data.map((item: IExpectedTaxAdminWithoutId) => {
          return {
            id: item.company_id,
            ...item,
          };
        }),
        hasNext: response.data.hasNext,
      };
    },
    staleTime: 30 * 60 * 1000,
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext
        ? lastPage.data[lastPage.data.length - 1].id
        : undefined;
    },
  });
};

export const useBranchAdmin = (
  branchFilter: OptionValue | null,
  keyword: string = '',
  keywordSearchCategory: 'name' = 'name',
) => {
  const page_size = 15;
  const urlPath = API_URLS_ADMIN.BRANCH_ADMIN;

  const [admin] = useAdminStore((state) => [state.admin]);
  let branch: string | null = null;
  if (admin && admin.branch !== '본점' && admin.branch !== undefined) {
    //지점일 경우 branch 세팅
    branch = admin.branch;
  } else if (branchFilter !== null && branchFilter.value !== '전체') {
    // 본점일 경우
    branch = branchFilter.label;
  }

  return useSuspenseInfiniteQuery<IBranchAdminApi>({
    initialPageParam: null,
    queryKey: ['branch_admin', branchFilter, keyword, keywordSearchCategory],
    queryFn: async ({ pageParam = null }) => {
      const params: IClientApiParams = {
        page_size: page_size,
        branch: branch,
        lastId: pageParam,
      };

      if (keyword.trim() !== '') {
        params[keywordSearchCategory] = keyword;
      }
      const axiosConfig: AxiosRequestConfig = {
        params,
      };

      const response = await apiAdmin.get(urlPath, axiosConfig);

      return response.data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext
        ? lastPage.data[lastPage.data.length - 1].id
        : undefined;
    },
    staleTime: 30 * 60 * 1000,
  });
};

export const useEtcSales = (companyNo: number | null) => {
  const urlPath = API_URLS_ADMIN.ETC_SALES + `/${companyNo}`;

  return useQuery<IEtcSales[]>({
    queryKey: ['etcSales', companyNo],
    queryFn: async () => {
      const response = await apiAdmin.get(urlPath);
      return response.data;
    },
    staleTime: 30 * 60 * 1000,
    enabled: !!companyNo,
  });
};

export const useFailedBatch = (
  keyword: string = '',
  keywordSearchCategory: 'company_name' | 'resident_number',
  branchFilter: OptionValue | null,
  staffFilter: OptionValue | null,
) => {
  const page_size = 15;

  const urlPath = API_URLS_ADMIN.FAILED_BATCH;
  const [admin] = useAdminStore((state) => [state.admin]);
  let branch: string | null = null;
  if (admin && admin.branch !== '본점' && admin.branch !== undefined) {
    //지점일 경우 branch 세팅
    branch = admin.branch;
  } else if (branchFilter !== null && branchFilter.value !== '전체') {
    // 본점일 경우
    branch = branchFilter.label;
  }

  let staff: string | null = null;
  if (staffFilter !== null && staffFilter.value !== '전체') {
    staff = staffFilter.value;
  }

  return useSuspenseInfiniteQuery<IFailedBatchApi>({
    initialPageParam: 1,
    queryKey: ['failedBatch', keyword, keywordSearchCategory, admin?.id, staff],
    queryFn: async ({ pageParam }) => {
      const params: IFailedBatchApiParams = {
        page_size: page_size,
        page_number: pageParam as number,
        branch: branch,
        staff: staff,
      };

      if (isNotEmpty(keyword)) {
        params[keywordSearchCategory] = keyword;
      }

      const response = await apiAdmin.get(urlPath, {
        params,
      });
      return response.data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.hasNextPage ? lastPage.nextPage : undefined;
    },
    staleTime: 30 * 60 * 1000,
  });
};

export const useBranchStaff = (branchId: number | null) => {
  const urlPath = API_URLS_ADMIN.BRANCH_STAFF;

  return useQuery<IBranchStaff[]>({
    queryKey: ['branch_staff', branchId],
    queryFn: async () => {
      const response = await apiAdmin.get(urlPath + `/${branchId}`);
      return response.data;
    },
    staleTime: 30 * 60 * 1000,
    enabled: branchId !== null,
  });
};

export const useCompanySync = (
  branchFilter: OptionValue | null,
  keyword: string = '',
  keywordSearchCategory: 'name' | 'resident_number' = 'name',
  staffFilter: OptionValue | null,
  companyTypeFilter: OptionValue | null,
  hometaxIdFilter: OptionValue | null,
  hometaxSignCertFilter: OptionValue | null,
  financingFilter: OptionValue | null,
  cardFilter: OptionValue | null,
  naverFilter: OptionValue | null,
  coupangFilter: OptionValue | null,
  bmFilter: OptionValue | null,
  yoFilter: OptionValue | null,
  cpeFilter: OptionValue | null,
) => {
  const page_size = 15;
  const urlPath = API_URLS_ADMIN.COMPANY_SYNC;

  const [admin] = useAdminStore((state) => [state.admin]);

  let branch: string | null = null;
  if (admin && admin.branch !== '본점' && admin.branch !== undefined) {
    //지점일 경우 branch 세팅
    branch = admin.branch;
  } else if (branchFilter !== null && branchFilter.value !== '전체') {
    // 본점일 경우
    branch = branchFilter.label;
  }

  let staff: string | null = null;
  if (staffFilter !== null && staffFilter.value !== '전체') {
    staff = staffFilter.value;
  }

  let companyType: string | null = null;
  if (companyTypeFilter !== null && companyTypeFilter.value !== '전체') {
    companyType = companyTypeFilter.value;
  }

  let hometax_id_yn: string | null = null;
  if (hometaxIdFilter !== null && hometaxIdFilter.value !== 'ID') {
    hometax_id_yn = hometaxIdFilter.value;
  }

  let hometax_cert_yn: string | null = null;
  if (
    hometaxSignCertFilter !== null &&
    hometaxSignCertFilter.value !== '인증서'
  ) {
    hometax_cert_yn = hometaxSignCertFilter.value;
  }

  let financing_id_yn: string | null = null;
  if (financingFilter !== null && financingFilter.value !== '여신') {
    financing_id_yn = financingFilter.value;
  }

  let card_sync_yn: string | null = null;
  if (cardFilter !== null && cardFilter.value !== '카드') {
    card_sync_yn = cardFilter.value;
  }

  let naver_id_yn: string | null = null;
  if (naverFilter !== null && naverFilter.value !== '네이버') {
    naver_id_yn = naverFilter.value;
  }

  let coupang_id_yn: string | null = null;
  if (coupangFilter !== null && coupangFilter.value !== '쿠팡') {
    coupang_id_yn = coupangFilter.value;
  }

  let bm_id_yn: string | null = null;
  if (bmFilter !== null && bmFilter.value !== '배민') {
    bm_id_yn = bmFilter.value;
  }

  let yo_id_yn: string | null = null;
  if (yoFilter !== null && yoFilter.value !== '요기요') {
    yo_id_yn = yoFilter.value;
  }

  let cpe_id_yn: string | null = null;
  if (cpeFilter !== null && cpeFilter.value !== '쿠팡이츠') {
    cpe_id_yn = cpeFilter.value;
  }

  return useSuspenseInfiniteQuery<ICompanySyncApi>({
    initialPageParam: null,
    queryKey: [
      'company_sync',
      branchFilter,
      keyword,
      keywordSearchCategory,
      admin?.id,
      staff,
      companyType,
      hometax_id_yn,
      hometax_cert_yn,
      financing_id_yn,
      card_sync_yn,
      naver_id_yn,
      coupang_id_yn,
      bm_id_yn,
      yo_id_yn,
      cpe_id_yn,
    ],
    queryFn: async ({ pageParam }) => {
      const params: ICompanySyncApiParams = {
        page_size: page_size,
        branch: branch,
        lastId: pageParam,
        staff: staff,
        company_type: companyType,
        hometax_id_yn,
        hometax_cert_yn,
        financing_id_yn,
        card_sync_yn,
        naver_id_yn,
        coupang_id_yn,
        bm_id_yn,
        yo_id_yn,
        cpe_id_yn,
      };

      if (isNotEmpty(keyword)) {
        params[keywordSearchCategory] = keyword;
      }

      const response = await apiAdmin.get(urlPath, {
        params,
      });
      return response.data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext
        ? lastPage.data[lastPage.data.length - 1].id
        : undefined;
    },
    staleTime: 30 * 60 * 1000,
  });
};
