interface LightBulbIconProps {
  fill: string;
  size: string;
}

const LightBulbIcon: React.FC<LightBulbIconProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon / emoji_objects">
        <path
          id="Vector"
          d="M12 22C11.5667 22 11.175 21.8958 10.825 21.6875C10.475 21.4792 10.2 21.2 10 20.85C9.45 20.85 8.97917 20.6542 8.5875 20.2625C8.19583 19.8708 8 19.4 8 18.85V15.3C7.01667 14.65 6.22917 13.7917 5.6375 12.725C5.04583 11.6583 4.75 10.5 4.75 9.25C4.75 7.23333 5.45417 5.52083 6.8625 4.1125C8.27083 2.70417 9.98333 2 12 2C14.0167 2 15.7292 2.70417 17.1375 4.1125C18.5458 5.52083 19.25 7.23333 19.25 9.25C19.25 10.5333 18.9542 11.7 18.3625 12.75C17.7708 13.8 16.9833 14.65 16 15.3V18.85C16 19.4 15.8042 19.8708 15.4125 20.2625C15.0208 20.6542 14.55 20.85 14 20.85C13.8 21.2 13.525 21.4792 13.175 21.6875C12.825 21.8958 12.4333 22 12 22ZM10 18.85H14V17.95H10V18.85ZM10 16.95H14V16H10V16.95ZM9.8 14H11.25V11.3L9.575 9.625C9.425 9.475 9.35 9.3 9.35 9.1C9.35 8.9 9.425 8.725 9.575 8.575C9.725 8.425 9.9 8.35 10.1 8.35C10.3 8.35 10.475 8.425 10.625 8.575L12 9.95L13.375 8.575C13.525 8.425 13.7 8.35 13.9 8.35C14.1 8.35 14.275 8.425 14.425 8.575C14.575 8.725 14.65 8.9 14.65 9.1C14.65 9.3 14.575 9.475 14.425 9.625L12.75 11.3V14H14.2C15.1 13.5667 15.8333 12.9292 16.4 12.0875C16.9667 11.2458 17.25 10.3 17.25 9.25C17.25 7.78333 16.7417 6.54167 15.725 5.525C14.7083 4.50833 13.4667 4 12 4C10.5333 4 9.29167 4.50833 8.275 5.525C7.25833 6.54167 6.75 7.78333 6.75 9.25C6.75 10.3 7.03333 11.2458 7.6 12.0875C8.16667 12.9292 8.9 13.5667 9.8 14Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default LightBulbIcon;
