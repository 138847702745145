import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface TextAreaProps {
  placeholder?: string;
  register?: UseFormRegisterReturn;
  style?: React.CSSProperties;
}

const TextArea: React.FC<TextAreaProps> = ({
  register,
  placeholder,
  style,
}) => {
  return (
    <textarea
      className="text-area-default"
      rows={10}
      {...register}
      placeholder={placeholder}
      style={style}
    ></textarea>
  );
};
export default TextArea;
