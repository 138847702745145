import React, { useState } from 'react';
import InputLabel from '@src/components/InputLabel.tsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Select from '@src/components/Select.tsx';
import Modal from '@src/components/Modal.tsx';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@src/util/store.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { IApiError, IApiPOSTSuccess } from '@src/util/interfaces.ts';
import { objectToQueryString } from '@src/util/functions.ts';
import api from '@src/util/api.ts';
import {
  API_URL,
  organizationCode,
  organizationCodeOptions,
  purposeCode,
  purposeCodeOptions,
} from '@src/util/constants.ts';
import Input from '@src/components/Input.tsx';
import { z } from 'zod';
import CheckBox from '@src/components/CheckBox.tsx';
import CertificatesDescription from '@src/pages/certificates/CertificatesDescription';
import Button from '@src/components/Button.tsx';
import BottomWrapper from '@src/components/BottomWrapper.tsx';

interface CertificateFormContent7Props {}

/**
 *  재무제표확인원
 *    년도*: year
 *    요청문서*
 *      is_financial_statement(재무상태표)(boolean)
 *      is_income_statement(손익계산서)(boolean)
 *      is_bill_of_materials(제조원가명세서)(boolean)
 *      is_retained_earnings_statement(이익잉여금처분계산서)(boolean)
 *    제출기관*: organization cvaDcumSbmsOrgnClCd
 *    제출목적*: purpose
 */

const CertificateForm7 = z.object({
  organization: z.object({
    value: z.enum(organizationCode),
    label: z.string(),
  }),
  purpose: z.object({
    value: z.enum(purposeCode),
    label: z.string(),
  }),
  year: z
    .string()
    .trim() // Optional: trim whitespace
    .refine((val) => val.length > 0, {
      message: '과세연도를 입력해 주세요.', // Custom message for empty input
    })
    .transform((val) => parseInt(val, 10))
    .refine((val) => !isNaN(val), {
      message: '정확한 과세연도를 입력해 주세요.',
    }),
  is_financial_statement: z.boolean(),
  is_income_statement: z.boolean(),
  is_bill_of_materials: z.boolean(),
  is_retained_earnings_statement: z.boolean(),
});

const CertificateForm7Server = CertificateForm7.omit({
  organization: true,
  purpose: true,
}).extend({
  company_id: z.number(),
  organization: z.string(),
  purpose: z.string(),
});

type TCertificateForm7 = z.infer<typeof CertificateForm7>;
type TCertificateForm7Server = z.infer<typeof CertificateForm7Server>;
const CertificateFormContent7: React.FC<CertificateFormContent7Props> = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<TCertificateForm7>({
    resolver: zodResolver(CertificateForm7),
    mode: 'onChange',
    defaultValues: {
      organization: { label: '기타', value: '기타' },
      purpose: { label: '기타', value: '기타' },
      is_financial_statement: true,
      is_bill_of_materials: true,
      is_income_statement: true,
      is_retained_earnings_statement: true,
    },
  });

  const { mutate, isSuccess, error, isPending } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TCertificateForm7Server
  >({
    mutationFn: (formData) => {
      const queryParams = objectToQueryString(formData);

      return api.post(
        API_URL + `/v1/communication/document?${queryParams}`,
        formData,
      );
    },
  });

  const onSubmit: SubmitHandler<TCertificateForm7> = (data) => {
    const formData = {
      company_id: user?.selectedCompany?.id ?? 0,
      type: '재무제표확인원',
      year: data.year,
      organization: data.organization.value,
      purpose: data.purpose.value,
      is_financial_statement: data.is_financial_statement,
      is_bill_of_materials: data.is_bill_of_materials,
      is_income_statement: data.is_income_statement,
      is_retained_earnings_statement: data.is_retained_earnings_statement,
    };

    mutate(formData, {
      onSuccess: (response) => {
        setModalOpen(true);
        console.log(response);
      },
      onError: (error) => {
        setModalOpen(true);
        console.log(error);
      },
    });
  };

  return (
    <>
      <form
        className="certificate-form-content"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CertificatesDescription
          title={
            '재무제표확인원은 홈택스 발급이 아닌 세무대리인 인감이 날인된 확인원입니다.\n해당 과세연도 정기 신고기한 다음날부터 발급 가능합니다.'
          }
          example={
            '법인의 경우 2023년 귀속 증명발급은 2024.4.1. 이후 발급 가능\n개인의 경우 2023년 귀속 증명발급은 2024.6.1. 이후 발급 가능\n(성실은 2024.7.1. 이후)'
          }
        />
        <InputLabel
          text={'년도'}
          required={true}
          description={'년도입력 : 예시) 2018'}
          error={
            errors.year && (
              <div className="error-message">
                {errors.year?.message?.toString()}
              </div>
            )
          }
        >
          <div className="flex-row" style={{ gap: '10px' }}>
            <Input
              placeholder="연도를 입력하세요"
              register={register('year')}
              isLoading={false}
              maxLength={4}
              inputMode={'numeric'}
              type={'number'}
            />
            <div>
              <p className="text-regular" style={{ lineHeight: '22px' }}>
                년
              </p>
            </div>
          </div>
        </InputLabel>
        <InputLabel
          text={'요청문서'}
          required={true}
          // error={
          //   errors.year && (
          //     <div className="error-message">
          //       {errors.year?.message?.toString()}
          //     </div>
          //   )
          // }
        >
          <Controller
            name={'is_financial_statement'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CheckBox
                checked={value}
                onChange={onChange}
                label={'재무상태표'}
                size={'lg'}
              />
            )}
          />
          <Controller
            name={'is_income_statement'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CheckBox
                checked={value}
                onChange={onChange}
                label={'손익계산서'}
                size={'lg'}
              />
            )}
          />
          <Controller
            name={'is_bill_of_materials'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CheckBox
                checked={value}
                onChange={onChange}
                label={'제조원가명세서'}
                size={'lg'}
              />
            )}
          />
          <Controller
            name={'is_retained_earnings_statement'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CheckBox
                checked={value}
                onChange={onChange}
                label={'이익잉여금처분계산서'}
                size={'lg'}
              />
            )}
          />
        </InputLabel>
        <InputLabel text="제출기관" required={true}>
          <Controller
            name="organization"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  name="organization"
                  options={organizationCodeOptions}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </InputLabel>
        <InputLabel text="제출목적" required={true}>
          <Controller
            name="purpose"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  name="purpose"
                  options={purposeCodeOptions}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </InputLabel>

        <BottomWrapper>
          <Button text={'신청하기'} isLoading={isPending} type={'submit'} />
        </BottomWrapper>
      </form>
      <Modal
        isOpen={isModalOpen}
        onOk={() => {
          setModalOpen(false);
          if (isSuccess) {
            navigate('/certificates/issued', { replace: true });
          } else {
            navigate(-1);
          }
        }}
        title={isSuccess ? '증명서 발급 요청' : '증명서 발급 실패'}
        content={
          isSuccess
            ? '증명서 발급 요청 되었습니다.'
            : error?.response.data.message ?? ''
        }
        okButton={'확인'}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};
export default CertificateFormContent7;
