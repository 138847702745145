import Select, { OptionValue } from '@src/components/Select.tsx';
import { useBranchStaff } from '@src/util/adminHooks.ts';
import { useAdminStore } from '@src/util/adminStore.ts';

interface StaffFilterSelectProps {
  branchFilter: OptionValue | null;
  staffFilter: OptionValue | null;
  setStaffFilter: (value: OptionValue | null) => void;
}

const StaffFilterSelect: React.FC<StaffFilterSelectProps> = ({
  branchFilter,
  staffFilter,
  setStaffFilter,
}) => {
  const [admin] = useAdminStore((state) => [state.admin]);

  const { data: branchStaffData } = useBranchStaff(
    admin?.id === undefined ? null : admin?.id,
  );

  const { data: branchStaffDataAll } = useBranchStaff(0);

  const generateOptions = () => {
    if (
      admin === null ||
      admin === undefined ||
      branchStaffData === undefined
    ) {
      return [];
    }

    if (admin.branch !== '본점') {
      const options: OptionValue[] = [
        {
          label: '전체',
          value: '전체',
        },
      ];
      const generatedOption: OptionValue[] = branchStaffData.map((item) => ({
        label: item.name,
        value: item.id.toString(),
      }));

      return options.concat(generatedOption);
    } else {
      const options: OptionValue[] = [
        {
          label: '전체',
          value: '전체',
        },
      ];

      let branchStaffAllCopy = branchStaffDataAll ?? [];

      if (branchFilter?.label !== '전체') {
        branchStaffAllCopy = branchStaffAllCopy.filter(
          (item) => item.admin.id.toString() === branchFilter?.value,
        );
      }

      const generatedOption = branchStaffAllCopy.map((item) => ({
        label: item.name,
        value: item.id.toString(),
      }));

      return options.concat(generatedOption);
    }
  };
  return (
    <div style={{ minWidth: '60px' }}>
      <Select
        name={'filterStaff'}
        options={generateOptions()}
        onChange={(selectedOption) => {
          setStaffFilter(selectedOption);
        }}
        value={staffFilter}
        size={'lg'}
        label={'담당자'}
      />
    </div>
  );
};

export default StaffFilterSelect;
