import React from 'react';

interface IconProps {
  iconSrc?: string;
  iconText?: string;
  type?: string;
  onClick?: () => void;
  text?: string;
  style?: React.CSSProperties;
  imgStyle?: React.CSSProperties;
}

const Icon: React.FC<IconProps> = ({
  iconSrc,
  iconText = '',
  type = '',
  onClick,
  text,
  style,
  imgStyle,
}) => {
  return (
    <div className={`icon ${type}`} onClick={onClick} style={style}>
      {iconSrc ? (
        <img src={iconSrc} alt={iconText} style={imgStyle} />
      ) : (
        <p>{text}</p>
      )}
    </div>
  );
};

export default Icon;
