import React, { useState } from 'react';
import TabList from '@src/components/TabList.tsx';
import TaxBilledTable from '@src/pages/tax/TaxBilledTable';
import TaxPaidTable from '@src/pages/tax/TaxPaidTable';
import TaxDebtTable from '@src/pages/tax/TaxDebtTable';
import Header from '@src/components/Header.tsx';
import HeaderTabs from '@src/components/HeaderTabs.tsx';
import { TAX_MENU, TAX_MENU_LIST } from '@src/util/constants.ts';
import SideBar from '@src/components/SideBar.tsx';
import Footer from '@src/components/Footer.tsx';
import Container from '@src/layouts/Container.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';

interface TaxPaidProps {}

const TaxPaid: React.FC<TaxPaidProps> = () => {
  const [activeTab, setActiveTab] = useState<string>('납부내역');
  const [open, setOpen] = useState(false);

  return (
    <Container
      header={<Header setOpen={setOpen} />}
      headerTab={
        <HeaderTabs menus={TAX_MENU_LIST} activeHeaderTab={TAX_MENU.TAX_PAID} />
      }
      sideBar={<SideBar />}
      footer={<Footer current={'tax'} />}
      modal={<CompanyDrawer open={open} setOpen={setOpen} />}
    >
      <div className={'tax-paid-wrapper-container'}>
        <div className={'tax-paid-container'}>
          <TabList
            value={activeTab}
            onChange={(newValue) => {
              setActiveTab(newValue);
            }}
            tabs={['납부내역', '고지내역', '체납내역']}
            style={{ marginBottom: '12px' }}
          />
          {activeTab === '납부내역' && <TaxPaidTable />}
          {activeTab === '고지내역' && <TaxBilledTable />}
          {activeTab === '체납내역' && <TaxDebtTable />}
        </div>
      </div>
    </Container>
  );
};

export default TaxPaid;
