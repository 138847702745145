import React from 'react';
import checkBoxFilled from '../assets/icons/login/check_box.svg'; // Used for the "checked" state
import checkBoxEmpty from '../assets/icons/login/check_box_outline_blank.svg';

interface CheckBoxProps {
  label?: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  size?: 'sm' | 'lg';
  disabled?: boolean;
  style?: React.CSSProperties;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  label,
  checked,
  onChange,
  size = 'lg',
  disabled,
  style,
}) => {
  return (
    <>
      <input
        type="checkbox"
        className="checkbox"
        defaultChecked={checked}
        disabled={disabled}
      />
      <label
        className={`checkbox-label ${size}`}
        onClick={(e) => {
          e.stopPropagation();
          if (!disabled) {
            onChange(!checked);
          }
        }}
        style={style}
      >
        {checked ? (
          <img src={checkBoxFilled} alt="Checked" />
        ) : (
          <img src={checkBoxEmpty} alt="Unchecked" />
        )}
        {size === 'sm' ? (
          <p className={'text-sm'} style={{ fontWeight: '400' }}>
            {label}
          </p>
        ) : (
          <p className={'text-regular'} style={{ lineHeight: '22px' }}>
            {label}
          </p>
        )}
      </label>
    </>
  );
};

export default CheckBox;
