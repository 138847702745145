interface ErrorIconProps {
  fill: string;
  size: string;
}

const ErrorIcon: React.FC<ErrorIconProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon / error">
        <path
          id="Vector"
          d="M25.9993 36.2499C26.4291 36.2499 26.7825 36.1117 27.0596 35.8352C27.3367 35.5588 27.4752 35.2056 27.4752 34.7758C27.4752 34.346 27.337 33.9926 27.0606 33.7155C26.7842 33.4384 26.431 33.2999 26.0012 33.2999C25.5715 33.2999 25.218 33.4381 24.9409 33.7146C24.6639 33.991 24.5253 34.3441 24.5253 34.7739C24.5253 35.2037 24.6635 35.5572 24.94 35.8342C25.2164 36.1113 25.5695 36.2499 25.9993 36.2499ZM26.1314 28.6082C26.4829 28.6082 26.7739 28.4905 27.0044 28.2551C27.2349 28.0197 27.3502 27.7277 27.3502 27.3791V16.3207C27.3502 15.9721 27.2315 15.6801 26.9942 15.4447C26.7568 15.2093 26.4624 15.0916 26.1109 15.0916C25.7594 15.0916 25.4683 15.2093 25.2378 15.4447C25.0072 15.6801 24.892 15.9721 24.892 16.3207V27.3791C24.892 27.7277 25.0106 28.0197 25.248 28.2551C25.4854 28.4905 25.7799 28.6082 26.1314 28.6082ZM26.0183 46.5832C23.1672 46.5832 20.4968 46.043 18.0072 44.9625C15.5176 43.8821 13.3374 42.4068 11.4663 40.5366C9.59526 38.6663 8.11928 36.4856 7.03836 33.9945C5.95745 31.5033 5.41699 28.8306 5.41699 25.9765C5.41699 23.1361 5.95721 20.4664 7.03766 17.9672C8.1181 15.4679 9.59342 13.2918 11.4636 11.4389C13.3338 9.58587 15.5145 8.11891 18.0057 7.038C20.4969 5.95709 23.1695 5.41663 26.0237 5.41663C28.864 5.41663 31.5338 5.95685 34.033 7.03729C36.5322 8.11774 38.7083 9.58403 40.5613 11.4362C42.4143 13.2883 43.8813 15.4664 44.9622 17.9702C46.0431 20.4741 46.5836 23.1446 46.5836 25.9819C46.5836 28.833 46.0433 31.5034 44.9629 33.993C43.8824 36.4825 42.4161 38.6595 40.564 40.5239C38.7118 42.3883 36.5338 43.8642 34.0299 44.9518C31.5261 46.0394 28.8556 46.5832 26.0183 46.5832ZM26.0274 44.1249C31.0537 44.1249 35.3267 42.3596 38.8461 38.8291C42.3655 35.2985 44.1253 31.0131 44.1253 25.9728C44.1253 20.9464 42.3689 16.6735 38.8562 13.1541C35.3436 9.63464 31.0582 7.87493 26.0003 7.87493C20.978 7.87493 16.7017 9.63126 13.1711 13.1439C9.64057 16.6566 7.87529 20.9419 7.87529 25.9999C7.87529 31.0221 9.64057 35.2985 13.1711 38.8291C16.7017 42.3596 20.9871 44.1249 26.0274 44.1249Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default ErrorIcon;
