import React from 'react';
import bmIcon from '/src/assets/icons/delivery/bm.png';
import ygyIcon from '/src/assets/icons/delivery/ygy.webp';
import cpeIcon from '/src/assets/icons/delivery/cpe.png';
import Icon from './Icon.tsx';
import { COLORS } from '@src/util/constants.ts';

interface DeliveryIconProps {
  name: string;
  size?: 'md' | 'lg';
}

const DeliveryIcon: React.FC<DeliveryIconProps> = ({ name, size = 'md' }) => {
  const imgStyle = {
    width: '24px',
    height: '24px',
    borderRadius: '6px',
    border: '',
  };

  if (size === 'lg') {
    imgStyle.width = '32px';
    imgStyle.height = '32px';
    imgStyle.borderRadius = '8px';
  }
  switch (name) {
    case 'BM':
      return (
        <Icon iconSrc={bmIcon} iconText={'배달의 민족'} imgStyle={imgStyle} />
      );
    case 'YO':
      return <Icon iconSrc={ygyIcon} iconText={'요기요'} imgStyle={imgStyle} />;
    case 'CPE':
      imgStyle.border = `0.1px solid ${COLORS['gray2']}`;
      return (
        <Icon iconSrc={cpeIcon} iconText={'쿠팡 이츠'} imgStyle={imgStyle} />
      );
  }
};
export default DeliveryIcon;
