import DetailHeader from '@src/components/DetailHeader.tsx';
import Container from '@src/layouts/Container.tsx';
import { useScrollToTop } from '@src/util/hooks.ts';

const MarketingTerms = () => {
  useScrollToTop();
  return (
    <Container header={<DetailHeader title={'마케팅 정보 수신동의 (선택)'} />}>
      <div className={'term-container'}>
        <p className={'text-regular'}>
          정율 세무회계(이하 ‘회사’)는 개인정보 보호법 및 정보 통신망 이용 촉진
          및 정보 보호 등에 관한 법률 등 관계 법령에 따라 광고성 정보를 전송하기
          위해 수신자의 사전 수신 동의를 받고 있습니다.
        </p>
        <h1 className={'text-md'}>1. 전송 방법</h1>
        <p className={'text-regular'}>
          회사 서비스 화면, E-mail, SMS, 회사 카카오톡 채널 등 회사의 각 개별
          서비스 및 채널에서 정한 방식에 따라 전송될 수 있습니다.
        </p>

        <h1 className={'text-md'}>2. 전송 내용</h1>
        <p className={'text-regular'}>
          세무, 노무 및 금융 서비스 관련 정보, 각종 혜택(할인, 이벤트 등), 회사
          서비스 이용을 권유하는 내용(추천 서비스, 새로운 기능 또는 제품 안내),
          신규 서비스 관련 정보 등 광고성 정보가 관계법령 규정을 준수하여
          발송됩니다.
        </p>

        <h1 className={'text-md'}>3. 수집 항목</h1>
        <p className={'text-regular'}>이름, 이메일, 전화번호</p>

        <h1 className={'text-md'}>4. 이용 목적</h1>
        <p className={'text-regular'}>
          세무, 노무 및 금융 서비스 관련 정보, 각종 혜택(할인, 이벤트 등), 회사
          서비스 이용을 권유하는 내용(추천 서비스, 새로운 기능 또는 제품 안내),
          신규 서비스 관련 정보, 고객 맞춤 마케팅, 뉴스레터 서비스 제공 등
          목적으로 이메일 주소와 전화번호를 사용합니다.
        </p>

        <h1 className={'text-md'}>5. 철회 안내</h1>
        <p className={'text-regular'}>
          수신 동의 이후 언제라도 동의를 철회할 수 있으며, 수신에 동의하지
          않으셔도 회사가 제공하는 기본적인 서비스를 이용할 수 있습니다. 단,
          할인이나 이벤트, 추천 서비스 등 마케팅 정보 안내 서비스는 제한됩니다.
        </p>

        <h1 className={'text-md'}>6. 수신 동의 변경</h1>
        <p className={'text-regular'}>
          회사 고객센터를 통해 수신 동의를 변경(동의/철회)하실 수 있습니다.
        </p>
      </div>
    </Container>
  );
};

export default MarketingTerms;
