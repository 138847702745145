import React, { useCallback, useState } from 'react';
import Container from '@src/layouts/Container';
import { LABOR_MENU, LABOR_MENU_LIST, URLS } from '@src/util/constants';
import { Link, useNavigate } from 'react-router-dom';
import DetailHeader from '@src/components/DetailHeader';
import HeaderTabs from '@src/components/HeaderTabs';
import { createQuestionStore, useUserStore } from '@src/util/store';
import { useIsMobile } from '@src/util/hooks.ts';
import SideBar from '@src/components/SideBar';
import Header from '@src/components/Header.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import BottomWrapper from '@src/components/BottomWrapper.tsx';

interface LaborProps {}

const SeniorQuestion2: React.FC<LaborProps> = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [user] = useUserStore((state) => [state.user]);
  const company_id = user?.selectedCompany?.id || 0;
  const [question, setQuestion] = createQuestionStore(company_id)((state) => [
    state.question,
    state.setQuestion,
  ]);
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    LABOR_MENU.REQUEST,
  );
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = useCallback((value: boolean) => {
    const current_page = `${URLS.LABOR}/end`;
    setQuestion({ senior_question_2: value });
    if (!value) {
      setQuestion({ senior_amount: 0, current_page });
      navigate(current_page);
    } else {
      navigate(`${URLS.LABOR}/senior/3`);
    }
  }, []);

  return (
    <Container
      sideBar={<SideBar />}
      header={
        isMobile ? (
          <DetailHeader title={'노무지원금 신청'} />
        ) : (
          <Header setOpen={setOpen} />
        )
      }
      headerTab={
        !isMobile && (
          <HeaderTabs
            menus={LABOR_MENU_LIST}
            activeHeaderTab={activeHeaderTab}
            setActiveHeaderTab={(value) => {
              setActiveHeaderTab(value);
            }}
          />
        )
      }
      modal={<CompanyDrawer open={open} setOpen={setOpen} />}
    >
      <div className={'labor-request-wrapper-container'}>
        <div className={'labor-request-container'}>
          <div className="labor-request-description-container">
            <h2 className="text-md">질문 2</h2>
            <p className="text-regular-up" style={{ color: '#31343A' }}>
              신규채용 근로자가 내국인(대한민국 국민) 인가요?
            </p>
            <div className={'labor-button-wrapper'}>
              <button className={'button'} onClick={() => handleClick(false)}>
                아니오
              </button>
              <button className={'button'} onClick={() => handleClick(true)}>
                예
              </button>
            </div>
          </div>
          <BottomWrapper>
            <Link
              className={'button-default'}
              to={question?.current_page || '/labor'}
            >
              돌아가기
            </Link>
          </BottomWrapper>
        </div>
      </div>
    </Container>
  );
};
export default SeniorQuestion2;
