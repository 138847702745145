import React from 'react';
import TailSpinner from './TailSpinner.tsx';

interface BottomButtonProps {
  text: string;
  disabled?: boolean;
  customClassName?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  style?: React.CSSProperties;
}

const BottomButton: React.FC<BottomButtonProps> = ({
  text,
  disabled,
  customClassName,
  type,
  onClick,
  isLoading,
  style,
}) => {
  const defaultClassName = 'bottom-button-default';
  const combinedClassName = customClassName
    ? `${defaultClassName} ${customClassName}`
    : defaultClassName;

  return (
    <button
      className={combinedClassName}
      disabled={disabled}
      type={type}
      onClick={onClick}
      style={style}
    >
      {isLoading ? <TailSpinner type={'button'} /> : text}
    </button>
  );
};

export default BottomButton;
