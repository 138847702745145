import {
  ColumnDef,
  Row,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import React, { ReactNode, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { COLORS } from '@src/util/constants.ts';

export interface TableProps<T extends { id: number }> {
  data: Array<T>;
  columns: Array<ColumnDef<T, string>>;
  onRowClick?: (id: number) => string;
  filterContent?: ReactNode;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  style?: React.CSSProperties;
  onRowClickCustom?: (row: Row<T>) => void;
  getRowStyle?: (row: Row<T>) => React.CSSProperties;
}

const Table = <T extends { id: number }>({
  data,
  columns,
  onRowClick,
  filterContent,
  fetchNextPage,
  hasNextPage,
  style,
  onRowClickCustom,
  getRowStyle,
}: TableProps<T>) => {
  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
  });
  useEffect(() => {
    if (inView && hasNextPage && fetchNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);
  const navigate = useNavigate();

  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const [isAdmin] = useState<boolean>(location.pathname.startsWith('/admin'));

  const handleRowClick = (row: Row<T>) => {
    if (onRowClick) {
      const path = onRowClick(row.original.id);
      navigate(path);
    } else if (onRowClickCustom) {
      onRowClickCustom(row);
    }
  };

  const adminStyle: React.CSSProperties = {
    position: 'sticky',
    top: 0,
    zIndex: 5000,
  };

  return (
    <>
      {filterContent}
      <table className={'table-container'} style={style}>
        <thead
          className={'table-header-container'}
          style={isAdmin ? { ...style, ...adminStyle } : style}
        >
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      className={'text-sm'}
                      style={{
                        whiteSpace: 'nowrap',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        maxWidth:
                          header.depth === 2 &&
                          (header.id === 'hometax_id' ||
                            header.id === 'hometax_sign_cert' ||
                            header.id === 'financing_id' ||
                            header.id === 'card_cert' ||
                            header.id === 'naver_id' ||
                            header.id === 'coupang_id' ||
                            header.id === 'bm_id' ||
                            header.id === 'cpe_id' ||
                            header.id === 'yo_id')
                            ? '94px'
                            : '',
                        width:
                          header.depth === 2 &&
                          (header.id === 'hometax_id' ||
                            header.id === 'hometax_sign_cert' ||
                            header.id === 'financing_id' ||
                            header.id === 'card_cert' ||
                            header.id === 'naver_id' ||
                            header.id === 'coupang_id' ||
                            header.id === 'bm_id' ||
                            header.id === 'cpe_id' ||
                            header.id === 'yo_id')
                            ? '94px'
                            : '',
                        borderRight:
                          (header.colSpan > 1 && header.depth === 1) ||
                          (header.depth === 2 &&
                            (header.id === 'company.resident_number' ||
                              header.id === 'interim_tax' ||
                              header.id === 'paid_tax' ||
                              header.id === 'is_3_month_vat_period_calc' ||
                              header.id === 'company_created_at' ||
                              header.id === 'hometax_id' ||
                              header.id === 'hometax_sign_cert' ||
                              header.id === 'financing_id' ||
                              header.id === 'card_cert' ||
                              header.id === 'naver_id' ||
                              header.id === 'coupang_id' ||
                              header.id === 'bm_id' ||
                              header.id === 'cpe_id' ||
                              header.id === 'yo_id'))
                            ? `1px solid ${COLORS['point']}`
                            : '',
                      }}
                      colSpan={header.colSpan}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody className={'table-body-container'}>
          {table.getRowModel().rows.map((row) => {
            const customRowStyle = getRowStyle ? getRowStyle(row) : {};

            return (
              <tr
                key={row.id}
                className={
                  onRowClick || onRowClickCustom
                    ? 'table-body-row hover'
                    : 'table-body-row'
                }
                onClick={() => {
                  handleRowClick(row);
                }}
                style={{
                  ...customRowStyle,
                  cursor: onRowClick || onRowClickCustom ? 'pointer' : '',
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      className={'text-sm'}
                      style={{
                        whiteSpace: 'nowrap',
                        padding: '20px 10px',
                        fontWeight: '400',
                        textAlign: 'center',
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot ref={inViewRef}>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </>
  );
};

export default Table;
