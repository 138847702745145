import '@src/scss/main.scss';
import { Link, useNavigate } from 'react-router-dom';
import React, { useCallback } from 'react';
import Label from '@src/components/Label';
import Input from '@src/components/Input';
import RadioButton from '@src/components/RadioButton';

import { Controller, useForm } from 'react-hook-form';
import { phoneRegex } from '@src/util/functions';
import { API_URLS, MANAGER_STATUS, URLS } from '@src/util/constants';
import { useManagerStore } from '@src/util/store';
import api from '@src/util/api';
import DetailHeader from '@src/components/DetailHeader';
import Container from '@src/layouts/Container';
import SideBar from '@src/components/SideBar';
import { useMutation } from '@tanstack/react-query';
import { IApiError, IApiPOSTSuccess } from '@src/util/interfaces.ts';
import Button from '@src/components/Button.tsx';
import BottomButtonGroup from '@src/components/BottomButtonGroup.tsx';

interface ManagerProps {}

interface IFormData {
  name: string;
  password?: string;
  password_confirm?: string;
  phone_number: string;
  is_root_rights: string;
}

/**
 * TODO
 *  수정이 완료 되었을 시점, vaul 모달 이용해서 확인 -> nav back 테스트 시작 기점으로 보자.
 */
const UpdateManager: React.FC<ManagerProps> = () => {
  const [manager, setManager] = useManagerStore((state) => [
    state.manager,
    state.setManager,
  ]);

  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>({
    mode: 'onSubmit',
    defaultValues: {
      is_root_rights: manager?.is_root_rights == true ? 'true' : 'false',
      name: manager?.name ?? '',
      password: '',
      password_confirm: '',
      phone_number: manager?.phone_number ?? '',
    },
  });

  const { mutate, isPending } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    IFormData
  >({
    mutationFn: (formData) => {
      return api.patch(API_URLS.MANAGER + `/${manager?.id}`, {
        ...formData,
        is_root_rights: formData.is_root_rights == 'true',
      });
    },
  });

  const onSubmit = useCallback(
    (data: IFormData) => {
      if (!data.password) {
        delete data.password;
      }
      if (data.password && data.password !== data.password_confirm) {
        return;
      }

      delete data.password_confirm;
      data.phone_number = data.phone_number.replace(/-/g, '');

      mutate(data, {
        onSuccess: () => {
          const updatedManager = {
            ...manager,
            name: data.name,
            phone_number: data.phone_number,
            is_root_rights: data.is_root_rights == 'true',
          };
          setManager(updatedManager);
          navigate(URLS.MY_PAGE + `?type=${MANAGER_STATUS.MANAGER}`);
        },
        onError: (error) => {
          console.log(error);
        },
      });
    },
    [manager],
  );

  return (
    <Container
      header={<DetailHeader title={'담당자 정보 수정'} />}
      sideBar={<SideBar />}
    >
      <div className={'add-manager-container-wrapper'}>
        <form
          className="add-manager-container"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={'add-manager-content'}>
            <div className={'single-input-container'}>
              <Label text="권한" required={true} />
              <Controller
                name={'is_root_rights'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioButton
                    name={'taxableType'}
                    options={[
                      { label: '총괄 관리자', value: 'true' },
                      { label: '일반 관리자', value: 'false' },
                    ]}
                    defaultValue={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className={'single-input-container'}>
              <Label text="이름" required={true} />
              <Input
                register={register('name', {
                  minLength: {
                    value: 1,
                    message: '최소 1글자 이상 입력해야 합니다.',
                  },
                })}
                name={'name'}
                customClassName={'bg-gray'}
                placeholder={'이름을 입력하세요'}
              />
              {errors.name && (
                <div className="error-message">{errors.name?.message}</div>
              )}
            </div>
            <div className={'single-input-container'}>
              <Label text="비밀번호" />
              <Input
                register={register('password')}
                name={'password'}
                customClassName={'bg-gray'}
                type={'password'}
                placeholder={'비밀번호를 입력하세요'}
              />
              {errors.password && (
                <div className="error-message">{errors.password?.message}</div>
              )}
            </div>
            <div className={'single-input-container'}>
              <Label text="비밀번호 확인" />
              <Input
                register={register('password_confirm')}
                name={'password_confirm'}
                customClassName={'bg-gray'}
                type={'password'}
                placeholder={'비밀번호를 입력하세요'}
              />
              {errors.password_confirm && (
                <div className="error-message">
                  {errors.password_confirm?.message}
                </div>
              )}
            </div>
            <div className={'single-input-container'}>
              <Label text="휴대폰번호" required={true} />
              <Input
                register={register('phone_number', {
                  pattern: {
                    value: phoneRegex,
                    message: '휴대폰 형식에 맞지 않습니다.',
                  },
                  minLength: {
                    value: 1,
                    message: '최소 1글자 이상 입력해야 합니다.',
                  },
                })}
                value={manager?.phone_number}
                name={'phone_number'}
                customClassName={'bg-gray'}
                placeholder={'휴대폰 번호를 입력하세요'}
              />
              {errors.phone_number && (
                <div className="error-message">
                  {errors.phone_number?.message}
                </div>
              )}
            </div>
            <BottomButtonGroup
              left={
                <Link to={URLS.MY_PAGE + `?type=${MANAGER_STATUS.MANAGER}`}>
                  취소
                </Link>
              }
              right={
                <Button text={'확인'} type={'submit'} isLoading={isPending} />
              }
            />
          </div>
        </form>
      </div>
    </Container>
  );
};

export default UpdateManager;
