import React, { useEffect, useState } from 'react';
import SearchBar from '@src/components/SearchBar.tsx';
import {
  CERTIFICATES_MENU_LIST,
  CERTIFICATES_MENU_TYPE,
  CERTIFICATES_REQUEST_MENU_CATEGORY,
  CERTIFICATES_REQUEST_MENU_CATEGORY_LIST,
  CERTIFICATES_REQUEST_MENU_LIST,
} from '@src/util/constants';
import OvalTabs from '@src/components/OvalTabs.tsx';
import CardMenu from '@src/components/CardMenu.tsx';
import { useNavigate } from 'react-router-dom';
import { useScrollToTop } from '@src/util/hooks.ts';
import Header from '@src/components/Header.tsx';
import HeaderTabs from '@src/components/HeaderTabs.tsx';
import SideBar from '@src/components/SideBar.tsx';
import Footer from '@src/components/Footer.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import Container from '@src/layouts/Container.tsx';
import { refreshToken } from '@src/util/api.ts';

interface CertificateRequestProps {}

const CertificateRequest: React.FC<CertificateRequestProps> = () => {
  const [activeOvalTab, setActiveOvalTab] = useState<string>(
    CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
  );
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  useScrollToTop();
  useEffect(() => {
    refreshToken();
  }, []);

  return (
    <Container
      header={<Header setOpen={setOpen} />}
      headerTab={
        <HeaderTabs
          menus={CERTIFICATES_MENU_LIST}
          activeHeaderTab={CERTIFICATES_MENU_TYPE.REQUEST}
        />
      }
      sideBar={<SideBar />}
      footer={<Footer current={'certificates'} />}
      modal={<CompanyDrawer open={open} setOpen={setOpen} />}
    >
      <div className={'main-page-container'}>
        <div className="certificate-request-search">
          <div className="certificate-text-box">
            <h1>증명서 발급</h1>
            <p>
              검색을 통해 원하시는 증명서를 간편하고 쉽게 발급 받으실 수
              있습니다.
            </p>
          </div>
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </div>
        <div className="certificate-oval-tab-container">
          <OvalTabs
            menus={CERTIFICATES_REQUEST_MENU_CATEGORY_LIST}
            activeCardHeaderTab={activeOvalTab}
            setActiveCardHeaderTab={setActiveOvalTab}
          />
        </div>
        <div className="certificate-request-document-list-container">
          {CERTIFICATES_REQUEST_MENU_LIST.filter((item) =>
            item.text.includes(searchValue),
          )
            .filter((item) =>
              item.type.includes(
                activeOvalTab as CERTIFICATES_REQUEST_MENU_CATEGORY,
              ),
            )
            .map((menu, i) => (
              <CardMenu
                key={i}
                text={menu.text}
                onClick={() => {
                  navigate(`/certificates/form/${menu.id}`);
                }}
              />
            ))}
        </div>
      </div>
    </Container>
  );
};
export default CertificateRequest;
