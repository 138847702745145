import React from 'react';
import Divider from './Divider.tsx';
import TailSpinner from '@src/components/TailSpinner.tsx';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  content?: string | React.ReactNode;
  okButton?: string;
  cancelButton?: string;
  onOk?: () => void;
  onCancel?: () => void;
  body?: React.ReactNode;
  style?: React.CSSProperties;
  isLoading?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  content,
  okButton,
  onOk,
  cancelButton,
  onCancel,
  body,
  style,
  isLoading,
}) => {
  if (!isOpen) return null;

  // Function to handle backdrop click
  const handleBackdropClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const renderBody = () => {
    if (body) {
      return body;
    } else {
      return (
        <>
          <div className="modal-header">
            <h1>{title}</h1>
          </div>
          <div className="modal-body">
            {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TailSpinner type={'full-page'} />
              </div>
            ) : typeof content === 'string' ? (
              <p style={{ whiteSpace: 'pre-wrap' }}>{content}</p>
            ) : (
              content
            )}
          </div>
          <Divider
            customClassName="overflow-divider-modal"
            style={{ display: isLoading ? 'none' : '' }}
          />
          <div
            className="modal-footer"
            style={{ display: isLoading ? 'none' : '' }}
          >
            {cancelButton && (
              <button
                onClick={onCancel}
                className="cancel-button"
                disabled={isLoading}
              >
                {cancelButton}
              </button>
            )}
            <button onClick={onOk} disabled={isLoading}>
              {okButton}
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <div className="modal-backdrop" onClick={handleBackdropClick}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        style={style}
      >
        {renderBody()}
      </div>
    </div>
  );
};

export default Modal;
