import { FC } from 'react';

interface BuildingIconProps {
  fill: string;
  size: string;
}

const BuildingIcon: FC<BuildingIconProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon / building">
        <path
          id="Vector"
          d="M6 21C5.71667 21 5.47917 20.9042 5.2875 20.7125C5.09583 20.5208 5 20.2833 5 20V4C5 3.71667 5.09583 3.47917 5.2875 3.2875C5.47917 3.09583 5.71667 3 6 3H18C18.2833 3 18.5208 3.09583 18.7125 3.2875C18.9042 3.47917 19 3.71667 19 4V20C19 20.2833 18.9042 20.5208 18.7125 20.7125C18.5208 20.9042 18.2833 21 18 21H13V17H11V21H6ZM7 19H9V15H15V19H17V5H7V19ZM9 13H11V11H9V13ZM9 9H11V7H9V9ZM13 13H15V11H13V13ZM13 9H15V7H13V9Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default BuildingIcon;
