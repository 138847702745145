import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from './constants.ts';
import { useUserStore } from '../util/store.ts';

const api: AxiosInstance = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(async (request) => {
  if (!request.url?.startsWith('/v1/auth/')) {
    const token = Cookies.get('accessToken');
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
  }
  return request;
});

export const refreshToken = async () => {
  const accessToken = Cookies.get('accessToken');
  const refreshToken = Cookies.get('refreshToken');

  const response = await api.post('/v1/auth/users/login/token', {
    accessToken,
    refreshToken,
  });

  const newAccessToken = response.data.accessToken;
  const newRefreshToken = response.data.refreshToken;

  if (Cookies.get('autoLogin') === 'N') {
    Cookies.set('accessToken', newAccessToken);
    Cookies.set('refreshToken', newRefreshToken);
  } else {
    Cookies.set('accessToken', newAccessToken, { expires: 7 });
    Cookies.set('refreshToken', newRefreshToken, { expires: 7 });
  }

  useUserStore.setState((state) => ({
    user: {
      ...response.data.user,
      selectedCompany:
        state.user?.selectedCompany ?? response.data.user?.companies?.[0],
    },
  }));

  return response.data.accessToken;
};

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newAccessToken = await refreshToken();
      originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
      return api(originalRequest);
    }

    return Promise.reject(error);
  },
);

export default api;
