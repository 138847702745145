import React, { Suspense, useState } from 'react';
import Container from '@src/layouts/Container';
import DetailHeader from '@src/components/DetailHeader.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URLS, COLORS } from '@src/util/constants.ts';
import IconSVG from '@src/components/IconSVG.tsx';
import Modal from '@src/components/Modal.tsx';
import api from '@src/util/api.ts';
import { useUserStore } from '@src/util/store.ts';
import Snackbar from '@src/components/SnackBar.tsx';
import TaskComment from '@src/pages/tax/TaskComment';
import SideBar from '@src/components/SideBar.tsx';
import TaskDetailContent from '@src/pages/tax/TaskDetailContent.tsx';
import TailSpinner from '@src/components/TailSpinner.tsx';

interface TaskDetailProps {}

const TaskDetail: React.FC<TaskDetailProps> = () => {
  const { id = '' } = useParams<{
    id: string;
  }>();
  const navigate = useNavigate();

  const user = useUserStore((state) => state.user);
  const company_id = user?.selectedCompany?.id ?? 0;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  return (
    <Container
      style={{ minHeight: 'calc(100vh - 56px - 64px)' }}
      header={
        <DetailHeader
          title={'요청 상세'}
          custom={
            <IconSVG
              iconSVG={'delete'}
              size={'24'}
              fill={COLORS['red']}
              onClick={() => setDeleteModalOpen(true)}
              containerStyle={{
                position: 'absolute',
                right: 0,
                cursor: 'pointer',
              }}
            />
          }
        />
      }
      sideBar={<SideBar />}
      footer={
        <Suspense fallback={<TailSpinner type={'full-page'} />}>
          <TaskComment taskId={id} setSnackBarOpen={setSnackBarOpen} />
        </Suspense>
      }
      modal={
        <>
          <Modal
            isOpen={deleteModalOpen}
            onOk={() => {
              setDeleteModalOpen(false);
              api.delete(API_URLS.TASK + `/${company_id}/${id}`).then(() => {
                setDeleteConfirmModalOpen(true);
              });
            }}
            title={'업무 요청 삭제'}
            content={'업무 요청을 삭제하시겠습니까?'}
            okButton={'삭제'}
            cancelButton={'취소'}
            onCancel={() => setDeleteModalOpen(false)}
            onClose={() => {
              setDeleteModalOpen(false);
            }}
          />
          <Modal
            isOpen={deleteConfirmModalOpen}
            onOk={() => {
              setDeleteConfirmModalOpen(false);
              navigate(-1);
            }}
            title={'업무 요청 삭제'}
            content={'업무 요청이 삭제되었습니다.'}
            okButton={'확인'}
            onClose={() => {
              setDeleteConfirmModalOpen(false);
            }}
          />
          <Snackbar
            message="댓글이 삭제되었습니다."
            open={snackBarOpen}
            type={'info'}
          />
        </>
      }
    >
      <TaskDetailContent />
    </Container>
  );
};

export default TaskDetail;
