import styled from '@emotion/styled';
import { COLORS } from '@src/util/constants.ts';
import React from 'react';

const StyledButtonContainer = styled.div`
  @media screen and (max-width: 480px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 16px 24px 16px;
  }

  @media screen and (min-width: 481px) {
    position: relative;
    margin-top: 100px;
    width: 100%;
  }

  width: 100%;
  box-sizing: border-box;
  z-index: 100;
  background-color: ${COLORS['white']};
  max-width: 786px;
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 12px;

  & > *:first-child {
    display: flex;
    padding: 14px 10px;
    justify-content: center;
    align-items: center;
    /*gap: 10px;*/
    flex: 1 0 0;
    text-decoration-line: none;
    cursor: pointer;

    border-radius: 3px;
    border: 1px solid ${COLORS['gray4']};
    background: ${COLORS['white']};

    color: ${COLORS['gray4']};
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }

  & > *:last-child {
    display: flex;
    padding: 14px 10px;
    justify-content: center;
    align-items: center;
    //gap: 10px;
    flex: 1 0 0;

    cursor: pointer;
    text-decoration-line: none;

    border-radius: 3px;
    background: ${COLORS['primary']};

    color: ${COLORS['white']};
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;

    &:disabled {
      cursor: default;
    }
  }
`;

interface BottomButtonGroupProps {
  left: React.ReactNode;
  right: React.ReactNode;
}

const BottomButtonGroup: React.FC<BottomButtonGroupProps> = ({
  left,
  right,
}) => {
  return (
    <StyledButtonContainer>
      {left}
      {right}
    </StyledButtonContainer>
  );
};

export default BottomButtonGroup;
