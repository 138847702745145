import reportIcon from '/src/assets/icons/bottom/report.svg';
import reportDisabledIcon from '/src/assets/icons/bottom/report-disabled.svg';
import certificatesIcon from '/src/assets/icons/bottom/certificates.svg';
import certificatesDisabledIcon from '/src/assets/icons/bottom/certificates-disabled.svg';
import taxInvoiceIcon from '/src/assets/icons/bottom/tax-invoice.svg';
import taxInvoiceDisabeldIcon from '/src/assets/icons/bottom/tax-invoice-disabled.svg';
import taxIcon from '/src/assets/icons/bottom/tax.svg';
import taxDisabledIcon from '/src/assets/icons/bottom/tax-disabled.svg';
import menuIcon from '/src/assets/icons/bottom/menu.svg';
import menuDisabledIcon from '/src/assets/icons/bottom/menu-disabled.svg';

import accountIcon from '/src/assets/icons/menu/account_circle.svg'; // 마이페이지
import calculatorIcon from '/src/assets/icons/menu/calculator.svg'; // 예상 세금
import moneyIcon from '/src/assets/icons/menu/money.svg'; // 노무지원금
import optionIcon from '/src/assets/icons/menu/option.svg'; // 기타
import receiptIcon from '/src/assets/icons/menu/receipt_long.svg'; // 증명서 발급
import reportIcon1 from '/src/assets/icons/menu/report.svg'; // 리포트
import syncIcon from '/src/assets/icons/menu/sync_1.svg'; // 데이터 연동
import taxIcon1 from '/src/assets/icons/menu/tax.svg'; // 세금관리

import pdfIcon from '/src/assets/icons/files/pdf.svg';
import excelIcon from '/src/assets/icons/files/excel.svg';
import hwpIcon from '/src/assets/icons/files/hwp.svg';
import jpgIcon from '/src/assets/icons/files/jpg.svg';
import pptIcon from '/src/assets/icons/files/ppt.svg';
import wordIcon from '/src/assets/icons/files/word.svg';

import { IIconSVGMap } from './interfaces.ts';
import SendIcon from '../assets/icons/SendIcon.tsx';
import CloseIcon from '../assets/icons/CloseIcon.tsx';
import DeleteIcon from '../assets/icons/DeleteIcon.tsx';
import CalculatorIcon from '../assets/icons/CalculatorIcon.tsx';
import SchoolIcon from '../assets/icons/SchoolIcon.tsx';
import SyncIcon from '../assets/icons/SyncIcon.tsx';
import ReportIcon from '../assets/icons/ReportIcon.tsx';
import ReceiptIcon from '../assets/icons/ReceiptIcon.tsx';
import MoneyIcon from '../assets/icons/MoneyIcon.tsx';
import TaxIcon from '../assets/icons/TaxIcon.tsx';
import AccountIcon from '../assets/icons/AccountIcon.tsx';
import EtcIcon from '../assets/icons/EtcIcon.tsx';
import PrintIcon from '@src/assets/icons/PrintIcon.tsx';
import ContractIcon from '@src/assets/icons/ContractIcon.tsx';
import UserListIcon from '@src/assets/icons/UserListIcon.tsx';
import CheckListIcon from '@src/assets/icons/CheckListIcon.tsx';
import BuildingIcon from '@src/assets/icons/BuildingIcon.tsx';
import ReplyIcon from '@src/assets/icons/ReplyIcon.tsx';
import { OptionValue } from '@src/components/Select.tsx';
import ErrorIcon from '@src/assets/icons/ErrorIcon.tsx';
import LightBulbIcon from '@src/assets/icons/LightBulbIcon.tsx';
import TaxInvoiceIcon from '@src/assets/icons/TaxInvoiceIcon.tsx';
import CheckIcon from '@src/assets/icons/CheckIcon.tsx';

export enum LaborQuestion {
  청년일자리도약장려금 = 'youth',
  시니어인턴지원금 = 'senior',
  육아휴직지원금 = 'baby',
}

export enum CardCompany {
  신한카드 = '신한카드',
  현대카드 = '현대카드',
  삼성카드 = '삼성카드',
  국민카드 = '국민카드',
  롯데카드 = '롯데카드',
  하나카드 = '하나카드',
  우리카드 = '우리카드',
  농협카드 = '농협카드',
  BC카드 = 'BC카드',
}

export enum MANAGER_STATUS {
  USER = 'USER',
  MANAGER = 'MANAGER',
  BUSINESS = 'BUSINESS',
}

export enum REPORT_TERM {
  MONTHLY = 'monthly',
  DAILY = 'daily',
  YEARLY = 'yearly',
  EXPECTED_PAYMENT = 'expected-payment',
}

export enum REPORT_CATEGORY {
  SALES = 'sales',
  PURCHASE = 'purchase',
  LABOR = 'labor',
  TOTAL = 'total',
}

export const REPORT_TERM_MENU_LIST = [
  { id: REPORT_TERM.MONTHLY, text: '월간 보고', url: '/reports/monthly' },
  { id: REPORT_TERM.DAILY, text: '일간 보고', url: '/reports/daily' },
  {
    id: REPORT_TERM.YEARLY,
    text: '연간 보고',
    url: '/reports/yearly',
  },
  {
    id: REPORT_TERM.EXPECTED_PAYMENT,
    text: '입금 예정',
    url: '/reports/expected-payment',
  },
];

export enum LABOR_MENU {
  REQUEST = 'request',
}

export const LABOR_MENU_LIST = [
  { id: LABOR_MENU.REQUEST, text: '노무지원금 신청', url: '/labor' },
];

export enum SYNC_MENU {
  SYNC = 'sync',
}

export const SYNC_MENU_LIST = [
  { id: SYNC_MENU.SYNC, text: '데이터 연동', url: '/sync' },
];

export enum HOMETAX_SYNC_MENU {
  HOMETAX = 'hometax',
}

export const HOMETAX_SYNC_MENU_LIST = [
  {
    id: HOMETAX_SYNC_MENU.HOMETAX,
    text: '홈택스 데이터 연결하기',
    url: '/sync/hometax',
  },
];

export enum CARD_SYNC_MENU {
  CARD = 'card',
}

export const CARD_SYNC_MENU_LIST = [
  { id: CARD_SYNC_MENU.CARD, text: '카드사 연결하기', url: '/sync/card' },
];

export enum COMMERCE_SYNC_MENU {
  COMMERCE = 'commerce',
}

export const COMMERCE_SYNC_MENU_LIST = [
  {
    id: COMMERCE_SYNC_MENU.COMMERCE,
    text: '커머스 연결하기',
    url: '/sync/commerce',
  },
];

export enum ADD_MANAGER_MENU {
  MANAGER = 'manager',
}

export const ADD_MANAGER_MENU_LIST = [
  {
    id: ADD_MANAGER_MENU.MANAGER,
    text: '담당자 등록',
    url: '/manager/register',
  },
];

export enum DELIVERY_SYNC_MENU {
  DELIVERY = 'delivery',
}

export const DELIVERY_SYNC_MENU_LIST = [
  {
    id: DELIVERY_SYNC_MENU.DELIVERY,
    text: '배달앱 연결하기',
    url: '/sync/delivery',
  },
];

export enum CUSTOMER_SEARCH_MENU {
  CUSTOMER = 'customer',
}

export const CUSTOMER_SEARCH_MENU_LIST = [
  {
    id: CUSTOMER_SEARCH_MENU.CUSTOMER,
    text: '거래처정보 목록조회',
    url: '/customer/search',
  },
];

export enum ADDRESS_SEARCH_MENU {
  ADDRESS = 'address',
}

export const ADDRESS_SEARCH_MENU_LIST = [
  {
    id: ADDRESS_SEARCH_MENU.ADDRESS,
    text: '주소조회',
    url: '/address/search',
  },
];

export enum INDUSTRY_SEARCH_MENU {
  INDUSTRY = 'industry',
}

export const INDUSTRY_SEARCH_MENU_LIST = [
  {
    id: INDUSTRY_SEARCH_MENU.INDUSTRY,
    text: '업종코드 목록조회',
    url: '/industry/search',
  },
];

export enum EXPECTED_PAYMENT_DETAIL_MENU {
  PAYMENT = 'payment',
}

export const EXPECTED_PAYMENT_DETAIL_MENU_LIST = [
  {
    id: EXPECTED_PAYMENT_DETAIL_MENU.PAYMENT,
    text: '입금 예정',
    url: '/expected-payment',
  },
];

export enum REPORT_CATEGORY_ADDITIONAL_MENU {
  MONTHLY_SALES = 'monthly_sales',
  DAILY_SALES = 'daily_sales',
}

export const REPORT_CATEGORY_ADDITIONAL_MENU_LIST = [
  {
    id: REPORT_CATEGORY_ADDITIONAL_MENU.MONTHLY_SALES,
    text: '월별매출',
    url: '/reports/additional/monthly/sales',
  },
  {
    id: REPORT_CATEGORY_ADDITIONAL_MENU.DAILY_SALES,
    text: '일별매출',
    url: '/reports/additional/daily/sales',
  },
];

export enum TAX_MENU {
  TASK = 'task',
  TAX_PAID = 'tax_paid',
  RECEIPT = 'receipt',
}

export const TAX_MENU_LIST = [
  { id: TAX_MENU.TASK, text: '업무처리 현황', url: '/tax/task' },
  { id: TAX_MENU.TAX_PAID, text: '세금납부 현황', url: '/tax/tax_paid' },
  { id: TAX_MENU.RECEIPT, text: '영수증 등록', url: '/tax/receipt' },
];

export const REPORT_TERM_TEXT: {
  [REPORT_TERM.DAILY]: string;
  [REPORT_TERM.MONTHLY]: string;
  [REPORT_TERM.YEARLY]: string;
} = {
  [REPORT_TERM.DAILY]: '일',
  [REPORT_TERM.MONTHLY]: '월',
  [REPORT_TERM.YEARLY]: '연',
};

export const REPORT_CATEGORY_TEXT: { [key in REPORT_CATEGORY]: string } = {
  [REPORT_CATEGORY.SALES]: '매출',
  [REPORT_CATEGORY.PURCHASE]: '매입',
  [REPORT_CATEGORY.TOTAL]: '손익',
  [REPORT_CATEGORY.LABOR]: '인건비',
};

export const REPORT_CATEGORY_MENU_LIST = [
  { id: REPORT_CATEGORY.SALES, text: '매출', color: 'blue' },
  { id: REPORT_CATEGORY.PURCHASE, text: '매입', color: 'red' },
  { id: REPORT_CATEGORY.LABOR, text: '인건비', color: 'red' },
  { id: REPORT_CATEGORY.TOTAL, text: '손익', color: 'black' },
];

export enum CERTIFICATES_ISSUED_MENU {
  ALL = '전체',
  ISSUED = '발급완료',
  DENIED = '발급불가',
  FAILED = '발급실패',
  PENDING = '발급요청',
}

export enum TASK_MENU {
  REQUEST = 'REQUEST',
  PROCEEDING = 'PROCEEDING',
  FEEDBACK = 'FEEDBACK',
  HOLD = 'HOLD',
  COMPLETE = 'COMPLETE',
}

export const TASK_MENU_CONVERT = {
  REQUEST: '요청',
  PROCEEDING: '진행',
  FEEDBACK: '피드백',
  HOLD: '보류',
  COMPLETE: '완료',
};

export const TASK_MENU_OPTIONS: OptionValue[] = [
  { label: '요청', value: 'REQUEST' },
  { label: '진행', value: 'PROCEEDING' },
  { label: '피드백', value: 'FEEDBACK' },
  { label: '보류', value: 'HOLD' },
  { label: '완료', value: 'COMPLETE' },
];

export const TASK_MENU_OPTIONS_WITH_ALL = [
  { label: '전체', value: '전체' },
].concat(TASK_MENU_OPTIONS);

export const TASK_MENU_COLOR_CONVERT = {
  REQUEST: '#8983F1',
  PROCEEDING: '#00BA88',
  FEEDBACK: '#F96464',
  HOLD: '#949494',
  COMPLETE: '#3182F6',
};

export const TASK_MENU_LIST = [
  { id: TASK_MENU.REQUEST, text: '요청', color: 'purple' },
  { id: TASK_MENU.PROCEEDING, text: '진행', color: 'green' },
  { id: TASK_MENU.FEEDBACK, text: '피드백', color: 'red' },
  { id: TASK_MENU.HOLD, text: '보류', color: 'gray4' },
  { id: TASK_MENU.COMPLETE, text: '완료', color: 'primary' },
];

export const CERTIFICATES_ISSUED_MENU_LIST = [
  { id: CERTIFICATES_ISSUED_MENU.ALL, text: '전체', color: 'blue' },
  { id: CERTIFICATES_ISSUED_MENU.ISSUED, text: '발급완료', color: 'blue' },
  { id: CERTIFICATES_ISSUED_MENU.DENIED, text: '발급불가', color: 'red' },
  { id: CERTIFICATES_ISSUED_MENU.FAILED, text: '발급실패', color: 'gray4' },
  { id: CERTIFICATES_ISSUED_MENU.PENDING, text: '발급요청', color: 'purple' },
];

export enum CERTIFICATES_MENU_TYPE {
  REQUEST = 'request',
  ISSUED = 'issued',
}

export enum CERTIFICATES_REQUEST_MENU_CATEGORY {
  ALL = 'all',
  POPULAR = 'popular',
  COMPANY = 'company',
  SETTLEMENT = 'settlement',
  ADDITIONAL_TAX = 'additional_tax',
  WITHOLD_TAX = 'withhold_tax',
  PAYMENT = 'payment',
  INCOME = 'income',
}

export enum REPORT_CATEGORY_SALES_TYPE {
  STATEMENT = 'statement',
  CARD = 'card',
  CASH = 'cash',
  COMMERCE_AND_DELIVERY = 'commerceAndDelivery',
}

export enum REPORT_CATEGORY_PURCHASE_TYPE {
  STATEMENT = 'statement',
  CARD = 'card',
  CASH = 'cash',
  RECEIPT = 'receipt',
}

export enum REPORT_CATEGORY_LABOR_TYPE {
  EMPLOYEE = 'employee',
  FREELANCER = 'freelancer',
  SEVERANCE = 'severance',
  ETC = 'etc',
}

export const REPORT_CATEGORY_SALES_MENU_LIST = [
  {
    id: REPORT_CATEGORY_SALES_TYPE.STATEMENT,
    text: '(세금)계산서',
    url: '/statement',
  },
  { id: REPORT_CATEGORY_SALES_TYPE.CARD, text: '카드', url: '/card' },
  {
    id: REPORT_CATEGORY_SALES_TYPE.CASH,
    text: '현금영수증',
    url: '/cash',
  },
  {
    id: REPORT_CATEGORY_SALES_TYPE.COMMERCE_AND_DELIVERY,
    text: '커머스/배달앱 등',
    url: '/commerceAndDelivery',
  },
];

export const REPORT_CATEGORY_PURCHASE_MENU_LIST = [
  {
    id: REPORT_CATEGORY_PURCHASE_TYPE.STATEMENT,
    text: '(세금)계산서',
    url: '/statement',
  },
  { id: REPORT_CATEGORY_PURCHASE_TYPE.CARD, text: '카드', url: '/card' },
  { id: REPORT_CATEGORY_PURCHASE_TYPE.CASH, text: '현금영수증', url: '/cash' },
  {
    id: REPORT_CATEGORY_PURCHASE_TYPE.RECEIPT,
    text: '간이영수증',
    url: '/receipt',
  },
];

export const REPORT_CATEGORY_LABOR_MENU_LIST = [
  { id: REPORT_CATEGORY_LABOR_TYPE.EMPLOYEE, text: '직원급여', url: '/labor' },
  {
    id: REPORT_CATEGORY_LABOR_TYPE.FREELANCER,
    text: '프리랜서',
    url: '/freelancer',
  },
  {
    id: REPORT_CATEGORY_LABOR_TYPE.SEVERANCE,
    text: '퇴직금',
    url: '/severance',
  },
  { id: REPORT_CATEGORY_LABOR_TYPE.ETC, text: '기타', url: '/etc' },
];

export const CERTIFICATES_MENU_LIST = [
  {
    id: CERTIFICATES_MENU_TYPE.REQUEST,
    text: '증명서 발급 신청',
    url: '/certificates/request',
  },
  {
    id: CERTIFICATES_MENU_TYPE.ISSUED,
    text: '증명서 발급 내역',
    url: '/certificates/issued',
  },
];

export enum TAX_INVOICE_MENU_TYPE {
  REGULAR = 'regular',
  EXEMPT = 'exempt',
  ISSUED = 'issued',
}

export const TAX_INVOICE_MENU_LIST = [
  {
    id: TAX_INVOICE_MENU_TYPE.REGULAR,
    text: '세금계산서(영세율포함)',
    url: '/tax-invoice/regular',
  },
  {
    id: TAX_INVOICE_MENU_TYPE.EXEMPT,
    text: '계산서(면세)',
    url: '/tax-invoice/exempt',
  },
  {
    id: TAX_INVOICE_MENU_TYPE.ISSUED,
    text: '발급목록조회',
    url: '/tax-invoice/issued',
  },
];

export const CERTIFICATES_REQUEST_MENU_CATEGORY_LIST = [
  {
    id: CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
    text: '전체',
    color: 'blue',
  },
  {
    id: CERTIFICATES_REQUEST_MENU_CATEGORY.POPULAR,
    text: '인기',
    color: 'blue',
  },
  {
    id: CERTIFICATES_REQUEST_MENU_CATEGORY.COMPANY,
    text: '회사',
    color: 'blue',
  },
  {
    id: CERTIFICATES_REQUEST_MENU_CATEGORY.SETTLEMENT,
    text: '결산',
    color: 'blue',
  },
  {
    id: CERTIFICATES_REQUEST_MENU_CATEGORY.ADDITIONAL_TAX,
    text: '부가',
    color: 'blue',
  },
  {
    id: CERTIFICATES_REQUEST_MENU_CATEGORY.WITHOLD_TAX,
    text: '원천',
    color: 'blue',
  },
  {
    id: CERTIFICATES_REQUEST_MENU_CATEGORY.PAYMENT,
    text: '납부',
    color: 'blue',
  },
  {
    id: CERTIFICATES_REQUEST_MENU_CATEGORY.INCOME,
    text: '소득',
    color: 'blue',
  },
];

export const CERTIFICATES_REQUEST_MENU_LIST = [
  {
    id: '0',
    text: '사업자등록증명',
    type: [
      CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
      CERTIFICATES_REQUEST_MENU_CATEGORY.POPULAR,
      CERTIFICATES_REQUEST_MENU_CATEGORY.COMPANY,
    ],
    url: '/certificates/form/0',
  },
  {
    id: '1',
    text: '표준재무제표증명',
    type: [
      CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
      CERTIFICATES_REQUEST_MENU_CATEGORY.POPULAR,
      CERTIFICATES_REQUEST_MENU_CATEGORY.SETTLEMENT,
    ],
    url: '/certificates/form/1',
  },
  {
    id: '2',
    text: '부가세 과세표준증명',
    type: [
      CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
      CERTIFICATES_REQUEST_MENU_CATEGORY.POPULAR,
      CERTIFICATES_REQUEST_MENU_CATEGORY.ADDITIONAL_TAX,
    ],
    url: '/certificates/form/2',
  },
  {
    id: '3',
    text: '부가세 면세사업자 수입금액증명',
    type: [
      CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
      CERTIFICATES_REQUEST_MENU_CATEGORY.POPULAR,
      CERTIFICATES_REQUEST_MENU_CATEGORY.ADDITIONAL_TAX,
    ],
    url: '/certificates/form/3',
  },
  {
    id: '4',
    text: '납세증명 (국세완납증명)',
    type: [
      CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
      CERTIFICATES_REQUEST_MENU_CATEGORY.POPULAR,
      CERTIFICATES_REQUEST_MENU_CATEGORY.PAYMENT,
    ],
    url: '/certificates/form/4',
  },
  {
    id: '5',
    text: '납부내역증명 (납세사실증명)',
    type: [
      CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
      CERTIFICATES_REQUEST_MENU_CATEGORY.POPULAR,
      CERTIFICATES_REQUEST_MENU_CATEGORY.PAYMENT,
    ],
    url: '/certificates/form/5',
  },
  {
    id: '6',
    text: '소득금액증명',
    type: [
      CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
      CERTIFICATES_REQUEST_MENU_CATEGORY.POPULAR,
      CERTIFICATES_REQUEST_MENU_CATEGORY.INCOME,
    ],
    url: '/certificates/form/6',
  },
  {
    id: '7',
    text: '재무제표확인원',
    type: [
      CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
      CERTIFICATES_REQUEST_MENU_CATEGORY.SETTLEMENT,
    ],
    url: '/certificates/form/7',
  },
  {
    id: '8',
    text: '매출처별 / 매입처별 세금계산서 합계표',
    type: [
      CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
      CERTIFICATES_REQUEST_MENU_CATEGORY.ADDITIONAL_TAX,
    ],
    url: '/certificates/form/8',
  },
  {
    id: '9',
    text: '매출처별 / 매입처별 계산서 합계표',
    type: [
      CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
      CERTIFICATES_REQUEST_MENU_CATEGORY.ADDITIONAL_TAX,
    ],
    url: '/certificates/form/9',
  },
  {
    id: '10',
    text: '갑근세원천징수확인서',
    type: [
      CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
      CERTIFICATES_REQUEST_MENU_CATEGORY.WITHOLD_TAX,
    ],
    url: '/certificates/form/10',
  },
  {
    id: '11',
    text: '원천징수영수증 (근로소득)',
    type: [
      CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
      CERTIFICATES_REQUEST_MENU_CATEGORY.WITHOLD_TAX,
    ],
    url: '/certificates/form/11',
  },
  {
    id: '12',
    text: '원천징수영수증 (사업소득)',
    type: [
      CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
      CERTIFICATES_REQUEST_MENU_CATEGORY.WITHOLD_TAX,
    ],
    url: '/certificates/form/12',
  },
  {
    id: '13',
    text: '중소기업기준검토표',
    type: [
      CERTIFICATES_REQUEST_MENU_CATEGORY.ALL,
      CERTIFICATES_REQUEST_MENU_CATEGORY.COMPANY,
    ],
    url: '/certificates/form/13',
  },
];

export const COMPANY_LIST_BACKGROUND_COLOR = [
  '#3182F6',
  '#FFA318',
  '#00BA88',
  '#0046FF',
  '#EBDA44',
  '#000000',
  '#873E23',
  '#89B3EF',
  '#31343A',
  '#8983F1',
];

export const API_URL = import.meta.env.VITE_BASE_URL
  ? import.meta.env.VITE_BASE_URL
  : 'https://dev-api.jylabs.co.kr';

export const URLS = {
  FIND_ID: '/find-id',
  ADD_MANAGER: '/my-page/manager/register',
  MY_PAGE: '/my-page',
  UPDATE_MANAGER: '/my-page/manager/update',
  MANAGER_DETAIL: '/my-page/manager/detail',
  CHANGE_PW: '/my-page/change-password',
  SYNC: '/sync',
  HOMETAX_SYNC: '/sync/hometax',
  COMMERCE_SYNC: '/sync/commerce',
  DELIVERY_SYNC: '/sync/delivery',
  CARD_SYNC: '/sync/card',
  LABOR: '/labor',
  EXPECTED_TAX: '/expected-tax',
  FIND_ID_COMPLETE: '/find-id/complete',
  FIND_PW: '/find-pw',
  REGISTER: '/register',
  REGISTER_EMAIL: '/register/email',
  TAX_INVOICE: '/tax-invoice',
  TAX_INVOICE_REGULAR: '/tax-invoice/regular',
  TAX_INVOICE_EXEMPT: '/tax-invoice/exempt',
  TAX_INVOICE_ISSUED: 'tax-invoice/issued',
  ADDRESS_SEARCH: '/address/search',
  INDUSTRY_SEARCH: '/industry/search',
  CUSTOMER_SEARCH: '/customer/search',
};

export const API_URLS = {
  FIND_ID: '/v1/auth/find/email',
  MANAGER: '/v1/business/managers',
  CERT: '/v1/cert',
  FIND_ID_COMPLETE: '/v1/find-id/complete',
  COMPANY: '/v1/business/company',
  BUSINESS: '/v1/business',
  VERIFY_PASSWORD: '/v1/business/verify-password',
  UPDATE_PASSWORD: '/v1/business/update-password',
  SEND_EMAIL: '/v1/auth/email/send',
  SEND_EMAIL_COMPLETE: '/v1/auth/email/complete',
  CORP_TAX: '/v1/report/corp-tax',
  VAT: '/v1/report/vat',
  UPLOAD_TASK_ATTACHMENT: '/v1/communication/task/attachment',
  TASK: '/v1/communication/task',
  LABOR: '/v1/communication/labor',
  CREATE_TASK_COMMENT: '/v1/communication/task/comment',
  TAX_PAID: '/v1/report/tax',
  RECEIPT: '/v1/receipt',
  RECEIPT_UPLOAD: '/v1/receipt',
  EXPECTED_PAYMENT: '/v1/report/deposit/schedule',
  AUTH: '/v1/auth',
  USERS: '/v1/auth/users',
  SEND_EMAIL_CHANGE_PASSWORD: '/v1/auth/change-pw/email/send',
  UPDATE_PASSWORD_USER: '/v1/auth/update/password',
  VERIFY_BIZ_NO: '/v1/auth/validate/biz-no',
  OPENAPI_ADDRESS: '/v1/openapi/address',
  INDUSTRY_SEARCH: '/v1/communication/industry',
  CUSTOMER_SEARCH: '/v1/communication/customer',
  ISSUE_TAX_INVOICE: '/v1/communication/issue/statement',
  TAX_INVOICE_ISSUED: '/v1/communication/statement',
};

export const API_URLS_ADMIN = {
  CLIENT: '/v1/admin/company',
  COMPANY_APPROVE: '/v1/admin/company/approve',
  COMPANY_REVOKE: '/v1/admin/company/revoke',
  COMPANY_SCRAP: '/v1/admin/company/scrap',
  COMPANY_LOGIN: '/v1/admin/company/login',
  BRANCH: '/v1/admin/branch',
  BRANCH_ADMIN: '/v1/admin',
  CERTIFICATES: '/v1/admin/document',
  CERTIFICATES_UPLOAD: '/v1/admin/document/upload',
  LABOR: '/v1/admin/labor',
  TASK: '/v1/admin/task',
  TASK_COMMENT: '/v1/admin/task/comment',
  RECEIPT: '/v1/admin/receipt',
  EXPECTED_TAX: '/v1/admin/expected-tax',
  MY_PAGE_DETAIL: '/v1/admin/detail',
  CHECK_PASSWORD: '/v1/admin/check-password',
  CHECK_ADMIN_PASSWORD: '/v1/admin/check-admin-password',
  PERIOD_BATCH_OPTION: '/v1/admin/batch/option',
  RECEIPT_DOWNLOAD: '/v1/admin/receipt/download',
  ETC_SALES: '/v1/admin/etc-sales',
  DAILY_BATCH: '/v1/admin/batch/daily',
  FAILED_BATCH: '/v1/admin/failed',
  FAILED_BATCH_ALL: '/v1/admin/failed/all',
  BRANCH_STAFF: '/v1/admin/staff',
  COMPANY_SYNC: '/v1/admin/sync',
};

export const BOTTOM_TAB_DATA = [
  {
    id: 'reports',
    url: '/reports',
    icon: reportIcon,
    disabledIcon: reportDisabledIcon,
    urlText: '리포트',
  },
  {
    id: 'taxInvoice',
    url: URLS.TAX_INVOICE_REGULAR,
    icon: taxInvoiceIcon,
    disabledIcon: taxInvoiceDisabeldIcon,
    urlText: '세금계산서',
  },
  {
    id: 'certificates',
    url: '/certificates/request',
    icon: certificatesIcon,
    disabledIcon: certificatesDisabledIcon,
    urlText: '증명서 발급',
  },
  {
    id: 'tax',
    url: '/tax',
    icon: taxIcon,
    disabledIcon: taxDisabledIcon,
    urlText: '세금관리',
  },
  {
    id: 'menu',
    url: '/menu',
    icon: menuIcon,
    disabledIcon: menuDisabledIcon,
    urlText: '메뉴',
  },
];
export const cvaDcumUseUsgCd: [string, ...string[]] = [
  '입찰, 계약용',
  '수금용',
  '관공서제출용',
  '대출용',
  '여권또는비자신청용',
  '국민건강보험공단제출용',
  '금융기관제출용',
  '신용카드발급용',
  '기타',
];
export const cerpUsePupClCd: [string, ...string[]] = ['기타', '대금수령'];

export const cvaDcumSbmsOrgnClCd: [string, ...string[]] = [
  '금융기관',
  '관공서',
  '조합/협회',
  '거래처',
  '학교',
  '외교부/재외공관',
  '기타',
];
export const organizationCode: [string, ...string[]] = [
  '금융기관',
  '관공서',
  '조합/협회',
  '거래처',
  '학교',
  '기타',
];

export const purposeCode: [string, ...string[]] = ['제출용', '기타'];
export const termCode: [string, ...string[]] = ['1기', '2기'];

export const monthCode: [string, ...string[]] = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

export const taxCode: [string, ...string[]] = [
  'all',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '59',
  '60',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '70',
  '71',
  '72',
  '73',
  '74',
  '75',
  '76',
  '77',
  '78',
  '79',
  '80',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '90',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '99',
];

type termCodeValue = '1기' | '2기';
type monthCodeValue =
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '09'
  | '10'
  | '11'
  | '12';

type cvaDcumUseUsgCdValue =
  | '입찰, 계약용'
  | '수금용'
  | '관공서제출용'
  | '대출용'
  | '여권또는비자신청용'
  | '국민건강보험공단제출용'
  | '금융기관제출용'
  | '신용카드발급용'
  | '기타';

type cvaDcumSbmsOrgnClCdValue =
  | '금융기관'
  | '관공서'
  | '조합/협회'
  | '거래처'
  | '학교'
  | '외교부/재외공관'
  | '기타';

type organizationCodeValue =
  | '금융기관'
  | '관공서'
  | '조합/협회'
  | '거래처'
  | '학교'
  | '기타';

type purposeCodeValue = '제출용' | '기타';

type taxCodeValue =
  | '전체'
  | '종합소득세'
  | '이자소득세'
  | '배당소득세'
  | '사업소득세'
  | '근로소득세(갑)'
  | '근로소득세(을)'
  | '기타소득세'
  | '연금소득세'
  | '기부장려금'
  | '근로장려금'
  | '자녀장려금'
  | '퇴직소득세'
  | '양도소득세'
  | '산림소득세'
  | '법인세'
  | '상속세'
  | '증여세'
  | '자산재평가세'
  | '부당이득세'
  | '토지초과이득세'
  | '부가가치세'
  | '특별소비세'
  | '주세'
  | '전화세'
  | '증권거래세'
  | '인지세'
  | '개별소비세'
  | '방위세'
  | '방위세(과년도)'
  | '교통세'
  | '교통세(과년도)'
  | '농특세'
  | '농특세(과년도)'
  | '종합부동산세'
  | '교통·에너지·환경세'
  | '교육세'
  | '교육세(이자분)'
  | '교육세(배당분)'
  | '교육세(주세분)'
  | '교육세(교통세)'
  | '교육세(금융)'
  | '교육세(보험)'
  | '교육세(특별소비세분)'
  | '기타교육세'
  | '토지대여료'
  | '토지대여료(과년도)'
  | '건물대여료'
  | '건물대여료(과년도)'
  | '기타재산수입'
  | '기타재산수입(과년도)'
  | '과태료'
  | '벌금'
  | '몰수금'
  | '변상금'
  | '위약금'
  | '가산금'
  | '가산금(과년도)'
  | '기타경상이전수입'
  | '징계부가금'
  | '관유물대여료'
  | '관유물대여료(과년도)'
  | '기타잡수입'
  | '수입대체경비'
  | '건물매각대'
  | '과징금'
  | '기타고정자산매각대'
  | '기타고정자산매각대(과년도)'
  | '토지매각대'
  | '토지매각대(과년도)'
  | '임야매각대'
  | '임야매각대(과년도)'
  | '기타토지및무형자산매각대'
  | '기타토지및무형자산매각대(과년도)'
  | '기타';
type cerpUsePupClCdValue = '기타' | '대금수령';
export const cvaDcumUseUsgCdOptions: {
  value: cvaDcumUseUsgCdValue;
  label: string;
}[] = [
  { value: '입찰, 계약용', label: '입찰, 계약용' },
  { value: '수금용', label: '수금용' },
  { value: '관공서제출용', label: '관공서제출용' },
  { value: '대출용', label: '대출용' },
  { value: '여권또는비자신청용', label: '여권또는비자신청용' },
  { value: '국민건강보험공단제출용', label: '국민건강보험공단제출용' },
  { value: '금융기관제출용', label: '금융기관제출용' },
  { value: '신용카드발급용', label: '신용카드발급용' },
  { value: '기타', label: '기타' },
];

export const cvaDcumSbmsOrgnClCdOptions: {
  value: cvaDcumSbmsOrgnClCdValue;
  label: string;
}[] = [
  { value: '금융기관', label: '금융기관' },
  { value: '관공서', label: '관공서' },
  { value: '조합/협회', label: '조합/협회' },
  { value: '거래처', label: '거래처' },
  { value: '학교', label: '학교' },
  { value: '외교부/재외공관', label: '외교부/재외공관' },
  { value: '기타', label: '기타' },
];

export const organizationCodeOptions: {
  value: organizationCodeValue;
  label: string;
}[] = [
  { value: '금융기관', label: '금융기관' },
  { value: '관공서', label: '관공서' },
  { value: '조합/협회', label: '조합/협회' },
  { value: '거래처', label: '거래처' },
  { value: '학교', label: '학교' },
  { value: '기타', label: '기타' },
];

export const purposeCodeOptions: {
  value: purposeCodeValue;
  label: string;
}[] = [
  { value: '제출용', label: '제출용' },
  { value: '기타', label: '기타' },
];

export const taxCodeOptions: {
  value: string;
  label: taxCodeValue;
}[] = [
  { value: 'all', label: '전체' },
  { value: '10', label: '종합소득세' },
  { value: '11', label: '이자소득세' },
  { value: '12', label: '배당소득세' },
  { value: '13', label: '사업소득세' },
  { value: '14', label: '근로소득세(갑)' },
  { value: '15', label: '근로소득세(을)' },
  { value: '16', label: '기타소득세' },
  { value: '17', label: '연금소득세' },
  { value: '18', label: '기부장려금' },
  { value: '19', label: '근로장려금' },
  { value: '20', label: '자녀장려금' },
  { value: '21', label: '퇴직소득세' },
  { value: '22', label: '양도소득세' },
  { value: '23', label: '산림소득세' },
  { value: '31', label: '법인세' },
  { value: '32', label: '상속세' },
  { value: '33', label: '증여세' },
  { value: '34', label: '자산재평가세' },
  { value: '35', label: '부당이득세' },
  { value: '36', label: '토지초과이득세' },
  { value: '41', label: '부가가치세' },
  { value: '42', label: '특별소비세' },
  { value: '43', label: '주세' },
  { value: '44', label: '전화세' },
  { value: '45', label: '증권거래세' },
  { value: '46', label: '인지세' },
  { value: '47', label: '개별소비세' },
  { value: '51', label: '방위세' },
  { value: '52', label: '방위세(과년도)' },
  { value: '53', label: '교통세' },
  { value: '54', label: '교통세(과년도)' },
  { value: '55', label: '농특세' },
  { value: '56', label: '농특세(과년도)' },
  { value: '57', label: '종합부동산세' },
  { value: '59', label: '교통·에너지·환경세' },
  { value: '60', label: '교육세' },
  { value: '61', label: '교육세(이자분)' },
  { value: '62', label: '교육세(배당분)' },
  { value: '63', label: '교육세(주세분)' },
  { value: '64', label: '교육세(교통세)' },
  { value: '65', label: '교육세(금융)' },
  { value: '66', label: '교육세(보험)' },
  { value: '67', label: '교육세(특별소비세분)' },
  { value: '68', label: '기타교육세' },
  { value: '70', label: '토지대여료' },
  { value: '71', label: '토지대여료(과년도)' },
  { value: '72', label: '건물대여료' },
  { value: '73', label: '건물대여료(과년도)' },
  { value: '74', label: '기타재산수입' },
  { value: '75', label: '기타재산수입(과년도)' },
  { value: '76', label: '과태료' },
  { value: '77', label: '벌금' },
  { value: '78', label: '몰수금' },
  { value: '79', label: '변상금' },
  { value: '80', label: '위약금' },
  { value: '81', label: '가산금' },
  { value: '82', label: '가산금(과년도)' },
  { value: '83', label: '기타경상이전수입' },
  { value: '84', label: '징계부가금' },
  { value: '85', label: '관유물대여료' },
  { value: '86', label: '관유물대여료(과년도)' },
  { value: '87', label: '기타잡수입' },
  { value: '88', label: '수입대체경비' },
  { value: '89', label: '건물매각대' },
  { value: '90', label: '과징금' },
  { value: '91', label: '기타고정자산매각대' },
  { value: '92', label: '기타고정자산매각대(과년도)' },
  { value: '93', label: '토지매각대' },
  { value: '94', label: '토지매각대(과년도)' },
  { value: '95', label: '임야매각대' },
  { value: '96', label: '임야매각대(과년도)' },
  { value: '97', label: '기타토지및무형자산매각대' },
  { value: '98', label: '기타토지및무형자산매각대(과년도)' },
  { value: '99', label: '기타' },
];

export const cerpUsePupClCdOptions: {
  value: cerpUsePupClCdValue;
  label: string;
}[] = [
  { value: '대금수령', label: '대금수령' },
  { value: '기타', label: '기타' },
];

export const termCodeOptions: {
  value: termCodeValue;
  label: string;
}[] = [
  { value: '1기', label: '1기' },
  { value: '2기', label: '2기' },
];

export enum TAX {
  부가세 = '부가세',
  법인세 = '법인세',
}

export const monthCodeOptions: {
  value: monthCodeValue;
  label: string;
}[] = [
  { value: '01', label: '1월' },
  { value: '02', label: '2월' },
  { value: '03', label: '3월' },
  { value: '04', label: '4월' },
  { value: '05', label: '5월' },
  { value: '06', label: '6월' },
  { value: '07', label: '7월' },
  { value: '08', label: '8월' },
  { value: '09', label: '9월' },
  { value: '10', label: '10월' },
  { value: '11', label: '11월' },
  { value: '12', label: '12월' },
];

export const MENU_LIST = [
  {
    imgSrc: reportIcon1,
    title: '리포트',
    url: '/reports',
    subMenu: [
      {
        title: '월간 보고',
        url: 'monthly',
      },
      {
        title: '일간 보고',
        url: 'daily',
      },
      {
        title: '연간 보고',
        url: 'yearly',
      },
      {
        title: '입금 예정',
        url: 'expected-payment',
      },
    ],
  },
  {
    imgSrc: taxInvoiceIcon,
    title: '세금계산서',
    url: URLS.TAX_INVOICE,
    subMenu: [
      {
        title: '세금계산서(영세율포함)',
        url: 'regular',
      },
      {
        title: '계산서(면세)',
        url: 'exempt',
      },
    ],
  },
  {
    imgSrc: receiptIcon,
    title: '증명서 발급',
    url: '/certificates',
    subMenu: [
      {
        title: '증명서 발급 신청',
        url: 'request',
      },
      {
        title: '증명서 발급 내역',
        url: 'issued',
      },
    ],
  },

  {
    imgSrc: taxIcon1,
    title: '세금 관리',
    url: '/tax',
    subMenu: [
      {
        title: '업무 처리 현황',
        url: 'task',
      },
      {
        title: '세금 납부 현황',
        url: 'tax_paid',
      },
      {
        title: '영수증 등록',
        url: 'receipt',
      },
    ],
  },
  {
    imgSrc: calculatorIcon,
    title: '예상 세금',
    url: URLS.EXPECTED_TAX,
    subMenu: [
      {
        title: '예상 부가세',
        url: TAX.부가세,
      },
      {
        title: '예상 법인세',
        url: TAX.법인세,
      },
    ],
  },
  {
    imgSrc: moneyIcon,
    title: '노무지원금',
    url: '/labor',
    subMenu: [
      {
        title: '노무지원금 신청',
        url: 'request',
      },
    ],
  },
  // {
  //   imgSrc: schoolIcon,
  //   title: '세무·노무 지식센터',
  //   url: '/qna',
  //   subMenu: [],
  // },
  {
    imgSrc: syncIcon,
    title: '데이터 연동',
    url: URLS.SYNC,
    subMenu: [],
  },
  {
    imgSrc: accountIcon,
    title: '마이 페이지',
    url: URLS.MY_PAGE,
    subMenu: [
      {
        title: '사업체 정보',
        url: MANAGER_STATUS.BUSINESS,
      },
      {
        title: '담당자 관리',
        url: MANAGER_STATUS.MANAGER,
      },
    ],
  },
  {
    imgSrc: optionIcon,
    title: '기타',
    url: '/terms',
    subMenu: [
      {
        title: '이용약관',
        url: 'service',
      },
      {
        title: '개인정보 처리방침',
        url: 'personal',
      },
    ],
  },
];

export const COLORS = {
  primary: '#3182F6',
  blue: '#0046FF',
  blueContainer: '#1861D7',
  blueBg: '#C6DBF7',
  point: '#89B3EF',
  bluegray1: '#C7D9F4',
  mint: '#63E6BE',
  green: '#00BA88',
  yellow: '#EBDA44',
  orange: '#FFA318',
  brown: '#873E23',
  red: '#F96464',
  redStrong: '#EF1B1D',
  purple: '#8983F1',
  shape: '#EFF3F9',
  main: '#31343A',
  gray6: '#505359',
  gray5: '#616161',
  gray4: '#949494',
  gray3: '#BDBDBD',
  gray2: '#E5E5E5',
  gray1: '#F5F5F5',
  gray0: '#FBFBFB',
  white: '#FFFFFF',
  bg: '#F7FCFF',
  black: '#000000',
};

export const FILE_ICONS = {
  xlsx: excelIcon,
  xls: excelIcon,
  hwp: hwpIcon,
  hwpx: hwpIcon,
  jpg: jpgIcon,
  pdf: pdfIcon,
  ppt: pptIcon,
  docx: wordIcon,
  jpeg: jpgIcon,
  png: jpgIcon,
};

export const iconSVGMap: IIconSVGMap = {
  send: SendIcon,
  close: CloseIcon,
  delete: DeleteIcon,
  report: ReportIcon,
  receipt: ReceiptIcon,
  money: MoneyIcon,
  tax: TaxIcon,
  calculator: CalculatorIcon,
  school: SchoolIcon,
  sync: SyncIcon,
  account: AccountIcon,
  etc: EtcIcon,
  print: PrintIcon,
  contract: ContractIcon,
  userList: UserListIcon,
  checkList: CheckListIcon,
  building: BuildingIcon,
  reply: ReplyIcon,
  error: ErrorIcon,
  lightbulb: LightBulbIcon,
  taxInvoice: TaxInvoiceIcon,
  check: CheckIcon,
};

export enum EXPECTED_PAYMENT_MENU {
  CARD = 'CARD',
  COMMERCE = 'COMMERCE',
}

export const EXPECTED_PAYMENT_MENU_LIST = [
  { id: EXPECTED_PAYMENT_MENU.CARD, text: '카드', url: 'card' },
  {
    id: EXPECTED_PAYMENT_MENU.COMMERCE,
    text: '커머스 / 배달앱 등',
    url: 'commerce',
  },
];

export const EXPECTED_TAX_MENU_LIST = [
  { id: TAX.부가세, text: '예상 부가세' },
  { id: TAX.법인세, text: '예상 법인세' },
];

export const MY_PAGE_MENU_LIST = [
  { id: MANAGER_STATUS.USER, text: '회원정보' },
  { id: MANAGER_STATUS.MANAGER, text: '담당자 관리' },
  { id: MANAGER_STATUS.BUSINESS, text: '사업체 정보' },
];

export enum TERMS_MENU {
  SERVICE = 'service',
  PERSONAL = 'personal',
}

export const TERMS_MENU_LIST = [
  { id: TERMS_MENU.SERVICE, text: '이용약관' },
  { id: TERMS_MENU.PERSONAL, text: '개인정보 처리방침' },
];

export const clientKeywordSearchFilterOption: {
  label: string;
  value: string;
}[] = [
  {
    label: '대표자명',
    value: '대표자명',
  },
  {
    label: '대표자이메일',
    value: '대표자이메일',
  },
  {
    label: '사업자등록번호',
    value: '사업자등록번호',
  },
  {
    label: '사업자명',
    value: '사업자명',
  },
];

export const customerKeywordSearchFilterOption: {
  label: string;
  value: string;
}[] = [
  {
    label: '거래처등록번호',
    value: '거래처등록번호',
  },
  {
    label: '거래처명',
    value: '거래처명',
  },
  {
    label: '대표자명',
    value: '대표자명',
  },
];

export const certificateIssuedKeywordSearchFilterOption: {
  label: string;
  value: string;
}[] = [
  {
    label: '대표자명',
    value: '대표자명',
  },
  {
    label: '사업자등록번호',
    value: '사업자등록번호',
  },
  {
    label: '사업자명',
    value: '사업자명',
  },
];

export const laborKeywordSearchFilterOption: {
  label: string;
  value: string;
}[] = [
  {
    label: '사업자명',
    value: '사업자명',
  },
  {
    label: '사업자등록번호',
    value: '사업자등록번호',
  },
  {
    label: '담당자 성함',
    value: '담당자 성함',
  },
  {
    label: '담당자 연락처',
    value: '담당자 연락처',
  },
];

export enum RECEIPT_STATUS_MENU {
  HOLD = '대기',
  DENIED = '반려',
  COMPLETE = '완료',
}

export const receiptKeywordSearchFilterOption: {
  label: string;
  value: string;
}[] = [
  {
    label: '사업자명',
    value: '사업자명',
  },
  {
    label: '사업자등록번호',
    value: '사업자등록번호',
  },
];

export enum RECEIPT_PURPOSE_MENU {
  '세금계산서' = '세금계산서',
  '계산서' = '계산서',
  '전자세금계산서' = '전자세금계산서',
  '전자계산서' = '전자계산서',
  '영수증' = '영수증',
}

export const branchAdminKeywordSearchFilterOption: {
  label: string;
  value: string;
}[] = [
  {
    label: '담당자',
    value: '담당자',
  },
];

export enum EmailVerificationStatus {
  INITIAL = '인증번호 요청',
  REQUESTED = '인증번호 재요청',
  VERIFIED = '인증됨',
}

export const etcSalesAddOption: {
  label: string;
  value: 'TAXABLE' | 'EXEMPT';
}[] = [
  {
    label: '과세',
    value: 'TAXABLE',
  },
  {
    label: '면세',
    value: 'EXEMPT',
  },
];

export const etcSalesAddValue: [string, ...string[]] = ['TAXABLE', 'EXEMPT'];

export enum TaxTypes {
  TAXABLE = '과세',
  EXEMPT = '면세',
}

export const taxInvoiceTypeCode: [string, ...string[]] = [
  'csh',
  'chck',
  'note',
  'crit',
];

export type taxInvoiceTypeValue = 'csh' | 'chck' | 'note' | 'crit';

export const taxInvoiceTypeOptions: {
  value: taxInvoiceTypeValue;
  label: string;
}[] = [
  { value: 'csh', label: '현금' },
  { value: 'chck', label: '수표' },
  { value: 'note', label: '어음' },
  { value: 'crit', label: '외상미수금' },
];

export const etxivKndCdOptions = [
  { label: '일반', value: '01' },
  { label: '영세율', value: '02' },
];
export const etxivKndCdExemptOptions = [{ label: '일반', value: '01' }];

export const etxivClCdOptions = [
  { label: '전자세금계산서', value: '01' },
  { label: '전자계산서', value: '03' },
];

export const prhSlsClCdOptions = [
  { label: '매출', value: '01' },
  { label: '매입', value: '02' },
];
