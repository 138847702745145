import React, { useEffect, useState } from 'react';
import { REPORT_TERM, REPORT_TERM_MENU_LIST } from '@src/util/constants';
import Header from '@src/components/Header.tsx';
import HeaderTabs from '@src/components/HeaderTabs.tsx';
import Footer from '@src/components/Footer.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import SideBar from '@src/components/SideBar.tsx';
import Container from '@src/layouts/Container.tsx';
import ExpectedPaymentContent from '@src/pages/reports/ExpectedPaymentContent.tsx';
import { refreshToken } from '@src/util/api.ts';

interface ExpectedPaymentProps {}

const ExpectedPayment: React.FC<ExpectedPaymentProps> = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    refreshToken();
  }, []);

  return (
    <Container
      header={<Header setOpen={setOpen} />}
      headerTab={
        <HeaderTabs
          menus={REPORT_TERM_MENU_LIST}
          activeHeaderTab={REPORT_TERM.EXPECTED_PAYMENT}
        />
      }
      footer={<Footer current={'reports'} />}
      modal={<CompanyDrawer open={open} setOpen={setOpen} />}
      sideBar={<SideBar />}
    >
      <ExpectedPaymentContent />
    </Container>
  );
};

export default ExpectedPayment;
