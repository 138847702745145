import React from 'react';
import Container from '@src/layouts/Container.tsx';
import AdminSideBar from '@src/components/AdminSideBar.tsx';
import BranchAdminContent from '@src/pages/admin/branch/BranchAdminContent.tsx';

interface BranchAdminProps {}

const BranchAdmin: React.FC<BranchAdminProps> = () => {
  return (
    <Container sideBar={<AdminSideBar />}>
      <BranchAdminContent />
    </Container>
  );
};

export default BranchAdmin;
