import React from 'react';
import Container from '@src/layouts/Container.tsx';
import AdminSideBar from '@src/components/AdminSideBar.tsx';
import ReceiptAdminContent from '@src/pages/admin/receipt/ReceiptAdminContent.tsx';

interface ReceiptAdminProps {}

const ReceiptAdmin: React.FC<ReceiptAdminProps> = () => {
  return (
    <Container sideBar={<AdminSideBar />}>
      <ReceiptAdminContent />
    </Container>
  );
};

export default ReceiptAdmin;
