import Table from '@src/components/Table.tsx';
import {
  IApiError,
  IApiPOSTSuccess,
  IBranchAdmin,
  IBranchStaff,
} from '@src/util/interfaces.ts';
import Select, { OptionValue } from '@src/components/Select.tsx';
import {
  companyRegisterNumberRegex,
  numericRegex,
  passwordRegex,
  phoneRegex,
} from '@src/util/functions.ts';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  API_URLS_ADMIN,
  branchAdminKeywordSearchFilterOption,
  COLORS,
} from '@src/util/constants.ts';
import Input from '@src/components/Input.tsx';
import Icon from '@src/components/Icon.tsx';
import searchIcon from '@src/assets/icons/search_black.svg';
import Button from '@src/components/Button.tsx';
import CustomModal from '@src/components/CustomModal.tsx';
import React, { useState } from 'react';
import IconSVG from '@src/components/IconSVG.tsx';
import InputLabel from '@src/components/InputLabel.tsx';
import TailSpinner from '@src/components/TailSpinner.tsx';
import Modal from '@src/components/Modal.tsx';
import { useAdminStore } from '@src/util/adminStore.ts';
import { useBranchAdmin, useBranchStaff } from '@src/util/adminHooks.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiAdmin from '@src/util/apiAdmin.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { z } from 'zod';
import CloseIcon from '@src/components/CloseIcon.tsx';
import BranchFilterSelect from '@src/components/BranchFilterSelect.tsx';

const KeywordSearchForm = z.object({
  keyword: z.string(),
  keywordSearchFilter: z.object({
    label: z.string(),
    value: z.string(),
  }),
});

const RegisterAdminBranchBase = z.object({
  admin_id: z
    .string()
    .trim()
    .min(1, { message: '이메일을 입력해 주세요.' })
    .email({ message: '이메일 형태가 아닙니다.' }),
  password: z.string().regex(passwordRegex, '비밀번호가 유효하지 않습니다.'),
  password_confirmation: z.string(),
  branch: z.string().trim().min(1, { message: '지점을 입력해 주세요.' }),
  resident_number_1: z.string().regex(numericRegex).min(3).max(3),
  resident_number_2: z.string().regex(numericRegex).min(2).max(2),
  resident_number_3: z.string().regex(numericRegex).min(3).max(5),
  name: z.string().trim().min(1, { message: '담당자 성명을 입력해 주세요.' }),
  phone_number: z.string().regex(phoneRegex, '전화번호가 유효하지 않습니다.'),
});

const RegisterAdminBranchForm = RegisterAdminBranchBase.refine(
  (data) => data.password === data.password_confirmation,
  {
    message: '비밀번호가 일치하지 않습니다.',
    path: ['password_confirmation'],
  },
);

const RegisterAdminBranchFormServer = RegisterAdminBranchBase.omit({
  password_confirmation: true,
  resident_number_1: true,
  resident_number_2: true,
  resident_number_3: true,
}).extend({
  resident_number: z.string(),
});

const BranchDetailAdminForm = z.object({
  id: z.string(),
  admin_id: z.string().trim().min(1),
  name: z.string().trim().min(1, '담당자를 입력해 주세요.'),
  resident_number_1: z.string().length(3),
  resident_number_2: z.string().length(2),
  resident_number_3: z.string().length(5),
  phone_number: z.string().regex(phoneRegex, '전화번호가 유효하지 않습니다.'),
  branch: z.string().trim().min(1, '지점을 입력해 주세요.'),
});

const BranchStaffAddForm = z.object({
  name: z.string().trim().min(1, '담당자를 입력해 주세요.'),
});

const BranchStaffAddFormServer = BranchStaffAddForm.extend({
  admin_id: z.number(),
});

const BranchDetailAdminFormServer = BranchDetailAdminForm.omit({
  resident_number_1: true,
  resident_number_2: true,
  resident_number_3: true,
  admin_id: true,
}).extend({
  resident_number: z
    .string()
    .regex(companyRegisterNumberRegex, '사업자 등록번호가 유효하지 않습니다.'),
});
type TBranchDetailAdminForm = z.infer<typeof BranchDetailAdminForm>;
type TBranchDetailAdminFormServer = z.infer<typeof BranchDetailAdminFormServer>;

type TRegisterAdminBranchForm = z.infer<typeof RegisterAdminBranchForm>;
type TRegisterAdminBranchFormServer = z.infer<
  typeof RegisterAdminBranchFormServer
>;

type TKeywordSearchForm = z.infer<typeof KeywordSearchForm>;
type TBranchStaffAddForm = z.infer<typeof BranchStaffAddForm>;
type TBranchStaffAddFormServer = z.infer<typeof BranchStaffAddFormServer>;

const BranchAdminContent = () => {
  const [admin] = useAdminStore((state) => [state.admin]);

  const [branchFilter, setBranchFilter] = useState<OptionValue | null>({
    label: '전체',
    value: '전체',
  });

  const [keyword, setKeyword] = useState<string>('');

  const [keywordSearchCategory, setKeywordSearchCategory] =
    useState<'name'>('name');
  const [addAdminBranchModalOpen, setAddAdminBranchModalOpen] =
    useState<boolean>(false);
  const [updateAdminBranchModalOpen, setUpdateAdminBranchModalOpen] =
    useState<boolean>(false);
  const [manageStaffModalOpen, setManageStaffModalOpen] =
    useState<boolean>(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalContent, setConfirmModalContent] = useState({
    title: '',
    content: '',
  });

  const { data, fetchNextPage, hasNextPage } = useBranchAdmin(
    branchFilter,
    keyword,
    keywordSearchCategory,
  );
  const flattenedData = data?.pages.flatMap((page) => page.data);

  const [selectedBranchId, setSelectedBranchId] = useState<number | null>(null);
  const { data: branchStaffData } = useBranchStaff(selectedBranchId);

  const queryClient = useQueryClient();

  const { mutate, isPending: isLoadingAdmin } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TRegisterAdminBranchFormServer
  >({
    mutationFn: (formData) => {
      return apiAdmin.post(API_URLS_ADMIN.BRANCH_ADMIN, formData);
    },
  });

  const {
    register: registerBranchStaffAdd,
    handleSubmit: handleBranchStaffAddSubmit,
    reset: resetBranchStaffAdd,
  } = useForm<TBranchStaffAddForm>({
    resolver: zodResolver(BranchStaffAddForm),
  });

  const { mutate: addStaff, isPending: addStaffIsPending } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TBranchStaffAddFormServer
  >({
    mutationFn: (formData) => {
      return apiAdmin.post(API_URLS_ADMIN.BRANCH_STAFF, formData);
    },
  });

  const { mutate: updateAdminMutate, isPending: updateAdminMutateIsPending } =
    useMutation<IApiPOSTSuccess, IApiError, TBranchDetailAdminFormServer>({
      mutationFn: (data) => {
        const { id, ...formData } = data;

        return apiAdmin.patch(
          API_URLS_ADMIN.MY_PAGE_DETAIL + `/${id}`,
          formData,
        );
      },
    });

  const { register, control, handleSubmit } = useForm<TKeywordSearchForm>({
    resolver: zodResolver(KeywordSearchForm),
    defaultValues: {
      keywordSearchFilter: { label: '담당자', value: '담당자' },
    },
  });

  const {
    register: registerAdmin,
    formState: { errors },
    handleSubmit: handleSubmitAdmin,
  } = useForm<TRegisterAdminBranchForm>({
    resolver: zodResolver(RegisterAdminBranchForm),
  });

  const {
    register: registerUpdateAdmin,
    handleSubmit: handleUpdateAdmin,
    reset: resetUpdateAdmin,
    formState: { errors: errorsUpdateAdmin },
  } = useForm<TBranchDetailAdminForm>({
    resolver: zodResolver(BranchDetailAdminForm),
    defaultValues: {
      admin_id: '',
      name: '',
      resident_number_1: '',
      resident_number_2: '',
      resident_number_3: '',
      phone_number: '',
      branch: '',
    },
  });

  const onSubmit: SubmitHandler<TKeywordSearchForm> = (data) => {
    const keywordSearchCategoryValue = data.keywordSearchFilter.value;
    if (keywordSearchCategoryValue === '담당자') {
      setKeywordSearchCategory('name');
    }
    setKeyword(data.keyword);
  };

  const onSubmitAdmin: SubmitHandler<TRegisterAdminBranchForm> = (data) => {
    const formData = {
      admin_id: data.admin_id,
      password: data.password,
      name: data.name,
      resident_number: `${data.resident_number_1}-${data.resident_number_2}-${data.resident_number_3}`,
      phone_number: data.phone_number,
      branch: data.branch,
    };

    mutate(formData, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['branch_admin'] });
        setAddAdminBranchModalOpen(false);
        setConfirmModalContent({
          title: '지점 추가',
          content: '지점이 추가되었습니다.',
        });
        setTimeout(() => {
          setConfirmModalOpen(true);
        }, 100);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const onSubmitUpdateAdmin: SubmitHandler<TBranchDetailAdminForm> = (data) => {
    const formData = {
      id: data.id,
      name: data.name,
      branch: data.branch,
      resident_number: `${data.resident_number_1}-${data.resident_number_2}-${data.resident_number_3}`,
      phone_number: data.phone_number,
    };

    updateAdminMutate(formData, {
      onSuccess: (response) => {
        console.log(response);
        queryClient.invalidateQueries({ queryKey: ['branch_admin'] });
        queryClient.invalidateQueries({ queryKey: ['branch'] });
        setUpdateAdminBranchModalOpen(false);
        setConfirmModalContent({
          title: '지점 상세',
          content: '지점 상세 정보가 변경되었습니다.',
        });
        setTimeout(() => {
          setConfirmModalOpen(true);
        }, 100);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const onSubmitAddStaff: SubmitHandler<TBranchStaffAddForm> = (formData) => {
    if (selectedBranchId !== null) {
      const formDataServer = {
        ...formData,
        admin_id: selectedBranchId,
      };

      addStaff(formDataServer, {
        onSuccess: (res) => {
          console.log(res);
          resetBranchStaffAdd();
          queryClient.invalidateQueries({
            queryKey: ['branch_staff', selectedBranchId],
          });
        },
        onError: (err) => {
          console.log(err);
        },
      });
    }
  };

  const columnHelper = createColumnHelper<IBranchAdmin>();

  const columns: Array<ColumnDef<IBranchAdmin, string>> = [
    columnHelper.accessor('branch', {
      id: 'branch',
      cell: (info) => {
        return <i>{info.getValue()}</i>;
      },
      header: () => <div style={{ minWidth: '120px' }}>지점</div>,
    }),
    columnHelper.accessor('resident_number', {
      id: 'resident_number',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>사업자등록번호</div>,
    }),
    columnHelper.accessor('admin_id', {
      id: 'admin_id',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>ID</div>,
    }),
    columnHelper.accessor('name', {
      id: 'name',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '96px' }}>담당자</div>,
    }),
    columnHelper.accessor('phone_number', {
      id: 'phone_number',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '104px' }}>연락처</div>,
    }),
    columnHelper.display({
      id: 'detail',
      cell: (props) => {
        return (
          <div className={'flex-row-center'}>
            <Button
              text={'담당자 관리'}
              style={{
                fontSize: '12px',
                lineHeight: '16px',
                fontWeight: '600',
                width: '92px',
                padding: '4px 10px',
                border: '1px solid #3182F6',
                borderRadius: '4px',
              }}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedBranchId(props.row.original.id);
                setManageStaffModalOpen(true);
              }}
            />
          </div>
        );
      },
      header: () => <div style={{ minWidth: '92px' }}>관리</div>,
    }),
  ];

  const columnHelperBranchStaff = createColumnHelper<IBranchStaff>();
  const branchStaffColumns: Array<ColumnDef<IBranchStaff, string>> = [
    columnHelperBranchStaff.accessor('name', {
      id: 'name',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '30px' }}>이름</div>,
    }),
    columnHelperBranchStaff.display({
      id: 'delete',
      cell: (props) => {
        return (
          <div className={'flex-row-center'}>
            <IconSVG
              fill={COLORS['red']}
              size={'24'}
              iconSVG={'delete'}
              containerStyle={{ cursor: 'pointer' }}
              onClick={() => {
                apiAdmin
                  .delete(
                    API_URLS_ADMIN.BRANCH_STAFF + `/${props.row.original.id}`,
                  )
                  .then((res) => {
                    console.log(res);
                    queryClient.invalidateQueries({
                      queryKey: ['branch_staff', selectedBranchId],
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
          </div>
        );
      },
      header: () => <div style={{ minWidth: '30px' }}>삭제</div>,
    }),
  ];

  return (
    <>
      <div
        className={'flex-column-flex-start-stretch'}
        style={{ gap: '24px', margin: '0 48px 48px 48px' }}
      >
        <p className={'text-md'}>
          {admin?.branch === '본점' ? '지점/담당자 리스트' : '담당자 관리'}
        </p>

        <Table<IBranchAdmin>
          data={flattenedData}
          columns={columns}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          onRowClickCustom={(row) => {
            const updateItem = flattenedData.find(
              (item) => item.id === row.original.id,
            );
            if (updateItem) {
              const splitResidentNumber = updateItem.resident_number.split('-');
              const updateAdminData: TBranchDetailAdminForm = {
                id: row.original.id.toString(),
                admin_id: updateItem.admin_id,
                name: updateItem.name,
                resident_number_1: splitResidentNumber[0],
                resident_number_2: splitResidentNumber[1],
                resident_number_3: splitResidentNumber[2],
                phone_number: updateItem.phone_number,
                branch: updateItem.branch,
              };
              resetUpdateAdmin(updateAdminData);
            }
            setUpdateAdminBranchModalOpen(true);
          }}
          filterContent={
            <div className={'flex-row-space-between-center'}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={'table-filter-container'}>
                  {admin?.branch === '본점' && (
                    <BranchFilterSelect
                      branchFilter={branchFilter}
                      setBranchFilter={setBranchFilter}
                    />
                  )}

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className={'table-filter-keyword-search-container'}>
                      <div style={{ width: '128px' }}>
                        <Controller
                          name="keywordSearchFilter"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                name="keywordSearchFilter"
                                options={branchAdminKeywordSearchFilterOption}
                                onChange={onChange}
                                value={value}
                                isFilterSearch={true}
                              />
                            );
                          }}
                        />
                      </div>

                      <Input
                        placeholder={'검색어 입력'}
                        register={register('keyword')}
                        style={{
                          height: 'auto',
                          borderRadius: '0',
                          borderLeft: 'none',
                          width: '414px',
                        }}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            event.preventDefault(); // Prevent the default behavior of the enter key
                            handleSubmit(onSubmit)(); // Execute the submit handler
                          }
                        }}
                      />
                      <Icon
                        iconSrc={searchIcon}
                        style={{
                          padding: '10px',
                          border: `1px solid ${COLORS['gray2']}`,
                          borderLeft: 'none',
                          borderBottomRightRadius: '4px',
                          borderTopRightRadius: '4px',
                          cursor: 'pointer',
                        }}
                        onClick={handleSubmit(onSubmit)}
                      />
                    </div>
                  </div>
                </div>
              </form>
              {admin?.branch === '본점' && (
                <Button
                  text={'+ 지점추가'}
                  style={{
                    marginLeft: '10px',
                    padding: '0 10px 0 10px',
                    alignSelf: 'flex-end',
                    width: '100px',
                    height: '46px',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => {
                    setAddAdminBranchModalOpen(true);
                  }}
                />
              )}
            </div>
          }
        />
      </div>
      <>
        <CustomModal
          isOpen={manageStaffModalOpen}
          onClose={() => {
            setManageStaffModalOpen(false);
          }}
          children={
            <div className={'modal-custom-wrapper'}>
              <div className="modal-custom-content-wrapper">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    marginBottom: '16px',
                  }}
                >
                  <h2 className={'modal-custom-title'}>담당자 관리</h2>
                  <CloseIcon
                    onClick={() => {
                      setManageStaffModalOpen(false);
                    }}
                  />
                </div>
                <div
                  className="modal-custom-content"
                  style={{
                    display: 'flex',
                    alignSelf: 'stretch',
                    gap: '40px',
                  }}
                >
                  <div
                    className={'flex-column-flex-start-flex-start'}
                    style={{ gap: '12px' }}
                  >
                    <div className={'flex-row'}>
                      <p className={'text-base'}>담당자 목록</p>
                    </div>
                    <Table<IBranchStaff>
                      data={branchStaffData ?? []}
                      columns={branchStaffColumns}
                    />
                  </div>
                  <div
                    className={'flex-column-flex-start-flex-start'}
                    style={{ gap: '12px' }}
                  >
                    <div className={'flex-row'}>
                      <p className={'text-base'}>담당자 추가</p>
                    </div>
                    <form
                      className={'flex-row-space-between-center'}
                      style={{
                        gap: '12px',
                        width: '100%',
                      }}
                      onSubmit={handleBranchStaffAddSubmit(onSubmitAddStaff)}
                    >
                      <div
                        className={'flex-row'}
                        style={{
                          width: '100%',
                          gap: '12px',
                        }}
                      >
                        <p style={{ whiteSpace: 'nowrap' }}>이름</p>
                        <Input
                          register={registerBranchStaffAdd('name')}
                          style={{ flex: 1 }}
                        />
                      </div>
                      <Button
                        text={'저장'}
                        type={'submit'}
                        style={{ maxWidth: '100px' }}
                        isLoading={addStaffIsPending}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          }
        />
        <CustomModal
          isOpen={updateAdminBranchModalOpen}
          onClose={() => {
            setUpdateAdminBranchModalOpen(false);
          }}
          children={
            <div className={'modal-custom-wrapper'}>
              <div className="modal-custom-content-wrapper">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    marginBottom: '16px',
                  }}
                >
                  <h2 className={'modal-custom-title'}>지점 상세</h2>
                  <CloseIcon
                    onClick={() => {
                      setUpdateAdminBranchModalOpen(false);
                    }}
                  />
                </div>
                <div
                  className="modal-custom-content"
                  style={{
                    display: 'flex',
                    alignSelf: 'stretch',
                    gap: '10px',
                  }}
                >
                  <div className={'flex-row'} style={{ gap: '12px' }}>
                    <div style={{ width: '120px' }}>
                      <p
                        className={'text-regular-bold'}
                        style={{ textAlign: 'left', width: '120px' }}
                      >
                        지점
                      </p>
                    </div>
                    <Input
                      register={registerUpdateAdmin('branch')}
                      isLoading={false}
                      inputMode={'text'}
                      type={'text'}
                      style={{ width: '100%' }}
                    />
                  </div>
                  {errorsUpdateAdmin.branch && (
                    <p
                      className={'error-message'}
                      style={{ marginLeft: '104px' }}
                    >
                      {errorsUpdateAdmin.branch.message}
                    </p>
                  )}
                  <div className={'flex-row'} style={{ gap: '12px' }}>
                    <div style={{ width: '120px' }}>
                      <p
                        className={'text-regular-bold'}
                        style={{
                          textAlign: 'left',
                          width: '120px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        사업자등록번호
                      </p>
                    </div>

                    <div
                      className={'branch-detail-admin-info-container-input-row'}
                    >
                      <Input
                        register={registerUpdateAdmin('resident_number_1')}
                        isLoading={false}
                        inputMode={'numeric'}
                        type={'text'}
                        maxLength={3}
                      />
                      <p style={{ margin: '0 5px' }}>-</p>
                      <Input
                        register={registerUpdateAdmin('resident_number_2')}
                        isLoading={false}
                        inputMode={'numeric'}
                        type={'text'}
                        maxLength={2}
                      />
                      <p style={{ margin: '0 5px' }}>-</p>
                      <Input
                        register={registerUpdateAdmin('resident_number_2')}
                        isLoading={false}
                        inputMode={'numeric'}
                        type={'text'}
                        maxLength={5}
                      />
                    </div>
                  </div>
                  {(errorsUpdateAdmin.resident_number_1 ||
                    errorsUpdateAdmin.resident_number_2 ||
                    errorsUpdateAdmin.resident_number_3) && (
                    <p
                      className={'error-message'}
                      style={{ marginLeft: '104px' }}
                    >
                      사업자 번호를 입력해 주세요.
                    </p>
                  )}
                  <div className={'flex-row'} style={{ gap: '12px' }}>
                    <div style={{ width: '120px' }}>
                      <p
                        className={'text-regular-bold'}
                        style={{ textAlign: 'left', width: '120px' }}
                      >
                        ID
                      </p>
                    </div>
                    <Input
                      register={registerUpdateAdmin('admin_id')}
                      isLoading={false}
                      inputMode={'text'}
                      type={'text'}
                      style={{
                        width: '100%',
                        backgroundColor: COLORS['gray0'],
                      }}
                      disabled={true}
                    />
                  </div>

                  <div className={'flex-row'} style={{ gap: '12px' }}>
                    <div style={{ width: '120px' }}>
                      <p
                        className={'text-regular-bold'}
                        style={{ textAlign: 'left', width: '120px' }}
                      >
                        담당자
                      </p>
                    </div>
                    <Input
                      register={registerUpdateAdmin('name')}
                      isLoading={false}
                      inputMode={'text'}
                      type={'text'}
                      style={{ width: '100%' }}
                    />
                  </div>
                  {errorsUpdateAdmin.name && (
                    <p
                      className={'error-message'}
                      style={{ marginLeft: '104px' }}
                    >
                      {errorsUpdateAdmin.name.message}
                    </p>
                  )}
                  <div className={'flex-row'} style={{ gap: '12px' }}>
                    <div style={{ width: '120px' }}>
                      <p
                        className={'text-regular-bold'}
                        style={{ textAlign: 'left', width: '120px' }}
                      >
                        연락처
                      </p>
                    </div>
                    <Input
                      register={registerUpdateAdmin('phone_number')}
                      isLoading={false}
                      inputMode={'numeric'}
                      type={'text'}
                      style={{ width: '100%' }}
                    />
                  </div>
                  {errorsUpdateAdmin.phone_number && (
                    <p
                      className={'error-message'}
                      style={{ marginLeft: '104px' }}
                    >
                      {errorsUpdateAdmin.phone_number.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          }
          footer={
            updateAdminMutateIsPending ? (
              <div
                className={'flex-row-center'}
                style={{ alignSelf: 'stretch', padding: '10px' }}
              >
                <TailSpinner type={'button-primary-color'} />
              </div>
            ) : (
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'left-button'}
                    onClick={() => setUpdateAdminBranchModalOpen(false)}
                  >
                    취소
                  </button>
                  <button
                    className={'right-button'}
                    type={'submit'}
                    onClick={handleUpdateAdmin(onSubmitUpdateAdmin)}
                  >
                    저장
                  </button>
                </div>
              </React.Fragment>
            )
          }
        />
        <CustomModal
          isOpen={addAdminBranchModalOpen}
          onClose={() => {
            setAddAdminBranchModalOpen(false);
          }}
          children={
            <form onSubmit={handleSubmitAdmin(onSubmitAdmin)}>
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        marginBottom: '16px',
                      }}
                    >
                      <h2 className={'modal-custom-title'}>지점 추가</h2>
                      <IconSVG
                        fill={COLORS['main']}
                        size={'24'}
                        iconSVG={'close'}
                        containerStyle={{ cursor: 'pointer' }}
                        onClick={() => {
                          setAddAdminBranchModalOpen(false);
                        }}
                      />
                    </div>
                    <InputLabel required text={'ID'}>
                      <Input
                        register={registerAdmin('admin_id')}
                        isLoading={false}
                        inputMode={'text'}
                        type={'text'}
                        placeholder={'ID 입력'}
                        style={{ backgroundColor: COLORS['gray0'] }}
                      />
                      {errors.admin_id && (
                        <p
                          className={'error-message'}
                          style={{ marginTop: '0' }}
                        >
                          {errors.admin_id.message}
                        </p>
                      )}
                    </InputLabel>

                    <InputLabel
                      required
                      text={'초기 비밀번호'}
                      description={
                        '영문자(대,소문자), 숫자를 포함하여 최소 8자 이상 15자 이하로 작성 해야 합니다.'
                      }
                    >
                      <Input
                        register={registerAdmin('password')}
                        isLoading={false}
                        inputMode={'text'}
                        type={'password'}
                        placeholder={'비밀번호를 입력하세요.'}
                        style={{ backgroundColor: COLORS['gray0'] }}
                      />
                      <Input
                        register={registerAdmin('password_confirmation')}
                        isLoading={false}
                        inputMode={'text'}
                        type={'password'}
                        placeholder={'비밀번호를 입력하세요.'}
                        style={{ backgroundColor: COLORS['gray0'] }}
                      />
                      {errors.password && (
                        <p
                          className={'error-message'}
                          style={{ marginTop: '0' }}
                        >
                          {errors.password.message}
                        </p>
                      )}
                      {errors.password_confirmation && (
                        <p
                          className={'error-message'}
                          style={{ marginTop: '0' }}
                        >
                          {errors.password_confirmation.message}
                        </p>
                      )}
                    </InputLabel>
                    <InputLabel required text={'지점명'}>
                      <Input
                        register={registerAdmin('branch')}
                        isLoading={false}
                        inputMode={'text'}
                        type={'text'}
                        placeholder={'지점명을 입력하세요.'}
                        style={{ backgroundColor: COLORS['gray0'] }}
                      />
                      {errors.branch && (
                        <p
                          className={'error-message'}
                          style={{ marginTop: '0' }}
                        >
                          {errors.branch.message}
                        </p>
                      )}
                    </InputLabel>
                    <InputLabel required text={'사업자등록번호'}>
                      <div className={'branch-add-container-input-row'}>
                        <Input
                          register={registerAdmin('resident_number_1')}
                          isLoading={false}
                          inputMode={'numeric'}
                          type={'text'}
                          maxLength={3}
                          style={{ backgroundColor: COLORS['gray0'] }}
                        />
                        <p style={{ margin: '0 5px' }}>-</p>
                        <Input
                          register={registerAdmin('resident_number_2')}
                          isLoading={false}
                          inputMode={'numeric'}
                          type={'text'}
                          maxLength={2}
                          style={{ backgroundColor: COLORS['gray0'] }}
                        />
                        <p style={{ margin: '0 5px' }}>-</p>
                        <Input
                          register={registerAdmin('resident_number_3')}
                          isLoading={false}
                          inputMode={'numeric'}
                          type={'text'}
                          maxLength={5}
                          style={{ backgroundColor: COLORS['gray0'] }}
                        />
                      </div>
                      {(errors.resident_number_1 ||
                        errors.resident_number_2 ||
                        errors.resident_number_3) && (
                        <p
                          className={'error-message'}
                          style={{ marginTop: '0' }}
                        >
                          사업자 번호를 입력해 주세요.
                        </p>
                      )}
                    </InputLabel>
                    <InputLabel required text={'담당자 성명'}>
                      <Input
                        register={registerAdmin('name')}
                        isLoading={false}
                        inputMode={'text'}
                        type={'text'}
                        placeholder={'이름을 입력하세요.'}
                        style={{ backgroundColor: COLORS['gray0'] }}
                      />
                      {errors.name && (
                        <p
                          className={'error-message'}
                          style={{ marginTop: '0' }}
                        >
                          {errors.name.message}
                        </p>
                      )}
                    </InputLabel>
                    <InputLabel required text={'휴대폰번호'}>
                      <Input
                        register={registerAdmin('phone_number')}
                        isLoading={false}
                        inputMode={'text'}
                        type={'text'}
                        placeholder={'휴대폰번호를 입력하세요.'}
                        style={{ backgroundColor: COLORS['gray0'] }}
                      />
                      {errors.phone_number && (
                        <p
                          className={'error-message'}
                          style={{ marginTop: '0' }}
                        >
                          {errors.phone_number.message}
                        </p>
                      )}
                    </InputLabel>
                  </div>
                </div>
              </React.Fragment>
            </form>
          }
          footer={
            isLoadingAdmin ? (
              <div
                className={'flex-row-center'}
                style={{ alignSelf: 'stretch', padding: '10px' }}
              >
                <TailSpinner type={'button-primary-color'} />
              </div>
            ) : (
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'left-button'}
                    onClick={() => setAddAdminBranchModalOpen(false)}
                  >
                    취소
                  </button>
                  <button
                    className={'right-button'}
                    type={'submit'}
                    onClick={handleSubmitAdmin(onSubmitAdmin)}
                  >
                    추가하기
                  </button>
                </div>
              </React.Fragment>
            )
          }
        />
        <Modal
          isOpen={confirmModalOpen}
          onOk={() => {
            setConfirmModalOpen(false);
          }}
          title={confirmModalContent.title}
          content={confirmModalContent.content}
          okButton={'확인'}
          onClose={() => {
            setConfirmModalOpen(false);
          }}
        />
      </>
    </>
  );
};
export default BranchAdminContent;
