import React, { Dispatch, SetStateAction } from 'react';
import { Drawer } from 'vaul';
import { COLORS } from '@src/util/constants.ts';
import IconSVG from '@src/components/IconSVG.tsx';
import { useTaxInvoiceCertStore } from '@src/util/store.ts';
import moment from 'moment';
import CloseIcon from '@src/components/CloseIcon.tsx';

interface TaxInvoiceCertDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  onOk: () => void;
}

const TaxInvoiceCertDrawer: React.FC<TaxInvoiceCertDrawerProps> = ({
  open,
  setOpen,
  onCancel,
  onOk,
}) => {
  const { setCertReadChecked } = useTaxInvoiceCertStore((state) => ({
    setCertReadChecked: state.setCertReadChecked,
  }));

  return (
    <Drawer.Root open={open} onOpenChange={setOpen} shouldScaleBackground>
      <Drawer.Portal>
        <Drawer.Overlay className="custom-drawer-overlay" />
        <Drawer.Content
          data-vaul-no-drag
          className={'custom-drawer-content-container bottom'}
        >
          <button
            className={'dont-show-btn'}
            onClick={() => {
              setCertReadChecked(moment());
              setOpen(false);
            }}
          >
            <IconSVG
              fill={COLORS['white']}
              size={'20'}
              containerStyle={{
                cursor: 'pointer',
                paddingBottom: '4px',
              }}
              iconSVG={'check'}
            />
            일주일 동안 보지 않기
          </button>
          <div className={'tax-invoice-cert-drawer-container'}>
            <div className={'tax-invoice-cert-drawer-header'}>
              <CloseIcon
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
            <div className={'tax-invoice-cert-drawer-body'}>
              <p className={'text-regular-up'} style={{ lineHeight: '28px' }}>
                세금계산서 발급은 데이터 연동에서
                <br />
                홈택스 인증서 등록이 필요합니다.
              </p>
            </div>
          </div>

          <div className="custom-drawer-content-footer">
            <button onClick={onCancel} className="cancel-button">
              닫기
            </button>
            <button onClick={onOk} className={'ok-button'}>
              인증서 등록
            </button>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default TaxInvoiceCertDrawer;
