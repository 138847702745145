import React from 'react';

type Menu = {
  id: string;
  text: string;
  color: string;
  subMenu?: number[];
};

interface OvalTabProps {
  menus: Menu[];
  activeCardHeaderTab: string;
  setActiveCardHeaderTab: (value: string) => void;
}

const OvalTabs: React.FC<OvalTabProps> = ({
  menus,
  activeCardHeaderTab,
  setActiveCardHeaderTab,
}) => {
  return (
    <div className="oval-tab-container">
      {menus.map((menu, i) => {
        return (
          <div
            className={`oval-tab-item ${
              activeCardHeaderTab === menu.id ? 'active' : ''
            } ${menu.color}`}
            onClick={() => setActiveCardHeaderTab(menu.id)}
            key={i}
          >
            {menu.text}
          </div>
        );
      })}
    </div>
  );
};

export default OvalTabs;
