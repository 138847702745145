import { useState } from 'react';
import ReportCard from '@src/pages/reports/ReportCard.tsx';
import ReportCardHeader from '@src/pages/reports/ReportCardHeader.tsx';
import { REPORT_CATEGORY, REPORT_TERM } from '@src/util/constants.ts';
import ReportCardStatement from '@src/pages/reports/ReportCardStatement.tsx';
import ReportGraph from '@src/pages/reports/ReportGraph.tsx';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useReportAPI } from '@src/util/hooks.ts';
import { useIsMobile } from '@src/util/hooks.ts';
import styled from '@emotion/styled';
import { Moment } from 'moment/moment';

const StyledReportCardPCContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: center;
  max-width: 786px;
  width: 100%;
`;

const StyledReportCardPCRow = styled.div<{
  justifyContent?: string;
  background?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent = 'space-between' }) => justifyContent};
  width: 100%;
  max-width: 786px;
  align-items: center;
  background: ${({ background = '#F7FCFF' }) => background};
`;

const ReportYearlyContent = () => {
  const [activeCardHeaderTab, setActiveCardHeaderTab] = useState<string>(
    REPORT_CATEGORY.SALES,
  );
  const navigate = useNavigate();
  const [today] = useState<Moment>(moment().subtract(1, 'days'));

  const { graphData, dashboardData } = useReportAPI(today, REPORT_TERM.YEARLY);
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div className={'flex-column-flex-start-center'}>
        <ReportCard>
          <ReportCardHeader
            activeHeaderTab={REPORT_TERM.YEARLY}
            activeCardHeaderTab={activeCardHeaderTab}
            setActiveCardHeaderTab={setActiveCardHeaderTab}
            today={today}
          />
          <ReportCardStatement
            data={dashboardData}
            activeCardHeaderTab={activeCardHeaderTab}
            activeHeaderTab={REPORT_TERM.YEARLY}
            moreClick={() => {
              navigate(
                `/reports/additional/${REPORT_TERM.YEARLY}/${activeCardHeaderTab}`,
              );
            }}
          />
        </ReportCard>
        <ReportGraph
          activeHeaderTab={REPORT_TERM.YEARLY}
          activeCardHeaderTab={activeCardHeaderTab}
          today={today}
          graphData={graphData}
        />
      </div>
    );
  } else {
    return (
      <StyledReportCardPCContainer>
        <StyledReportCardPCRow>
          <ReportCard>
            <ReportCardHeader
              activeHeaderTab={REPORT_TERM.YEARLY}
              activeCardHeaderTab={REPORT_CATEGORY.SALES}
              setActiveCardHeaderTab={null}
              today={today}
            />
            <ReportCardStatement
              data={dashboardData}
              activeHeaderTab={REPORT_TERM.YEARLY}
              activeCardHeaderTab={REPORT_CATEGORY.SALES}
              moreClick={() => {
                navigate(
                  `/reports/additional/${REPORT_TERM.YEARLY}/${REPORT_CATEGORY.SALES}`,
                );
              }}
            />
          </ReportCard>
          <ReportCard>
            <ReportCardHeader
              activeHeaderTab={REPORT_TERM.YEARLY}
              activeCardHeaderTab={REPORT_CATEGORY.PURCHASE}
              setActiveCardHeaderTab={null}
              today={today}
            />
            <ReportCardStatement
              data={dashboardData}
              activeCardHeaderTab={REPORT_CATEGORY.PURCHASE}
              activeHeaderTab={REPORT_TERM.YEARLY}
              moreClick={() => {
                navigate(
                  `/reports/additional/${REPORT_TERM.YEARLY}/${REPORT_CATEGORY.PURCHASE}`,
                );
              }}
            />
          </ReportCard>
        </StyledReportCardPCRow>
        <StyledReportCardPCRow background={'white'}>
          <ReportGraph
            activeHeaderTab={REPORT_TERM.YEARLY}
            activeCardHeaderTab={REPORT_CATEGORY.SALES}
            today={today}
            graphData={graphData}
          />
          <ReportGraph
            activeHeaderTab={REPORT_TERM.YEARLY}
            activeCardHeaderTab={REPORT_CATEGORY.PURCHASE}
            today={today}
            graphData={graphData}
          />
        </StyledReportCardPCRow>
        <StyledReportCardPCRow>
          <ReportCard>
            <ReportCardHeader
              activeHeaderTab={REPORT_TERM.YEARLY}
              activeCardHeaderTab={REPORT_CATEGORY.LABOR}
              setActiveCardHeaderTab={null}
              today={today}
            />
            <ReportCardStatement
              data={dashboardData}
              activeHeaderTab={REPORT_TERM.YEARLY}
              activeCardHeaderTab={REPORT_CATEGORY.LABOR}
              moreClick={() => {
                navigate(
                  `/reports/additional/${REPORT_TERM.YEARLY}/${REPORT_CATEGORY.LABOR}`,
                );
              }}
            />
          </ReportCard>
          <ReportCard>
            <ReportCardHeader
              activeHeaderTab={REPORT_TERM.YEARLY}
              activeCardHeaderTab={REPORT_CATEGORY.TOTAL}
              setActiveCardHeaderTab={null}
              today={today}
            />
            <ReportCardStatement
              data={dashboardData}
              activeHeaderTab={REPORT_TERM.YEARLY}
              activeCardHeaderTab={REPORT_CATEGORY.TOTAL}
              moreClick={() => {
                navigate(
                  `/reports/additional/${REPORT_TERM.YEARLY}/${REPORT_CATEGORY.TOTAL}`,
                );
              }}
            />
          </ReportCard>
        </StyledReportCardPCRow>
        <StyledReportCardPCRow background={'white'}>
          <ReportGraph
            activeHeaderTab={REPORT_TERM.YEARLY}
            activeCardHeaderTab={REPORT_CATEGORY.LABOR}
            today={today}
            graphData={graphData}
            // barChartStyle={{ justifyContent: 'flex-start' }}
          />
          <ReportGraph
            activeHeaderTab={REPORT_TERM.YEARLY}
            activeCardHeaderTab={REPORT_CATEGORY.TOTAL}
            today={today}
            graphData={graphData}
            // barChartStyle={{ justifyContent: 'flex-start' }}
          />
        </StyledReportCardPCRow>
      </StyledReportCardPCContainer>
    );
  }
};

export default ReportYearlyContent;
