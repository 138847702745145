import React from 'react';
import Container from '@src/layouts/Container.tsx';
import AdminSideBar from '@src/components/AdminSideBar.tsx';
import TaskAdminContent from '@src/pages/admin/task/TaskAdminContent.tsx';

interface TaskAdminProps {}

const TaskAdmin: React.FC<TaskAdminProps> = () => {
  return (
    <Container sideBar={<AdminSideBar />}>
      <TaskAdminContent />
    </Container>
  );
};

export default TaskAdmin;
