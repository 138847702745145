import React from 'react';
import styled from '@emotion/styled';
import { isNotEmpty } from '@src/util/functions.ts';

const StyledTwoButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  margin-bottom: 12px;
`;

const StyledCancelButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0;

  color: #31343a;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;

  cursor: pointer;
  text-decoration: none;

  background-color: #f5f5f5;
  border-radius: 6px;
  border: none;
  width: 100%;
  box-sizing: border-box;

  &:disabled {
    cursor: default;
  }
`;

const StyledOkButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0;

  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;

  cursor: pointer;
  text-decoration: none;

  background-color: #3182f6;
  border-radius: 6px;
  border: none;
  width: 100%;
  box-sizing: border-box;

  &:disabled {
    cursor: default;
  }
`;

const StyledTailSpinner = styled.div`
  border-radius: 50%;
  animation: spin 1.2s linear infinite;

  border: 2px solid;
  border-color: white transparent transparent transparent;
  width: 12px;
  height: 12px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface CustomModalTwoButtonProps {
  onCancel: () => void;
  onOk: () => void;
  okText?: string;
  isPending?: boolean;
}

const CustomModalTwoButton: React.FC<CustomModalTwoButtonProps> = ({
  onCancel,
  onOk,
  okText,
  isPending,
}) => {
  return (
    <StyledTwoButtonContainer>
      <StyledCancelButton onClick={onCancel} disabled={isPending}>
        취소
      </StyledCancelButton>
      <StyledOkButton onClick={onOk} disabled={isPending}>
        {isPending ? (
          <StyledTailSpinner />
        ) : isNotEmpty(okText) ? (
          okText
        ) : (
          '확인'
        )}
      </StyledOkButton>
    </StyledTwoButtonContainer>
  );
};

export default CustomModalTwoButton;
