import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface CheckBoxRHFProps {
  register: UseFormRegisterReturn;
  style?: React.CSSProperties;
}

const CheckBoxRHF: React.FC<CheckBoxRHFProps> = ({ register, style }) => {
  return <input type="checkbox" style={style} {...register} />;
};

export default CheckBoxRHF;
