interface TaxInvoiceIconProps {
  fill: string;
  size: string;
}

const TaxInvoiceIcon: React.FC<TaxInvoiceIconProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon / document">
        <g id="Vector">
          <path
            d="M20.75 16.25C20.58 16.08 20.38 16 20.15 16H20V15C20 14.45 19.8 13.98 19.41 13.59C19.02 13.2 18.55 13 18 13C17.45 13 16.98 13.2 16.59 13.59C16.2 13.98 16 14.45 16 15V16H15.85C15.62 16 15.42 16.08 15.25 16.25C15.08 16.42 15 16.62 15 16.85V20.15C15 20.38 15.08 20.58 15.25 20.75C15.42 20.92 15.62 21 15.85 21H20.15C20.38 21 20.58 20.92 20.75 20.75C20.92 20.58 21 20.38 21 20.15V16.85C21 16.62 20.92 16.42 20.75 16.25ZM19.08 16H16.93V15.07C16.93 14.48 17.41 14 18.01 14C18.61 14 19.08 14.48 19.08 15.07V16Z"
            fill={fill}
          />
          <path
            d="M16.71 7.29C16.52 7.1 16.28 7 16 7H8C7.72 7 7.48 7.1 7.29 7.29C7.1 7.48 7 7.72 7 8C7 8.28 7.1 8.52 7.29 8.71C7.48 8.9 7.72 9 8 9H16C16.28 9 16.52 8.9 16.71 8.71C16.9 8.52 17 8.28 17 8C17 7.72 16.9 7.48 16.71 7.29Z"
            fill={fill}
          />
          <path
            d="M7.29 16.71C7.48 16.9 7.72 17 8 17H13C13.28 17 13.52 16.9 13.71 16.71C13.9 16.52 14 16.28 14 16C14 15.72 13.9 15.48 13.71 15.29C13.52 15.1 13.28 15 13 15H8C7.72 15 7.48 15.1 7.29 15.29C7.1 15.48 7 15.72 7 16C7 16.28 7.1 16.52 7.29 16.71Z"
            fill={fill}
          />
          <path
            d="M14.71 12.71C14.9 12.52 15 12.28 15 12C15 11.72 14.9 11.48 14.71 11.29C14.52 11.1 14.28 11 14 11H8C7.72 11 7.48 11.1 7.29 11.29C7.1 11.48 7 11.72 7 12C7 12.28 7.1 12.52 7.29 12.71C7.48 12.9 7.72 13 8 13H14C14.28 13 14.52 12.9 14.71 12.71Z"
            fill={fill}
          />
          <path
            d="M5 19V5H19V11C19 11.55 19.45 12 20 12C20.55 12 21 11.55 21 11V5C21 4.45 20.8 3.98 20.41 3.59C20.02 3.2 19.55 3 19 3H5C4.45 3 3.98 3.2 3.59 3.59C3.2 3.98 3 4.45 3 5V19C3 19.55 3.2 20.02 3.59 20.41C3.98 20.8 4.45 21 5 21H12C12.55 21 13 20.55 13 20C13 19.45 12.55 19 12 19H5Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};

export default TaxInvoiceIcon;
