import '@src/scss/main.scss';
import {
  CardCert,
  HometaxUpdate,
  IApiError,
  ICert,
  PersonalCardList,
} from '@src/util/interfaces';
import api from '@src/util/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  API_URLS,
  CARD_SYNC_MENU,
  CARD_SYNC_MENU_LIST,
  CardCompany,
} from '@src/util/constants';
import { useUserStore } from '@src/util/store';
import React, { useCallback, useRef, useState } from 'react';
import DetailHeader from '@src/components/DetailHeader';
import Container from '@src/layouts/Container';
import CustomModal from '@src/components/CustomModal';
import TailSpinner from '@src/components/TailSpinner';
import SideBar from '@src/components/SideBar';
import CheckBox from '@src/components/CheckBox';
import Modal from '@src/components/Modal';
import CardIcon from '@src/components/CardIcon.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import Header from '@src/components/Header.tsx';
import { useIsMobile } from '@src/util/hooks.ts';
import HeaderTabs from '@src/components/HeaderTabs.tsx';

interface DataSyncProps {}

const CardSync: React.FC<DataSyncProps> = () => {
  const [user] = useUserStore((state) => [state.user]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [clearModalOpen, setClearModalOpen] = useState<boolean>(false);

  const [card_id, set_card_id] = useState<string>('');
  const [card_pw, set_card_password] = useState<string>('');
  const [card_company, set_card_company] = useState<CardCompany>(
    CardCompany.국민카드,
  );
  const [error, setError] = useState<string>('');

  const [errorModal, setErrorModal] = useState('');

  const [card_cert_id, set_card_cert_id] = useState<number>(-1);
  const [title, setTitle] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const lastCallsRef = useRef<{ [key: string]: number }>({});

  const [personalListModalTitle, setPersonalListModalTitle] =
    useState<string>('');
  const [personalListModal, setPersonalListModal] = useState<boolean>(false);
  const [personalCardItems, setPersonalCardItems] = useState<
    PersonalCardList[]
  >([]);
  const [open, setOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    CARD_SYNC_MENU.CARD,
  );

  const company_id = user?.selectedCompany?.id;
  const isPersonal = user?.selectedCompany?.type == 'PERSONAL';

  const queryClient = useQueryClient();
  const cert = useQuery<ICert>({
    queryKey: [`cert`, company_id],
    queryFn: () => api.get(API_URLS.CERT + `/${company_id}`),
    staleTime: 30 * 60 * 1000,
    enabled: !!user && !!user.selectedCompany,
    refetchOnWindowFocus: false,
  });

  const linkedCardCompany =
    cert?.data?.data.card_certs.map((cert) => cert.card_company) || [];
  const notLinkedCardCompany: CardCompany[] = Object.values(CardCompany).filter(
    (company) => !linkedCardCompany.includes(company),
  );

  const { mutate: updateCert, isPending: isLoading } = useMutation({
    mutationFn: (data: HometaxUpdate) =>
      api.post(API_URLS.CERT + `/card`, { ...data }),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['cert', company_id] });
      setModalOpen(false);
      if (isPersonal) {
        const findCard = response.data.find(
          (cardCert: CardCert) => cardCert.card_id == card_id,
        );
        handleGetCardList(findCard.id, findCard.card_company);
      }
    },
    onError: (e: IApiError) => {
      setError(e.response.data.message);
    },
  });

  const { mutate: updateCertClear } = useMutation({
    mutationFn: (data: { card_cert_id: number }) =>
      api.delete(API_URLS.CERT + `/card/${company_id}/${data.card_cert_id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cert', company_id] });
      setClearModalOpen(false);
    },
    onError: (e: IApiError) => {
      setError(e.response.data.message);
    },
  });

  const { mutate: getPersonalCardList, isPending: personalCardListLoading } =
    useMutation({
      mutationFn: (data: { id: number }) =>
        api.get(API_URLS.CERT + `/card/personal/${company_id}/${data.id}`),
      onSuccess: (response) => {
        setPersonalCardItems(response.data);
        setPersonalListModal(true);
      },
      onError: (e: IApiError) => {
        setErrorModal(e.response.data.message);
      },
    });

  const { mutate: savePersonalCard } = useMutation({
    mutationFn: (data: PersonalCardList[]) =>
      api.post(API_URLS.CERT + `/card/personal/${company_id}`, { data }),
    onSuccess: () => {
      setPersonalListModal(false);
    },
    onError: (e: IApiError) => {
      setError(e.response.data.message);
    },
  });

  const handleClear = useCallback(
    (card_cert_id: number, value: string, title: string) => {
      set_card_cert_id(card_cert_id);
      setValue(value);
      setTitle(title);
      setClearModalOpen(true);
    },
    [],
  );

  const handleModalOpen = useCallback((card_company: CardCompany) => {
    set_card_id('');
    set_card_password('');
    setModalOpen(true);
    set_card_company(card_company);
  }, []);

  const handleGetCardList = useCallback((id: number, title: string) => {
    if (isPersonal) {
      const now = Date.now();
      const key = `${id}_${title}`;
      const lastCallTime = lastCallsRef.current[key];

      if (lastCallTime && now - lastCallTime < 30000) {
        setErrorModal(
          '해당 아이디로 카드데이터를 연동하고 있으니 잠시 후 다시 시도해 주세요.',
        );
        return;
      }

      getPersonalCardList({ id });
      setPersonalListModalTitle(title);
      lastCallsRef.current[key] = now;
    }
  }, []);

  const handleCheckboxChange = useCallback(
    (index: number) => {
      const updatedItems = personalCardItems.map((item, i) => {
        if (i === index) {
          return { ...item, scrap: !item.scrap };
        }
        return item;
      });
      setPersonalCardItems(updatedItems);
    },
    [personalCardItems],
  );

  if (isLoading || personalCardListLoading) {
    return <TailSpinner type={'full-page'} />;
  }

  return (
    <Container
      header={
        isMobile ? (
          <DetailHeader title={'카드사 연결하기'} />
        ) : (
          <Header setOpen={setOpen} />
        )
      }
      headerTab={
        !isMobile && (
          <HeaderTabs
            menus={CARD_SYNC_MENU_LIST}
            activeHeaderTab={activeHeaderTab}
            setActiveHeaderTab={(value) => {
              setActiveHeaderTab(value);
            }}
          />
        )
      }
      sideBar={<SideBar />}
      modal={
        <>
          <CompanyDrawer open={open} setOpen={setOpen} />
          <CustomModal
            onClose={() => {
              setError('');
              setModalOpen(false);
            }}
            isOpen={modalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2 className={'modal-custom-title'}>
                      {`${card_company} 연결`}
                    </h2>
                    <div className={'modal-input-wrapper'}>
                      <input
                        className={'modal-input'}
                        value={card_id}
                        placeholder={'아이디 입력'}
                        onFocus={() => {
                          setError('');
                        }}
                        onChange={(e) => set_card_id(e.target.value)}
                      />
                      <input
                        type={'password'}
                        className={'modal-input'}
                        value={card_pw}
                        placeholder={'패스워드 입력'}
                        onFocus={() => {
                          setError('');
                        }}
                        onChange={(e) => set_card_password(e.target.value)}
                      />
                      {error ? (
                        <p className={'error-message'}>{error}</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'left-button'}
                    onClick={() => setModalOpen(false)}
                  >
                    취소
                  </button>
                  <button
                    className={'right-button'}
                    onClick={() =>
                      updateCert({ card_id, card_pw, card_company, company_id })
                    }
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />
          <CustomModal
            onClose={() => {
              setError('');
              setClearModalOpen(false);
            }}
            isOpen={clearModalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2 className={'modal-custom-title'}>{`${title}`}</h2>
                  </div>
                  {value ? (
                    <div className={'modal-input-wrapper'}>
                      <input
                        className={'modal-input'}
                        value={value}
                        disabled={true}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {error ? <p className={'error-message'}>{error}</p> : <></>}
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'left-button'}
                    onClick={() => setClearModalOpen(false)}
                  >
                    취소
                  </button>
                  <button
                    className={'right-button'}
                    onClick={() => updateCertClear({ card_cert_id })}
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />

          <CustomModal
            onClose={() => {
              setError('');
              setPersonalListModal(false);
            }}
            isOpen={personalListModal}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2 className={'modal-custom-title'}>
                      {`${personalListModalTitle} 연동`}
                    </h2>
                  </div>
                  {personalCardItems.map((item, index) => (
                    <div
                      className="personal-card-wrapper"
                      key={item.card_number}
                    >
                      <CheckBox
                        checked={item.scrap}
                        onChange={() => handleCheckboxChange(index)}
                      />
                      <div className={'personal-card-name-wrapper'}>
                        <p className={'personal-card-text'}>{item.card_name}</p>
                        <p className={'personal-card-text'}>
                          {item.card_number}
                        </p>
                      </div>
                    </div>
                  ))}
                  {error ? <p className={'error-message'}>{error}</p> : <></>}
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'right-button'}
                    onClick={() => savePersonalCard(personalCardItems)}
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />

          <Modal
            isOpen={errorModal != ''}
            onOk={() => {
              setErrorModal('');
            }}
            title={'오류발생'}
            content={errorModal}
            okButton={'확인'}
            onClose={() => {
              setErrorModal('');
            }}
          />
        </>
      }
    >
      <div className={'data-sync-wrapper-container'}>
        <div className="data-sync-card-wrapper">
          {notLinkedCardCompany && notLinkedCardCompany.length > 0 && (
            <div className={'data-sync-card-container'}>
              <h4 className={'card-title'}>연결 전</h4>
              {notLinkedCardCompany.map((card_company: CardCompany, index) => {
                return (
                  <div
                    className={'data-sync-content-wrapper'}
                    key={`${index}-${card_company}`}
                  >
                    <div className={'data-sync-content'}>
                      <div className={'icon-wrapper'}>
                        <CardIcon name={card_company} size={'lg'} />
                        <p className={'icon-label'}>{card_company}</p>
                      </div>
                      <button
                        className={'sync-button'}
                        onClick={() => handleModalOpen(card_company)}
                      >
                        연결하기
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {cert?.data?.data.card_certs &&
            cert?.data?.data.card_certs.length > 0 && (
              <div className={'data-sync-card-container'}>
                <h4 className={'card-title'}>연결 완료</h4>
                {cert?.data?.data.card_certs.map((card_cert, index) => {
                  return (
                    <div
                      className={'data-sync-content-wrapper'}
                      key={`${index}-${card_cert.card_company}`}
                    >
                      <div className={'data-sync-content'}>
                        <div
                          className={`icon-wrapper ${
                            isPersonal ? 'pointer' : ''
                          }`}
                          onClick={() =>
                            handleGetCardList(
                              card_cert.id,
                              card_cert.card_company,
                            )
                          }
                        >
                          <CardIcon name={card_cert.card_company} size={'lg'} />
                          <p className={'icon-label'}>
                            {card_cert.card_company}
                            {card_cert.password_error && (
                              <span className={'error-message'}>
                                {' '}
                                (비밀번호 오류)
                              </span>
                            )}
                          </p>
                        </div>
                        <button
                          className={'sync-button-clear'}
                          onClick={() =>
                            handleClear(
                              card_cert.id,
                              card_cert.card_id,
                              `${card_cert.card_company} 연결 해제`,
                            )
                          }
                        >
                          연결해제
                        </button>
                      </div>
                      {isPersonal ? (
                        <div className={'data-sync-explain'}>
                          <p className={'blue-text'}>
                            연결 완료된 카드 로고를 누르면 연동할 카드를 선택할
                            수 있습니다.
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                })}
              </div>
            )}
        </div>
      </div>
    </Container>
  );
};

export default CardSync;
