import { FC } from 'react';

interface PrintIconProps {
  fill: string;
  size: string;
}

const PrintIcon: FC<PrintIconProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon / print">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 6C6 4.34315 7.34315 3 9 3H15C16.6569 3 18 4.34315 18 6V7H19C20.6569 7 22 8.34315 22 10V17C22 17.5523 21.5523 18 21 18H18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18H3C2.44772 18 2 17.5523 2 17V10C2 8.34315 3.34315 7 5 7H6V6ZM8 7H16V6C16 5.44772 15.5523 5 15 5H9C8.44772 5 8 5.44772 8 6V7ZM5 9C4.44772 9 4 9.44772 4 10V16H6V14C6 13.4477 6.44772 13 7 13H17C17.5523 13 18 13.4477 18 14V16H20V10C20 9.44771 19.5523 9 19 9H5ZM15 11C15 10.4477 15.4477 10 16 10H18C18.5523 10 19 10.4477 19 11C19 11.5523 18.5523 12 18 12H16C15.4477 12 15 11.5523 15 11ZM8 15V18C8 18.5523 8.44772 19 9 19H15C15.5523 19 16 18.5523 16 18V15H8Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default PrintIcon;
