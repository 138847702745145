import React from 'react';

import { IReportDashboard } from '@src/util/interfaces';
import { processData } from '@src/util/functions';
import { REPORT_CATEGORY, REPORT_TERM_TEXT } from '@src/util/constants';
import ReportCardSales from './ReportCardSales.tsx';
import ReportCardPurchase from './ReportCardPurchase.tsx';
import ReportCardLabor from './ReportCardLabor.tsx';
import ReportCardTotal from './ReportCardTotal.tsx';
import chevronRightBlue from '../../assets/icons/report/chevron_right_blue.svg';
import Icon from '../../components/Icon.tsx';
import { useUserStore } from '@src/util/store.ts';

interface ReportCardStatementProps {
  data?: IReportDashboard;
  activeCardHeaderTab: string;
  activeHeaderTab: string;
  moreClick?: () => void;
}

const ReportCardStatement: React.FC<ReportCardStatementProps> = ({
  data,
  activeCardHeaderTab,
  activeHeaderTab,
  moreClick,
}) => {
  const [user] = useUserStore((state) => [state.user]);

  const processedData = processData(
    data,
    user?.selectedCompany?.tax_type !== 'EXEMPT',
  );

  const renderReportCardContent = () => {
    if (processedData) {
      if (activeCardHeaderTab === REPORT_CATEGORY.SALES) {
        return (
          <ReportCardSales
            data={processedData.sales}
            activeHeaderTab={activeHeaderTab}
            activeCardHeaderTab={activeCardHeaderTab}
          />
        );
      } else if (activeCardHeaderTab === REPORT_CATEGORY.PURCHASE) {
        return (
          <ReportCardPurchase
            data={processedData.purchase}
            activeHeaderTab={activeHeaderTab}
            activeCardHeaderTab={activeCardHeaderTab}
          />
        );
      } else if (activeCardHeaderTab === REPORT_CATEGORY.LABOR) {
        return (
          <ReportCardLabor
            data={processedData.labor}
            activeHeaderTab={activeHeaderTab}
            activeCardHeaderTab={activeCardHeaderTab}
          />
        );
      } else {
        return (
          <ReportCardTotal
            data={{
              sales: processedData.sales.total,
              purchase: processedData.purchase.total,
              labor: processedData.labor.total,
              total: processedData.total,
              activeCardHeaderTab,
              activeHeaderTab,
            }}
          />
        );
      }
    }
  };

  return (
    <>
      {renderReportCardContent()}
      {moreClick && (
        <div className="tab-card-statement-more" onClick={moreClick}>
          <button className="text">
            {REPORT_TERM_TEXT[activeHeaderTab as keyof typeof REPORT_TERM_TEXT]}
            별 더보기
          </button>
          <Icon iconSrc={chevronRightBlue} iconText={'>'} />
        </div>
      )}
    </>
  );
};

export default ReportCardStatement;
