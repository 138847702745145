import React from 'react';
import OvalTabs from '@src/components/OvalTabs.tsx';
import {
  REPORT_CATEGORY,
  REPORT_CATEGORY_MENU_LIST,
  REPORT_TERM,
} from '@src/util/constants.ts';
import { getDateInfo } from '@src/util/functions.ts';
import { Moment } from 'moment';
interface ReportCardHeaderProps {
  activeHeaderTab: string;
  activeCardHeaderTab: string;
  setActiveCardHeaderTab: ((value: string) => void) | null;
  today: Moment;
}

const ReportCardHeader: React.FC<ReportCardHeaderProps> = ({
  activeHeaderTab,
  activeCardHeaderTab,
  setActiveCardHeaderTab,
  today,
}) => {
  let menus;
  if (activeHeaderTab === REPORT_TERM.YEARLY) {
    menus = REPORT_CATEGORY_MENU_LIST;
  } else {
    menus = REPORT_CATEGORY_MENU_LIST.filter(
      (item) => item.id !== REPORT_CATEGORY.LABOR,
    );
  }

  if (setActiveCardHeaderTab === null) {
    menus = menus.filter((item) => item.id === activeCardHeaderTab);
  }

  return (
    <div className="report-card-header">
      <OvalTabs
        menus={menus}
        activeCardHeaderTab={activeCardHeaderTab}
        setActiveCardHeaderTab={
          setActiveCardHeaderTab === null ? () => null : setActiveCardHeaderTab
        }
      />
      <p>{getDateInfo(activeHeaderTab, today)}</p>
    </div>
  );
};

export default ReportCardHeader;
