import React, { KeyboardEventHandler } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface InputProps {
  type?: string;
  placeholder?: string;
  name?: string;
  customClassName?: string;
  register?: UseFormRegisterReturn;
  isLoading?: boolean;
  label?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  value?: string | number;
  maxLength?: number;
  autoComplete?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  style?: React.CSSProperties;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  useThousandSeparator?: boolean;
}

const Input: React.FC<InputProps> = ({
  type = 'text',
  placeholder,
  name,
  customClassName,
  register,
  isLoading,
  readOnly,
  value,
  label,
  required = false,
  disabled,
  maxLength,
  inputMode,
  autoComplete,
  onChange,
  style,
  onKeyDown,
  useThousandSeparator = false,
}) => {
  const formatWithThousandSeparator = (value: string): string => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let value = e.target.value;
    if (useThousandSeparator) {
      // Remove existing commas before formatting
      value = formatWithThousandSeparator(value.replace(/,/g, ''));
    }
    if (onChange) {
      // Call the onChange prop with the formatted value
      e.target.value = value;
      onChange(e);
    }
  };
  const defaultClassName = 'input-default';
  const combinedClassName = customClassName
    ? `${defaultClassName} ${customClassName}`
    : defaultClassName;

  return (
    <>
      {label && (
        <p className="input-label-default">
          {label}
          {required && <span className="input-label-required">*</span>}
        </p>
      )}
      <input
        type={type}
        value={
          useThousandSeparator && typeof value === 'string'
            ? formatWithThousandSeparator(value)
            : value
        }
        name={name}
        className={combinedClassName}
        readOnly={readOnly}
        placeholder={placeholder}
        disabled={isLoading || disabled}
        maxLength={maxLength}
        inputMode={inputMode}
        autoComplete={autoComplete}
        onChange={handleChange}
        {...register}
        style={style}
        onKeyDown={onKeyDown}
      />
    </>
  );
};

export default Input;
