import '@src/scss/main.scss';
import { HometaxUpdate, IApiError, ICert } from '@src/util/interfaces';
import api from '@src/util/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  API_URLS,
  COMMERCE_SYNC_MENU,
  COMMERCE_SYNC_MENU_LIST,
} from '@src/util/constants';
import { useUserStore } from '@src/util/store';
import React, { useCallback, useState } from 'react';
import DetailHeader from '@src/components/DetailHeader';
import Container from '@src/layouts/Container';
import CustomModal from '@src/components/CustomModal';
import TailSpinner from '@src/components/TailSpinner';
import SideBar from '@src/components/SideBar';
import CommerceIcon from '@src/components/CommerceIcon.tsx';
import { useIsMobile } from '@src/util/hooks.ts';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import Header from '@src/components/Header.tsx';
import HeaderTabs from '@src/components/HeaderTabs.tsx';

interface DataSyncProps {}

const CommerceSync: React.FC<DataSyncProps> = () => {
  const [user] = useUserStore((state) => [state.user]);
  const [naverModalOpen, setNaverModalOpen] = useState<boolean>(false);
  const [coupangModalOpen, setCoupangModalOpen] = useState<boolean>(false);
  const [clearModalOpen, setClearModalOpen] = useState<boolean>(false);

  const [naver_client_id, set_naver_client_id] = useState<string>('');
  const [naver_client_id_error, set_naver_client_id_error] =
    useState<string>('');
  const [naver_client_secret, set_naver_client_secret] = useState<string>('');
  const [naver_client_secret_error, set_naver_client_secret_error] =
    useState<string>('');

  const [coupang_vendor_id, set_coupang_vendor_id] = useState<string>('');
  const [coupang_vendor_id_error, set_coupang_vendor_id_error] =
    useState<string>('');
  const [coupang_access_key, set_coupang_access_key] = useState<string>('');
  const [coupang_access_key_error, set_coupang_access_key_error] =
    useState<string>('');
  const [coupang_secret_key, set_coupang_secret_key] = useState<string>('');
  const [coupang_secret_key_error, set_coupang_secret_key_error] =
    useState<string>('');

  const [key, setKey] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const [error, setError] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    COMMERCE_SYNC_MENU.COMMERCE,
  );

  const company_id = user?.selectedCompany?.id;

  const queryClient = useQueryClient();
  const cert = useQuery<ICert>({
    queryKey: [`cert`, company_id],
    queryFn: () => api.get(API_URLS.CERT + `/${company_id}`),
    staleTime: 30 * 60 * 1000,
    enabled: !!user && !!user.selectedCompany,
    refetchOnWindowFocus: false,
  });

  const { mutate: updateNaverCert, isPending: isLoading } = useMutation({
    mutationFn: (data: HometaxUpdate) =>
      api.post(API_URLS.CERT + `/naver`, { ...data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cert', company_id] });
      setNaverModalOpen(false);
    },
    onError: (e: IApiError) => {
      setError(e.response.data.message);
    },
  });

  const { mutate: updateCoupangCert, isPending: updateLoading } = useMutation({
    mutationFn: (data: HometaxUpdate) =>
      api.post(API_URLS.CERT + `/coupang`, { ...data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cert', company_id] });
      setCoupangModalOpen(false);
    },
    onError: (e: IApiError) => {
      setError(e.response.data.message);
    },
  });

  const { mutate: updateCertClear } = useMutation({
    mutationFn: (data: { key: string; value: string }) =>
      api.patch(API_URLS.CERT + `/${user?.selectedCompany?.id}`, {
        [data.key]: data.value,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cert', company_id] });
      setClearModalOpen(false);
    },
    onError: (e: IApiError) => {
      setError(e.response.data.message);
    },
  });

  const naverSyncDisable =
    cert?.data?.data.naver_client_id &&
    cert?.data?.data.naver_client_secret &&
    cert?.data?.data.naver_key_error == false;
  const naverError = cert?.data?.data.naver_key_error == true;

  const coupangSyncDisable =
    cert?.data?.data.coupang_vendor_id &&
    cert?.data?.data.coupang_access_key &&
    cert?.data?.data.coupang_secret_key &&
    cert?.data?.data.coupang_key_error == false;
  const coupangError = cert?.data?.data.coupang_key_error == true;

  const handleNaverSyncClick = useCallback(() => {
    if (!naver_client_id) {
      set_naver_client_id_error('애플리케이션 ID를 입력해주세요');
      return;
    }
    if (!naver_client_secret) {
      set_naver_client_secret_error('애플리케이션 시크릿을 입력해주세요');
      return;
    }
    updateNaverCert({ naver_client_id, naver_client_secret, company_id });
  }, [naver_client_id, naver_client_secret, company_id]);

  const handleCoupangSyncClick = useCallback(() => {
    if (!coupang_vendor_id) {
      set_coupang_vendor_id_error('업체코드를 입력해주세요');
      return;
    }
    if (!coupang_access_key) {
      set_coupang_access_key_error('access key를 입력해주세요');
      return;
    }
    if (!coupang_secret_key) {
      set_coupang_secret_key('secret key를 입력해주세요');
      return;
    }
    updateCoupangCert({
      coupang_vendor_id,
      coupang_access_key,
      coupang_secret_key,
      company_id,
    });
  }, [coupang_vendor_id, coupang_access_key, coupang_secret_key, company_id]);

  const handleClear = useCallback(
    (key: string, value: string, title: string) => {
      setKey(key);
      setValue(value);
      setTitle(title);
      setClearModalOpen(true);
    },
    [],
  );

  if (isLoading || updateLoading) {
    return <TailSpinner type={'full-page'} />;
  }

  return (
    <Container
      header={
        isMobile ? (
          <DetailHeader title={'커머스 연결하기'} />
        ) : (
          <Header setOpen={setOpen} />
        )
      }
      sideBar={<SideBar />}
      headerTab={
        !isMobile && (
          <HeaderTabs
            menus={COMMERCE_SYNC_MENU_LIST}
            activeHeaderTab={activeHeaderTab}
            setActiveHeaderTab={(value) => {
              setActiveHeaderTab(value);
            }}
          />
        )
      }
      modal={
        <>
          <CompanyDrawer open={open} setOpen={setOpen} />
          <CustomModal
            onClose={() => {
              setError('');
              setNaverModalOpen(false);
            }}
            isOpen={naverModalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2
                      className={'modal-custom-title'}
                    >{`네이버 API 연결`}</h2>
                    <div className={'modal-input-wrapper'}>
                      <input
                        className={'modal-input'}
                        value={naver_client_id}
                        placeholder={'애플리케이션 ID'}
                        onFocus={() => set_naver_client_id_error('')}
                        onChange={(e) => set_naver_client_id(e.target.value)}
                      />
                      {naver_client_id_error ? (
                        <p className={'error-message'}>
                          {naver_client_id_error}
                        </p>
                      ) : (
                        <></>
                      )}
                      <input
                        className={'modal-input'}
                        value={naver_client_secret}
                        placeholder={'애플리케이션 시크릿'}
                        onFocus={() => set_naver_client_secret_error('')}
                        onChange={(e) =>
                          set_naver_client_secret(e.target.value)
                        }
                      />
                      {naver_client_secret_error ? (
                        <p className={'error-message'}>
                          {naver_client_secret_error}
                        </p>
                      ) : (
                        <></>
                      )}
                      {error ? (
                        <p className={'error-message'}>{error}</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'left-button'}
                    onClick={() => setNaverModalOpen(false)}
                  >
                    취소
                  </button>
                  <button
                    className={'right-button'}
                    onClick={() => handleNaverSyncClick()}
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />

          <CustomModal
            onClose={() => {
              setError('');
              setCoupangModalOpen(false);
            }}
            isOpen={coupangModalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2 className={'modal-custom-title'}>{`쿠팡 API 연결`}</h2>
                    <div className={'modal-input-wrapper'}>
                      <input
                        className={'modal-input'}
                        value={coupang_vendor_id}
                        placeholder={'업체코드 입력'}
                        onFocus={() => set_coupang_vendor_id_error('')}
                        onChange={(e) => set_coupang_vendor_id(e.target.value)}
                      />
                      {coupang_vendor_id_error ? (
                        <p className={'error-message'}>
                          {coupang_vendor_id_error}
                        </p>
                      ) : (
                        <></>
                      )}
                      <input
                        className={'modal-input'}
                        value={coupang_access_key}
                        placeholder={'access key 입력'}
                        onFocus={() => set_coupang_access_key_error('')}
                        onChange={(e) => set_coupang_access_key(e.target.value)}
                      />
                      {coupang_access_key_error ? (
                        <p className={'error-message'}>
                          {coupang_access_key_error}
                        </p>
                      ) : (
                        <></>
                      )}
                      <input
                        className={'modal-input'}
                        value={coupang_secret_key}
                        placeholder={'secret key 입력'}
                        onFocus={() => set_coupang_secret_key_error('')}
                        onChange={(e) => set_coupang_secret_key(e.target.value)}
                      />
                      {coupang_secret_key_error ? (
                        <p className={'error-message'}>
                          {coupang_secret_key_error}
                        </p>
                      ) : (
                        <></>
                      )}
                      {error ? (
                        <p className={'error-message'}>{error}</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'left-button'}
                    onClick={() => setCoupangModalOpen(false)}
                  >
                    취소
                  </button>
                  <button
                    className={'right-button'}
                    onClick={() => handleCoupangSyncClick()}
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />

          <CustomModal
            onClose={() => {
              setError('');
              setClearModalOpen(false);
            }}
            isOpen={clearModalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2 className={'modal-custom-title'}>{`${title}`}</h2>
                  </div>
                  {value ? (
                    <div className={'modal-input-wrapper'}>
                      <input
                        className={'modal-input'}
                        value={value}
                        disabled={true}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {error ? <p className={'error-message'}>{error}</p> : <></>}
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'left-button'}
                    onClick={() => setClearModalOpen(false)}
                  >
                    취소
                  </button>
                  <button
                    className={'right-button'}
                    onClick={() => updateCertClear({ key, value: '' })}
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />
        </>
      }
    >
      <div className={'data-sync-wrapper-container'}>
        <div className="data-sync-detail-container">
          <div className={'data-sync-content-wrapper'}>
            <div className={'data-sync-content'}>
              <div className={'icon-wrapper'}>
                <CommerceIcon name={'NAVER'} size={'lg'} />
                <p className={'icon-label'}>
                  네이버 API 연결
                  {naverError && (
                    <span className={'error-message'}> (API 연결 오류)</span>
                  )}
                </p>
              </div>
              {naverSyncDisable && (
                <button
                  className={'sync-button-clear'}
                  onClick={() =>
                    handleClear(
                      'naver_client_id',
                      cert?.data?.data.naver_client_id || '',
                      '네이버 API 연결 해제',
                    )
                  }
                >
                  연결해제
                </button>
              )}
              {!naverSyncDisable && (
                <button
                  className={'sync-button'}
                  onClick={() => setNaverModalOpen(true)}
                >
                  연결하기
                </button>
              )}
            </div>
          </div>
          <div className={'data-sync-content-wrapper'}>
            <div className={'data-sync-content'}>
              <div className={'icon-wrapper'}>
                <CommerceIcon name={'COUPANG'} size={'lg'} />
                <p className={'icon-label'}>
                  쿠팡 API 연결
                  {coupangError && (
                    <span className={'error-message'}> (API 연결 오류)</span>
                  )}
                </p>
              </div>
              {coupangSyncDisable && (
                <button
                  className={'sync-button-clear'}
                  onClick={() =>
                    handleClear(
                      'coupang_vendor_id',
                      cert?.data?.data.coupang_vendor_id || '',
                      '쿠팡 API 연결 해제',
                    )
                  }
                >
                  연결해제
                </button>
              )}
              {!coupangSyncDisable && (
                <button
                  className={'sync-button'}
                  onClick={() => setCoupangModalOpen(true)}
                >
                  연결하기
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CommerceSync;
