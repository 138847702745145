import React from 'react';

interface DividerProps {
  customClassName?: string;
  style?: React.CSSProperties;
}

const Divider: React.FC<DividerProps> = ({ customClassName, style }) => {
  const defaultClassName = 'divider-default';
  const combinedClassName = customClassName
    ? `${defaultClassName} ${customClassName}`
    : defaultClassName;

  return <div className={combinedClassName} style={style}></div>;
};

export default Divider;
