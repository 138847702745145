import Container from '@src/layouts/Container.tsx';
import DetailHeader from '@src/components/DetailHeader.tsx';
import SideBar from '@src/components/SideBar.tsx';
import Cookies from 'js-cookie';
import { useScrollToTop } from '@src/util/hooks.ts';
import { useEffect, useState } from 'react';
import LandingHeader from '@src/components/LandingHeader.tsx';
import { useIsMobile } from '@src/util/hooks.ts';

const PersonalTerms = () => {
  useScrollToTop();

  const hasToken = Cookies.get('accessToken') || Cookies.get('refreshToken');

  const [isFromChomchomTax, setIsFromChomchomTax] = useState<boolean>(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    setIsFromChomchomTax(
      /^https:\/\/.*\.chomchomtax\.co\.kr/.test(document.referrer),
    );
  }, []);

  return (
    <Container
      header={
        isFromChomchomTax ? (
          <LandingHeader />
        ) : isMobile ? (
          <DetailHeader title={'개인정보처리방침'} />
        ) : (
          <DetailHeader title={'개인정보처리방침'} backVisible={false} />
        )
      }
      sideBar={hasToken && <SideBar />}
    >
      <div className={'term-container'}>
        {isFromChomchomTax && (
          <>
            <h1
              className={'text-xl'}
              style={{ alignSelf: 'center', marginTop: '30px' }}
            >
              개인정보처리방침
            </h1>
            <br />
          </>
        )}
        <h1 className={'text-md'}>1. 개인정보처리방침의 목적</h1>
        <p className={'text-regular'}>
          정율 세무회계(이하 '회사')는 「개인정보 보호법」에 따라 아래와 같은
          내용으로 개인정보를 수집하고 있고, 그 관리에 있어 책임과 의무를
          준수하고 있습니다. 개인정보 수집 및 이용과 관련하여 다음 사항을
          안내드리오니, 내용을 읽고 모든 내용을 이해한 후 동의 여부를 결정하여
          주시기 바랍니다.
          <br />
          <br />
          (1) 회사의 노력
          <br />
          구체적으로 회사는 개인정보 처리와 관련하여 다음의 노력을 하고
          있습니다.
        </p>
        <ul
          style={{
            listStyleType: 'disc',
          }}
        >
          <li>
            이용자의 개인정보를 암호화하고 있습니다.
            <ul>
              <li style={{ listStyleType: 'circle' }}>
                정보주체의 개인정보는 비밀번호는 암호화되어 저장 및 관리되고
                있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송
                데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도
                보안기능을 사용하고 있습니다.
              </li>
            </ul>
          </li>
          <li>
            개인정보 유출 방지를 위해 외부로부터의 접근을 엄격히 제한하고
            있습니다.
            <ul>
              <li style={{ listStyleType: 'circle' }}>
                고객의 개인정보가 저장되어 있는 서버는 외부로부터의 접속을
                제한하고 있습니다.
              </li>
            </ul>
          </li>
          <li>
            이용자의 개인정보에 접근할 수 있는 사람을 최소화하고 교육하고
            있습니다.
            <ul>
              <li style={{ listStyleType: 'circle' }}>
                개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,
                변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한
                조치를 하고 있으며 개인정보가 포함된 서류, 보조저장매체 등을
                잠금장치가 있는 안전한 장소에 보관하고 있습니다.
              </li>
            </ul>
          </li>
          <li>
            임직원에게 이용자의 개인정보 보호에 대해 정기적인 교육을 실시하고
            있습니다.
            <ul>
              <li style={{ listStyleType: 'circle' }}>
                개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화
                하여 개인정보를 관리하는 대책을 시행하고 있습니다.
              </li>
            </ul>
          </li>
        </ul>

        <h1 className={'text-md'}>2. 개인정보의 수집 및 이용</h1>
        <p className={'text-regular'}>
          (1) 개인정보 수집 항목 및 목적 <br />
          회원 가입 시 또는 서비스 이용 과정에서 아래와 같은 서비스를 제공하기
          위하여 필요한 최소한의 개인 정보를 수집하고 있습니다.
        </p>
        <table>
          <thead>
            <tr>
              <th>구분</th>
              <th>수집목적</th>
              <th>수집항목</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>홈페이지 회원가입 및 관리</td>
              <td>회원 가입의사 확인, 회원제 서비스 제공</td>
              <td>
                이메일 주소, 성명, 전화번호, 회원의 회사 정보(상호,
                사업자등록번호), 카카오 가입시 해당 계정 정보
              </td>
            </tr>
            <tr>
              <td>본인인증</td>
              <td>본인인증 등 제한적 본인확인제 시행에 따른 본인확인</td>
              <td>이메일 주소, 성명, 전화번호</td>
            </tr>
            <tr>
              <td>서비스 제공</td>
              <td>서비스 제공을 위한 정보 수집</td>
              <td>
                회원의 회사 정보(상호, 사업자등록번호, 사업자 유형, 업종, 사업장
                주소, 사업장 대표자명), 각종 인증 정보(공동인증서 정보, 홈택스
                ID/PW, 여신금융협회 ID/PW, 커머스 ID/PW, 배달앱 ID/PW 등),
                신용카드 관련 정보(카드사 ID/PW, 카드번호, 카드소유자명 등)
              </td>
            </tr>
            <tr>
              <td>고객센터 이용시</td>
              <td>
                민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지,
                처리결과 통보 등
              </td>
              <td>문의 및 상담 내용에 포함된 개인정보</td>
            </tr>
            <tr>
              <td>마케팅 및 광고에 활용</td>
              <td>맞춤형 광고 제공 등</td>
              <td>이메일 주소, 성명, 전화번호</td>
            </tr>
          </tbody>
        </table>

        <p className={'text-regular'}>
          (2) 개인정보 수집 방법 <br />
          회사는 다음과 같은 방법을 통해 개인정보를 수집합니다.
        </p>

        <ul
          style={{
            listStyleType: 'disc',
          }}
        >
          <li>
            회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해
            동의를 하고 직접 정보를 입력하는 경우
          </li>
          <li>
            서비스 이용 또는 업무처리 등 과정에서 자동 생성 및 수집되는 경우
          </li>
          <li>
            회원가입 및 서비스 이용을 위하여 이용자가 회사에게 직접 개인정보
            조회 등에 대한 권한을 위임하면 회사가 직접 국세청, 카드사 인터넷뱅킹
            사이트 등을 조회하는 경우
          </li>
          <li>제휴 서비스 또는 단체로부터 개인정보를 제공받은 경우</li>
        </ul>

        <p className={'text-regular'}>
          (3) 개인정보 보유 및 이용기간 <br />
          회사는 이용자의 서비스 마지막 이용일 또는 회원 탈퇴 후 1년이 되는
          시점, 법령에 따른 보존 기간이 경과되는 시점 또는 개인정보의 수집ㆍ이용
          목적을 달성할 때까지
        </p>
        <h1 className={'text-md'}>3. 개인정보 제공</h1>
        <p className={'text-regular'}>
          (1) 제3자 제공 <br />
          회사는 원칙적으로 제3자에게 개인정보를 제공하지 않습니다. 다만, 법령에
          규정된 경우 또는 이용자의 편의를 위하는 경우 이용자에게 별도 동의를
          받아서 다음의 제3자에게 제공할 수 있습니다.
        </p>
        <table>
          <thead>
            <tr>
              <th>제공받는 자</th>
              <th>제공하는 개인정보 항목</th>
              <th>제공 목적</th>
              <th>보유기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>노무법인 정율</td>
              <td>
                회사명, 사업자등록번호, 한국표준산업분류코드, 대표자 성명,
                이메일, 휴대폰번호
              </td>
              <td>노무 지원금 조회/추천/업무 대행</td>
              <td>
                서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존
                기간
              </td>
            </tr>
          </tbody>
        </table>
        <p className={'text-regular'}>
          (2) 위탁업체 제공 <br />
          회사는 서비스 제공을 위해 필요한 범위 내에서 아래와 같이 개인정보 취급
          업무를 외부 전문업체에게 위탁하고 있습니다.
        </p>
        <table>
          <thead>
            <tr>
              <th>업체명</th>
              <th>위탁업무</th>
              <th>위탁기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Twilio Inc.</td>
              <td>본인 인증</td>
              <td>
                서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존
                기간
              </td>
            </tr>
            <tr>
              <td>주식회사 정율랩스</td>
              <td>회사의 서비스 제공을 위한 시스템/플랫폼 제공</td>
              <td>
                서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존
                기간
              </td>
            </tr>
            <tr>
              <td>Amazon Web Service Inc.</td>
              <td>
                회사 데이터 저장 및 관리 / 네트워크를 통하여 개인정보가 업무
                필요시에 전송
              </td>
              <td>
                서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존
                기간
              </td>
            </tr>
          </tbody>
        </table>

        <p className={'text-regular'}>
          회사는 서비스 제공의 안정성과 최신 기술을 이용자에게 제공하기 위해
          국외에 개인정보를 위탁하고 있으며, 이용자로부터 취득 또는 생성한
          개인정보를 AWS (Amazon Web Service Inc.)가 보유하고 있는
          데이터베이스에 저장합니다. AWS는 해당 서버의 물리적인 관리만을 행하고,
          이용자의 개인정보에 접근할 수 없습니다. 이용자는 회사의
          개인정보보호책임자 및 담당부서를 통해 개인정보의 국외 이전을 거부할 수
          있습니다. 이용자가 개인정보의 국외 이전을 거부하는 경우 회사는 해당
          이용자의 개인정보를 국외 이전 대상에서 제외합니다. 다만, 이 경우
          회사의 서비스 중 개인정보 국외 이전이 필수적으로 수반되는 서비스의
          이용이 제한될 수 있습니다.
        </p>

        <table>
          <thead>
            <tr>
              <th>업체명</th>
              <th>위탁목적 / 이전 일시 및 방법</th>
              <th>보유 기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Amazon Web Service Inc.</td>
              <td>
                회사 데이터 저장 및 관리 / 네트워크를 통하여 개인정보가 업무
                필요시에 전송
              </td>
              <td>
                서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존
                기간
              </td>
            </tr>
          </tbody>
        </table>
        <h1 className={'text-md'}>4. 개인정보 파기</h1>
        <p className={'text-regular'}>
          회사는 원칙적으로 개인정보 처리 목적이 달성된 경우에는 지체없이 해당
          개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
          <br />
          <br />
          (1) 파기 절차 <br />
          이용자가 입력한 정보는 내부 방침 및 기타 관련 법령에 따라 일정기간
          저장된 후 혹은 즉시 파기됩니다. <br />
          <br />
          (2) 파기 기한 <br />
          이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의
          종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의
          폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의
          처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를
          파기합니다. <br />
          <br />
          (3) 파기 방법 <br />
          전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
          사용합니다. 종이로 출력된 분쇄기로 분쇄하거나 소각을 통하여
          파기합니다.
        </p>
        <h1 className={'text-md'}>
          5. 개인정보 자동수집장치의 설치ㆍ운영 및 거부에 관한 사항
        </h1>
        <p className={'text-regular'}>
          회사는 이용자들의 정보를 수시로 저장하고 불러오는 ‘쿠키(cookie)’를
          운용하는 경우가 있습니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가
          이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터
          하드디스크에 저장됩니다. <br />
          <br />
          (1) 사용 목적 <br />
          이용자가 회사 웹사이트에 방문할 경우 웹사이트 서버는 이용자의
          디바이스에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을
          유지하고 맞춤화된 서비스를 제공하게 됩니다. 물론 이용자는 쿠키 설치에
          대한 선택권을 가지고 있습니다. 이용자는 웹브라우저에서 옵션을
          설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을
          거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. <br />
          <br />
          (2) 쿠키 설정 거부방법 <br />
          쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을
          선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
          거치거나, 모든 쿠키의 저장을 거부하는 방법이 있습니다. 단, 쿠키 설치를
          거부하였을 경우 로그인이 필요한 서비스 등 일부 서비스 이용에 어려움이
          있을 수 있습니다. 쿠키 저장 거부는 ‘웹 브라우저 상단의 도구 →
          인터넷옵션 → 개인정보 메뉴의 옵션 설정을 통해 거부할 수 있습니다.
        </p>
        <h1 className={'text-md'}>6. 기타 사항</h1>
        <p className={'text-regular'}>
          (1) 이용자의 권리 <br />
          이용자는 언제든지 자신의 개인정보를 조회하거나 수정할 수 있으며,
          수집・이용에 대한 동의 철회 또는 가입 해지를 요청할 수 있습니다.
          <br />
          <br />
          (2) 개인정보 수정 및 삭제 <br />
          이용자는 자신의 개인정보에 대한 수정 및 삭제를 요청할 수 있습니다.
          수정의 경우 회사는 수정 완료 전까지 해당 개인정보의 이용을 중지하며,
          삭제의 경우 요청 즉시 개인정보를 삭제합니다. 이러한 권리 행사는 회사에
          대해 개인정보 보호법 시행령에 따라 서면, 전자우편, 모사전송(FAX) 등을
          통해 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
          <br />
          이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는
          회사는 정정 또는 삭제를 완료할 때까지 개인정보를 이용하거나 제공하지
          않습니다.
          <br />
          회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의
          요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를
          확인합니다.
          <br />
          권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을
          통하여 하실 수 있습니다. 이 경우 개인정보보호법 시행규칙 별지 제11호
          서식에 따른 위임장을 제출하셔야 합니다.
          <br />
          개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제4항, 제37조
          제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
          <br />
          개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
          대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다. <br />
          <br />
          (3) 개인정보보호책임자
        </p>
        <ul
          style={{
            listStyleType: 'disc',
          }}
        >
          <li>책임자: 홍석구 세무사</li>
          <li>담당부서: 개인정보보호팀</li>
          <li>연락처: 02-2662-1026 / jungyul_tax@jungyul.co.kr</li>
        </ul>
        <p className={'text-regular'}>
          (4) 권익침해 구제방법 <br />
          회사의 서비스를 이용하면서 발생한 모든 개인정보 보호 관련 문의,
          불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로
          문의하실 수 있습니다. 정보주체의 문의에 대해 지체 없이 답변 및
          처리해드리겠습니다. <br />
          개인정보가 침해되어 이에 대한 신고나 상담이 필요한 경우 아래 기관에
          문의하여 도움을 받으실 수 있습니다. <br />
          <br />
          1) 개인정보침해 신고센터
        </p>

        <ul
          style={{
            listStyleType: 'disc',
          }}
        >
          <li>(국번없이)118</li>
          <li>https://privacy.kisa.or.kr</li>
        </ul>

        <p className={'text-regular'}> 2) 개인정보 분쟁조정위원회</p>

        <ul
          style={{
            listStyleType: 'disc',
          }}
        >
          <li>소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</li>
          <li>홈페이지 : www.kopico.go.kr</li>
          <li>전화 : (국번없이) 1833-6972</li>
          <li>
            주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 12층
          </li>
        </ul>

        <p className={'text-regular'}>3) 대검찰청 사이버수사과</p>

        <ul
          style={{
            listStyleType: 'disc',
          }}
        >
          <li>(국번없이)1301</li>
          <li>cid@spo.go.kr</li>
        </ul>

        <p className={'text-regular'}>4) 경찰청 사이버안전국</p>

        <ul
          style={{
            listStyleType: 'disc',
          }}
        >
          <li>(국번없이)182</li>
          <li>https://cyberbureau.police.go.kr</li>
        </ul>

        <h1 className={'text-md'}>7. 개인정보처리방침변경</h1>
        <p className={'text-regular'}>
          회사는 법률이나 서비스의 변경에 따라 개인정보처리방침을 개정할 수
          있습니다. 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는
          개별공지)을 통하여 공지할 것입니다. 변경된 개인정보처리방침은 게시한
          날로부터 7일 후부터 효력이 발생합니다. 다만, 수집하는 개인정보의 항목,
          이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생하는
          경우에는 최소 30일 전에 미리 알려드리겠습니다.
        </p>
        <ul
          style={{
            listStyleType: 'disc',
          }}
        >
          <li>공고일자: 2024년 06월 03일</li>
          <li>시행일자: 2024년 06월 03일</li>
        </ul>

        <p className={'text-regular'}>
          이 개인정보처리방침은 2024년 06월 03일부터 적용됩니다.
        </p>
      </div>
    </Container>
  );
};

export default PersonalTerms;
