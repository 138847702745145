import { FC } from 'react';

interface CalculatorIconProps {
  fill: string;
  size: string;
}

const CalculatorIcon: FC<CalculatorIconProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon / calculator">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 3C4.44772 3 4 3.44772 4 4V19.5C4 20.3021 4.67681 21 5.57143 21H18.4286C19.3232 21 20 20.3021 20 19.5V4C20 3.44772 19.5523 3 19 3H5ZM2 4C2 2.34315 3.34315 1 5 1H19C20.6569 1 22 2.34315 22 4V19.5C22 21.4594 20.3743 23 18.4286 23H5.57143C3.62573 23 2 21.4594 2 19.5V4Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 6.25C6 5.55964 6.55964 5 7.25 5H16.75C17.4404 5 18 5.55965 18 6.25V8.75C18 9.44036 17.4404 10 16.75 10H7.25C6.55964 10 6 9.44035 6 8.75V6.25ZM8 7V8H16V7H8Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 13.5C6 12.6716 6.67157 12 7.5 12C8.32843 12 9 12.6716 9 13.5C9 14.3284 8.32843 15 7.5 15C6.67157 15 6 14.3284 6 13.5Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 13.5C10.5 12.6716 11.1716 12 12 12C12.8284 12 13.5 12.6716 13.5 13.5C13.5 14.3284 12.8284 15 12 15C11.1716 15 10.5 14.3284 10.5 13.5Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 13.5C15 12.6716 15.6716 12 16.5 12C17.3284 12 18 12.6716 18 13.5C18 14.3284 17.3284 15 16.5 15C15.6716 15 15 14.3284 15 13.5Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 17.5C6 16.6716 6.67157 16 7.5 16C8.32843 16 9 16.6716 9 17.5C9 18.3284 8.32843 19 7.5 19C6.67157 19 6 18.3284 6 17.5Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 17.5C10.5 16.6716 11.1716 16 12 16C12.8284 16 13.5 16.6716 13.5 17.5C13.5 18.3284 12.8284 19 12 19C11.1716 19 10.5 18.3284 10.5 17.5Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 17.5C15 16.6716 15.6716 16 16.5 16C17.3284 16 18 16.6716 18 17.5C18 18.3284 17.3284 19 16.5 19C15.6716 19 15 18.3284 15 17.5Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};

export default CalculatorIcon;
