import AdminSideBar from '@src/components/AdminSideBar.tsx';
import Container from '@src/layouts/Container.tsx';
import CertificatesDetailAdminContent from '@src/pages/admin/certificates/CertificatesDetailAdminContent.tsx';

const CertificatesDetailAdmin = () => {
  return (
    <Container sideBar={<AdminSideBar />}>
      <CertificatesDetailAdminContent />
    </Container>
  );
};

export default CertificatesDetailAdmin;
