import React from 'react';
import { COLORS } from '@src/util/constants.ts';
import IconSVG from '@src/components/IconSVG.tsx';

interface CloseIconProps {
  onClick: () => void;
}

const CloseIcon: React.FC<CloseIconProps> = ({ onClick }) => {
  return (
    <IconSVG
      fill={COLORS['main']}
      size={'24'}
      iconSVG={'close'}
      containerStyle={{ cursor: 'pointer' }}
      onClick={onClick}
    />
  );
};

export default CloseIcon;
