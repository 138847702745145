import React from 'react';
import ChevronButton from './ChevronButton.tsx';

interface ListItemProps {
  name: string;
  value: string | number;
  onClick?: () => void;
  style?: React.CSSProperties;
  divider?: boolean;
  nameIcon?: React.ReactNode;
}

const ListItem: React.FC<ListItemProps> = ({
  name,
  value,
  onClick,
  style,
  divider = false,
  nameIcon,
}) => {
  let customClassName = 'list-item';
  if (divider) {
    customClassName += ' divider';
  }

  if (typeof value === 'number') {
    value = `${value.toLocaleString()}원`;
  }

  const renderName = () => {
    if (nameIcon) {
      return (
        <div className="icon-name-container">
          {nameIcon}
          <p className="text-regular">{name}</p>
        </div>
      );
    } else {
      return <p className="text-regular">{name}</p>;
    }
  };

  return (
    <div className={customClassName} style={style}>
      {renderName()}
      <div className="list-item-detail">
        {value}
        {onClick && <ChevronButton direction="right" onClick={onClick} />}
      </div>
    </div>
  );
};

export default ListItem;
