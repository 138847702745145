import { FC } from 'react';

interface SchoolIconProps {
  fill: string;
  size: string;
}

const SchoolIcon: FC<SchoolIconProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon / school">
        <path
          id="Vector"
          d="M6.0502 17.775C5.71686 17.5917 5.45853 17.3458 5.2752 17.0375C5.09186 16.7292 5.0002 16.3833 5.0002 16V11.2L2.6002 9.87499C2.41686 9.77499 2.28353 9.64999 2.2002 9.49999C2.11686 9.34999 2.0752 9.18333 2.0752 8.99999C2.0752 8.81666 2.11686 8.64999 2.2002 8.49999C2.28353 8.34999 2.41686 8.22499 2.6002 8.12499L11.0502 3.52499C11.2002 3.44166 11.3544 3.37916 11.5127 3.33749C11.671 3.29583 11.8335 3.27499 12.0002 3.27499C12.1669 3.27499 12.3294 3.29583 12.4877 3.33749C12.646 3.37916 12.8002 3.44166 12.9502 3.52499L22.4752 8.72499C22.6419 8.80833 22.771 8.92916 22.8627 9.08749C22.9544 9.24583 23.0002 9.41666 23.0002 9.59999V16C23.0002 16.2833 22.9044 16.5208 22.7127 16.7125C22.521 16.9042 22.2835 17 22.0002 17C21.7169 17 21.4794 16.9042 21.2877 16.7125C21.096 16.5208 21.0002 16.2833 21.0002 16V10.1L19.0002 11.2V16C19.0002 16.3833 18.9085 16.7292 18.7252 17.0375C18.5419 17.3458 18.2835 17.5917 17.9502 17.775L12.9502 20.475C12.8002 20.5583 12.646 20.6208 12.4877 20.6625C12.3294 20.7042 12.1669 20.725 12.0002 20.725C11.8335 20.725 11.671 20.7042 11.5127 20.6625C11.3544 20.6208 11.2002 20.5583 11.0502 20.475L6.0502 17.775ZM12.0002 12.7L18.8502 8.99999L12.0002 5.29999L5.1502 8.99999L12.0002 12.7ZM12.0002 18.725L17.0002 16.025V12.25L12.9752 14.475C12.8252 14.5583 12.6669 14.6208 12.5002 14.6625C12.3335 14.7042 12.1669 14.725 12.0002 14.725C11.8335 14.725 11.6669 14.7042 11.5002 14.6625C11.3335 14.6208 11.1752 14.5583 11.0252 14.475L7.0002 12.25V16.025L12.0002 18.725Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default SchoolIcon;
