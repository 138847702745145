import React from 'react';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';

interface AdminRouteProps {
  children?: React.ReactNode;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ children }) => {
  const hasToken =
    Cookies.get('accessTokenAdmin') || Cookies.get('refreshTokenAdmin');

  if (!hasToken) {
    return <Navigate to={'/admin/login'} replace />;
  }

  return children;
};

export default AdminRoute;
