import React from 'react';
import attachmentIcon from '/src/assets/icons/attachment.svg';
import Icon from './Icon.tsx';
import TailSpinner from '@src/components/TailSpinner.tsx';

interface FileInputProps {
  style?: React.CSSProperties;
  onFilesSelected?: (files: FileList | null) => void;
  iconDisplay?: boolean;
  text?: string;
  textStyle?: React.CSSProperties;
  isLoading?: boolean;
}

const FileInput: React.FC<FileInputProps> = ({
  style,
  onFilesSelected,
  iconDisplay,
  text,
  textStyle,
  isLoading,
}) => {
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (onFilesSelected) {
      onFilesSelected(event.target.files);
    }
  };

  return (
    <label className={'file-input-default'} style={style}>
      <input
        type="file"
        multiple={true}
        onChange={handleFileChange}
        disabled={isLoading}
      />
      {iconDisplay && (
        <div className="file-input-icon-container">
          <Icon iconSrc={attachmentIcon} />
        </div>
      )}
      {isLoading ? (
        <TailSpinner type={'button-primary-color'} />
      ) : (
        text && <p style={textStyle}>{text}</p>
      )}
    </label>
  );
};

export default FileInput;
