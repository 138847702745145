import React from 'react';
import chevronRight from '../assets/icons/chevron_right_black.svg';

interface CardMenuProps {
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const CardMenu: React.FC<CardMenuProps> = ({ text, onClick }) => {
  return (
    <button className="card-menu-default pointer" onClick={onClick}>
      <p>{text}</p>
      <img src={chevronRight} alt={'>'} />
    </button>
  );
};

export default CardMenu;
