import React, { useRef, useState } from 'react';
import InputLabel from '@src/components/InputLabel.tsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import RadioButton from '@src/components/RadioButton.tsx';
import {
  API_URLS,
  etxivClCdOptions,
  prhSlsClCdOptions,
} from '@src/util/constants.ts';
import ReactDatePicker from '@src/components/DatePicker.tsx';
import { ko } from 'date-fns/locale/ko';
import Button from '@src/components/Button.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import moment from 'moment/moment';
import { useMutation } from '@tanstack/react-query';
import {
  IApiError,
  ITaxInvoiceIssued,
  ITaxInvoiceIssuedResponse,
} from '@src/util/interfaces.ts';
import { AxiosRequestConfig } from 'axios';
import api from '@src/util/api.ts';
import { useUserStore } from '@src/util/store.ts';
import DatePicker from 'react-datepicker';
import calendarMonth from '/src/assets/icons/calendar_month.svg';
import Table from '@src/components/Table.tsx';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

interface TaxInvoiceIssuedProps {}

const TaxInvoiceIssuedForm = z
  .object({
    etxivClCd: z.enum(['01', '03']), //전자세금계산서 - 01, 전자계산서 - 03
    prhSlsClCd: z.enum(['01', '02']), //매출 - 01, 매입 - 02
    start_date: z.date(), //YYYYMMDD
    end_date: z.date(), //YYYYMMDD
  })
  .refine(
    (data) => {
      const start = moment(data.start_date, 'YYYYMMDD');
      const end = moment(data.end_date, 'YYYYMMDD');
      return start.isSameOrBefore(end); // Validation: start_date < end_date
    },
    {
      message: '시작일자는 종료일자보다 이전이어야 합니다.',
      path: ['end_date'],
    },
  )
  .refine(
    (data) => {
      const start = moment(data.start_date);
      const end = moment(data.end_date);
      const diffInMonths = end.diff(start, 'months', true); // Allow fractional months
      return diffInMonths <= 3;
    },
    {
      message: '조회 기간은(는) 3개월 이내의 날짜를 입력하세요.',
      path: ['end_date'],
    },
  );

const TaxInvoiceIssuedFormServer = z.object({
  etxivClCd: z.enum(['01', '03']), //전자세금계산서 - 01, 전자계산서 - 03
  prhSlsClCd: z.enum(['01', '02']), //매출 - 01, 매입 - 02
  start_date: z.string(),
  end_date: z.string(),
});

type TTaxInvoiceIssuedForm = z.infer<typeof TaxInvoiceIssuedForm>;
type TTaxInvoiceIssuedFormServer = z.infer<typeof TaxInvoiceIssuedFormServer>;

const TaxInvoiceIssued: React.FC<TaxInvoiceIssuedProps> = () => {
  const [user] = useUserStore((state) => [state.user]);
  const company_id = user?.selectedCompany?.id;
  const datePickerStartDateRef = useRef<DatePicker>(null);
  const datePickerEndDateRef = useRef<DatePicker>(null);

  const [data, setData] = useState<ITaxInvoiceIssued[] | null>(null);

  const columnHelper = createColumnHelper<ITaxInvoiceIssued>();
  /**
   *   wrtDt: string; //작성일자(YYYY-MM-DD)
   *   isnDtm: string; //발급일자(YYYY-MM-DD)
   *   dmnrTxprDscmNo: string; //공급받는자등록번호(종사업장 번호)
   *   tnmNm: string; //상호
   *   rprsFnm: string; //대표자명
   *   lsatNm: string; //품목명
   *   totaAmt: number; //합계금액
   *   sumSplCft: number; //공급가액
   *   sumTxamt: number; //세액
   *   etxivKndCd: string; //전자세금계산서 종류
   */
  const columns: Array<ColumnDef<ITaxInvoiceIssued, string>> = [
    columnHelper.accessor('wrtDt', {
      id: 'wrtDt',
      header: () => <div>작성일자</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '80px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {info.getValue()}
        </i>
      ),
    }),
    columnHelper.accessor('isnDtm', {
      id: 'isnDtm',
      header: () => <div>발급일자</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '80px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {info.getValue()}
        </i>
      ),
    }),
    columnHelper.accessor('dmnrTxprDscmNo', {
      id: 'dmnrTxprDscmNo',
      header: () => <div>공급받는자등록번호(종사업장 번호)</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '80px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {info.getValue()}
        </i>
      ),
    }),
    columnHelper.accessor('tnmNm', {
      id: 'tnmNm',
      header: () => <div>상호</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '80px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {info.getValue()}
        </i>
      ),
    }),
    columnHelper.accessor('rprsFnm', {
      id: 'rprsFnm',
      header: () => <div>대표자명</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '80px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {info.getValue()}
        </i>
      ),
    }),
    columnHelper.accessor('lsatNm', {
      id: 'lsatNm',
      header: () => <div>품목명</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '80px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {info.getValue()}
        </i>
      ),
    }),
    columnHelper.accessor('totaAmtStr', {
      id: 'totaAmtStr',
      header: () => <div>합계금액</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '80px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {info.getValue()}
        </i>
      ),
    }),
    columnHelper.accessor('sumSplCftStr', {
      id: 'sumSplCftStr',
      header: () => <div>공급가액</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '80px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {info.getValue()}
        </i>
      ),
    }),
    columnHelper.accessor('sumTxamtStr', {
      id: 'sumTxamtStr',
      header: () => <div>세액</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '80px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {info.getValue()}
        </i>
      ),
    }),
    columnHelper.accessor('etxivKndCd', {
      id: 'etxivKndCd',
      header: () => <div>전자세금계산서 종류</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '80px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {info.getValue()}
        </i>
      ),
    }),
  ];

  const {
    control: controlIssued,
    setValue: setValueIssued,
    handleSubmit: handleSubmitIssued,
    formState: { errors },
  } = useForm<TTaxInvoiceIssuedForm>({
    resolver: zodResolver(TaxInvoiceIssuedForm),
    defaultValues: {
      etxivClCd: '01',
      prhSlsClCd: '01',
      start_date: new Date(),
      end_date: new Date(),
    },
  });

  const { mutate: getTaxInvoiceIssued, isPending: isPendingTaxInvoiceIssued } =
    useMutation<
      ITaxInvoiceIssuedResponse,
      IApiError,
      TTaxInvoiceIssuedFormServer
    >({
      mutationFn: (formData) => {
        const axiosConfig: AxiosRequestConfig = {
          params: {
            ...formData,
            pageSize: 100,
            pageNum: 1,
          },
        };
        return api.get(
          API_URLS.TAX_INVOICE_ISSUED + `/${company_id}`,
          axiosConfig,
        );
      },
    });

  const onSubmitIssued: SubmitHandler<TTaxInvoiceIssuedForm> = (data) => {
    const formData: TTaxInvoiceIssuedFormServer = {
      etxivClCd: data.etxivClCd,
      prhSlsClCd: data.prhSlsClCd,
      start_date: moment(data.start_date).format('YYYYMMDD'),
      end_date: moment(data.end_date).format('YYYYMMDD'),
    };

    getTaxInvoiceIssued(formData, {
      onSuccess: (response) => {
        setData(response.data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const renderResponse = () => {
    if (data === null) {
      return;
    } else {
      if (data.length === 0) {
        return (
          <p className={'text-regular'} style={{ textAlign: 'center' }}>
            조회되는 발급목록이 없습니다.
          </p>
        );
      } else {
        return (
          <div style={{ overflowX: 'auto' }}>
            <Table<ITaxInvoiceIssued> data={data} columns={columns} />
          </div>
        );
      }
    }
  };

  return (
    <form
      className={'tax-invoice-container-wrapper'}
      style={{
        maxWidth: '1190px',
        width: '100%',
        alignSelf: 'center',
        alignItems: 'flex-start',
      }}
      onSubmit={handleSubmitIssued(onSubmitIssued)}
    >
      <div className={'tax-invoice-container'}>
        <div className={'tax-invoice-content'} style={{ marginTop: '24px' }}>
          <InputLabel text={'분류'} required>
            <Controller
              name={'etxivClCd'}
              control={controlIssued}
              render={({ field: { onChange, value } }) => (
                <RadioButton
                  name={'etxivClCd'}
                  options={etxivClCdOptions}
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
          </InputLabel>
          <InputLabel text={'구분'} required>
            <Controller
              name={'prhSlsClCd'}
              control={controlIssued}
              render={({ field: { onChange, value } }) => (
                <RadioButton
                  name={'prhSlsClCd'}
                  options={prhSlsClCdOptions}
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
          </InputLabel>
          <InputLabel
            text={'조회기간(작성일자)'}
            required
            error={
              errors.end_date && (
                <p className={'error-message'}>{errors.end_date.message}</p>
              )
            }
          >
            <div className={'tax-invoice-issued-date-container'}>
              <Controller
                control={controlIssued}
                name={'start_date'}
                render={({ field: { value } }) => (
                  <div
                    className={'flex-row'}
                    style={{
                      gap: '4px',
                      flex: 1,
                    }}
                  >
                    <ReactDatePicker
                      value={value}
                      onChange={(date) =>
                        setValueIssued('start_date', date, {
                          shouldValidate: true,
                        })
                      }
                      className={'react-calendar'}
                      locale={ko}
                      ref={datePickerStartDateRef}
                    />
                    <button
                      className={'tax-invoice-black-btn'}
                      style={{
                        width: '44px',
                        height: '44px',
                      }}
                      type={'button'}
                      onClick={() =>
                        datePickerStartDateRef?.current?.setOpen(true)
                      }
                    >
                      <img src={calendarMonth} alt={'+'} />
                    </button>
                  </div>
                )}
              />
              <span style={{ margin: '0 10px' }}>~</span>
              <Controller
                control={controlIssued}
                name={'end_date'}
                render={({ field: { value } }) => (
                  <div
                    className={'flex-row'}
                    style={{
                      gap: '4px',
                      flex: 1,
                    }}
                  >
                    <ReactDatePicker
                      value={value}
                      onChange={(date) =>
                        setValueIssued('end_date', date, {
                          shouldValidate: true,
                        })
                      }
                      className={'react-calendar'}
                      locale={ko}
                      ref={datePickerEndDateRef}
                    />
                    <button
                      className={'tax-invoice-black-btn'}
                      style={{
                        width: '44px',
                        height: '44px',
                      }}
                      type={'button'}
                      onClick={() =>
                        datePickerEndDateRef?.current?.setOpen(true)
                      }
                    >
                      <img src={calendarMonth} alt={'+'} />
                    </button>
                  </div>
                )}
              />
            </div>
          </InputLabel>
          <Button
            text={'조회하기'}
            isLoading={isPendingTaxInvoiceIssued}
            type={'submit'}
          />
          {renderResponse()}
        </div>
      </div>
    </form>
  );
};

export default TaxInvoiceIssued;
