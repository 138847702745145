import React, { useEffect, useState } from 'react';

type ProgressBarProps = {
  base: number;
  current: number;
  color: 'primary' | 'blue' | 'red' | 'purple' | 'green' | 'gray4';
  textShow?: boolean;
  containerStyle?: React.CSSProperties;
  progressBarStyle?: React.CSSProperties;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  base,
  current,
  color,
  textShow = true,
  containerStyle,
  progressBarStyle,
}) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    let currentPercentage = (current / base) * 100;
    if (base === 0) {
      if (current !== 0) {
        currentPercentage = 100;
      } else {
        currentPercentage = 0;
      }
    }
    setPercentage(currentPercentage);
  }, [current, base]);

  return (
    <div className={`progress-bar-container ${color}`} style={containerStyle}>
      <div
        className={`progress-bar ${color}`}
        style={{
          width: `${percentage > 100 ? 100 : percentage}%`,
          ...progressBarStyle,
        }}
      >
        {textShow && (
          <span
            className="text-lg"
            style={{ color: 'white', marginLeft: '16px' }}
          >{`${Math.round(percentage)}%`}</span>
        )}
        {percentage > 100 && (
          <div
            className="dotted-line"
            style={{ left: `${(100 / percentage) * 100}%` }}
          />
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
