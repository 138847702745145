import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import { useAdminStore } from '@src/util/adminStore.ts';
import { API_URL } from './constants.ts';

const apiAdmin: AxiosInstance = axios.create({
  baseURL: API_URL,
});

apiAdmin.interceptors.request.use(async (request) => {
  if (!request.url?.startsWith('/v1/auth/')) {
    const token = Cookies.get('accessTokenAdmin');
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
  }
  return request;
});

export const refreshToken = async () => {
  const accessToken = Cookies.get('accessTokenAdmin');
  const refreshToken = Cookies.get('refreshTokenAdmin');

  const response = await apiAdmin.post('/v1/auth/admin/login/token', {
    accessToken,
    refreshToken,
  });

  const newAccessToken = response.data.accessToken;
  const newRefreshToken = response.data.refreshToken;

  if (Cookies.get('autoLoginAdmin') === 'N') {
    Cookies.set('accessTokenAdmin', newAccessToken);
    Cookies.set('refreshTokenAdmin', newRefreshToken);
  } else {
    Cookies.set('accessTokenAdmin', newAccessToken, { expires: 7 });
    Cookies.set('refreshTokenAdmin', newRefreshToken, { expires: 7 });
  }

  useAdminStore.setState({
    admin: {
      ...response.data.user,
    },
  });

  return response.data.accessToken;
};

apiAdmin.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newAccessToken = await refreshToken();
      originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
      return apiAdmin(originalRequest);
    }

    return Promise.reject(error);
  },
);

export default apiAdmin;
