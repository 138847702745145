import '@src/scss/main.scss';
import logoImg from '/src/assets/icons/logo-final.png';
import descriptionImg from '/src/assets/images/description-img.png';
import Button from '@src/components/Button.tsx';
import DividerOr from '@src/components/DividerOr.tsx';
import KakaoButton from '@src/components/KakaoButton.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { Suspense, useEffect, useState } from 'react';
import { loadScript } from '@src/util/functions';
import Cookies from 'js-cookie';
import { useMutation } from '@tanstack/react-query';
import api from '@src/util/api';
import { API_URLS, COLORS } from '@src/util/constants';
import { IApiError } from '@src/util/interfaces';
import TailSpinner from '@src/components/TailSpinner';
import { IUser, useUserStore } from '@src/util/store';
import Modal from '@src/components/Modal';

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const redirect_url = import.meta.env.VITE_KAKAO_LOGIN_REDIRECT_URL;
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const [user, setUser] = useUserStore((state) => [state.user, state.setUser]);

  const [errorModal, setErrorModal] = useState('');
  const [errorModalTitle, setErrorModalTitle] = useState('');

  const { mutate: kakaoLogin } = useMutation({
    mutationFn: (data: { code: string }) =>
      api.post(API_URLS.USERS + `/login/kakao`, { ...data, redirect_url }),
    onSuccess: (response) => {
      const { status, data } = response.data;
      if (status == 'LOGIN' || status == 'REGISTER') {
        //TODO 사실상 다 여기로 들어오기 때문에 변경 필요
        const { accessToken, refreshToken, user: userResponse } = data;

        const user: IUser = {
          ...userResponse,
          selectedCompany:
            userResponse?.companies?.length === 0
              ? null
              : userResponse?.companies?.[0],
        };

        Cookies.set('accessToken', accessToken, { expires: 7 });
        Cookies.set('refreshToken', refreshToken, { expires: 7 });
        if (!user?.current_manager) {
          user.current_manager = undefined;
        }
        setUser(user);

        if (user?.companies?.length === 0) {
          navigate('/register/company', { replace: true });
        } else if (user?.role?.reports) {
          navigate('/reports', { replace: true });
        } else {
          navigate('/menu', { replace: true });
        }
      }
    },
    onError: (e: IApiError) => {
      setErrorModal(e.response.data.message);
      setErrorModalTitle(e.response.data.title);
    },
  });

  useEffect(() => {
    loadScript(
      'https://t1.kakaocdn.net/kakao_js_sdk/2.6.0/kakao.min.js',
      'sha384-6MFdIr0zOira1CHQkedUqJVql0YtcZA1P0nbPrQYJXVJZUkTk/oX4U9GhUIs3/z8',
      'anonymous',
      () => {
        if (window.Kakao && !window.Kakao.isInitialized()) {
          window.Kakao.init(import.meta.env.VITE_KAKAO_JS_KEY);
        }
      },
    );

    if (code) {
      kakaoLogin({ code });
    } else {
      const accessToken = Cookies.get('accessToken');
      if (accessToken) {
        if (user?.role?.reports) {
          navigate('/reports', { replace: true });
        } else {
          navigate('/menu', { replace: true });
        }
      }
    }
  }, []);

  return (
    <Suspense fallback={<TailSpinner type={'full-page'} />}>
      <div className="login-container">
        <div className="body-container">
          <img
            className="login-logo-img"
            src={logoImg}
            alt="jungyul"
            style={{ width: '110px' }}
          />
          <p className="login-title">
            <span className={'login-title-highlight'}>1초</span>만에 받아보는
            <br />
            <span className={'login-title-highlight'}>실시간 세무 리포트</span>
            {/*24시간 잠들지 않는 <br />*/}
            {/*<span className={'login-title-highlight'}>촘촘한</span> 세무 서비스*/}
          </p>
          <div
            style={{
              backgroundColor: COLORS['bg'],
              width: '320px',
              height: '320px',
              alignSelf: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '160px',
            }}
          >
            <img
              src={descriptionImg}
              style={{ width: '350px', marginTop: '10px' }}
              alt={'description'}
            />
          </div>
          <div className="login-buttons-container">
            <Button
              text="이메일로 시작하기"
              onClick={() => {
                navigate('/login/email');
              }}
            />
            <DividerOr />
            <KakaoButton
              label="카카오로 시작하기"
              redirect_url={redirect_url}
            />
          </div>
          <div className="login-footer">
            <p className={'text-sm'}>정율 세무회계</p>
            <p className={'text-xs'} style={{ color: COLORS['gray3'] }}>
              대표자: 홍석구 | 개인정보보호책임자: 홍석구 | 사업자등록번호:
              583-45-00340
              <br />
              통신판매업신고번호 : 2024-서울강남-03010 | 주소: 서울시 강남구
              논현로 556, 3층
              <br />
              <br />
              <span style={{ fontWeight: '800', color: COLORS['gray4'] }}>
                [고객센터]
              </span>
              <br />
              대표번호: 02-2662-1026 | 이메일: jungyul_tax@jungyul.co.kr
              <br />
              운영시간: (평일) 09:00 - 18:00 (점심) 12:00 - 13:00 | 주말•공휴일
              휴무
              <br />
              Copyright © Jungyul Tax Consulting. All rights reserved.
              <br />
              <br />
            </p>
            <div className={'flex-row'} style={{ gap: '10px' }}>
              <button
                className={'text-xs'}
                style={{
                  color: COLORS['gray4'],
                  fontWeight: '800',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => {
                  navigate('/terms/service');
                }}
              >
                이용약관
              </button>
              <button
                className={'text-xs'}
                style={{
                  color: COLORS['gray4'],
                  fontWeight: '800',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => {
                  navigate('/terms/personal');
                }}
              >
                개인정보 처리방침
              </button>
            </div>
          </div>
        </div>
        <Modal
          isOpen={errorModal != ''}
          onOk={() => {
            setErrorModal('');
          }}
          title={errorModalTitle}
          content={errorModal}
          okButton={'확인'}
          onClose={() => {
            setErrorModal('');
          }}
        />
      </div>
    </Suspense>
  );
};

export default Login;
