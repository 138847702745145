import React, { useState } from 'react';
import Container from '@src/layouts/Container';
import DetailHeader from '@src/components/DetailHeader.tsx';
import InputLabel from '@src/components/InputLabel.tsx';
import Input from '@src/components/Input.tsx';
import TextArea from '@src/components/TextArea.tsx';
import FileInput from '@src/components/FileInput.tsx';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUserStore } from '@src/util/store.ts';
import AttachedFile from '@src/components/AttachedFile.tsx';
import api from '@src/util/api.ts';
import { API_URLS } from '@src/util/constants.ts';
import {
  IApiError,
  IApiPOSTSuccess,
  ITaskAttachment,
  ITaskAttachmentResponse,
} from '@src/util/interfaces.ts';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import Modal from '@src/components/Modal.tsx';
import SideBar from '@src/components/SideBar.tsx';
import { useIsMobile } from '@src/util/hooks.ts';
import Header from '@src/components/Header.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import BottomButtonGroup from '@src/components/BottomButtonGroup.tsx';
import Button from '@src/components/Button.tsx';

interface TaskRequestProps {}

const TaskRequestForm = z.object({
  title: z
    .string()
    .trim()
    .refine((val) => val.length > 0, {
      message: '제목을 입력해 주세요.',
    }),
  description: z.string().refine((val) => val.length > 0, {
    message: '내용을 입력해 주세요.',
  }),
});

const TaskRequestFormServer = TaskRequestForm.extend({
  attachments: z.array(
    z.object({
      url: z.string(),
      filename: z.string(),
      size: z.number(),
    }),
  ),
  company_id: z.number(),
  branch: z.string(),
});

type TTaskRequestForm = z.infer<typeof TaskRequestForm>;
type TTaskRequestFormServer = z.infer<typeof TaskRequestFormServer>;

const TaskRequest: React.FC<TaskRequestProps> = () => {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const [selectedFilesFromServer, setSelectedFilesFromServer] = useState<
    {
      filename: string;
      size: number;
      url: string;
    }[]
  >([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<TTaskRequestForm>({
    resolver: zodResolver(TaskRequestForm),
    mode: 'onSubmit',
  });

  const handleFilesSelected = (files: FileList | null) => {
    if (files) {
      uploadFiles(files).then((data) => {
        setSelectedFilesFromServer(data);
      });
    }
  };
  const uploadFiles = async (files: FileList): Promise<ITaskAttachment[]> => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      const axiosConfig: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      api
        .post(
          API_URLS.UPLOAD_TASK_ATTACHMENT + `/${user?.selectedCompany?.id}`,
          formData,
          axiosConfig,
        )
        .then((res: AxiosResponse<ITaskAttachmentResponse>) => {
          if (res.data && Array.isArray(res.data)) {
            resolve(res.data);
          }
        })
        .catch((err: AxiosResponse<IApiError>) => {
          reject(err);
        });
    });
  };
  const { mutate } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TTaskRequestFormServer
  >({
    mutationFn: (formData) => {
      return api.post(API_URLS.TASK, formData);
    },
  });

  const onSubmit: SubmitHandler<TTaskRequestForm> = (data) => {
    const formData = {
      company_id: user?.selectedCompany?.id ?? 0,
      title: data.title,
      description: data.description,
      attachments: selectedFilesFromServer,
      branch: user?.selectedCompany?.admin?.branch ?? '',
    };

    mutate(formData, {
      onSuccess: () => {
        setModalOpen(true);
      },
    });
  };

  return (
    <Container
      header={
        isMobile ? (
          <DetailHeader title={'업무 요청'} />
        ) : (
          <Header setOpen={setOpen} />
        )
      }
      sideBar={<SideBar />}
      modal={
        <>
          <CompanyDrawer open={open} setOpen={setOpen} />
          <Modal
            isOpen={isModalOpen}
            onOk={() => {
              setModalOpen(false);
              navigate(-1);
            }}
            title={'업무 요청완료'}
            content={'업무 요청이 완료되었습니다.'}
            okButton={'확인'}
            onClose={() => {
              setModalOpen(false);
            }}
          />
        </>
      }
    >
      <form
        className={'task-request-wrapper-container'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={'task-request-container'}>
          <InputLabel text={'제목'}>
            <Input
              placeholder={'제목을 입력하세요.'}
              register={register('title')}
            />
            {errors.title && (
              <div className="error-message">
                {errors.title?.message?.toString()}
              </div>
            )}
          </InputLabel>
          <InputLabel text={'내용 입력'}>
            <TextArea
              placeholder={'내용을 입력하세요.'}
              register={register('description')}
            />
            {errors.description && (
              <div className="error-message">
                {errors.description?.message?.toString()}
              </div>
            )}
          </InputLabel>
          <InputLabel text={`첨부 파일 (${selectedFilesFromServer.length}/20)`}>
            {selectedFilesFromServer.length === 0 ? (
              <>
                <FileInput
                  onFilesSelected={handleFilesSelected}
                  iconDisplay={true}
                />
                <p
                  className={'error-message'}
                  style={{ marginTop: '0', fontSize: '10px' }}
                >
                  pdf, jpg, xlsx, docx, hwpx 첨부 가능 (최대 20개, 총 용량
                  100mb)
                </p>
              </>
            ) : (
              <>
                {selectedFilesFromServer.map((file, i) => {
                  return (
                    <AttachedFile
                      filename={file.filename}
                      size={file.size}
                      key={i}
                    />
                  );
                })}
              </>
            )}
          </InputLabel>
        </div>
        <BottomButtonGroup
          left={
            <Button
              text={'취소'}
              type={'button'}
              onClick={() => {
                navigate(-1);
              }}
            />
          }
          right={<Button text={'확인'} type={'submit'} />}
        />
      </form>
    </Container>
  );
};

export default TaskRequest;
