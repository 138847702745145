import Select, { OptionValue } from '@src/components/Select.tsx';
import { useBranch } from '@src/util/adminHooks.ts';
import { IBranch } from '@src/util/interfaces.ts';

interface BranchFilterSelectProps {
  branchFilter: OptionValue | null;
  setBranchFilter: (value: OptionValue | null) => void;
}

const BranchFilterSelect: React.FC<BranchFilterSelectProps> = ({
  branchFilter,
  setBranchFilter,
}) => {
  const { data: branchData } = useBranch();

  const generateOptions = (branchData: IBranch[]) => {
    const options: OptionValue[] = [
      {
        label: '전체',
        value: '전체',
      },
    ];

    const generatedOption: OptionValue[] = branchData.map((item) => ({
      label: item.branch,
      value: item.admin_id.toString(),
    }));

    return options.concat(generatedOption);
  };

  return (
    <div style={{ minWidth: '60px' }}>
      <Select
        name={'filterBranch'}
        options={branchData ? generateOptions(branchData) : []}
        onChange={(selectedOption) => {
          setBranchFilter(selectedOption);
        }}
        value={branchFilter}
        size={'lg'}
        label={'소속지점'}
      />
    </div>
  );
};

export default BranchFilterSelect;
