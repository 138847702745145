import React, { Suspense, useState } from 'react';
import { useAdminSidebarStore } from '@src/util/adminStore.ts';
import TailSpinner from '@src/components/TailSpinner.tsx';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorModal from '@src/components/ErrorModal.tsx';

interface ContainerProps {
  headerGroupMaxWidth?: number;
  header?: React.ReactNode;
  headerTab?: React.ReactNode;
  footer?: React.ReactNode;
  sideBar?: React.ReactNode;
  children: React.ReactNode;
  modal?: React.ReactNode;
  style?: React.CSSProperties;
}

const Container: React.FC<ContainerProps> = ({
  headerGroupMaxWidth,
  header,
  headerTab,
  footer,
  sideBar,
  children,
  modal,
  style,
}) => {
  const { collapsed } = useAdminSidebarStore();
  const [isAdmin] = useState<boolean>(location.pathname.startsWith('/admin'));
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  return (
    <ErrorBoundary
      fallback={
        <ErrorModal
          errorModalOpen={errorModalOpen}
          setErrorModalOpen={setErrorModalOpen}
        />
      }
      onError={() => {
        setErrorModalOpen(true);
      }}
    >
      <div style={{ display: 'flex', position: 'relative' }}>
        {sideBar}
        <main
          className="container-default"
          style={{
            // paddingTop: headerTab ? '96px' : '56px',
            paddingBottom: footer ? '64px' : '0',
            paddingLeft:
              !isAdmin || !sideBar ? '0' : collapsed ? '64px' : '220px',
            ...style,
          }}
        >
          <header
            className="header-group"
            style={{ maxWidth: `${headerGroupMaxWidth}px` }}
          >
            {header}
            {headerTab}
          </header>
          <Suspense fallback={<TailSpinner type={'full-page'} />}>
            {children}
          </Suspense>
          {footer}
          {modal}
        </main>
      </div>
    </ErrorBoundary>
  );
};

export default Container;
