import React, { Dispatch, SetStateAction } from 'react';
import { Drawer } from 'vaul';

interface DrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
  cancelButton?: string;
  okButton?: string;
  isLoading?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  type?: 'full' | 'bottom';
}

const CustomDrawer: React.FC<DrawerProps> = ({
  open,
  setOpen,
  children,
  cancelButton,
  okButton,
  isLoading,
  onOk,
  onCancel,
  type = 'bottom',
}) => {
  return (
    <Drawer.Root open={open} onOpenChange={setOpen} shouldScaleBackground>
      <Drawer.Portal>
        <Drawer.Overlay className="custom-drawer-overlay" />
        <Drawer.Content
          data-vaul-no-drag
          className={`custom-drawer-content-container ${type}`}
        >
          {children}
          <div
            className="custom-drawer-content-footer"
            style={{ display: !(cancelButton || okButton) ? 'none' : '' }}
          >
            {cancelButton && (
              <button
                onClick={onCancel}
                className="cancel-button"
                disabled={isLoading}
              >
                {cancelButton}
              </button>
            )}
            {okButton && (
              <button
                onClick={onOk}
                disabled={isLoading}
                className={'ok-button'}
              >
                {okButton}
              </button>
            )}
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default CustomDrawer;
