import { useState } from 'react';
import { useExpectedPaymentSingle, useQueryParams } from '@src/util/hooks.ts';
import {
  EXPECTED_PAYMENT_MENU,
  EXPECTED_PAYMENT_MENU_LIST,
} from '@src/util/constants.ts';
import Card from '@src/components/Card.tsx';
import ChevronButton from '@src/components/ChevronButton.tsx';
import {
  deliveryAndCommerceMapper,
  getNextDateStr,
  getPreviousDateStr,
} from '@src/util/functions.ts';
import moment from 'moment';
import HeaderTabs from '@src/components/HeaderTabs.tsx';
import ListItem from '@src/components/ListItem.tsx';
import CardIcon from '@src/components/CardIcon.tsx';
import CommerceAndDeliveryIcon from '@src/components/CommerceAndDeliveryIcon.tsx';
import TailSpinner from '@src/components/TailSpinner.tsx';

const ExpectedPaymentDetailContent = () => {
  const query = useQueryParams();
  const [date, setDate] = useState(query.get('date'));
  const { data } = useExpectedPaymentSingle(date);
  const [activeTab, setActiveTab] = useState<string>(
    EXPECTED_PAYMENT_MENU.CARD,
  );

  if (!date) {
    return <TailSpinner type={'full-page'} />;
  }

  return (
    <div className={'expected-payment-detail-wrapper-container'}>
      <div className={'expected-payment-detail-container'}>
        <div className={'expected-payment-detail-card-container'}>
          <Card
            style={{
              padding: '24px 24px 12px 24px',
              gap: '4px',
              maxWidth: '584px',
              width: '100%',
            }}
          >
            <div className={'expected-payment-detail-card-header'}>
              <ChevronButton
                direction={'left'}
                onClick={() => setDate(getPreviousDateStr(date))}
              />
              <p className="text-base">
                {moment(date).get('year')}년 {moment(date).get('month') + 1}월{' '}
                {moment(date).get('date')}일
              </p>
              <ChevronButton
                direction={'right'}
                onClick={() => setDate(getNextDateStr(date))}
              />
            </div>
            <div className={'expected-payment-detail-card-body'}>
              <p className={'text-xl'}>
                {data.data.sum.amount.toLocaleString()}원
              </p>
            </div>
            <div className={'expected-payment-detail-card-footer'}>
              <div className={'expected-payment-detail-card-footer-row'}>
                <p className={'text-regular'}>카드</p>
                <p className={'text-regular'}>
                  {data.data.sum.card_amount.toLocaleString()}원
                </p>
              </div>
              <div className={'expected-payment-detail-card-footer-row'}>
                <p className={'text-regular'}>커머스 / 배달앱 등</p>
                <p className={'text-regular'}>
                  {data.data.sum.delivery_commerce_amount.toLocaleString()}원
                </p>
              </div>
            </div>
          </Card>
        </div>
        <div className={'expected-payment-detail-tab-container'}>
          <HeaderTabs
            menus={EXPECTED_PAYMENT_MENU_LIST}
            activeHeaderTab={activeTab}
            setActiveHeaderTab={(value) => setActiveTab(value)}
            headerStyle={{ paddingLeft: 0 }}
          />
          {activeTab == EXPECTED_PAYMENT_MENU.CARD
            ? data.data.card.map((item, i) => {
                return (
                  <ListItem
                    name={item.type}
                    value={item.pay_amount}
                    divider={true}
                    key={i}
                    nameIcon={<CardIcon name={item.type} />}
                  />
                );
              })
            : data.data.delivery_commerce.map((item, i) => {
                return (
                  <ListItem
                    name={deliveryAndCommerceMapper(item.type)}
                    value={item.pay_amount}
                    divider={true}
                    key={i}
                    nameIcon={<CommerceAndDeliveryIcon name={item.type} />}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default ExpectedPaymentDetailContent;
