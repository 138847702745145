import React from 'react';

interface LabelProps {
  text?: string;
  required?: boolean;
  style?: React.CSSProperties;
}

const Label: React.FC<LabelProps> = ({ text, required = false, style }) => {
  return (
    <label className="label-default" style={style}>
      {text}
      {required && <span className="label-required">*</span>}
    </label>
  );
};

export default Label;
