import React from 'react';
import Container from '@src/layouts/Container.tsx';
import AdminSideBar from '@src/components/AdminSideBar.tsx';
import CertificatesAdminContent from '@src/pages/admin/certificates/CertificatesAdminContent.tsx';

interface CertificatesAdminProps {}

const CertificatesAdmin: React.FC<CertificatesAdminProps> = () => {
  return (
    <Container sideBar={<AdminSideBar />}>
      <div
        className={'flex-column-flex-start-stretch'}
        style={{ gap: '24px', margin: '0 48px 48px 48px' }}
      >
        <p className={'text-md'}>증명서발급 관리</p>
        <CertificatesAdminContent />
      </div>
    </Container>
  );
};

export default CertificatesAdmin;
