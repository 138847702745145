import '@src/scss/main.scss';
import { HometaxUpdate, IApiError, ICert } from '@src/util/interfaces';
import api from '@src/util/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  API_URLS,
  DELIVERY_SYNC_MENU,
  DELIVERY_SYNC_MENU_LIST,
} from '@src/util/constants';
import { useUserStore } from '@src/util/store';
import React, { useCallback, useState } from 'react';
import DetailHeader from '@src/components/DetailHeader';
import Container from '@src/layouts/Container';
import CustomModal from '@src/components/CustomModal';
import TailSpinner from '@src/components/TailSpinner';
import SideBar from '@src/components/SideBar';
import DeliveryIcon from '@src/components/DeliveryIcon.tsx';
import { useIsMobile } from '@src/util/hooks.ts';
import Header from '@src/components/Header.tsx';
import HeaderTabs from '@src/components/HeaderTabs.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';

interface DataSyncProps {}

enum DeliveryCategory {
  bm = 'bm',
  yo = 'yo',
  cpe = 'cpe',
}

const DeliverySync: React.FC<DataSyncProps> = () => {
  const [user] = useUserStore((state) => [state.user]);

  const [moodalOpen, setModalOpen] = useState<boolean>(false);
  const [clearModalOpen, setClearModalOpen] = useState<boolean>(false);

  const [delivery_id, set_delivery_id] = useState<string>('');
  const [id_error, set_id_error] = useState<string>('');
  const [delivery_password, set_delivery_password] = useState<string>('');
  const [password_error, set_password_error] = useState<string>('');
  const [modal_title, set_modal_title] = useState<string>('');

  const [updateApiUrl, setUpdateApiUrl] = useState<DeliveryCategory>(
    DeliveryCategory.bm,
  );
  const [key, setKey] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    DELIVERY_SYNC_MENU.DELIVERY,
  );

  const company_id = user?.selectedCompany?.id;

  const queryClient = useQueryClient();
  const cert = useQuery<ICert>({
    queryKey: [`cert`, company_id],
    queryFn: () => api.get(API_URLS.CERT + `/${company_id}`),
    staleTime: 30 * 60 * 1000,
    enabled: !!user && !!user.selectedCompany,
    refetchOnWindowFocus: false,
  });

  const { mutate: updateCert, isPending: updateLoading } = useMutation({
    mutationFn: (data: HometaxUpdate) =>
      api.post(API_URLS.CERT + `/${updateApiUrl}`, { ...data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cert', company_id] });
      setModalOpen(false);
    },
    onError: (e: IApiError) => {
      setError(e.response.data.message);
    },
  });

  const { mutate: updateCertClear } = useMutation({
    mutationFn: (data: { key: string; value: string }) =>
      api.patch(API_URLS.CERT + `/${user?.selectedCompany?.id}`, {
        [data.key]: data.value,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cert', company_id] });
      setClearModalOpen(false);
    },
    onError: (e: IApiError) => {
      setError(e.response.data.message);
    },
  });

  const bmSyncDisable =
    cert?.data?.data.bm_id &&
    cert?.data?.data.bm_password &&
    cert?.data?.data.bm_password_error == false;
  const bmError = cert?.data?.data.bm_password_error == true;

  const yoSyncDisable =
    cert?.data?.data.yo_id &&
    cert?.data?.data.yo_password &&
    cert?.data?.data.yo_password_error == false;
  const yoError = cert?.data?.data.yo_password_error == true;

  const cpeSyncDisable =
    cert?.data?.data.cpe_id &&
    cert?.data?.data.cpe_password &&
    cert?.data?.data.cpe_password_error == false;
  const cpeError = cert?.data?.data.cpe_password_error == true;

  const handleClear = useCallback(
    (key: string, value: string, title: string) => {
      setKey(key);
      setValue(value);
      setTitle(title);
      setClearModalOpen(true);
    },
    [],
  );

  const handleBmSyncClick = useCallback(() => {
    set_delivery_id('');
    set_delivery_password('');
    set_modal_title('배달의 민족');
    setModalOpen(true);
    setUpdateApiUrl(DeliveryCategory.bm);
  }, []);
  const handleYoSyncClick = useCallback(() => {
    set_delivery_id('');
    set_delivery_password('');
    set_modal_title('요기요');
    setModalOpen(true);
    setUpdateApiUrl(DeliveryCategory.yo);
  }, []);
  const handleCpeSyncClick = useCallback(() => {
    set_delivery_id('');
    set_delivery_password('');
    set_modal_title('쿠팡 이츠');
    setModalOpen(true);
    setUpdateApiUrl(DeliveryCategory.cpe);
  }, []);

  const handleModalOkClick = useCallback(() => {
    const updateId = updateApiUrl + '_id';
    const updatePw = updateApiUrl + '_password';
    updateCert({
      [updateId]: delivery_id,
      [updatePw]: delivery_password,
      company_id,
    });
  }, [updateApiUrl, delivery_id, delivery_password]);

  if (updateLoading) {
    return <TailSpinner type={'full-page'} />;
  }

  return (
    <Container
      header={
        isMobile ? (
          <DetailHeader title={'배달앱 연결하기'} />
        ) : (
          <Header setOpen={setOpen} />
        )
      }
      sideBar={<SideBar />}
      headerTab={
        !isMobile && (
          <HeaderTabs
            menus={DELIVERY_SYNC_MENU_LIST}
            activeHeaderTab={activeHeaderTab}
            setActiveHeaderTab={(value) => {
              setActiveHeaderTab(value);
            }}
          />
        )
      }
      modal={
        <>
          <CompanyDrawer open={open} setOpen={setOpen} />
          <CustomModal
            onClose={() => {
              setError('');
              setModalOpen(false);
            }}
            isOpen={moodalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2 className={'modal-custom-title'}>
                      {`${modal_title} 연결`}
                    </h2>
                    <div className={'modal-input-wrapper'}>
                      <input
                        className={'modal-input'}
                        value={delivery_id}
                        placeholder={'아이디 입력'}
                        onFocus={() => {
                          setError('');
                          set_id_error('');
                        }}
                        onChange={(e) => set_delivery_id(e.target.value)}
                      />
                      {id_error ? (
                        <p className={'error-message'}>{id_error}</p>
                      ) : (
                        <></>
                      )}
                      <input
                        type={'password'}
                        className={'modal-input'}
                        value={delivery_password}
                        placeholder={'패스워드 입력'}
                        onFocus={() => {
                          setError('');
                          set_password_error('');
                        }}
                        onChange={(e) => set_delivery_password(e.target.value)}
                      />
                      {password_error ? (
                        <p className={'error-message'}>{password_error}</p>
                      ) : (
                        <></>
                      )}
                      {error ? (
                        <p className={'error-message'}>{error}</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'left-button'}
                    onClick={() => setModalOpen(false)}
                  >
                    취소
                  </button>
                  <button
                    className={'right-button'}
                    onClick={() => handleModalOkClick()}
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />
          <CustomModal
            onClose={() => {
              setError('');
              setClearModalOpen(false);
            }}
            isOpen={clearModalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2 className={'modal-custom-title'}>{`${title}`}</h2>
                  </div>
                  {value ? (
                    <div className={'modal-input-wrapper'}>
                      <input
                        className={'modal-input'}
                        value={value}
                        disabled={true}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {error ? <p className={'error-message'}>{error}</p> : <></>}
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'left-button'}
                    onClick={() => setClearModalOpen(false)}
                  >
                    취소
                  </button>
                  <button
                    className={'right-button'}
                    onClick={() => updateCertClear({ key, value: '' })}
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />
        </>
      }
    >
      <div className={'data-sync-wrapper-container'}>
        <div className="data-sync-detail-container">
          <div className={'data-sync-content-wrapper'}>
            <div className={'data-sync-content'}>
              <div className={'icon-wrapper'}>
                <DeliveryIcon name={'BM'} size={'lg'} />
                <p className={'icon-label'}>
                  배달의 민족
                  {bmError && (
                    <span className={'error-message'}> (비밀번호 오류)</span>
                  )}
                </p>
              </div>
              {bmSyncDisable && (
                <button
                  className={'sync-button-clear'}
                  onClick={() =>
                    handleClear(
                      'bm_id',
                      cert?.data?.data.bm_id || '',
                      '배달의 민족 연결 해제',
                    )
                  }
                >
                  연결해제
                </button>
              )}
              {!bmSyncDisable && (
                <button
                  className={'sync-button'}
                  onClick={() => handleBmSyncClick()}
                >
                  연결하기
                </button>
              )}
            </div>
          </div>
          <div className={'data-sync-content-wrapper'}>
            <div className={'data-sync-content'}>
              <div className={'icon-wrapper'}>
                <DeliveryIcon name={'YO'} size={'lg'} />
                <p className={'icon-label'}>
                  요기요
                  {yoError && (
                    <span className={'error-message'}> (비밀번호 오류)</span>
                  )}
                </p>
              </div>
              {yoSyncDisable && (
                <button
                  className={'sync-button-clear'}
                  onClick={() =>
                    handleClear(
                      'yo_id',
                      cert?.data?.data.yo_id || '',
                      '요기요 연결 해제',
                    )
                  }
                >
                  연결해제
                </button>
              )}
              {!yoSyncDisable && (
                <button
                  className={'sync-button'}
                  onClick={() => handleYoSyncClick()}
                >
                  연결하기
                </button>
              )}
            </div>
          </div>
          <div className={'data-sync-content-wrapper'}>
            <div className={'data-sync-content'}>
              <div className={'icon-wrapper'}>
                <DeliveryIcon name={'CPE'} size={'lg'} />
                <p className={'icon-label'}>
                  쿠팡 이츠
                  {cpeError && (
                    <span className={'error-message'}> (비밀번호 오류)</span>
                  )}
                </p>
              </div>
              {cpeSyncDisable && (
                <button
                  className={'sync-button-clear'}
                  onClick={() =>
                    handleClear(
                      'cpe_id',
                      cert?.data?.data.cpe_id || '',
                      '쿠팡 이츠 연결 해제',
                    )
                  }
                >
                  연결해제
                </button>
              )}
              {!cpeSyncDisable && (
                <button
                  className={'sync-button'}
                  onClick={() => handleCpeSyncClick()}
                >
                  연결하기
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DeliverySync;
