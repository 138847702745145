import React from 'react';
import Container from '@src/layouts/Container.tsx';
import AdminSideBar from '@src/components/AdminSideBar.tsx';
import ClientContent from '@src/pages/admin/client/ClientContent.tsx';

interface ClientProps {}

const Client: React.FC<ClientProps> = () => {
  return (
    <Container sideBar={<AdminSideBar />}>
      <ClientContent />
    </Container>
  );
};

export default Client;
