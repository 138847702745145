import { FC } from 'react';

interface SyncIconProps {
  fill: string;
  size: string;
}

const SyncIcon: FC<SyncIconProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon / sync">
        <g id="Vector">
          <path
            d="M6.49977 18C5.52977 18 4.70977 17.66 4.02977 16.97C3.34977 16.29 2.99977 15.46 2.99977 14.5C2.99977 13.54 3.33977 12.71 4.02977 12.03C4.70977 11.35 5.53977 11 6.49977 11H6.99977C6.99977 9.61999 7.48977 8.43999 8.45977 7.45999C9.42977 6.47999 10.6098 5.99999 11.9998 5.99999C13.3898 5.99999 14.5598 6.48999 15.5398 7.45999C15.7898 7.70999 15.9998 7.96999 16.1898 8.23999C16.7698 8.07999 17.3798 7.98999 18.0098 7.98999C18.1298 7.98999 18.2498 7.99999 18.3698 8.00999C18.0298 7.28999 17.5698 6.62999 16.9698 6.02999C15.6098 4.66999 13.9598 3.98999 12.0098 3.98999C10.4298 3.98999 9.00977 4.45999 7.75977 5.40999C6.50977 6.35999 5.67977 7.59999 5.25977 9.13999C3.98977 9.41999 2.96977 10.07 2.17977 11.09C1.39977 12.11 1.00977 13.27 1.00977 14.57C1.00977 16.09 1.54977 17.37 2.61977 18.42C3.68977 19.47 4.98977 20 6.50977 20H13.1198C12.5298 19.43 12.0498 18.75 11.6998 18H6.49977Z"
            fill={fill}
          />
          <path
            d="M17.4998 16.1L16.0498 14.65C15.9498 14.55 15.8298 14.5 15.6998 14.5C15.5698 14.5 15.4498 14.55 15.3498 14.65C15.2498 14.75 15.1998 14.87 15.1998 15C15.1998 15.13 15.2498 15.25 15.3498 15.35L17.2998 17.3C17.4998 17.5 17.7298 17.6 17.9998 17.6C18.2698 17.6 18.4998 17.5 18.6998 17.3L20.6498 15.35C20.7498 15.25 20.7998 15.13 20.7998 15C20.7998 14.87 20.7498 14.75 20.6498 14.65C20.5498 14.55 20.4298 14.5 20.2998 14.5C20.1698 14.5 20.0498 14.55 19.9498 14.65L18.4998 16.1V12.5C18.4998 12.37 18.4498 12.25 18.3498 12.15C18.2498 12.05 18.1298 12 17.9998 12C17.8698 12 17.7498 12.05 17.6498 12.15C17.5498 12.25 17.4998 12.37 17.4998 12.5V16.1ZM17.9998 20C16.6198 20 15.4398 19.51 14.4598 18.54C13.4898 17.56 12.9998 16.39 12.9998 15C12.9998 13.61 13.4898 12.44 14.4598 11.46C15.4398 10.49 16.6098 9.99999 17.9998 9.99999C19.3898 9.99999 20.5598 10.49 21.5398 11.46C22.5198 12.44 22.9998 13.61 22.9998 15C22.9998 16.39 22.5098 17.56 21.5398 18.54C20.5698 19.52 19.3898 20 17.9998 20Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};

export default SyncIcon;
