import React from 'react';

interface CertificatesDescriptionProps {
  title?: string;
  description?: string;
  example?: string;
}

const CertificatesDescription: React.FC<CertificatesDescriptionProps> = ({
  title,
  description,
  example,
}) => {
  return (
    <div className="certificate-description-container">
      {title && <h1 style={{ whiteSpace: 'pre-wrap' }}>{title}</h1>}
      {description && (
        <p
          className={'text-xs'}
          style={{ fontWeight: 400, whiteSpace: 'pre-wrap' }}
        >
          {description}
        </p>
      )}
      {example && (
        <div
          className={'flex-row-center-center'}
          style={{ gap: '8px', alignItems: 'flex-start' }}
        >
          <p className={'text-xs'} style={{ whiteSpace: 'nowrap' }}>
            예시)
          </p>
          <p
            className={'text-xs'}
            style={{ fontWeight: 400, whiteSpace: 'pre-wrap' }}
          >
            {example}
          </p>
        </div>
      )}
    </div>
  );
};

export default CertificatesDescription;
