import React from 'react';
import Table from '@src/components/Table.tsx';
import { ICertificateIssued } from '@src/util/interfaces.ts';
import { useCertificateIssued } from '@src/util/hooks.ts';
import { createColumnHelper } from '@tanstack/react-table';
import {
  downloadPdfUsingFetch,
  formatDateString,
  selectColorCertificateIssued,
} from '@src/util/functions.ts';
import Icon from '@src/components/Icon.tsx';

import downloadBlue from '/src/assets/icons/download-blue.svg';
import downloadGray from '/src/assets/icons/download-gray.svg';
const columnHelper = createColumnHelper<ICertificateIssued>();

const columns = [
  columnHelper.accessor('status', {
    id: 'status',
    cell: (info) => {
      const color = selectColorCertificateIssued(info.getValue());
      return <div style={{ color: color }}>{info.getValue()}</div>;
    },
    header: () => <div style={{ minWidth: '48px' }}>상태</div>,
  }),
  columnHelper.accessor('type', {
    id: 'type',
    cell: (info) => <i>{info.getValue()}</i>,
    header: () => <div style={{ minWidth: '188px' }}>증명서 제목</div>,
  }),
  columnHelper.accessor('download_url', {
    id: 'download_url',
    cell: (info) => {
      return ['발급완료'].includes(info.row.getValue('status')) ? (
        <Icon
          iconSrc={downloadBlue}
          iconText={'download-enabled'}
          onClick={() =>
            downloadPdfUsingFetch(
              info.getValue(),
              `${info.row.getValue('type')}.pdf`,
            )
          }
        />
      ) : (
        <Icon iconSrc={downloadGray} iconText={'download-disabled'} />
      );
    },
    header: () => <div style={{ minWidth: '48px' }}>다운로드</div>,
  }),
  columnHelper.accessor('request_date', {
    id: 'request_date',
    cell: (info) => <i>{formatDateString(info.getValue())}</i>,
    header: () => <div style={{ minWidth: '64px' }}>요청일</div>,
  }),
  columnHelper.accessor('complete_date', {
    id: 'complete_date',
    cell: (info) => <i>{formatDateString(info.getValue())}</i>,
    header: () => <div style={{ minWidth: '64px' }}>발급일</div>,
  }),
  columnHelper.accessor('memo', {
    id: 'memo',
    cell: (info) => <i>{!info.getValue() ? '-' : info.getValue()}</i>,
    header: () => <div style={{ minWidth: '88px' }}>비고</div>,
  }),
];

interface CertificateIssuedTableProps {
  activeCardHeaderTab: string;
}

const CertificateIssuedTable: React.FC<CertificateIssuedTableProps> = ({
  activeCardHeaderTab,
}) => {
  const { data } = useCertificateIssued();

  return (
    <Table<ICertificateIssued>
      data={data.data.items.filter(
        (item) =>
          item.status === activeCardHeaderTab || activeCardHeaderTab === '전체',
      )}
      columns={columns}
    />
  );
};

export default CertificateIssuedTable;
