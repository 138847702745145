import React, { useState } from 'react';
import {
  API_URLS,
  COLORS,
  TAX_MENU,
  TAX_MENU_LIST,
} from '@src/util/constants.ts';
import filterIcon from '/src/assets/icons/filter.svg';
import checkCircleIcon from '/src/assets/icons/check_circle.svg';
import Icon from '@src/components/Icon.tsx';
import {
  IApiError,
  ITaskAttachment,
  ITaskAttachmentResponse,
} from '@src/util/interfaces.ts';
import FileInput from '@src/components/FileInput.tsx';
import api from '@src/util/api.ts';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useUserStore } from '@src/util/store.ts';
import { useQueryClient } from '@tanstack/react-query';
import Header from '@src/components/Header.tsx';
import HeaderTabs from '@src/components/HeaderTabs.tsx';
import SideBar from '@src/components/SideBar.tsx';
import Footer from '@src/components/Footer.tsx';
import Container from '@src/layouts/Container.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import FilterModal from '@src/components/FilterModal.tsx';
import Modal from '@src/components/Modal.tsx';
import IconSVG from '@src/components/IconSVG.tsx';
import ReceiptContent from '@src/pages/tax/ReceiptContent.tsx';

interface ReceiptProps {}

const Receipt: React.FC<ReceiptProps> = () => {
  const user = useUserStore((state) => state.user);

  const statusOptions = ['전체', '대기', '완료', '불가'];
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [selectedStatusValue, setSelectedStatusValue] =
    useState<string>('전체');

  const filterOptions = ['전체', '세금계산서', '계산서', '영수증'];
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [selectedFilterValue, setSelectedFilterValue] =
    useState<string>('전체');

  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [receiptModalUrl, setReceiptModalUrl] = useState('');
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [registerModalStatus, setRegisterModalStatus] = useState<
    'loading' | 'success' | 'fail' | ''
  >('');
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();

  const handleFilesSelected = (files: FileList | null) => {
    if (files) {
      setRegisterModalStatus('loading');
      uploadFiles(files)
        .then(() => {
          setRegisterModalStatus('success');
        })
        .catch((err) => {
          console.log(err);
          setRegisterModalStatus('fail');
        })
        .finally(() => {
          setRegisterModalOpen(true);
        });
    }
  };
  const uploadFiles = async (files: FileList): Promise<ITaskAttachment[]> => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }

      const axiosConfig: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      api
        .post(
          API_URLS.RECEIPT_UPLOAD + `/${user?.selectedCompany?.id}`,
          formData,
          axiosConfig,
        )
        .then((res: AxiosResponse<ITaskAttachmentResponse>) => {
          if (res.data && Array.isArray(res.data)) {
            queryClient.invalidateQueries({ queryKey: ['receipt'] });
            resolve(res.data);
          }
        })
        .catch((err: AxiosResponse<IApiError>) => {
          console.log(err);
          reject(err);
        });
    });
  };

  return (
    <Container
      header={<Header setOpen={setOpen} />}
      headerTab={
        <HeaderTabs menus={TAX_MENU_LIST} activeHeaderTab={TAX_MENU.RECEIPT} />
      }
      sideBar={<SideBar />}
      footer={<Footer current={'tax'} />}
      modal={
        <>
          <CompanyDrawer open={open} setOpen={setOpen} />
          <FilterModal
            filterModalOpen={statusModalOpen}
            setFilterModalOpen={setStatusModalOpen}
            filterOptions={statusOptions}
            selectedFilterValue={selectedStatusValue}
            setSelectedFilterValue={setSelectedStatusValue}
          />
          <FilterModal
            filterModalOpen={filterModalOpen}
            setFilterModalOpen={setFilterModalOpen}
            filterOptions={filterOptions}
            selectedFilterValue={selectedFilterValue}
            setSelectedFilterValue={setSelectedFilterValue}
          />
          <Modal
            isOpen={receiptModalOpen}
            onClose={() => {
              setReceiptModalOpen(false);
            }}
            style={{
              width: '80%',
              maxWidth: '384px',
              margin: '0',
              padding: '0',
            }}
            body={
              <div style={{ position: 'relative' }}>
                <IconSVG
                  fill={'#FFFFFF'}
                  size={'24'}
                  containerStyle={{
                    padding: '8px',
                    boxSizing: 'border-box',
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    cursor: 'pointer',
                  }}
                  iconSVG={'close'}
                  onClick={() => {
                    setReceiptModalUrl('');
                    setReceiptModalOpen(false);
                  }}
                />
                <img
                  src={receiptModalUrl}
                  alt="img"
                  style={{ width: '100%', objectFit: 'cover' }}
                />
              </div>
            }
          />
          <Modal
            isOpen={registerModalOpen}
            onOk={() => {
              setRegisterModalOpen(false);
            }}
            title={
              registerModalStatus === 'fail'
                ? '영수증 등록 실패'
                : '영수증 등록'
            }
            content={
              registerModalStatus === 'fail'
                ? '영수증 업로드에 실패했습니다. 이미지 파일만(jpg, jpeg, png, gif) 업로드 가능합니다.'
                : registerModalStatus === 'success'
                  ? '영수증 등록이 완료되었습니다.'
                  : ''
            }
            okButton={'확인'}
            onClose={() => {
              setRegisterModalOpen(false);
              setRegisterModalStatus('');
            }}
          />
        </>
      }
    >
      <div className={'receipt-wrapper-container'}>
        <div className={'receipt-container'}>
          <div className={'receipt-register-container'}>
            <div className="receipt-register-text-box">
              <p className={'text-lg'} style={{ color: COLORS['white'] }}>
                영수증 등록
              </p>
              <p
                className={'text-base'}
                style={{ color: COLORS['white'], fontWeight: '400' }}
              >
                영수증 사진을 첨부하면 프로그램 분석 완료 후 목록에서 확인하실
                수 있습니다.
              </p>
            </div>
            <FileInput
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              text={'영수증 등록하기'}
              textStyle={{
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '16px',
                color: COLORS['primary'],
              }}
              iconDisplay={false}
              onFilesSelected={handleFilesSelected}
              isLoading={registerModalStatus === 'loading'}
            />
          </div>
          <div className={'receipt-list-container'}>
            <div style={{ transform: 'rotateX(180deg)' }}>
              <div className={'receipt-list-title-container'}>
                <p className={'text-regular-bold'}>영수증 목록</p>
                <div className={'receipt-list-icon-container'}>
                  <Icon
                    iconSrc={filterIcon}
                    onClick={() => setFilterModalOpen(true)}
                  />
                  <Icon
                    iconSrc={checkCircleIcon}
                    onClick={() => setStatusModalOpen(true)}
                  />
                </div>
              </div>
              <ReceiptContent
                selectedFilterValue={selectedFilterValue}
                selectedStatusValue={selectedStatusValue}
                setReceiptModalUrl={setReceiptModalUrl}
                setReceiptModalOpen={setReceiptModalOpen}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Receipt;
