import logoImg from '/src/assets/icons/logo-final.png';
import Button from '@src/components/Button.tsx';

const LandingHeader = () => {
  return (
    <nav className="landing-header">
      <img
        className="login-logo-img"
        src={logoImg}
        alt="jungyul"
        style={{ width: '110px', cursor: 'pointer' }}
        onClick={() => {
          window.location.href = 'https://www.chomchomtax.co.kr';
        }}
      />
      <Button
        text={'무료로 시작하기'}
        onClick={() => {
          window.location.href = 'https://jylabs.co.kr';
        }}
        style={{
          maxWidth: '120px',
          borderRadius: '4px',
          height: '40px',
        }}
      />
    </nav>
  );
};

export default LandingHeader;
