import React, { useCallback, useState } from 'react';
import Container from '@src/layouts/Container';
import { LABOR_MENU, LABOR_MENU_LIST, URLS } from '@src/util/constants';
import { Link, useNavigate } from 'react-router-dom';
import DetailHeader from '@src/components/DetailHeader';
import HeaderTabs from '@src/components/HeaderTabs';
import { createQuestionStore, useUserStore } from '@src/util/store';
import { useIsMobile } from '@src/util/hooks.ts';
import SideBar from '@src/components/SideBar';
import BottomWrapper from '@src/components/BottomWrapper.tsx';

interface LaborProps {}

const BasicQuestion3: React.FC<LaborProps> = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [user] = useUserStore((state) => [state.user]);
  const company_id = user?.selectedCompany?.id || 0;
  const [question, setQuestion] = createQuestionStore(company_id)((state) => [
    state.question,
    state.setQuestion,
  ]);
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    LABOR_MENU.REQUEST,
  );

  const handleClick = useCallback((value: boolean) => {
    setQuestion({ basic_question_3: value });
    if (!value) {
      navigate(`${URLS.LABOR}/stop`);
    } else {
      setQuestion({ current_page: `${URLS.LABOR}/possible` });
      navigate(`${URLS.LABOR}/possible`);
    }
  }, []);

  return (
    <Container
      sideBar={<SideBar />}
      header={<DetailHeader title={'노무지원금 신청'} />}
      headerTab={
        isMobile ? (
          ''
        ) : (
          <HeaderTabs
            menus={LABOR_MENU_LIST}
            activeHeaderTab={activeHeaderTab}
            setActiveHeaderTab={(value) => {
              setActiveHeaderTab(value);
            }}
          />
        )
      }
    >
      <div className={'labor-request-wrapper-container'}>
        <div className={'labor-request-container'}>
          <div className="labor-request-description-container">
            <h2 className="text-md">질문 3</h2>
            <p className="text-regular-up" style={{ color: '#31343A' }}>
              현재 사업장 구성원 중 대표자, 가족, 친인척을 제외하고 4대 보험을
              가입한 근로자가 1명 이상 있나요?
            </p>
            <div className={'labor-button-wrapper'}>
              <button className={'button'} onClick={() => handleClick(false)}>
                아니오
              </button>
              <button className={'button'} onClick={() => handleClick(true)}>
                예
              </button>
            </div>
          </div>
          <BottomWrapper>
            <Link
              className={'button-default'}
              to={question?.current_page || '/labor'}
            >
              돌아가기
            </Link>
          </BottomWrapper>
        </div>
      </div>
    </Container>
  );
};
export default BasicQuestion3;
