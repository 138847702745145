import React from 'react';
import bcIcon from '/src/assets/icons/cards/bc.jpeg';
import hnIcon from '/src/assets/icons/cards/hana.png';
import hdIcon from '/src/assets/icons/cards/hd.png';
import kbIcon from '/src/assets/icons/cards/kb.png';
import ssIcon from '/src/assets/icons/cards/samsung.png';
import ltIcon from '/src/assets/icons/cards/lte.png';
import nhIcon from '/src/assets/icons/cards/nh.jpeg';
import shbIcon from '/src/assets/icons/cards/sh.png';
import wrIcon from '/src/assets/icons/cards/wr.webp';
import cardIcon from '/src/assets/icons/cards/card.svg';
import Icon from './Icon.tsx';
import { COLORS } from '@src/util/constants.ts';

interface CardIconProps {
  name: string;
  size?: 'md' | 'lg';
}

const CardIcon: React.FC<CardIconProps> = ({ name, size = 'md' }) => {
  const imgStyle = {
    width: '24px',
    height: '24px',
    borderRadius: '6px',
    border: '',
  };

  if (size === 'lg') {
    imgStyle.width = '32px';
    imgStyle.height = '32px';
    imgStyle.borderRadius = '8px';
  }

  switch (name) {
    case '비씨카드':
      return (
        <Icon iconSrc={bcIcon} iconText={'비씨카드'} imgStyle={imgStyle} />
      );
    case 'BC카드':
      return (
        <Icon iconSrc={bcIcon} iconText={'비씨카드'} imgStyle={imgStyle} />
      );
    case '하나카드':
      return (
        <Icon iconSrc={hnIcon} iconText={'하나카드'} imgStyle={imgStyle} />
      );
    case '현대카드':
      return (
        <Icon iconSrc={hdIcon} iconText={'현대카드'} imgStyle={imgStyle} />
      );
    case 'KB카드':
      return (
        <Icon iconSrc={kbIcon} iconText={'국민카드'} imgStyle={imgStyle} />
      );
    case '국민카드':
      return (
        <Icon iconSrc={kbIcon} iconText={'국민카드'} imgStyle={imgStyle} />
      );
    case '삼성카드':
      return (
        <Icon iconSrc={ssIcon} iconText={'삼성카드'} imgStyle={imgStyle} />
      );
    case '롯데카드':
      return (
        <Icon iconSrc={ltIcon} iconText={'롯데카드'} imgStyle={imgStyle} />
      );
    case '농협NH카드':
      return (
        <Icon iconSrc={nhIcon} iconText={'농협카드'} imgStyle={imgStyle} />
      );
    case '농협카드':
      return (
        <Icon iconSrc={nhIcon} iconText={'농협카드'} imgStyle={imgStyle} />
      );
    case '신한카드':
      return (
        <Icon iconSrc={shbIcon} iconText={'신한카드'} imgStyle={imgStyle} />
      );
    case '우리카드':
      imgStyle.border = `0.1px solid ${COLORS['gray2']}`;
      return (
        <Icon iconSrc={wrIcon} iconText={'우리카드'} imgStyle={imgStyle} />
      );
    default:
      return <Icon iconSrc={cardIcon} iconText={'기타'} />;
  }
};
export default CardIcon;
