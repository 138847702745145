import { Dispatch, SetStateAction } from 'react';
import { ICustomer } from '@src/util/interfaces.ts';
import { useTaxInvoiceFormStore } from '@src/util/store.ts';
import CustomDrawer from '@src/components/CustomDrawer.tsx';
import { formatBusinuessNumber } from '@src/util/functions.ts';
import CloseIcon from '@src/components/CloseIcon.tsx';

interface CustomerSearchDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedCustomer: ICustomer;
  onCloseAndNavigateBack: () => void;
}

const CustomerSearchDetailDrawer: React.FC<CustomerSearchDetailDrawerProps> = ({
  open,
  setOpen,
  selectedCustomer,
  onCloseAndNavigateBack,
}) => {
  const { taxInvoiceForm, setTaxInvoiceForm } = useTaxInvoiceFormStore(
    (state) => ({
      taxInvoiceForm: state.taxInvoiceForm,
      setTaxInvoiceForm: state.setTaxInvoiceForm,
    }),
  );

  const handleOk = () => {
    const {
      tnmNm,
      rprsFnm,
      txprDscmNoEncCntn,
      pfbAdr,
      bcNm,
      itmNm,
      mchrgEmlAdr,
    } = selectedCustomer;
    const txprDscmNoEncCntnStr = txprDscmNoEncCntn.toString();
    const updatedFormData = {
      ...taxInvoiceForm,
      dmnrTnmNm: tnmNm, //상호
      dmnrRprsFnm: rprsFnm, // 성명
      registrationNumberConsumer: {
        registrationNumber1Consumer: txprDscmNoEncCntnStr.slice(0, 3),
        registrationNumber2Consumer: txprDscmNoEncCntnStr.slice(3, 5),
        registrationNumber3Consumer: txprDscmNoEncCntnStr.slice(5, 10),
      },
      dmnrPfbAdr: pfbAdr, // 사업장주소
      dmnrBcNm: bcNm, // 업태
      dmnrItmNm: itmNm, // 종목
      dmnrMchrgEmlAdr: mchrgEmlAdr, // 이메일
    };

    setTaxInvoiceForm(updatedFormData);
    onCloseAndNavigateBack();
  };

  return (
    <CustomDrawer open={open} setOpen={setOpen}>
      <div className={'industry-search-detail-drawer-container'}>
        <div className={'industry-search-detail-drawer-header'}>
          <span>거래처정보 확인</span>
          <CloseIcon
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
        <div className={'industry-search-detail-drawer-body'}>
          <div className={'industry-search-detail-drawer-item'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              거래처명
            </p>
            <p className={'text-regular'}>{selectedCustomer.tnmNm}</p>
          </div>
          <div className={'industry-search-detail-drawer-item'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              거래처등록번호
            </p>
            <p className={'text-regular'} style={{ width: '200px' }}>
              {formatBusinuessNumber(
                selectedCustomer.txprDscmNoEncCntn.toString(),
              )}
            </p>
          </div>
          <div className={'industry-search-detail-drawer-item'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              대표자
            </p>
            <p className={'text-regular'} style={{ width: '200px' }}>
              {selectedCustomer.rprsFnm}
            </p>
          </div>
          <div className={'industry-search-detail-drawer-item'}>
            <p
              className={'text-regular-bold'}
              style={{ width: '92px', whiteSpace: 'normal' }}
            >
              사업장주소
            </p>
            <p className={'text-regular'} style={{ width: '200px' }}>
              {selectedCustomer.pfbAdr}
            </p>
          </div>
          <div className={'industry-search-detail-drawer-item'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              업태
            </p>
            <p className={'text-regular'} style={{ width: '200px' }}>
              {selectedCustomer.bcNm}
            </p>
          </div>
          <div className={'industry-search-detail-drawer-item'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              종목
            </p>
            <p className={'text-regular'} style={{ width: '200px' }}>
              {selectedCustomer.itmNm}
            </p>
          </div>
          <div className={'industry-search-detail-drawer-item'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              이메일
            </p>
            <p className={'text-regular'} style={{ width: '200px' }}>
              {selectedCustomer.mchrgEmlAdr}
            </p>
          </div>
        </div>
        <div className={'industry-search-detail-drawer-footer'}>
          <button
            className={'industry-search-detail-drawer-footer-cancel'}
            onClick={() => {
              setOpen(false);
            }}
          >
            취소
          </button>
          <button
            className={'industry-search-detail-drawer-footer-ok'}
            onClick={handleOk}
          >
            확인
          </button>
        </div>
      </div>
    </CustomDrawer>
  );
};

export default CustomerSearchDetailDrawer;
