import React, { useState } from 'react';
import Container from '@src/layouts/Container';
import {
  LABOR_MENU,
  LABOR_MENU_LIST,
  LaborQuestion,
  URLS,
} from '@src/util/constants.ts';
import { Link, useNavigate } from 'react-router-dom';
import DetailHeader from '@src/components/DetailHeader';
import HeaderTabs from '@src/components/HeaderTabs';
import { useIsMobile } from '@src/util/hooks.ts';
import SideBar from '@src/components/SideBar';
import BottomWrapper from '@src/components/BottomWrapper.tsx';

interface LaborProps {}

const PossibleQuestion: React.FC<LaborProps> = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    LABOR_MENU.REQUEST,
  );

  const [radioValue, setRadioValue] = useState<LaborQuestion>(
    LaborQuestion.청년일자리도약장려금,
  );

  return (
    <Container
      sideBar={<SideBar />}
      header={
        <DetailHeader
          title={'노무지원금 신청'}
          custom={
            <button
              className={'labor-custom'}
              onClick={() => navigate(`${URLS.LABOR}`)}
            >
              처음으로
            </button>
          }
        />
      }
      headerTab={
        isMobile ? (
          ''
        ) : (
          <HeaderTabs
            menus={LABOR_MENU_LIST}
            activeHeaderTab={activeHeaderTab}
            setActiveHeaderTab={(value) => {
              setActiveHeaderTab(value);
            }}
          />
        )
      }
    >
      <div className={'labor-request-wrapper-container'}>
        <div className={'labor-request-container'}>
          <div className="labor-request-description-container">
            <h2 className="question-title">
              귀 사업장은 노무지원금 검토 <br />
              가능 사업장입니다.
            </h2>
            <p className={'question-text'}>
              정확한 확인을 위하여 아래 노무지원금 중 <br />
              하나를 선택 후 질문에 응답해 주세요.
            </p>
            <div className={'question-radio-container'}>
              <div className={'question-radio-wrapper'}>
                <input
                  className={'pointer'}
                  type="radio"
                  name="question-group"
                  id="option1"
                  value={LaborQuestion.청년일자리도약장려금}
                  checked={radioValue === LaborQuestion.청년일자리도약장려금}
                  onChange={(e) =>
                    setRadioValue(e.target.value as LaborQuestion)
                  }
                />
                <label
                  htmlFor="option1"
                  className={
                    radioValue === LaborQuestion.청년일자리도약장려금
                      ? 'checked'
                      : ''
                  }
                >
                  청년일자리도약장려금
                </label>
              </div>
              <div className={'question-radio-wrapper'}>
                <input
                  className={'pointer'}
                  type="radio"
                  name="question-group"
                  id="option2"
                  value={LaborQuestion.시니어인턴지원금}
                  checked={radioValue === LaborQuestion.시니어인턴지원금}
                  onChange={(e) =>
                    setRadioValue(e.target.value as LaborQuestion)
                  }
                />
                <label
                  htmlFor="option2"
                  className={
                    radioValue === LaborQuestion.시니어인턴지원금
                      ? 'checked'
                      : ''
                  }
                >
                  시니어인턴 지원금
                </label>
              </div>
              <div className={'question-radio-wrapper'}>
                <input
                  className={'pointer'}
                  type="radio"
                  name="question-group"
                  id="option3"
                  value={LaborQuestion.육아휴직지원금}
                  checked={radioValue === LaborQuestion.육아휴직지원금}
                  onChange={(e) =>
                    setRadioValue(e.target.value as LaborQuestion)
                  }
                />
                <label
                  htmlFor="option3"
                  className={
                    radioValue === LaborQuestion.육아휴직지원금 ? 'checked' : ''
                  }
                >
                  육아휴직 지원금
                </label>
              </div>
            </div>
          </div>
          <BottomWrapper>
            <Link
              className={'button-default'}
              to={`${URLS.LABOR}/${radioValue}/1`}
            >
              다음
            </Link>
          </BottomWrapper>
        </div>
      </div>
    </Container>
  );
};
export default PossibleQuestion;
