import React from 'react';

interface TabListProps {
  style?: React.CSSProperties;
  value: string;
  onChange: (newValue: string) => void;
  tabs: string[];
}

const TabList: React.FC<TabListProps> = ({ style, tabs, value, onChange }) => {
  return (
    <div className={'tab-list-container'} style={style}>
      {tabs.map((item, index) => (
        <button
          key={index}
          className={`tab ${value === item ? 'active' : ''}`}
          onClick={() => onChange(item)}
        >
          {item}
        </button>
      ))}
    </div>
  );
};

export default TabList;
