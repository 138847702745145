import CustomDrawer from '@src/components/CustomDrawer.tsx';
import { Dispatch, SetStateAction } from 'react';
import { TIndustrySearchResponse } from '@src/pages/industry/IndustrySearch.tsx';
import { useTaxInvoiceFormStore } from '@src/util/store.ts';
import CloseIcon from '@src/components/CloseIcon.tsx';

interface IndustrySearchDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedIndustry: TIndustrySearchResponse;
  onCloseAndNavigateBack: () => void;
  year: string;
}

const IndustrySearchDetailDrawer: React.FC<IndustrySearchDetailDrawerProps> = ({
  open,
  setOpen,
  selectedIndustry,
  year,
  onCloseAndNavigateBack,
}) => {
  const { taxInvoiceForm, setTaxInvoiceForm } = useTaxInvoiceFormStore(
    (state) => ({
      taxInvoiceForm: state.taxInvoiceForm,
      setTaxInvoiceForm: state.setTaxInvoiceForm,
    }),
  );

  const handleOk = () => {
    const updatedFormData = {
      ...taxInvoiceForm,
      dmnrBcNm: selectedIndustry.bcNm,
      dmnrItmNm: selectedIndustry.tfbDclsNm,
    };

    setTaxInvoiceForm(updatedFormData);
    onCloseAndNavigateBack();
  };

  return (
    <CustomDrawer open={open} setOpen={setOpen}>
      <div className={'industry-search-detail-drawer-container'}>
        <div className={'industry-search-detail-drawer-header'}>
          <span>업태확인</span>
          <CloseIcon
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
        <div className={'industry-search-detail-drawer-body'}>
          <div className={'industry-search-detail-drawer-item'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              귀속연도
            </p>
            <p className={'text-regular'}>{year}</p>
          </div>
          <div className={'industry-search-detail-drawer-item'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              업종코드
            </p>
            <p className={'text-regular'}>{selectedIndustry.tfbCd}</p>
          </div>
          <div className={'industry-search-detail-drawer-item'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              업종
            </p>
            <p className={'text-regular'}>{selectedIndustry.bcNm}</p>
          </div>
          <div className={'industry-search-detail-drawer-item'}>
            <p className={'text-regular-bold'} style={{ width: '92px' }}>
              세세분류명
            </p>
            <p className={'text-regular'}>{selectedIndustry.tfbDclsNm}</p>
          </div>
        </div>
        <div className={'industry-search-detail-drawer-footer'}>
          <button
            className={'industry-search-detail-drawer-footer-cancel'}
            onClick={() => {
              setOpen(false);
            }}
          >
            취소
          </button>
          <button
            className={'industry-search-detail-drawer-footer-ok'}
            onClick={handleOk}
          >
            확인
          </button>
        </div>
      </div>
    </CustomDrawer>
  );
};

export default IndustrySearchDetailDrawer;
