import React from 'react';
import Container from '@src/layouts/Container.tsx';
import AdminSideBar from '@src/components/AdminSideBar.tsx';
import LaborAdminContent from '@src/pages/admin/labor/LaborAdminContent.tsx';

interface LaborAdminProps {}

const LaborAdmin: React.FC<LaborAdminProps> = () => {
  return (
    <Container sideBar={<AdminSideBar />}>
      <div
        className={'flex-column-flex-start-stretch'}
        style={{ gap: '24px', margin: '0 48px 48px 48px' }}
      >
        <p className={'text-md'}>노무지원금 관리</p>
        <LaborAdminContent />
      </div>
    </Container>
  );
};

export default LaborAdmin;
