import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Input from '@src/components/Input';
import Button from '@src/components/Button';
import { API_URLS, COLORS, URLS } from '@src/util/constants';
import Snackbar from '@src/components/SnackBar';
import Container from '@src/layouts/Container';
import DetailHeader from '@src/components/DetailHeader';
import api from '@src/util/api';
import { useMutation } from '@tanstack/react-query';
import SideBar from '@src/components/SideBar';
import InputLabel from '@src/components/InputLabel.tsx';
import { z } from 'zod';
import { passwordRegex } from '@src/util/functions.ts';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { IApiError, IApiPOSTSuccess } from '@src/util/interfaces.ts';
import BottomButtonGroup from '@src/components/BottomButtonGroup.tsx';

interface FindIdProps {}

const PasswordVerifyForm = z.object({
  password: z.string().min(1),
});

const PasswordChangeForm = z
  .object({
    password: z.string().regex(passwordRegex, '비밀번호가 유효하지 않습니다.'),
    password_confirm: z.string(),
  })
  .refine((data) => data.password === data.password_confirm, {
    message: '비밀번호가 일치하지 않습니다.',
    path: ['password_confirm'],
  });

type TPasswordVerifyForm = z.infer<typeof PasswordVerifyForm>;
type TPasswordChangeForm = z.infer<typeof PasswordChangeForm>;

export const ChangePw: React.FC<FindIdProps> = () => {
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = useState<string>('');
  const [snackBarType, setSnackBarType] = useState<'info' | 'error'>('info');
  const [isPasswordVerified, setPasswordVerified] = useState(false);

  const {
    register: verifyPasswordRegister,
    handleSubmit: verifyPasswordHandleSubmit,
    formState: { errors: verifyPasswordErrors, isDirty: verifyPasswordIsDirty },
    setError: verifyPasswordSetError,
    reset: verifyPasswordReset,
  } = useForm<TPasswordVerifyForm>({
    resolver: zodResolver(PasswordVerifyForm),
  });

  const {
    register: changePasswordRegister,
    handleSubmit: changePasswordHandleSubmit,
    reset: changePasswordReset,
    setError: changePasswordSetError,
    formState: { errors: changePasswordErrors },
  } = useForm<TPasswordChangeForm>({
    resolver: zodResolver(PasswordChangeForm),
  });

  const { mutate: verifyPassword, isPending: verifyPasswordLoading } =
    useMutation<IApiPOSTSuccess, IApiError, TPasswordVerifyForm>({
      mutationFn: (formData) => {
        return api.post(API_URLS.VERIFY_PASSWORD, formData);
      },
    });

  const { mutate: changePassword, isPending: changePasswordLoading } =
    useMutation<IApiPOSTSuccess, IApiError, TPasswordVerifyForm>({
      mutationFn: (formData) => {
        return api.post(API_URLS.UPDATE_PASSWORD, formData);
      },
    });

  const onSubmitVerifyPassword: SubmitHandler<TPasswordVerifyForm> = (data) => {
    if (!isPasswordVerified) {
      verifyPassword(data, {
        onSuccess: (res) => {
          console.log(res);
          setPasswordVerified(true);
          setSnackBarType('info');
          setSnackBarContent('비밀번호가 인증 되었습니다.');
          setSnackBarOpen(true);
          setTimeout(() => {
            setSnackBarOpen(false);
          }, 3000);
        },
        onError: (err) => {
          console.log(err);
          verifyPasswordSetError('password', {
            message: err.response.data.message,
          });
        },
      });
    }
  };

  const onSubmitPasswordChange: SubmitHandler<TPasswordChangeForm> = (data) => {
    if (isPasswordVerified) {
      changePassword(
        { password: data.password },
        {
          onSuccess: (res) => {
            console.log(res);
            setPasswordVerified(false);

            verifyPasswordReset();
            changePasswordReset();
            setSnackBarType('info');
            setSnackBarContent('비밀번호가 변경 되었습니다.');
            setSnackBarOpen(true);
            setTimeout(() => {
              setSnackBarOpen(false);
            }, 3000);
          },
          onError: (err) => {
            console.log(err);
            changePasswordSetError('password_confirm', {
              message: err.response.data.message,
            });
          },
        },
      );
    }
  };
  return (
    <Container
      header={<DetailHeader title={'비밀번호 변경'} />}
      sideBar={<SideBar />}
      modal={
        <Snackbar
          message={snackBarContent}
          open={snackBarOpen}
          type={snackBarType}
        />
      }
    >
      <div className="email-container-wrapper">
        <div style={{ height: '48px' }}></div>
        <div className="input-container">
          <div
            className="input-label-container"
            style={{ marginBottom: '30px', gap: '28px' }}
          >
            <form onSubmit={verifyPasswordHandleSubmit(onSubmitVerifyPassword)}>
              <InputLabel
                text={'기존 비밀번호'}
                required
                error={
                  verifyPasswordErrors.password && (
                    <p className={'error-message'}>
                      {verifyPasswordErrors.password.message}
                    </p>
                  )
                }
              >
                <div className="flex-row" style={{ gap: '12px' }}>
                  <Input
                    register={verifyPasswordRegister('password')}
                    isLoading={false}
                    inputMode={'text'}
                    type={'password'}
                    placeholder={'기존 비밀번호'}
                    style={{
                      backgroundColor:
                        isPasswordVerified || verifyPasswordLoading
                          ? COLORS['gray0']
                          : '',
                      flex: 1,
                    }}
                    disabled={isPasswordVerified || verifyPasswordLoading}
                    autoComplete={'off'}
                  />
                  <Button
                    text={isPasswordVerified ? '인증완료' : '인증하기'}
                    disabled={isPasswordVerified || !verifyPasswordIsDirty}
                    style={{ width: '96px' }}
                    isLoading={verifyPasswordLoading}
                  />
                </div>
              </InputLabel>
            </form>
            <form
              id={'change-password-form'}
              onSubmit={changePasswordHandleSubmit(onSubmitPasswordChange)}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'stretch',
              }}
            >
              <InputLabel
                text={'새 비밀번호'}
                required
                description={
                  '영문자(대,소문자), 숫자를 포함하여 최소 8자 이상 15자 이하로 작성 해야 합니다.'
                }
                error={
                  changePasswordErrors.password ? (
                    <p className={'error-message'}>
                      {changePasswordErrors.password.message}
                    </p>
                  ) : changePasswordErrors.password_confirm ? (
                    <p className={'error-message'}>
                      {changePasswordErrors.password_confirm.message}
                    </p>
                  ) : (
                    <></>
                  )
                }
              >
                <Input
                  register={changePasswordRegister('password')}
                  inputMode={'text'}
                  type={'password'}
                  placeholder={'새 비밀번호 입력'}
                  style={{
                    flex: 1,
                  }}
                />
                <Input
                  register={changePasswordRegister('password_confirm')}
                  inputMode={'text'}
                  type={'password'}
                  placeholder={'새 비밀번호 재입력'}
                  style={{
                    flex: 1,
                  }}
                />
              </InputLabel>
            </form>
          </div>
          <BottomButtonGroup
            left={
              <Link className={'button-left-default'} to={URLS.MY_PAGE}>
                취소
              </Link>
            }
            right={
              <Button
                text={'확인'}
                type={'submit'}
                form={'change-password-form'}
                isLoading={changePasswordLoading}
              />
            }
          />
        </div>
      </div>
    </Container>
  );
};

export default ChangePw;
