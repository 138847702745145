import React from 'react';
import { Drawer } from 'vaul';

interface FilterModalProps {
  filterModalOpen: boolean;
  setFilterModalOpen: (value: boolean) => void;
  filterOptions: string[];
  selectedFilterValue: string;
  setSelectedFilterValue: (value: string) => void;
}

const FilterModal: React.FC<FilterModalProps> = ({
  filterModalOpen,
  setFilterModalOpen,
  filterOptions,
  selectedFilterValue,
  setSelectedFilterValue,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedFilterValue(value);
    setFilterModalOpen(false);
  };
  const handleContainerClick = (value: string) => {
    setSelectedFilterValue(value);
    setFilterModalOpen(false);
  };

  return (
    <Drawer.Root
      open={filterModalOpen}
      onOpenChange={setFilterModalOpen}
      shouldScaleBackground
    >
      <Drawer.Portal>
        <Drawer.Overlay className="filter-drawer-overlay" />
        <Drawer.Content data-vaul-no-drag className="filter-drawer-content">
          <div className={'filter-drawer-content-body'}>
            {filterOptions.map((option, i) => {
              return (
                <div
                  className="filter-row-container"
                  key={i}
                  onClick={() => handleContainerClick(option)} // Make the container clickable
                >
                  <p className={'text-regular'} style={{ lineHeight: '22px' }}>
                    {option}
                  </p>
                  <input
                    type="radio"
                    id={option}
                    value={option}
                    checked={selectedFilterValue === option}
                    onChange={handleChange}
                  />
                </div>
              );
            })}
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default FilterModal;
