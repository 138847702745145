import React from 'react';
import naverIcon from '/src/assets/icons/commerce/naver.jpeg';
import coupangIcon from '/src/assets/icons/commerce/cp.webp';
import Icon from './Icon.tsx';
import { COLORS } from '@src/util/constants.ts';
import IconSVG from '@src/components/IconSVG.tsx';

interface CommerceIconProps {
  name: string;
  size?: 'md' | 'lg';
}

const CommerceIcon: React.FC<CommerceIconProps> = ({ name, size = 'md' }) => {
  const imgStyle = {
    width: '24px',
    height: '24px',
    borderRadius: '6px',
    border: '',
  };

  if (size === 'lg') {
    imgStyle.width = '32px';
    imgStyle.height = '32px';
    imgStyle.borderRadius = '8px';
  }

  switch (name) {
    case 'NAVER':
      return (
        <Icon iconSrc={naverIcon} iconText={'NAVER'} imgStyle={imgStyle} />
      );
    case 'COUPANG':
      imgStyle.border = `0.1px solid ${COLORS['gray2']}`;
      return (
        <Icon iconSrc={coupangIcon} iconText={'COUPANG'} imgStyle={imgStyle} />
      );
    case 'ETC':
      return <IconSVG fill={COLORS['main']} size={'24'} iconSVG={'etc'} />;
  }
};
export default CommerceIcon;
