import Table from '@src/components/Table.tsx';
import { IReceiptAdmin } from '@src/util/interfaces.ts';
import Select, { OptionValue } from '@src/components/Select.tsx';
import {
  convertToKST,
  formatDateString,
  generateOptions,
  isEmpty,
} from '@src/util/functions.ts';
import {
  API_URLS_ADMIN,
  COLORS,
  RECEIPT_STATUS_MENU,
  receiptKeywordSearchFilterOption,
} from '@src/util/constants.ts';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Input from '@src/components/Input.tsx';
import Icon from '@src/components/Icon.tsx';
import searchIcon from '@src/assets/icons/search_black.svg';
import Modal from '@src/components/Modal.tsx';
import IconSVG from '@src/components/IconSVG.tsx';
import Snackbar from '@src/components/SnackBar.tsx';
import { useAdminStore } from '@src/util/adminStore.ts';
import { useState } from 'react';
import { useReceiptAdmin } from '@src/util/adminHooks.ts';
import { useQueryClient } from '@tanstack/react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import Button from '@src/components/Button.tsx';
import apiAdmin from '@src/util/apiAdmin.ts';
import { z } from 'zod';
import BranchFilterSelect from '@src/components/BranchFilterSelect.tsx';
import StaffFilterSelect from '@src/components/StaffFilterSelect.tsx';

const KeywordSearchForm = z.object({
  keyword: z.string(),
  keywordSearchFilter: z.object({
    label: z.string(),
    value: z.string(),
  }),
});

type TKeywordSearchForm = z.infer<typeof KeywordSearchForm>;
const ReceiptAdminContent = () => {
  const [admin] = useAdminStore((state) => [state.admin]);
  const [statusFilter, setStatusFilter] = useState<OptionValue | null>({
    label: '전체',
    value: '전체',
  });

  const [branchFilter, setBranchFilter] = useState<OptionValue | null>({
    label: '전체',
    value: '전체',
  });

  const [staffFilter, setStaffFilter] = useState<OptionValue | null>({
    label: '전체',
    value: '전체',
  });

  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = useState<string>('');
  const [receiptModalOpen, setReceiptModalOpen] = useState<boolean>(false);
  const [receiptModalUrl, setReceiptModalUrl] = useState<string>('');

  const [keyword, setKeyword] = useState<string>('');

  const [keywordSearchCategory, setKeywordSearchCategory] = useState<
    'name' | 'resident_number'
  >('name');

  const { data, fetchNextPage, hasNextPage } = useReceiptAdmin(
    statusFilter,
    branchFilter,
    keyword,
    keywordSearchCategory,
    staffFilter,
  );
  const queryClient = useQueryClient();

  const flattenedData = data?.pages.flatMap((page) => page.data);

  const { register, control, handleSubmit } = useForm<TKeywordSearchForm>({
    resolver: zodResolver(KeywordSearchForm),
    defaultValues: {
      keywordSearchFilter: { label: '사업자명', value: '사업자명' },
    },
  });

  const columnHelper = createColumnHelper<IReceiptAdmin>();

  const columns: Array<ColumnDef<IReceiptAdmin, string>> = [
    columnHelper.accessor('company.admin.branch', {
      id: 'company.admin.branch',
      cell: (info) => {
        return <i>{info.getValue()}</i>;
      },
      header: () => <div style={{ minWidth: '88px' }}>소속지점</div>,
    }),
    columnHelper.accessor('company.adminStaff.name', {
      id: 'company.adminStaff.name',
      cell: (info) => {
        return <i>{info.getValue()}</i>;
      },
      header: () => <div style={{ minWidth: '88px' }}>담당자</div>,
    }),
    columnHelper.accessor('company.name', {
      id: 'company.name',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '160px' }}>사업자명</div>,
    }),
    columnHelper.accessor('company.resident_number', {
      id: 'company.resident_number',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>사업자등록번호</div>,
    }),
    columnHelper.display({
      id: 'image',
      cell: (props) => {
        return (
          <div className={'flex-row-center'}>
            <Button
              text={'보기'}
              style={{
                fontSize: '12px',
                lineHeight: '16px',
                fontWeight: '600',
                width: '92px',
                padding: '4px 10px',
                border: '1px solid #3182F6',
                borderRadius: '4px',
              }}
              onClick={(e) => {
                e.stopPropagation();
                setReceiptModalUrl(props.row.original.url);
                setReceiptModalOpen(true);
              }}
            />
          </div>
        );
      },
      header: () => <div style={{ minWidth: '44px' }}>영수증</div>,
    }),
    columnHelper.accessor('created_at', {
      id: 'createdAt',
      cell: (info) => <i>{convertToKST(info.getValue())}</i>,
      header: () => <div style={{ minWidth: '72px' }}>업로드 날짜</div>,
    }),
    columnHelper.accessor('tx_dt', {
      id: 'tx_dt',
      cell: (info) => (
        <i>
          {info.getValue().trim() === ''
            ? '-'
            : formatDateString(info.getValue())}
        </i>
      ),
      header: () => <div style={{ minWidth: '72px' }}>영수증 날짜</div>,
    }),
    columnHelper.accessor('tot_amount', {
      id: 'tot_amount',
      cell: (info) => <i>{info.getValue().toLocaleString()}</i>,
      header: () => <div style={{ minWidth: '160px' }}>금액</div>,
    }),
    columnHelper.accessor('business_name', {
      id: 'business_name',
      cell: (info) => <i>{isEmpty(info.getValue()) ? '-' : info.getValue()}</i>,
      header: () => <div style={{ minWidth: '240px' }}>상호</div>,
    }),
    columnHelper.accessor('purpose', {
      id: 'purpose',
      cell: (info) => (
        <i>
          {info.getValue() === null || info.getValue().trim() === ''
            ? '-'
            : info.getValue()}
        </i>
      ),
      header: () => <div style={{ minWidth: '160px' }}>구분</div>,
    }),
    columnHelper.display({
      id: 'status',
      cell: (props) => {
        return (
          <Select
            options={generateOptions(Object.values(RECEIPT_STATUS_MENU), false)}
            value={{
              label: props.row.original.status,
              value: props.row.original.status,
            }}
            size={'sm'}
            name={'status'}
            onChange={(selectedValue) => {
              if (selectedValue) {
                apiAdmin
                  .patch(API_URLS_ADMIN.RECEIPT + `/${props.row.original.id}`, {
                    status: selectedValue.value,
                  })
                  .then((res) => {
                    console.log(res);
                    queryClient.invalidateQueries({
                      queryKey: ['receiptAdmin'],
                    });
                    setSnackBarContent(
                      `상태가 ${selectedValue.label}으로 변경되었습니다.`,
                    );
                    setSnackBarOpen(true);
                    setTimeout(() => {
                      setSnackBarOpen(false);
                    }, 3000);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }}
          />
        );
      },
      header: () => <div style={{ minWidth: '80px' }}>처리 여부</div>,
    }),
    columnHelper.accessor('reason', {
      id: 'reason',
      cell: (info) => (
        <i>{info.getValue().trim() === '' ? '-' : info.getValue()}</i>
      ),
      header: () => <div style={{ minWidth: '132px' }}>반려 사유</div>,
    }),
  ];

  const onSubmit: SubmitHandler<TKeywordSearchForm> = (data) => {
    const keywordSearchCategoryValue = data.keywordSearchFilter.value;
    if (keywordSearchCategoryValue === '사업자명') {
      setKeywordSearchCategory('name');
    } else if (keywordSearchCategoryValue === '사업자등록번호') {
      setKeywordSearchCategory('resident_number');
    }
    setKeyword(data.keyword);
  };

  return (
    <>
      <div
        className={'flex-column-flex-start-stretch'}
        style={{ gap: '24px', margin: '0 48px 48px 48px' }}
      >
        <p className={'text-md'}>영수증 등록 관리</p>

        <Table<IReceiptAdmin>
          data={flattenedData}
          columns={columns}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          onRowClick={(id) => `/admin/receipt/${id}`}
          filterContent={
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={'table-filter-container'}>
                <div style={{ minWidth: '60px' }}>
                  <Select
                    name={'filterStatus'}
                    options={generateOptions(
                      Object.values(RECEIPT_STATUS_MENU),
                      true,
                    )}
                    onChange={(selectedOption) => {
                      setStatusFilter(selectedOption);
                    }}
                    value={statusFilter}
                    size={'lg'}
                    label={'처리 여부'}
                  />
                </div>

                {admin?.branch === '본점' && (
                  <BranchFilterSelect
                    branchFilter={branchFilter}
                    setBranchFilter={setBranchFilter}
                  />
                )}
                <StaffFilterSelect
                  branchFilter={branchFilter}
                  staffFilter={staffFilter}
                  setStaffFilter={setStaffFilter}
                />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className={'table-filter-keyword-search-container'}>
                    <div style={{ width: '128px' }}>
                      <Controller
                        name="keywordSearchFilter"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              name="keywordSearchFilter"
                              options={receiptKeywordSearchFilterOption}
                              onChange={onChange}
                              value={value}
                              isFilterSearch={true}
                            />
                          );
                        }}
                      />
                    </div>

                    <Input
                      placeholder={'검색어 입력'}
                      register={register('keyword')}
                      style={{
                        height: 'auto',
                        borderRadius: '0',
                        borderLeft: 'none',
                        width: '414px',
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault(); // Prevent the default behavior of the enter key
                          handleSubmit(onSubmit)(); // Execute the submit handler
                        }
                      }}
                    />
                    <Icon
                      iconSrc={searchIcon}
                      style={{
                        padding: '10px',
                        border: `1px solid ${COLORS['gray2']}`,
                        borderLeft: 'none',
                        borderBottomRightRadius: '4px',
                        borderTopRightRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </div>
            </form>
          }
        />
      </div>
      <>
        <Modal
          isOpen={receiptModalOpen}
          onClose={() => {
            setReceiptModalOpen(false);
          }}
          style={{
            width: '80%',
            maxWidth: '384px',
            margin: '0',
            padding: '0',
          }}
          body={
            <div style={{ position: 'relative' }}>
              <IconSVG
                fill={'#FFFFFF'}
                size={'24'}
                containerStyle={{
                  padding: '8px',
                  boxSizing: 'border-box',
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  cursor: 'pointer',
                }}
                iconSVG={'close'}
                onClick={() => {
                  setReceiptModalUrl('');
                  setReceiptModalOpen(false);
                }}
              />
              <img
                src={receiptModalUrl}
                alt="img"
                style={{ width: '100%', objectFit: 'cover' }}
              />
            </div>
          }
        />
        <Snackbar message={snackBarContent} open={snackBarOpen} type={'info'} />
      </>
    </>
  );
};

export default ReceiptAdminContent;
