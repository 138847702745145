import '@src/scss/main.scss';
import React, { useEffect, useState } from 'react';
import Header from '@src/components/Header.tsx';
import Footer from '@src/components/Footer.tsx';
import Container from '@src/layouts/Container';
import { useUserStore } from '@src/util/store.ts';
import { MENU_LIST } from '@src/util/constants.ts';
import MenuItem from '@src/pages/menu/MenuItem';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import { refreshToken } from '@src/util/api.ts';

interface MenuProps {}

const Menu: React.FC<MenuProps> = () => {
  const user = useUserStore((state) => state.user);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    refreshToken();
  }, []);

  return (
    <Container
      header={<Header setOpen={setOpen} />}
      footer={<Footer current={'menu'} />}
      modal={<CompanyDrawer open={open} setOpen={setOpen} />}
    >
      <div className="menu-body-container">
        {MENU_LIST.map((menu, i) => {
          if (menu.title === '예상 세금') {
            let updatedSubMenu = JSON.parse(JSON.stringify(menu.subMenu));
            if (user?.selectedCompany?.tax_type === 'EXEMPT') {
              updatedSubMenu = updatedSubMenu.filter(
                (item: { title: string; url: string }) =>
                  item.title !== '예상 부가세',
              );
            }
            if (user?.selectedCompany?.type !== 'CORP') {
              updatedSubMenu = updatedSubMenu.map(
                (item: { title: string; url: string }) => {
                  if (item.title === '예상 법인세') {
                    return { ...item, title: '예상 소득세' };
                  } else {
                    return { ...item };
                  }
                },
              );
            }

            return (
              <MenuItem data={{ ...menu, subMenu: updatedSubMenu }} key={i} />
            );
          }
          return <MenuItem data={menu} key={i} />;
        })}
      </div>
    </Container>
  );
};

export default Menu;
