import React from 'react';
import Label from './Label.tsx';

interface InputLabelProps {
  text?: string;
  required?: boolean;
  children: React.ReactNode;
  description?: string;
  error?: React.ReactNode;
  containerStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
}

const InputLabel: React.FC<InputLabelProps> = ({
  text,
  required = false,
  children,
  description,
  error,
  containerStyle,
  labelStyle,
}) => {
  return (
    <div className="input-label-container" style={containerStyle}>
      <Label text={text} required={required} style={labelStyle} />
      {children}
      {description && (
        <div className="input-label-description">
          <p>{description}</p>
        </div>
      )}
      {error}
    </div>
  );
};

export default InputLabel;
