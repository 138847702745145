import React from 'react';
import ProgressBar from '@src/components/ProgressBar.tsx';
import { COLORS } from '@src/util/constants.ts';

interface TaskProgressBarProps {
  color: 'primary' | 'blue' | 'red' | 'purple' | 'green' | 'gray4';
  current: number;
  base: number;
  text: string;
}

const TaskProgressBar: React.FC<TaskProgressBarProps> = ({
  color,
  current,
  base,
  text,
}) => {
  return (
    <div className="task-progress-bar-container">
      <div
        className="flex-row-space-between-center"
        style={{ marginBottom: '5px' }}
      >
        <p className="text-regular-bold">{text}</p>
        <p className="text-regular-bold">
          <span style={{ color: COLORS[color] }}>{current}</span>건
        </p>
      </div>
      <ProgressBar
        current={current}
        base={base}
        color={color}
        textShow={false}
        containerStyle={{ height: '10px', backgroundColor: '#F5F5F5' }}
        progressBarStyle={{ height: '10px' }}
      />
    </div>
  );
};

export default TaskProgressBar;
