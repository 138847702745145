import {
  COLORS,
  FILE_ICONS,
  TASK_MENU_COLOR_CONVERT,
  TASK_MENU_CONVERT,
} from '@src/util/constants.ts';
import { formatFileSize, getFileExtension } from '@src/util/functions.ts';
import Icon from '@src/components/Icon.tsx';
import moment from 'moment';
import { useCommunicationTaskDetail } from '@src/util/hooks.ts';
import { useParams } from 'react-router-dom';

const TaskDetailContent = () => {
  const { id = '' } = useParams<{
    id: string;
  }>();
  const {
    data: { status, title, description, attachments, created_at, updated_at },
  } = useCommunicationTaskDetail(id);

  return (
    <div
      className="task-detail-container"
      style={{
        maxHeight: 'calc(100vh - 360px)',
        overflowY: 'auto',
        paddingBottom: '80px',
      }}
    >
      <div className={'task-detail-row'}>
        <p
          className={'text-regular-bold'}
          style={{ width: '68px', boxSizing: 'border-box' }}
        >
          상태
        </p>
        <p
          className={'text-regular-bold'}
          style={{ color: TASK_MENU_COLOR_CONVERT[status] }}
        >
          {TASK_MENU_CONVERT[status]}
        </p>
      </div>
      <div className={'task-detail-row'}>
        <p
          className={'text-regular-bold'}
          style={{ width: '68px', boxSizing: 'border-box' }}
        >
          제목
        </p>
        <p className={'text-regular'} style={{ color: COLORS['gray6'] }}>
          {title}
        </p>
      </div>
      <div className={'task-detail-row'}>
        <p
          className={'text-regular-bold'}
          style={{ width: '68px', boxSizing: 'border-box' }}
        >
          내용
        </p>
        <p
          className={'text-regular'}
          style={{ color: COLORS['gray6'], whiteSpace: 'pre-line' }}
        >
          {description}
        </p>
      </div>
      <div className={'task-detail-row'}>
        <p
          className={'text-regular-bold'}
          style={{ width: '68px', boxSizing: 'border-box' }}
        >
          첨부파일
        </p>
        <div className={'flex-column-flex-start-flex-start'}>
          {attachments.map((item, i) => {
            const type = getFileExtension(item.filename);
            return (
              <div className={'task-detail-icon-row'} key={i}>
                <Icon
                  iconSrc={FILE_ICONS[type as keyof typeof FILE_ICONS]}
                  style={{ width: '20px', height: '20px', cursor: 'text' }}
                  imgStyle={{ width: '20px', height: '20px' }}
                />
                <p className={'text-regular'}>
                  {item.filename} ({formatFileSize(item.size)})
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={'task-detail-row'}>
        <p
          className={'text-regular-bold'}
          style={{ width: '68px', boxSizing: 'border-box' }}
        >
          요청일
        </p>
        <p className={'text-regular'}>
          {moment(created_at).format('YYYY-MM-DD')}
        </p>
      </div>
      <div className={'task-detail-row'}>
        <p
          className={'text-regular-bold'}
          style={{ width: '68px', boxSizing: 'border-box' }}
        >
          수정일
        </p>
        <p className={'text-regular'}>
          {moment(updated_at).format('YYYY-MM-DD')}
        </p>
      </div>
    </div>
    // </div>
  );
};

export default TaskDetailContent;
