import React from 'react';
import HeaderTabs from '@src/components/HeaderTabs.tsx';
import {
  REPORT_CATEGORY,
  REPORT_CATEGORY_LABOR_MENU_LIST,
  REPORT_CATEGORY_PURCHASE_MENU_LIST,
  REPORT_CATEGORY_SALES_MENU_LIST,
} from '@src/util/constants.ts';
import ListItem from '@src/components/ListItem.tsx';
import { IReportDashboard } from '@src/util/interfaces.ts';
import {
  deliveryAndCommerceMapper,
  formatDateString,
  getLastSegment,
} from '@src/util/functions.ts';
import CardIcon from '@src/components/CardIcon.tsx';
import CommerceIcon from '@src/components/CommerceIcon.tsx';
import DeliveryIcon from '@src/components/DeliveryIcon.tsx';
import { useUserStore } from '@src/util/store.ts';

interface ReportAdditionalDetailProps {
  category: Exclude<REPORT_CATEGORY, REPORT_CATEGORY.TOTAL>;
  type: string;
  setType: (value: string) => void;
  data: IReportDashboard;
}

const ReportAdditionalDetail: React.FC<ReportAdditionalDetailProps> = ({
  category,
  type,
  setType,
  data,
}) => {
  const [user] = useUserStore((state) => [state.user]);

  const renderItemList = (data: IReportDashboard) => {
    if (category === REPORT_CATEGORY.LABOR) {
      if (
        type === 'employee' ||
        type === 'etc' ||
        type === 'severance' ||
        type === 'freelancer'
      ) {
        const itemList = data.data.labor?.[type].list ?? [];
        return itemList.map((listItem, i) => {
          return (
            <ListItem
              name={formatDateString(listItem.date)}
              value={listItem.amount}
              divider={true}
              key={i}
            />
          );
        });
      }
    } else if (category === REPORT_CATEGORY.PURCHASE) {
      switch (type) {
        case 'statement':
          return data.data.purchase.statement.list.map((listItem, i) => (
            <ListItem
              name={listItem.sup_biz_name}
              value={
                user?.selectedCompany?.tax_type !== 'EXEMPT'
                  ? listItem.sup_amount
                  : listItem.tot_amount
              }
              divider={true}
              key={i}
            />
          ));
        case 'card':
          return data.data.purchase.card.list.map((listItem, i) => (
            <ListItem
              name={`${listItem.card_company} ${getLastSegment(
                listItem.card_number,
              )}`}
              value={
                user?.selectedCompany?.tax_type !== 'EXEMPT'
                  ? listItem.sup_amount
                  : listItem.tot_amount
              }
              divider={true}
              key={i}
              nameIcon={<CardIcon name={listItem.card_company} />}
            />
          ));
        case 'cash':
          return data.data.purchase.cash.list.map((listItem, i) => (
            <ListItem
              name={formatDateString(listItem.tx_dt)}
              value={
                user?.selectedCompany?.tax_type !== 'EXEMPT'
                  ? listItem.sup_amount
                  : listItem.tot_amount
              }
              divider={true}
              key={i}
            />
          ));
        case 'receipt':
          return data.data.purchase.receipt.list.map((listItem, i) => (
            <ListItem
              name={formatDateString(listItem.tx_dt)}
              value={
                user?.selectedCompany?.tax_type !== 'EXEMPT'
                  ? listItem.sup_amount
                  : listItem.tot_amount
              }
              divider={true}
              key={i}
            />
          ));
      }
    } else if (category === REPORT_CATEGORY.SALES) {
      switch (type) {
        case 'statement':
          return data.data.sales.statement.list.map((listItem, i) => (
            <ListItem
              name={listItem.sup_biz_name}
              value={
                user?.selectedCompany?.tax_type !== 'EXEMPT'
                  ? listItem.sup_amount
                  : listItem.tot_amount
              }
              divider={true}
              key={i}
            />
          ));
        case 'card':
          return data.data.sales.card.list.map((listItem, i) => (
            <ListItem
              name={listItem.card_company}
              value={
                user?.selectedCompany?.tax_type !== 'EXEMPT'
                  ? listItem.sup_amount
                  : listItem.tot_amount
              }
              divider={true}
              key={i}
              nameIcon={<CardIcon name={listItem.card_company} />}
            />
          ));
        case 'cash':
          return data.data.sales.cash.list.map((listItem, i) => (
            <ListItem
              name={formatDateString(listItem.tx_dt)}
              value={
                user?.selectedCompany?.tax_type !== 'EXEMPT'
                  ? listItem.sup_amount
                  : listItem.tot_amount
              }
              divider={true}
              key={i}
            />
          ));
        case 'commerceAndDelivery':
          return (
            <>
              {data.data.sales.commerce.list.map((listItem, i) => {
                return (
                  <ListItem
                    name={deliveryAndCommerceMapper(listItem.commerce_company)}
                    value={
                      user?.selectedCompany?.tax_type !== 'EXEMPT'
                        ? listItem.sup_amount
                        : listItem.tot_amount
                    }
                    divider={true}
                    key={i}
                    nameIcon={<CommerceIcon name={listItem.commerce_company} />}
                  />
                );
              })}
              {data.data.sales.delivery.list.map((listItem, i) => {
                return (
                  <ListItem
                    name={deliveryAndCommerceMapper(listItem.delivery_company)}
                    value={
                      user?.selectedCompany?.tax_type !== 'EXEMPT'
                        ? listItem.sup_amount
                        : listItem.tot_amount
                    }
                    divider={true}
                    key={i}
                    nameIcon={<DeliveryIcon name={listItem.delivery_company} />}
                  />
                );
              })}
            </>
          );
      }
    }
  };

  return (
    <div
      style={{
        paddingLeft: '16px',
        paddingRight: '16px',
        maxWidth: '786px',
        boxSizing: 'border-box',
        width: '100%',
      }}
    >
      <HeaderTabs
        menus={
          category === REPORT_CATEGORY.SALES
            ? REPORT_CATEGORY_SALES_MENU_LIST
            : category === REPORT_CATEGORY.PURCHASE
              ? REPORT_CATEGORY_PURCHASE_MENU_LIST
              : REPORT_CATEGORY_LABOR_MENU_LIST
        }
        activeHeaderTab={type}
        setActiveHeaderTab={(value) => setType(value)}
        headerStyle={{ padding: 0, marginTop: '24px' }}
      />
      {renderItemList(data)}
    </div>
  );
};

export default ReportAdditionalDetail;
