import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import RadioButton from '@src/components/RadioButton.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import InputLabel from '@src/components/InputLabel.tsx';
import Select from '@src/components/Select.tsx';
import { useMutation } from '@tanstack/react-query';
import { IApiError, IApiPOSTSuccess } from '@src/util/interfaces.ts';
import api from '@src/util/api.ts';
import {
  API_URL,
  cvaDcumSbmsOrgnClCd,
  cvaDcumSbmsOrgnClCdOptions,
  cvaDcumUseUsgCd,
  cvaDcumUseUsgCdOptions,
} from '@src/util/constants.ts';
import Modal from '@src/components/Modal.tsx';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@src/util/store.ts';
import { objectToQueryString } from '@src/util/functions.ts';
import CertificatesDescription from '@src/pages/certificates/CertificatesDescription';
import Button from '@src/components/Button.tsx';
import BottomWrapper from '@src/components/BottomWrapper.tsx';

interface CertificateFormContent0Props {}

/**
 *  사업자등록증명
 *    발급유형: englCvaAplnYn (한글증명 - N, 영문증명 - Y)
 *    사용용도: cvaDcumUseUsgCd
 *    제출처: cvaDcumSbmsOrgnClCd
 *    주민등록번호 공개여부: resnoOpYn (비공개 - N, 공개 - Y)
 */

const CertificateForm0 = z.object({
  englCvaAplnYn: z.enum(['N', 'Y']),
  cvaDcumUseUsgCd: z.object({
    value: z.enum(cvaDcumUseUsgCd),
    label: z.string(),
  }),
  cvaDcumSbmsOrgnClCd: z.object({
    value: z.enum(cvaDcumSbmsOrgnClCd),
    label: z.string(),
  }),
  resnoOpYn: z.enum(['Y', 'N']),
});

const CertificateForm0Server = CertificateForm0.omit({
  cvaDcumUseUsgCd: true,
  cvaDcumSbmsOrgnClCd: true,
}).extend({
  company_id: z.number(),
  cvaDcumUseUsgCd: z.string(),
  cvaDcumSbmsOrgnClCd: z.string(),
});

type TCertificateForm0 = z.infer<typeof CertificateForm0>;
type TCertificateForm0Server = z.infer<typeof CertificateForm0Server>;

const CertificateFormContent0: React.FC<CertificateFormContent0Props> = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);

  const { control, handleSubmit } = useForm<TCertificateForm0>({
    resolver: zodResolver(CertificateForm0),
    mode: 'onChange',
    defaultValues: {
      englCvaAplnYn: 'N',
      cvaDcumUseUsgCd: { label: '기타', value: '기타' },
      cvaDcumSbmsOrgnClCd: { label: '기타', value: '기타' },
      resnoOpYn: 'N',
    },
  });

  const { mutate, isSuccess, error, isPending } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TCertificateForm0Server
  >({
    mutationFn: (formData) => {
      const queryParams = objectToQueryString(formData);

      return api.post(
        API_URL + `/v1/communication/document?${queryParams}`,
        formData,
      );
    },
  });

  const onSubmit: SubmitHandler<TCertificateForm0> = (data) => {
    const formData = {
      company_id: user?.selectedCompany?.id ?? 0,
      type: '사업자등록증명',
      englCvaAplnYn: data.englCvaAplnYn,
      cvaDcumSbmsOrgnClCd: data.cvaDcumSbmsOrgnClCd.value,
      cvaDcumUseUsgCd: data.cvaDcumUseUsgCd.value,
      resnoOpYn: data.resnoOpYn,
    };

    mutate(formData, {
      onSuccess: (response) => {
        setModalOpen(true);
        console.log(response);
      },
      onError: (error) => {
        setModalOpen(true);
        console.log(error);
      },
    });
  };

  return (
    <>
      <form
        className="certificate-form-content"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CertificatesDescription
          title={'국세청(홈택스)에서 발급하는 사업자등록증명입니다.'}
        />
        <InputLabel text={'발급유형'}>
          <Controller
            name={'englCvaAplnYn'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioButton
                name={'englCvaAplnYn'}
                options={[
                  { label: '한글증명', value: 'N' },
                  { label: '영문증명', value: 'Y' },
                ]}
                defaultValue={value}
                onChange={onChange}
              />
            )}
          />
        </InputLabel>
        <InputLabel text="사용용도">
          <Controller
            name="cvaDcumUseUsgCd"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  name="cvaDcumUseUsgCd"
                  options={cvaDcumUseUsgCdOptions}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </InputLabel>
        <InputLabel text="제출처">
          <Controller
            name="cvaDcumSbmsOrgnClCd"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  name="cvaDcumSbmsOrgnClCd"
                  options={cvaDcumSbmsOrgnClCdOptions}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </InputLabel>
        <InputLabel
          text={'주민등록번호 공개여부'}
          description={'비공개시 출력 예 : 881111-*******'}
        >
          <Controller
            name={'resnoOpYn'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioButton
                name={'resnoOpYn'}
                options={[
                  { label: '비공개', value: 'N' },
                  { label: '공개', value: 'Y' },
                ]}
                defaultValue={value}
                onChange={onChange}
              />
            )}
          />
        </InputLabel>

        <BottomWrapper>
          <Button text={'신청하기'} isLoading={isPending} type={'submit'} />
        </BottomWrapper>
      </form>
      <Modal
        isOpen={isModalOpen}
        onOk={() => {
          setModalOpen(false);
          if (isSuccess) {
            navigate('/certificates/issued', { replace: true });
          } else {
            navigate(-1);
          }
        }}
        title={isSuccess ? '증명서 발급 성공' : '증명서 발급 실패'}
        content={
          isSuccess
            ? '증명서 발급이 완료되었습니다. \n 증명서 발급 내역 메뉴에서 다운로드 가능합니다.'
            : error?.response.data.message ?? ''
        }
        okButton={'확인'}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};

export default CertificateFormContent0;
