import React from 'react';
import kakaoIcon from '../assets/icons/login/kakao.svg';

interface KakaoLoginButtonProps {
  label: string;
  redirect_url: string;
}

const KakaoButton: React.FC<KakaoLoginButtonProps> = ({
  label,
  redirect_url,
}) => {
  return (
    <button
      className="button-kakao"
      onClick={() => {
        window.Kakao.Auth.authorize({
          redirectUri: redirect_url,
          // serviceTerms: "service_20240603"
        });
      }}
    >
      <div className="kakao-icon">
        <img src={kakaoIcon} alt="카카오" />
      </div>
      {label}
    </button>
  );
};

export default KakaoButton;
