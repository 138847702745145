import { FC } from 'react';

interface ReportIconProps {
  fill: string;
  size: string;
}

const ReportIcon: FC<ReportIconProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon / report">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V8H18C18.5523 8 19 8.44772 19 9V19H21C21.5523 19 22 19.4477 22 20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20C2 19.4477 2.44772 19 3 19H5V13C5 12.4477 5.44772 12 6 12H9V4ZM11 19H13V5H11V19ZM15 19H17V10H15V19ZM9 19V14H7V19H9Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default ReportIcon;
