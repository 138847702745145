import React, { useEffect, useState } from 'react';

type Option = {
  label: string;
  value: string;
};

type RadioButtonProps = {
  name: string;
  options: Option[];
  disabled?: boolean;
  defaultValue: string;
  onChange: (value: string) => void;
  containerStyle?: React.CSSProperties;
  labelDivContainer?: React.CSSProperties;
};

const RadioButton: React.FC<RadioButtonProps> = ({
  name,
  options,
  disabled = false,
  defaultValue,
  onChange,
  containerStyle,
  labelDivContainer,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(defaultValue);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (!disabled) {
      setSelectedValue(value);
      onChange(value);
    }
  };

  return (
    <div className="radio-button-container" id={name} style={containerStyle}>
      {options.map((option, i) => {
        const uniqueId = `${name}-${option.value}-${i}`;
        return (
          <div key={i} style={labelDivContainer}>
            <input
              type="radio"
              name={name}
              id={uniqueId}
              value={option.value}
              checked={selectedValue === option.value}
              onChange={handleChange}
              disabled={disabled}
            />
            <label
              htmlFor={uniqueId}
              className={option.value === selectedValue ? 'checked' : ''}
            >
              {option.label}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default RadioButton;
