import { FC } from 'react';

interface TaxIconProps {
  fill: string;
  size: string;
}

const TaxIcon: FC<TaxIconProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon / tax">
        <path
          id="Vector"
          d="M5 16V11C5 10.7167 5.09583 10.4792 5.2875 10.2875C5.47917 10.0958 5.71667 10 6 10C6.28333 10 6.52083 10.0958 6.7125 10.2875C6.90417 10.4792 7 10.7167 7 11V16C7 16.2833 6.90417 16.5208 6.7125 16.7125C6.52083 16.9042 6.28333 17 6 17C5.71667 17 5.47917 16.9042 5.2875 16.7125C5.09583 16.5208 5 16.2833 5 16ZM11 16V11C11 10.7167 11.0958 10.4792 11.2875 10.2875C11.4792 10.0958 11.7167 10 12 10C12.2833 10 12.5208 10.0958 12.7125 10.2875C12.9042 10.4792 13 10.7167 13 11V16C13 16.2833 12.9042 16.5208 12.7125 16.7125C12.5208 16.9042 12.2833 17 12 17C11.7167 17 11.4792 16.9042 11.2875 16.7125C11.0958 16.5208 11 16.2833 11 16ZM3 21C2.71667 21 2.47917 20.9042 2.2875 20.7125C2.09583 20.5208 2 20.2833 2 20C2 19.7167 2.09583 19.4792 2.2875 19.2875C2.47917 19.0958 2.71667 19 3 19H21C21.2833 19 21.5208 19.0958 21.7125 19.2875C21.9042 19.4792 22 19.7167 22 20C22 20.2833 21.9042 20.5208 21.7125 20.7125C21.5208 20.9042 21.2833 21 21 21H3ZM17 16V11C17 10.7167 17.0958 10.4792 17.2875 10.2875C17.4792 10.0958 17.7167 10 18 10C18.2833 10 18.5208 10.0958 18.7125 10.2875C18.9042 10.4792 19 10.7167 19 11V16C19 16.2833 18.9042 16.5208 18.7125 16.7125C18.5208 16.9042 18.2833 17 18 17C17.7167 17 17.4792 16.9042 17.2875 16.7125C17.0958 16.5208 17 16.2833 17 16ZM21 8H2.9C2.65 8 2.4375 7.9125 2.2625 7.7375C2.0875 7.5625 2 7.35 2 7.1V6.55C2 6.36667 2.04583 6.20833 2.1375 6.075C2.22917 5.94167 2.35 5.83333 2.5 5.75L11.1 1.45C11.3833 1.31667 11.6833 1.25 12 1.25C12.3167 1.25 12.6167 1.31667 12.9 1.45L21.45 5.725C21.6333 5.80833 21.7708 5.93333 21.8625 6.1C21.9542 6.26667 22 6.44167 22 6.625V7C22 7.28333 21.9042 7.52083 21.7125 7.7125C21.5208 7.90417 21.2833 8 21 8ZM6.45 6H17.55L12 3.25L6.45 6Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default TaxIcon;
