import AdminSideBar from '@src/components/AdminSideBar.tsx';
import Container from '@src/layouts/Container.tsx';
import AdminSyncContent from '@src/pages/admin/sync/AdminSyncContent.tsx';

const AdminSync = () => {
  return (
    <Container sideBar={<AdminSideBar />}>
      <AdminSyncContent />
    </Container>
  );
};

export default AdminSync;
