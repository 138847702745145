import React, { useState } from 'react';
import InputLabel from '@src/components/InputLabel.tsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import RadioButton from '@src/components/RadioButton.tsx';
import Select from '@src/components/Select.tsx';
import Modal from '@src/components/Modal.tsx';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@src/util/store.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { IApiError, IApiPostDocumentSuccess } from '@src/util/interfaces.ts';
import {
  generateLastYearsOptions,
  generateTxnrmStrtYm,
  objectToQueryString,
} from '@src/util/functions.ts';
import api from '@src/util/api.ts';
import {
  API_URL,
  cvaDcumSbmsOrgnClCd,
  cvaDcumSbmsOrgnClCdOptions,
  cvaDcumUseUsgCd,
  cvaDcumUseUsgCdOptions,
} from '@src/util/constants.ts';
import { z } from 'zod';
import moment from 'moment';
import CertificatesDescription from '@src/pages/certificates/CertificatesDescription';
import Button from '@src/components/Button.tsx';
import { useIsMobile } from '@src/util/hooks.ts';
import BottomWrapper from '@src/components/BottomWrapper.tsx';

interface CertificateFormContent6Props {}

/**
 *  소득금액증명
 *    발급유형: englCvaAplnYn (한글증명 - N, 영문증명 - Y)
 *    과세기간시작*: txnrmStrtYm(YYYY) -- 작년부터 20년
 *    과세기간끝*: txnrmEndYm(YYYY) -- 작년부터 20년
 *    소득발생처 공개여부: incOcplOpYn (비공개 - N, 공개 - Y)
 *    사용용도: cvaDcumUseUsgCd
 *    제출처: cvaDcumSbmsOrgnClCd
 *    주민등록번호 공개여부: resnoOpYn (비공개 - N, 공개 - Y)
 */
const CertificateForm6 = z.object({
  englCvaAplnYn: z.enum(['N', 'Y']),
  taxTermStartYear: z.object({
    label: z.string(),
    value: z.enum(generateTxnrmStrtYm(moment().year(), 20)),
  }),
  taxTermEndYear: z.object({
    label: z.string(),
    value: z.enum(generateTxnrmStrtYm(moment().year(), 20)),
  }),
  incOcplOpYn: z.enum(['Y', 'N']),
  cvaDcumUseUsgCd: z.object({
    value: z.enum(cvaDcumUseUsgCd),
    label: z.string(),
  }),
  cvaDcumSbmsOrgnClCd: z.object({
    value: z.enum(cvaDcumSbmsOrgnClCd),
    label: z.string(),
  }),
  resnoOpYn: z.enum(['Y', 'N']),
});

const CertificateForm6Server = CertificateForm6.omit({
  cvaDcumUseUsgCd: true,
  cvaDcumSbmsOrgnClCd: true,
  taxTermStartYear: true,
  taxTermEndYear: true,
}).extend({
  company_id: z.number(),
  cvaDcumUseUsgCd: z.string(),
  cvaDcumSbmsOrgnClCd: z.string(),
  txnrmStrtYm: z.string(),
  txnrmEndYm: z.string(),
});

type TCertificateForm6 = z.infer<typeof CertificateForm6>;
type TCertificateForm6Server = z.infer<typeof CertificateForm6Server>;
const CertificateFormContent6: React.FC<CertificateFormContent6Props> = () => {
  const [modalContent, setModalContent] = useState({
    title: '',
    content: '',
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const isMobile = useIsMobile();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TCertificateForm6>({
    resolver: zodResolver(CertificateForm6),
    mode: 'onChange',
    defaultValues: {
      englCvaAplnYn: 'N',
      incOcplOpYn: 'N',
      cvaDcumUseUsgCd: { label: '기타', value: '기타' },
      cvaDcumSbmsOrgnClCd: { label: '기타', value: '기타' },
      resnoOpYn: 'N',
    },
  });

  const { mutate, isSuccess, error, isPending } = useMutation<
    IApiPostDocumentSuccess,
    IApiError,
    TCertificateForm6Server
  >({
    mutationFn: (formData) => {
      const queryParams = objectToQueryString(formData);

      return api.post(
        API_URL + `/v1/communication/document?${queryParams}`,
        formData,
      );
    },
  });

  const onSubmit: SubmitHandler<TCertificateForm6> = (data) => {
    const formData = {
      company_id: user?.selectedCompany?.id ?? 0,
      type: '소득금액증명',
      englCvaAplnYn: data.englCvaAplnYn,
      cvaDcumSbmsOrgnClCd: data.cvaDcumSbmsOrgnClCd.value,
      cvaDcumUseUsgCd: data.cvaDcumUseUsgCd.value,
      txnrmStrtYm: data.taxTermStartYear.value,
      txnrmEndYm: data.taxTermEndYear.value,
      incOcplOpYn: data.incOcplOpYn,
      resnoOpYn: data.resnoOpYn,
    };
    mutate(formData, {
      onSuccess: (response) => {
        setModalContent(response.data);
        setModalOpen(true);
        console.log(response.data);
      },
      onError: (error) => {
        setModalOpen(true);
        console.log(error);
      },
    });
  };

  return (
    <>
      <form
        className="certificate-form-content"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CertificatesDescription
          title={
            '소득금액증명은 법인사업자는 발급이 불가합니다.\n근로소득자용, 연말정산 사업소득자용 소득금액증명은 해당 과세연도 다음해 5월 1일 이후 발급 가능합니다.\n종합소득세 신고자용 소득금액증명은 해당 과세연도 다음해 7월 1일 이후 발급 가능합니다.'
          }
          example={
            '근로소득자, 연말정산 사업소득자용 : 2023년 귀속 증명발급은 2024.5.1. 이후 발급 가능\n종합소득세 신고자용 : 2023년 귀속 증명발급은 2024.7.1. 이후 발급 가능 (성실은 2024.8.1. 이후)'
          }
        />
        <InputLabel text={'발급유형'}>
          <Controller
            name={'englCvaAplnYn'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioButton
                name={'englCvaAplnYn'}
                options={[
                  { label: '한글증명', value: 'N' },
                  { label: '영문증명', value: 'Y' },
                ]}
                defaultValue={value}
                onChange={onChange}
              />
            )}
          />
        </InputLabel>
        <InputLabel
          text={'과세기간'}
          required={true}
          error={
            (errors.taxTermStartYear || errors.taxTermEndYear) && (
              <div className="error-message">과세기간을 입력해 주세요.</div>
            )
          }
        >
          <div className="flex-row" style={{ gap: '8px' }}>
            <div style={{ minWidth: !isMobile ? '120px' : '' }}>
              <Controller
                name="taxTermStartYear"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      name="taxTermStartYear"
                      options={generateLastYearsOptions(
                        moment().subtract(1, 'years').year(),
                        20,
                      )}
                      onChange={onChange}
                      value={value}
                    />
                  );
                }}
              />
            </div>
            <p className="text-regular" style={{ lineHeight: '22px' }}>
              년
            </p>
            <p className="text-regular" style={{ lineHeight: '22px' }}>
              ~
            </p>
            <div style={{ minWidth: !isMobile ? '120px' : '' }}>
              <Controller
                name="taxTermEndYear"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      name="taxTermEndYear"
                      options={generateLastYearsOptions(
                        moment().subtract(1, 'years').year(),
                        20,
                      )}
                      onChange={onChange}
                      value={value}
                    />
                  );
                }}
              />
            </div>
            <p className="text-regular" style={{ lineHeight: '22px' }}>
              년
            </p>
          </div>
        </InputLabel>
        <InputLabel text={'소득발생처 공개여부'}>
          <Controller
            name={'incOcplOpYn'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioButton
                name={'incOcplOpYn'}
                options={[
                  { label: '비공개', value: 'N' },
                  { label: '공개', value: 'Y' },
                ]}
                defaultValue={value}
                onChange={onChange}
              />
            )}
          />
        </InputLabel>
        <InputLabel text="사용용도">
          <Controller
            name="cvaDcumUseUsgCd"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  name="cvaDcumUseUsgCd"
                  options={cvaDcumUseUsgCdOptions}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </InputLabel>
        <InputLabel text="제출처">
          <Controller
            name="cvaDcumSbmsOrgnClCd"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  name="cvaDcumSbmsOrgnClCd"
                  options={cvaDcumSbmsOrgnClCdOptions}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </InputLabel>

        <InputLabel
          text={'주민등록번호 공개여부'}
          description={'비공개시 출력 예 : 881111-*******'}
        >
          <Controller
            name={'resnoOpYn'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioButton
                name={'resnoOpYn'}
                options={[
                  { label: '비공개', value: 'N' },
                  { label: '공개', value: 'Y' },
                ]}
                defaultValue={value}
                onChange={onChange}
              />
            )}
          />
        </InputLabel>

        <BottomWrapper>
          <Button text={'신청하기'} isLoading={isPending} type={'submit'} />
        </BottomWrapper>
      </form>
      <Modal
        isOpen={isModalOpen}
        onOk={() => {
          setModalOpen(false);
          if (isSuccess) {
            navigate('/certificates/issued', { replace: true });
          } else {
            navigate(-1);
          }
        }}
        title={isSuccess ? modalContent.title : '증명서 발급 실패'}
        content={
          isSuccess ? modalContent.content : error?.response.data.message ?? ''
        }
        okButton={'확인'}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};
export default CertificateFormContent6;
