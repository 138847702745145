import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { COLORS, REPORT_CATEGORY } from '@src/util/constants.ts';

interface ReportBarChartProps {
  labels: string[];
  dataSet: number[];
  type: string;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const ReportBarChart: React.FC<ReportBarChartProps> = ({
  labels,
  dataSet,
  type,
}) => {
  const backgroundColor: string[] = [];

  dataSet.forEach((data) => {
    if (data === 0) {
      backgroundColor.push(COLORS['gray1']);
    } else {
      if (type === REPORT_CATEGORY.SALES) {
        backgroundColor.push(COLORS['primary']);
      } else if (type === REPORT_CATEGORY.PURCHASE) {
        backgroundColor.push(COLORS['red']);
      } else if (type === REPORT_CATEGORY.TOTAL) {
        if (data < 0) {
          backgroundColor.push(COLORS['red']);
        } else {
          backgroundColor.push(COLORS['primary']);
        }
      }
    }
  });

  let max = 0;
  let min = 0;
  let stepSize = 0;
  if (Math.min(...dataSet) < 0) {
    const maxAbsNum = Math.max(...dataSet.map((a) => Math.abs(a)));
    max = maxAbsNum / 0.8;
    min = max * -1;
    stepSize = max / 2;
  } else if (dataSet.every((val) => val === 0)) {
    min = 0;
    max = 100;
    stepSize = 25;
  } else {
    const maxNum = Math.max(...dataSet);
    max = maxNum / 0.8;
    min = 0;
    stepSize = max / 4;
  }

  const data = {
    labels,
    datasets: [
      {
        data: dataSet,
        backgroundColor: backgroundColor ?? [],
        borderRadius: 100,
        barThickness: 12,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          font: {
            size: 8,
          },
          color: COLORS['gray4'],
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          drawTicks: false,
          offset: false,
          color: COLORS['gray1'],
        },
        ticks: {
          display: false,
          stepSize: stepSize,
        },
        border: {
          display: false,
        },
        max: max,
        min: min,
      },
    },
  };

  return <Bar options={options} data={data} />;
};

export default ReportBarChart;
